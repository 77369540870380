import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import MapFill from '@iconify/icons-eva/map-fill';
import clockOutline from '@iconify/icons-eva/clock-outline';
// import polygon from '@iconify/icons-ic/poly';
// import lockFill from '@iconify/icons-eva/lock-fill';
// import personAddFill from '@iconify/icons-eva/person-add-fill';
// import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon(peopleFill)
  // },
  {
    title: 'device',
    path: '/dashboard/device',
    icon: getIcon(fileTextFill)
  },
  {
    title: 'map',
    path: '/dashboard/map',
    icon: getIcon(MapFill)
  },
  {
    title: 'Log',
    path: '/log',
    icon: getIcon(clockOutline)
  },
];

export default sidebarConfig;
