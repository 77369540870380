import styled from '@emotion/styled';
import { Autocomplete, Box, Collapse, Container, Divider, Grid, List, ListItemButton, Button, ListItemText, TextField, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Api } from '../../components/api';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import EditDevice from './EditDevice 1';


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto'
}));

export default function EditDeviceMain({ account, listModel = [], dltVender, listVehicleType = [], listProvince = [], listVehicleRegisterType = [] }) {

    const { id } = useParams();
    // const location = useLocation();

    const [state, setState] = useState(null)
    const [masterGroup, setMasterGroup] = useState([])

    useEffect(() => {
        const { c_coname } = account;
        Api.get(`/Tracking/DevicesInfo?device=${id}`)
            .then(res => {
                setState(res.data);
                // console.log('res.data', res.data)
            }
            ).catch(e => {
                console.log('error', e)
            })
        Api.get(`Tracking/Group?cname=${account.c_coname}`)
            .then(res => {
                var list = res.data;
                setMasterGroup(list)
            }
            ).catch(e => {
                console.log('error', e)
            })

    }, [account, id])

    // const isgroups = state !== null ? masterGroup.find((e, index) => e.group_id === state.group_id) === undefined ? null : masterGroup.find((e, index) => e.group_id === state.group_id) : null
    return (
        <Page>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Device</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    {state !== null && (
                        <EditDevice listModel={listModel} dltVender={dltVender} state={state} masterGroup={masterGroup} listVehicleType={listVehicleType} listProvince={listProvince} listVehicleRegisterType={listVehicleRegisterType}
                            // isgroups={isgroups}
                        />
                    )}
                </MainStyle>
            </Container>
        </Page>
    )
}
