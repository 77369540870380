import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, DialogContent, Tooltip, DialogActions, IconButton, Button, Grid, TextField, Box, MenuItem } from '@mui/material'
import PhonelinkSetupOutlinedIcon from '@mui/icons-material/PhonelinkSetupOutlined';
import { Api } from '../../components/api';
import { useSnackbar } from 'notistack';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fDateTo } from '../../utils/formatTime';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';

export default function EditDevice({ data, handleGetData, account }) {
    const { c_coname, contype, cogcode, cocode } = account;

    const [open, setOpen] = useState(false)
    const [cnames, setcnames] = useState([])
    const [groups, setGroups] = useState([])
    const [modelGps, setModelGps] = useState([])
    const [provinces, setProvinces] = useState([])
    const [vehiclesType, setVehiclesType] = useState([])
    const [vehicleRegisterType, setVehicleRegisterType] = useState([])


    const [dataDlt, setDataDlt] = useState(null)


    const getDataMaster = () => {
        Api.get(`/v0/gps/province?cocode=${cocode}`)
            .then(res => {
                setProvinces(res.data)
            }
            )
        Api.get(`/v0/gps/vehicle_types?cocode=${cocode}`)
            .then(res => {
                setVehiclesType(res.data)

            }
            )
        Api.get(`/v0/gps/vehicle_register?cocode=${cocode}`)
            .then(res => {
                setVehicleRegisterType(res.data)

            }
            )
        Api.get(`v0/gps/gpsmodels?cocode=${cocode}`)
            .then(res => {
                setModelGps(res.data)
            })
    }


    const onGetCompany = () => {
        Api.get(`/v0/if/Cnames`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setcnames(res.data)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }
    const onGetGroup = () => {
        Api.get(`/Tracking/Group?cname=${c_coname}`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setGroups(res.data)
            })
    }
    const onGetDltCur = () => {
        Api.get(`/gps/get_dlt_cur?device=${data.device}`)
            .then((res) => {
                setDataDlt(res.data)
            })
    }
    const [completedRequests, setCompletedRequests] = useState(0);
    const totalRequests = 3;
    const onClickOpen = () => {
        // Promise.all(
        //     [
        //         onGetCompany(),
        //         onGetGroup(),
        //         getDataMaster(),
        //         onGetDltCur()
        //     ]
        // ).then(res => {
        //     setOpen(true)
        // })
        onGetCompany()
        onGetGroup()
        getDataMaster()
        onGetDltCur()
        setOpen(true)
        // setTimeout(() => {
        //     setOpen(true)
        // }, 100);
    }
    const onClickClose = () => {
        setOpen(false)
        setDataDlt(null)
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <React.Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                            onHandle()
                        }}
                    >
                        ใช่
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        ปิด
                    </Button>
                </React.Fragment>
            )
        });
    };
    const schema = Yup.object().shape({
        device: Yup.string().required('required'),
        devicename: Yup.string().required('required')
    });
    const formik = useFormik({
        initialValues: {
            ...data
        },
        validationSchema: schema,
        enableReinitialize: true,
        onSubmit: (values) => {
            const data = JSON.stringify({
                ...values
            });
            Api.put('/v0/gps/device', data)
                .then(res => {
                    handleSnackbar({ message: "บันทึกข้อมูลสำเร็จ", variant: "success" })
                    // onClickClose()
                    handleGetData()
                    onGetDltCur()
                })
                .catch(error => {
                    handleSnackbar({ message: "ไม่สามารถบันทึกข้อมูล", variant: "error" })
                })
        }
    });
    const { errors, touched, isSubmitting, values, setFieldValue, handleSubmit, getFieldProps, setErrors, resetForm, submitForm } = formik;
    const onChangeDate = (value, name) => {
        if (String(value) === "Invalid Date") {
            setFieldValue(name, value)
        } else {
            setFieldValue(name, fDateTo(value, "yyyy-MM-dd"))
        }
    }


    const sendToDlt = ({ unit_id, device }) => {
        const data = { unit_id: unit_id, device: device }
        Api.post(`/gps/dlt_cur`, data)
            .then(res => {
                setDataDlt(res.data)
                handleSnackbar({ message: "บันทึกข้อมูลการส่ง DLT สำเร็จ", variant: "success" })
            })
            .catch(err => {
                handleSnackbar({ message: "ไม่สามารถบันทึกข้อมูลการส่ง DLT ", variant: "error" })
            })
    }

    const delSendToDlt = ({ device }) => {
        Api.delete(`/gps/dlt_cur?device=${device}`)
            .then(res => {
                setDataDlt(null)
                handleSnackbar({ message: "ลบข้อมูลการส่ง DLT สำเร็จ", variant: "success" })
            })
            .catch(err => {
                handleSnackbar({ message: "ไม่สามารถลบข้อมูลการส่ง DLT ", variant: "error" })
            })
    }

    return (
        <div>
            <Tooltip title="แก้ไขอุปกรณ์ / update device" placement="bottom" enterDelay={200} leaveDelay={5}>
                <IconButton size="small" onClick={onClickOpen}>
                    <PhonelinkSetupOutlinedIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>แก้ไขข้อมูลอุปกรณ์</DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ padding: "10px" }}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                                    <Grid container spacing={1} >
                                        <Grid item xs={6}>
                                            <Grid container spacing={1} >
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="รหัสอุปกรณ์" name="device"
                                                        {...getFieldProps('device')}
                                                        error={Boolean(touched.device && errors.device)}
                                                        helperText={touched.device && errors.device}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="ชื่ออุปกรณ์" name="devicename"
                                                        {...getFieldProps('devicename')}
                                                        error={Boolean(touched.group_id && errors.devicename)}
                                                        helperText={touched.devicename && errors.devicename}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="รุ่น GPS" select name="gpsmodel" {...getFieldProps('gpsmodel')}
                                                        disabled={(contype !== "00" && contype !== "01")}
                                                    >
                                                        {
                                                            modelGps.map((value, index) => {
                                                                return (
                                                                    <MenuItem value={value.gpstype} key={`MenuItem${index}`}>{`${value.gpsmodel}/${value.gpstype}`}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}><TextField size="small" fullWidth label="เลข imei" name="imei" {...getFieldProps('imei')} disabled={(contype !== "00" && contype !== "01")} /></Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="เบอร์โทร GPS" name="telgps" {...getFieldProps('telgps')} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="ผู้ประกอบการขนส่ง" name="cname" select {...getFieldProps('cname')}
                                                        disabled={(contype !== "00" && contype !== "01")}
                                                    >
                                                        {
                                                            cnames.map((value, index) => {
                                                                return (
                                                                    <MenuItem value={value.c_coname} key={`MenuItem0${index}`}>{value.fullname}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="กลุ่ม" name="group_id" select {...getFieldProps('group_id')}>
                                                        {
                                                            groups.map((value, index) => {
                                                                return (
                                                                    <MenuItem value={value.group_id} key={`MenuItem1${index}`}>{value.group_name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>

                                                    {
                                                        dataDlt !== null ? (
                                                            <Button variant="contained" disabled={(contype !== "00" && contype !== "01")} onClick={() => { handleSnackWithAction({ message: "ต้องการยกเลิก?", onHandle: () => delSendToDlt({ device: values.device }) }) }} color={"success"}> {`ข้อมูลถูกส่ง DLT แล้ว`} </Button>
                                                        ) : (
                                                            <Button variant="contained" onClick={() => { sendToDlt({ device: values.device, unit_id: values.unit_id }) }} color={"error"} disabled={(contype !== "00" && contype !== "01") || (data.unit_id === "")}> {`ข้อมูลยังไม่ได้ส่ง DLT`} </Button>
                                                        )
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid container spacing={1} >
                                                <Grid item xs={12}><TextField size="small" fullWidth label="เลขตัวถัง" name="vehicle_chassis_no" {...getFieldProps('vehicle_chassis_no')} disabled={(contype !== "00" && contype !== "01")} /></Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="ยี่ห้อรถ" select name="vehicle_type" {...getFieldProps('vehicle_type')}
                                                        disabled={(contype !== "00" && contype !== "01")}
                                                    >
                                                        {
                                                            vehiclesType.map((value, index) => {
                                                                return (
                                                                    <MenuItem value={value.vehicle_type} key={`MenuItem2${index}`}>{value.vehicle_type}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}><TextField size="small" fullWidth label="ชื่อรถ" name='car_id' {...getFieldProps('car_id')} /></Grid>
                                                <Grid item xs={12}><TextField size="small" fullWidth label="ทะเบียน" name='vehicle_id' {...getFieldProps('vehicle_id')} disabled={(contype !== "00" && contype !== "01")} /></Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="จังหวัด" select name="province_code" {...getFieldProps('province_code')} >

                                                        {
                                                            provinces.map((value, index) => {
                                                                return (
                                                                    <MenuItem value={value.province_code} key={`MenuItem3${index}`}>{value.province_name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField size="small" fullWidth label="ประเภทรถ" select name='vehicle_register_type' {...getFieldProps('vehicle_register_type')} >
                                                        {
                                                            vehicleRegisterType.map((value, index) => {
                                                                return (
                                                                    <MenuItem value={value.vehicle_register_type_id} key={`MenuItem4${index}`}>{value.vehicle_register_type_name}</MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DatePicker
                                                        onChange={(e) => { onChangeDate(e, "dateinstall") }}
                                                        value={values.dateinstall}
                                                        inputFormat="dd/MM/yyyy"
                                                        format="yyyy/MM/dd"
                                                        label="วันที่ติดตั้ง"
                                                        renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY"  {...params} />}
                                                        disabled={(contype !== "00" && contype !== "01")}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DatePicker
                                                        onChange={(e) => { onChangeDate(e, "dateexp") }}
                                                        value={values.dateexp}
                                                        inputFormat="dd/MM/yyyy"
                                                        format="yyyy/MM/dd"
                                                        label="วันหมดอายุ"
                                                        renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY"  {...params} />}
                                                        disabled={(contype !== "00" && contype !== "01")}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}><TextField size="small" fullWidth label="unit id" name='unit_id' {...getFieldProps('unit_id')} disabled={((contype !== "00" && contype !== "01") || (dataDlt !== null && values.unit_id !== ''))} /></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </FormikProvider>
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submitForm}>บันทึก</Button>
                    <Button onClick={onClickClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}
