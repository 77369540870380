import React, { useEffect, useState } from 'react'
import { Box, Dialog, Card, Grid, DialogTitle, TextField, DialogContent, DialogActions, TableContainer, Table, TableHead, IconButton, TableRow, TableCell, TableBody, Typography, Button, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import SearchIcon from '@mui/icons-material/Search';
import { fakerList } from '../fakerDash';
import { Api } from '../../../components/api';

import _ from "lodash";
import PlaylistSave from './PlaylistSave';

export default function PlaylistCustom({ c_coname, openCustom, handleDialogCustom, handleSubscribe, userId, onGetGroup, handleSubscribeCustom }) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [subList, setSublist] = useState([])
    const [searchList, setSearchList] = useState([])
    const [search, setSearch] = useState('')
    const [search_, set_Search] = useState('')

    useEffect(() => {
        const Time = setTimeout(() => {
            set_Search(search)
        }, 500);

        return () => {
            clearTimeout(Time)
        }
    }, [search])


    useEffect(() => {
        if (search_ !== '') {
            const Time = setTimeout(() => {
                Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=${search_}`).then((res) => {
                    var list = res.data;
                    setSearchList(list)
                }
                ).catch(e => {
                    console.log('error', e)
                    setSearchList([])
                })
            }, 500);

            return () => {
                clearTimeout(Time)
            }
        } else {
            setSearchList([])
        }
    }, [search_])


    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    // function intersection(a, b) {
    //     return a.filter((value) => b.indexOf(value) !== -1);
    // }

    // function union(a, b) {
    //     return [...a, ...not(b, a)];
    // }


    const handleCheckedSearch = () => {
        setSublist(e => e.concat(checkedSearch));
        setCheckedSearch([])
    };

    const handleCheckedSub = () => {
        setSublist(not(subList, checkeSub));
        setCheckedSub([])

    };


    const [checkedSearch, setCheckedSearch] = React.useState([]);
    const [checkeSub, setCheckedSub] = React.useState([]);



    const [openSave, setOpenSave] = useState(false)

    const handleSave = () => {
        setOpenSave(!openSave)
    }


    const onSubmit = (values) => {
        const sub = _.uniq(subList, ["device"]).map(sl => sl.device)
        const data = JSON.stringify({
            userid: userId,
            group_id: values.groupId,
            group_name: values.groupName,
            devices: sub
        });
        // console.log('data', data)
        Api.post('/Tracking/GroupCustom', data)
            .then(res => {
                // handleSubscribe({ subs: sub, groupId: values.groupId, cname: c_coname })
                handleSubscribeCustom({ subs: sub, groupId: { group_id: values.groupId, cname: '' } })
                handleDialogCustom()
                setSearchList([])
                setCheckedSearch([])
                setCheckedSub([])
                setSublist([])
                onGetGroup()
                handleSave()
            }
            ).catch(e => {
                console.log('error', e)
                // handleSnackbar({ variant: 'error', message: error.response.data.Message });
            }
            )
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openCustom}
            onClose={handleDialogCustom}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            sx={{
                ".MuiBackdrop-root": {
                    backgroundColor: "rgba(111, 126, 140, 0.2)"
                }
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    <Box>
                        Subscribe
                    </Box>
                    <Box

                    >
                        <TextField
                            fullWidth
                            size="small"
                            label={"search"}
                            value={search}
                            autoFocus
                            onChange={(e) => {
                                setSearch(e.target.value)
                            }}
                            autoComplete='off'
                        />
                    </Box>
                </Box>
            </DialogTitle>
            <DialogContent
                dividers={true}
                sx={(theme) => ({
                    width: !fullScreen ? "600px" : "auto",
                    height: "500px",
                    // textAlign: "center",
                    padding: "5px",
                    // overflow: "hidden"
                })
                }
            >
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={5}>
                        <Card
                            sx={{
                                width: "auto",
                                height: "450px",
                                bgcolor: 'background.paper',
                                overflow: 'auto',
                                padding: "10px"
                            }}
                        >
                            {
                                _.sortBy(subList, ["name"]).map((_val, _index) => (
                                    <Typography
                                        key={"CheckedSub" + _index}
                                        onClick={() => {
                                            if (checkeSub.indexOf(_val) !== -1) {
                                                setCheckedSub(e => e.filter(_v => _v !== _val))
                                            } else {
                                                setCheckedSub(e => e.concat(_val))
                                            }
                                        }}
                                        sx={{
                                            fontWeight: checkeSub.indexOf(_val) !== -1 ? "bold" : "normal",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {/* {`${_val.vehicle_id} (${_val.name})`} */}
                                        {`${_val.name}`}
                                    </Typography>
                                ))
                            }
                        </Card>
                    </Grid>
                    <Grid item xs={2}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedSub}
                                disabled={checkeSub.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                disabled={checkedSearch.length === 0}
                                aria-label="move selected right"

                                onClick={handleCheckedSearch}
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Card
                            sx={{
                                width: "auto",
                                height: "450px",
                                bgcolor: 'background.paper',
                                overflow: 'auto',
                                padding: "10px"
                            }}
                        >
                            {
                                _.sortBy(not(searchList, subList), ["name"]).map((_val, _index) => (
                                    <Typography
                                        key={"CheckedSearch" + _index}
                                        onClick={() => {
                                            if (checkedSearch.indexOf(_val) !== -1) {
                                                setCheckedSearch(e => e.filter(_v => _v !== _val))
                                            } else {
                                                setCheckedSearch(e => e.concat(_val))
                                            }
                                        }}
                                        sx={{
                                            fontWeight: checkedSearch.indexOf(_val) !== -1 ? "bold" : "normal",
                                            cursor: "pointer"
                                        }}
                                    >
                                        {/* {`${_val.vehicle_id} (${_val.name})`} */}
                                        {`${_val.name}`}
                                    </Typography>
                                ))
                            }
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
                <Box>
                    <Button disabled={subList.length === 0}
                        onClick={() => {
                            handleSave()
                            // handleDialogSaveToggle
                        }}
                    >
                        Save
                    </Button>
                    <Button disabled={subList.length === 0}
                        onClick={() => {
                            setSublist([])
                        }}
                    >
                        Clear
                    </Button>
                </Box>
                <Box>

                    <Button
                        onClick={handleDialogCustom}
                    >
                        Cancel
                    </Button>
                    <Button onClick={
                        () => {
                            const sub = _.uniq(subList, ["device"]).map(sl => sl.device)
                            handleSubscribe({ subs: sub, groupId: '', cname: '' })
                            handleDialogCustom()
                            setSearchList([])
                        }
                    }
                        disabled={subList.length === 0}
                    >
                        Subscribe
                    </Button>
                </Box>
            </DialogActions>
            <PlaylistSave openSave={openSave} handleSave={handleSave} onSubmit={onSubmit} />

        </Dialog >
    )
}