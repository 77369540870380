import { atom } from "recoil";

export const listDevicesState = atom({
    key: 'listDevicesState',
    default: [],
});

export const firstLoadState = atom({
    key: 'firstLoadState',
    default: true,
});
export const loadingState = atom({
    key: 'loadingState',
    default: true,
})

export const listGroupsState = atom({
    key: 'listGroupsState',
    default: [],
})
export const filterGroupState = atom({
    key: 'filterGroupState',
    default: null,
})
export const mapState = atom({
    key: 'mapState',
    default: null,
})
export const selectDeviceState = atom({
    key: 'selectDeviceState',
    default: null,
})
export const queryState = atom({
    key: 'queryState',
    default: '',
})
export const searchState = atom({
    key: 'searchState',
    default: '',
})
export const groupIntervalsState = atom({
    key: 'groupIntervalsState',
    default: [],
})

export const listDevicesCustomState = atom({
    key: 'listDevicesCustomState',
    default: [],
});
export const groupRouteState = atom({
    key: 'groupRouteState',
    default: [],
});

export const listDevicesChartState = atom({
    key: 'listDevicesChartState',
    default: [],
});

export const stationDashboardState = atom({
    key: 'stationDashboardState',
    default: [],
});

