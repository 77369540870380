import React, { useState } from 'react'

import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { fDateTo } from '../../utils/formatTime';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import _ from 'lodash';

// import { Autocomplete } from '@mui/lab'


import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { DatePicker } from '@mui/lab';
import DialogBooking from './DialogBooking';
import DialogGroupWork from './DialogGroupWork';
import DialogCustomer from './DialogCustomer';
import DialogLocation from './DialogLocation';
import DialogVehicle from './DialogVehicle';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
const top100Films = [
    { title: 'สีทอง' },
    { title: 'พูดภาษาจีน' },
    { title: 'จอ' },
    { title: 'usb' },
    { title: 'น้ำดื่ม' },
    { title: "ผ้าห่ม" }
]


export default function NotMainBooking() {
    const [open, setOpen] = React.useState(false);
    const [openDetail, setOpenDetail] = React.useState(false);
    const [openWork, setOpenWork] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);
    const [openFormDialog, setOpenFormDialog] = React.useState({
        dialogBooking: false,
        dialogGroupWork: false,
        dialogCustomer: false,
        dialogVehicle: false
    });
    const [dialogFieldName, setDialogFieldName] = useState("")


    const handleOpenForm = (name) => {
        setOpenFormDialog({ ...openFormDialog, [name]: true })
    }
    const handleCloseForm = (name) => {
        setOpenFormDialog({ ...openFormDialog, [name]: false })
        setDialogFieldName("")
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick" || reason == "escapeKeyDown")
            return;
        setOpen(false);
    };

    const handleClickOpenDetail = () => {
        setOpenDetail(true);
    };

    const handleCloseDetail = (event, reason) => {
        if (reason && reason == "backdropClick" || reason == "escapeKeyDown")
            return;
        setOpenDetail(false);
    };


    const handleClickOpenWork = () => {
        setOpenWork(true);
    };

    const handleCloseWork = (event, reason) => {
        if (reason && reason == "backdropClick" || reason == "escapeKeyDown")
            return;
        setOpenWork(false);
    };

    const [tripRows, setTripRows] = useState([])
    const [workRows, setWorkRows] = useState([])

    const [formValue, setFormValue] = useState({
        autoTrip: false
    })

    const [formCus, setFormCus] = useState({
    })

    const handleNewTrip = () => {
        const tripNo = tripRows.length + 1
        const trip = {
            ...formValue,
            tripNo: `T${tripNo.toString().padStart(6, '0')}`,
            works: []
        }
        setTripRows(e => e.concat(trip));
    };

    const handleDelTrip = (tripNo) => {
        const trips = tripRows.filter(v => v.tripNo !== tripNo)
        setTripRows(trips);
    };


    const handleNewWork = (tripNo) => {
        const trips = tripRows.filter(t => t.tripNo === tripNo).length
        const trip = tripRows.find(v => v.tripNo === tripNo)
        const works = trip.works.length + 1
        if (trips > 0) {
            const tripWork = {
                ...trip,
                works: trip.works.concat({ workNo: `W${works.toString().padStart(6, '0')}`, })
            }
            const notTrip = tripRows.filter(t => t.tripNo !== tripNo)
            const nTrip = notTrip.concat(tripWork)
            setTripRows(nTrip);
        }
    };

    const handleDelWork = (tripNo, workNo) => {
        const trips = tripRows.filter(t => t.tripNo === tripNo).length
        const trip = tripRows.find(v => v.tripNo === tripNo)
        if (trips > 0) {
            const tripWork = {
                ...trip,
                works: trip.works.filter(w => w.workNo !== workNo)
            }
            const notTrip = tripRows.filter(t => t.tripNo !== tripNo)
            const nTrip = notTrip.concat(tripWork)
            setTripRows(nTrip);
        }
    };

    const onChangeHandle = (e) => {
        setFormValue(v => {
            return ({ ...v, [e.target.name]: e.target.value })
        })
    }
    const onChangeCusHandleSelect = (e) => {
        console.log('e', e)
        setFormCus(v => {
            return ({ ...v, ...e })
        })
    }
    const onChangeHandleCheck = (e) => {
        setFormValue(v => {
            return ({ ...v, [e.target.name]: e.target.checked })
        })
    }

    const onChangeHandleSelect = ({ name, value }) => {
        setFormValue(v => {
            return ({ ...v, [name]: value })
        })
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ padding: "10px" }}>
                <Stack spacing={1}>
                    <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ใบจอง</Typography></Box>
                    <Stack spacing={1} direction="row">
                        <Grid container spacing={1} sx={{ marginBottom: "5px" }} >
                            <Grid item xs={6} md={4}>
                                <TextField size="small" label='ค้นหา'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end" >
                                                <IconButton size="small" onClick={() => { handleOpenForm("dialogBooking") }} ><SearchIcon fontSize="inherit" /></IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} md={8}>
                                <Stack spacing={1} direction="row">
                                    <IconButton ><PostAddOutlinedIcon /></IconButton>
                                    <IconButton ><EditNoteIcon /></IconButton>
                                    <IconButton ><PlaylistRemoveIcon /></IconButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Box>
                        <Stack >
                            <Grid container spacing={1} >
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={5} md={5}>
                                            <TextField size="small" label='กลุ่มประเภท' fullWidth
                                                value={formValue.grouptype}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton size="small" onClick={() => { handleOpenForm("dialogGroupWork") }} ><SearchIcon fontSize="inherit" /></IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                onChange={onChangeHandle} name='grouptype' />
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <TextField size="small" label='ประเภทงาน' onChange={onChangeHandle} value={formValue.grouptypename} name='grouptypename' fullWidth />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField size="small" label='ลูกค้า' fullWidth
                                                value={formCus.name}
                                                InputLabelProps={{ shrink: formCus.name ? true : false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton size="small" onClick={() => {
                                                                handleOpenForm("dialogCustomer")
                                                            }}><SearchIcon fontSize="inherit" /></IconButton>
                                                        </InputAdornment>
                                                    )
                                                }} name='name' />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField size="small" label='' value={formCus.type} name='type' fullWidth InputLabelProps={{ shrink: formCus.type ? true : false }} />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Stack direction="row">
                                                <ExpandMore
                                                    expand={expanded}
                                                    onClick={handleExpandClick}
                                                    aria-expanded={expanded}
                                                    aria-label="show more"
                                                    size="small"
                                                >
                                                    <ExpandMoreIcon fontSize="inherit" />
                                                </ExpandMore>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Collapse in={expanded} timeout="auto" unmountOnExit style={{ marginTop: "5px", marginBottom: "8px" }}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={7} md={7}>
                                                <TextField size="small" label='เลขที่ผู้เสียภาษี' value={formCus.taxId} name='taxId' fullWidth InputLabelProps={{ shrink: formCus.taxId ? true : false }} />
                                            </Grid>
                                            <Grid item xs={5} md={5}>
                                                <TextField size="small" label='คำนำหน้า' value={formCus.prefix} name='prefix' fullWidth InputLabelProps={{ shrink: formCus.prefix ? true : false }} />
                                            </Grid>
                                            <Grid item xs={8} md={8}>
                                                <TextField size="small" label='ชื่อผู้ประกอบการ' value={formCus.operatorname} name='operatorname' fullWidth InputLabelProps={{ shrink: formCus.operatorname ? true : false }} />
                                            </Grid>
                                            <Grid item xs={4} md={4}>
                                                <TextField size="small" label='สาขา' value={formCus.branch} name='branch' fullWidth InputLabelProps={{ shrink: formCus.branch ? true : false }} />
                                            </Grid>
                                            <Grid item xs={9} md={9}>
                                                <TextField size="small" label='ที่อยู่' value={formCus.address} name='address' fullWidth InputLabelProps={{ shrink: formCus.address ? true : false }} />
                                            </Grid>
                                            <Grid item xs={3} md={3}>
                                                <TextField size="small" label='ประเภทที่อยู่' value={formCus.addresstype} name='addresstype' fullWidth InputLabelProps={{ shrink: formCus.addresstype ? true : false }} />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField size="small" label='แขวง/ตำบล' value={formCus.subdistrict} name='subdistrict' fullWidth InputLabelProps={{ shrink: formCus.subdistrict ? true : false }} />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField size="small" label='เขต/อำเภอ' value={formCus.district} name='district' fullWidth InputLabelProps={{ shrink: formCus.district ? true : false }} />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField size="small" label='จังหวัด' value={formCus.province} name='province' fullWidth InputLabelProps={{ shrink: formCus.province ? true : false }} />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField size="small" label='รหัสไปรษณีย์' value={formCus.zipCode} name='zipCode' fullWidth InputLabelProps={{ shrink: formCus.zipCode ? true : false }} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField size="small" label='เบอร์ติดต่อ' value={formCus.tel} name='tel' fullWidth InputLabelProps={{ shrink: formCus.tel ? true : false }} />
                                            </Grid>
                                        </Grid>
                                    </Collapse>
                                    <Box sx={{ marginBottom: "10px" }}>
                                        <Divider />
                                    </Box>
                                    <Grid container spacing={1} >
                                        <Grid item xs={5} md={5}>
                                            <TextField size="small" label='รับเวลา' placeholder='DD-MM-YYYY' value={formValue.dateStart} onChange={onChangeHandle} name='dateStart' fullWidth />
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <TextField size="small" label='สถานที่' value={formValue.localStart} onChange={onChangeHandle} name='localStart' fullWidth
                                                InputLabelProps={{ shrink: formValue.localStart ? true : false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton size="small" onClick={() => {
                                                                handleOpenForm("dialogLocation")
                                                                setDialogFieldName("localStart")
                                                            }}><SearchIcon fontSize="inherit" /></IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} md={5}>
                                            <TextField size="small" label='ส่งเวลา' value={formValue.dateEnd} placeholder='DD-MM-YYYY' onChange={onChangeHandle} name='dateEnd' fullWidth />
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <TextField size="small" label='สถานที่' value={formValue.localEnd} onChange={onChangeHandle} name='localEnd' fullWidth
                                                InputLabelProps={{ shrink: formValue.localEnd ? true : false }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton size="small" onClick={() => {
                                                                handleOpenForm("dialogLocation")
                                                                setDialogFieldName("localEnd")
                                                            }}><SearchIcon fontSize="inherit" /></IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12} md={12}>
                                            <TextField size="small" label='เลขที่อ้างอิง' value={formValue.ref} onChange={onChangeHandle} name='ref' fullWidth />
                                        </Grid>
                                        <Grid item xs={5} md={5}>
                                            <TextField size="small" label='ผู้ติดต่อ' value={formValue.Contact} onChange={onChangeHandle} name='Contact' fullWidth />
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <TextField size="small" label='เบอร์โทร' value={formValue.tel} onChange={onChangeHandle} name='tel' fullWidth />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <Autocomplete
                                                multiple
                                                limitTags={2}
                                                id="multiple-limit-tags"
                                                options={top100Films}
                                                getOptionLabel={(option) => option?.title}
                                                defaultValue={[]}
                                                renderInput={(params) => (
                                                    <TextField size="small" label='รายละเอียดความต้องการ' {...params} sx={{ overFlow: "auto" }} fullWidth />
                                                )}
                                            // sx={{ width: '500px' }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField size="small" label='หมายเหตุ' value={formValue.remake} multiline maxRows={3} minRows={3} onChange={onChangeHandle} name='remake' fullWidth />
                                            <Stack spacing={1} direction="row">
                                                <Checkbox checked={formValue.autoTrip} onChange={onChangeHandleCheck} name='autoTrip' />
                                                <Typography sx={{ paddingTop: "8px" }} >Auto trip</Typography>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "end", padding: "10px" }}>
                        <Box
                            sx={(theme) => ({
                                [theme.breakpoints.up('md')]: {
                                    marginTop: -6,
                                }
                            })}
                        >
                            <Stack spacing={1} direction="row">
                                <Button variant="outlined" size="small" color="info">บันทึกใบจอง</Button>
                                <Button variant="outlined" size="small" color="info">ยกเลิกใบจอง</Button>
                            </Stack>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                            <Typography>รายละเอียดการเดินทาง</Typography>
                            <Box>
                                <Stack spacing={1} direction="row">
                                    <Button variant="outlined" size="small" color="error" onClick={handleNewTrip}>
                                        <AddOutlinedIcon fontSize="small" />
                                        {/* จัด trip */}
                                    </Button>
                                    {/* <Button variant="outlined" size="small" color="error">ลบ trip</Button> */}
                                </Stack>
                            </Box>
                        </Box>
                        <Box sx={{ minHeight: "250px", height: "400px", borderStyle: "solid", borderColor: "#e5e7eb", borderRadius: "10px", overflow: "auto" }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow >
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>

                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            เลขที่ใบเดินทาง
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            วันที่เริ่มจอง
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            วันที่สิ้นสุดจอง
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            ต้นทาง
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            ปลายทาง
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            ประเภทรถ
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            จำนวนรถ
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            จำนวนคนขับ
                                        </TableCell>
                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                            action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        _.orderBy(tripRows, ["tripNo"], ["asc"]).map((value, index) => {
                                            return (
                                                <React.Fragment key={`TableRow-${index + 1}`}>
                                                    <TableRow  >
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            {value.tripNo}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            {/* {fDateTo(value.dateStart, "dd-MM-yyyy")} */}
                                                            {value.dateStart}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            {/* {fDateTo(value.dateEnd, "dd-MM-yyyy")} */}
                                                            {value.dateEnd}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            {value.departure}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            {value.destination}
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            <TextField size="small" label='ประเภทรถ' fullWidth
                                                                inputProps={{
                                                                    style: {
                                                                        height: "15px",
                                                                        fontSize: "12px",
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end" >
                                                                            <IconButton size="small" onClick={() => { handleOpenForm("dialogVehicle") }}><SearchIcon fontSize="inherit" /></IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                InputLabelProps={{ style: { fontSize: '12px' } }}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            <TextField size="small" label='จำนวนรถ' fullWidth
                                                                inputProps={{
                                                                    style: {
                                                                        height: "15px",
                                                                        fontSize: "12px",
                                                                        width: "60px"
                                                                    }
                                                                }}
                                                                InputLabelProps={{ style: { fontSize: '12px' } }}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            <TextField size="small" label='จำนวนคนขับ' fullWidth
                                                                inputProps={{
                                                                    style: {
                                                                        height: "15px",
                                                                        fontSize: "12px",
                                                                        width: "60px"
                                                                    }
                                                                }}
                                                                InputLabelProps={{ style: { fontSize: '12px' } }}
                                                            />
                                                        </TableCell>
                                                        <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                            <Stack spacing={1} direction="row" justifyContent="end">
                                                                <IconButton onClick={() => { handleNewWork(value.tripNo) }} size="small"><AddOutlinedIcon fontSize="inherit" /></IconButton>
                                                                <IconButton onClick={handleClickOpenDetail} size="small"><FindInPageIcon fontSize="inherit" /></IconButton>
                                                                <IconButton onClick={() => handleDelTrip(value.tripNo)} size="small"><DeleteOutlineIcon fontSize="inherit" /></IconButton>
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        value?.works.length > 0 && (
                                                            <TableRow >
                                                                <TableCell colSpan={10} sx={{ padding: "0px" }}>
                                                                    <Box>
                                                                        <Table>
                                                                            <TableBody>
                                                                                {
                                                                                    _.orderBy(value.works, ["workTrip"], ["asc"]).map((work, wIndex) => {
                                                                                        return (
                                                                                            <TableRow >
                                                                                                <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", width: 60, textAlign: "left" }}>
                                                                                                    {/* &nbsp;&nbsp;{`${index + 1}.${wIndex + 1}`} */}
                                                                                                </TableCell>
                                                                                                <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                                                    {/* <DatePicker
                                                                                                        renderInput={() => (
                                                                                                            <TextField size="small" label='วันที่เริ่ม' fullWidth
                                                                                                                inputProps={{
                                                                                                                    style: {
                                                                                                                        height: "15px",
                                                                                                                        fontSize: "12px"
                                                                                                                    }
                                                                                                                }}
                                                                                                                InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                                                            />
                                                                                                        )} 
                                                                                                        /> */}
                                                                                                    <TextField size="small" label='วันที่เริ่ม' fullWidth
                                                                                                        inputProps={{
                                                                                                            style: {
                                                                                                                height: "15px",
                                                                                                                fontSize: "12px"
                                                                                                            }
                                                                                                        }}
                                                                                                        InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                                                    <TextField size="small" label='วันที่สิ้นสุด' fullWidth
                                                                                                        inputProps={{
                                                                                                            style: {
                                                                                                                height: "15px",
                                                                                                                fontSize: "12px"
                                                                                                            }
                                                                                                        }}
                                                                                                        InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                                                    <TextField size="small" label='ทะเบียนรถ' fullWidth
                                                                                                        inputProps={{
                                                                                                            style: {
                                                                                                                height: "15px",
                                                                                                                fontSize: "12px"
                                                                                                            }
                                                                                                        }}
                                                                                                        InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                                                    <TextField size="small" label='พนักงาน' fullWidth
                                                                                                        inputProps={{
                                                                                                            style: {
                                                                                                                height: "15px",
                                                                                                                fontSize: "12px"
                                                                                                            }
                                                                                                        }}
                                                                                                        InputLabelProps={{ style: { fontSize: '12px' } }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell sx={{ padding: "5px", whiteSpace: "nowrap", textAlign: "left" }}>
                                                                                                    <Stack spacing={1} direction="row" justifyContent="end">
                                                                                                        {/* <IconButton onClick={handleClickOpenWork} size="small"><AddOutlinedIcon fontSize="inherit" /></IconButton> */}
                                                                                                        <IconButton onClick={handleClickOpenWork} size="small"><FindInPageIcon fontSize="inherit" /></IconButton>
                                                                                                        <IconButton onClick={() => handleDelWork(value.tripNo, work.workNo)} size="small"><DeleteOutlineIcon fontSize="inherit" /></IconButton>
                                                                                                    </Stack>
                                                                                                </TableCell>
                                                                                            </TableRow>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </TableBody>
                                                                        </Table>
                                                                    </Box>
                                                                </TableCell>
                                                            </TableRow >
                                                        )
                                                    }
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </TableBody >
                            </Table >
                        </Box>
                    </Box >
                </Stack >
                <Dialog
                    open={openDetail}
                    maxWidth="md"
                    fullWidth={true}
                    onClose={handleCloseDetail}
                    slotProps={{
                        backdrop: {
                            style: {
                                // background: "#F8F8FF", opacity: 0.3
                                background: "#a7d8de", opacity: 0.2
                                // background: "#000", opacity: 0.1
                            }
                        }
                    }}
                >
                    <DialogTitle>รายละเอียดการเดินทาง</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDetail}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        <Box>
                            <Stack >
                                <Grid container spacing={1} >
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={5} md={5}>
                                                <TextField size="small" label='กลุ่มประเภท' fullWidth
                                                    value={formValue.grouptype}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" ><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={onChangeHandle} name='grouptype' />
                                            </Grid>
                                            <Grid item xs={7} md={7}>
                                                <TextField size="small" label='ประเภทงาน' onChange={onChangeHandle} value={formValue.grouptypename} name='grouptypename' fullWidth />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField size="small" label='ลูกค้า' fullWidth
                                                    value={formCus.name}
                                                    InputLabelProps={{ shrink: formCus.name ? true : false }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" onClick={() => {
                                                                    handleOpenForm("dialogCustomer")
                                                                }}><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }} name='name' />
                                            </Grid>
                                            <Grid item xs={6} md={6}>
                                                <TextField size="small" label='' value={formCus.type} name='type' fullWidth InputLabelProps={{ shrink: formCus.type ? true : false }} />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <Stack direction="row">
                                                    <ExpandMore
                                                        expand={expanded}
                                                        onClick={handleExpandClick}
                                                        aria-expanded={expanded}
                                                        aria-label="show more"
                                                        size="small"
                                                    >
                                                        <ExpandMoreIcon fontSize="inherit" />
                                                    </ExpandMore>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                        <Collapse in={expanded} timeout="auto" unmountOnExit style={{ marginTop: "5px", marginBottom: "8px" }}>
                                            <Grid container spacing={1} >
                                                <Grid item xs={7} md={7}>
                                                    <TextField size="small" label='เลขที่ผู้เสียภาษี' value={formCus.taxId} name='taxId' fullWidth InputLabelProps={{ shrink: formCus.taxId ? true : false }} />
                                                </Grid>
                                                <Grid item xs={5} md={5}>
                                                    <TextField size="small" label='คำนำหน้า' value={formCus.prefix} name='prefix' fullWidth InputLabelProps={{ shrink: formCus.prefix ? true : false }} />
                                                </Grid>
                                                <Grid item xs={8} md={8}>
                                                    <TextField size="small" label='ชื่อผู้ประกอบการ' value={formCus.operatorname} name='operatorname' fullWidth InputLabelProps={{ shrink: formCus.operatorname ? true : false }} />
                                                </Grid>
                                                <Grid item xs={4} md={4}>
                                                    <TextField size="small" label='สาขา' value={formCus.branch} name='branch' fullWidth InputLabelProps={{ shrink: formCus.branch ? true : false }} />
                                                </Grid>
                                                <Grid item xs={9} md={9}>
                                                    <TextField size="small" label='ที่อยู่' value={formCus.address} name='address' fullWidth InputLabelProps={{ shrink: formCus.address ? true : false }} />
                                                </Grid>
                                                <Grid item xs={3} md={3}>
                                                    <TextField size="small" label='ประเภทที่อยู่' value={formCus.addresstype} name='addresstype' fullWidth InputLabelProps={{ shrink: formCus.addresstype ? true : false }} />
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <TextField size="small" label='แขวง/ตำบล' value={formCus.subdistrict} name='subdistrict' fullWidth InputLabelProps={{ shrink: formCus.subdistrict ? true : false }} />
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <TextField size="small" label='เขต/อำเภอ' value={formCus.district} name='district' fullWidth InputLabelProps={{ shrink: formCus.district ? true : false }} />
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <TextField size="small" label='จังหวัด' value={formCus.province} name='province' fullWidth InputLabelProps={{ shrink: formCus.province ? true : false }} />
                                                </Grid>
                                                <Grid item xs={6} md={6}>
                                                    <TextField size="small" label='รหัสไปรษณีย์' value={formCus.zipCode} name='zipCode' fullWidth InputLabelProps={{ shrink: formCus.zipCode ? true : false }} />
                                                </Grid>
                                                <Grid item xs={12} md={12}>
                                                    <TextField size="small" label='เบอร์ติดต่อ' value={formCus.tel} name='tel' fullWidth InputLabelProps={{ shrink: formCus.tel ? true : false }} />
                                                </Grid>
                                            </Grid>
                                        </Collapse>
                                        <Box sx={{ marginBottom: "10px" }}>
                                            <Divider />
                                        </Box>
                                        <Grid container spacing={1} >
                                            <Grid item xs={5} md={5}>
                                                <TextField size="small" label='รับเวลา' placeholder='DD-MM-YYYY' value={formValue.dateStart} onChange={onChangeHandle} name='dateStart' fullWidth />
                                            </Grid>
                                            <Grid item xs={7} md={7}>
                                                <TextField size="small" label='สถานที่' value={formValue.departure} onChange={onChangeHandle} name='departure' fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" onClick={() => { handleOpenForm("dialogLocation") }}><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={5} md={5}>
                                                <TextField size="small" label='ส่งเวลา' value={formValue.dateEnd} placeholder='DD-MM-YYYY' onChange={onChangeHandle} name='dateEnd' fullWidth />
                                            </Grid>
                                            <Grid item xs={7} md={7}>
                                                <TextField size="small" label='สถานที่' value={formValue.destination} onChange={onChangeHandle} name='destination' fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" onClick={() => { handleOpenForm("dialogLocation") }}><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4} md={4}>
                                                <TextField size="small" label='ประเภทรถ'
                                                    // value={formValue.destination} onChange={onChangeHandle} name='destination'
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={4} md={4}>
                                                <TextField size="small" label='จำนวนรถ'
                                                    //  value={formValue.destination} onChange={onChangeHandle} name='destination' 
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={4} md={4}>
                                                <TextField size="small" label='จำนวนคนขับ'
                                                    // value={formValue.destination} onChange={onChangeHandle} name='destination'
                                                    fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={12} md={12}>
                                                <TextField size="small" label='เลขที่อ้างอิง' value={formValue.ref} onChange={onChangeHandle} name='ref' fullWidth />
                                            </Grid>
                                            <Grid item xs={5} md={5}>
                                                <TextField size="small" label='ผู้ติดต่อ' value={formValue.Contact} onChange={onChangeHandle} name='Contact' fullWidth />
                                            </Grid>
                                            <Grid item xs={7} md={7}>
                                                <TextField size="small" label='เบอร์โทร' value={formValue.tel} onChange={onChangeHandle} name='tel' fullWidth />
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                                <TextField size="small" label='หมายเหตุ' value={formValue.remake} multiline maxRows={3} minRows={3} onChange={onChangeHandle} name='remake' fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                    </DialogContent >
                    <DialogActions>
                        <Button onClick={handleCloseDetail}>บันทึก</Button>
                        <Button onClick={handleCloseDetail}>ยกเลิก</Button>
                    </DialogActions>
                </Dialog >


                <Dialog
                    open={openWork}
                    maxWidth="md"
                    fullWidth={true}
                    onClose={handleCloseWork}
                    slotProps={{
                        backdrop: {
                            style: {
                                // background: "#F8F8FF", opacity: 0.3
                                background: "#a7d8de", opacity: 0.2
                                // background: "#000", opacity: 0.1
                            }
                        }
                    }}
                >
                    <DialogTitle>รายละเอียดงาน</DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseWork}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <DialogContent dividers>
                        {/* <Grid container spacing={1} >
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1} >
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='เลขที่ใบจอง' onChange={onChangeHandle} name='autoTrip' fullWidth /> 
                                </Grid>
                                <Grid item xs={6} md={6}>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='วันที่' onChange={onChangeHandle} name='autoTrip' fullWidth /> 
                                </Grid>
                                <Grid item xs={6} md={6}>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={1} >
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='เลขที่ trip' onChange={onChangeHandle} name='autoTrip' fullWidth /> 
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='เลขที่ใบงาน' onChange={onChangeHandle} name='autoTrip' fullWidth /> 
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='วันที่' onChange={onChangeHandle} name='autoTrip' fullWidth /> 
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='วันที่' onChange={onChangeHandle} name='autoTrip' fullWidth /> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid> */}
                        <Box sx={{ marginTop: "10px" }}>
                            <Grid container spacing={1} >
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12}>
                                            <Typography fontSize={12}>ต้นทาง</Typography>
                                        </Grid>
                                        <Grid item xs={5} md={5}>
                                            <TextField size="small" label='สถานที่' fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton size="small" onClick={() => { handleOpenForm("dialogLocation") }}><SearchIcon fontSize="inherit" /></IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <TextField size="small" label=''
                                                // onChange={onChangeHandle} name='autoTrip'
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField size="small" label='fight'
                                                // onChange={onChangeHandle} name='autoTrip'
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField size="small" label='วันที่'
                                                // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={12}>
                                            <Typography fontSize={12}>ปลายทาง</Typography>
                                        </Grid>
                                        <Grid item xs={5} md={5}>
                                            <TextField size="small" label='สถานที่' fullWidth
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end" >
                                                            <IconButton size="small" onClick={() => { handleOpenForm("dialogLocation") }}><SearchIcon fontSize="inherit" /></IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={7} md={7}>
                                            <TextField size="small" label=''
                                                // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField size="small" label='fight'
                                                // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <TextField size="small" label='วันที่'
                                                // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ marginTop: "10px" }}>
                            <Grid container spacing={1} >
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={5}>
                                            <TextField size="small" label='ไกด์' // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={7}>
                                            <TextField size="small" label='' // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField size="small" label='เบอร์ติดต่อ' // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField size="small" label='ทัวร์โค้ด' // onChange={onChangeHandle} name='autoTrip' 
                                                fullWidth />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box>
                            <Box sx={{ marginTop: "10px" }}>
                                <Divider />
                            </Box>
                            <Box sx={{ marginTop: "10px" }}>
                                <Grid container spacing={1} >
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={5}>
                                                <TextField size="small" label='ประเภท' fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" ><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField size="small" label='' // onChange={onChangeHandle} name='autoTrip' 
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField size="small" label='รายการ' // onChange={onChangeHandle} name='autoTrip' 
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField size="small" label='ทะเบียน' // onChange={onChangeHandle} name='autoTrip' 
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField multiline minRows={3} maxRows={3} size="small" label='หมายเหตุ' // onChange={onChangeHandle} name='autoTrip' 
                                                    fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1} >
                                            <Grid item xs={5}>
                                                <TextField size="small" label='คนขับ' fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" ><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField size="small" label='' // onChange={onChangeHandle} name='autoTrip' 
                                                    fullWidth />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextField size="small" label='คนรถ' fullWidth
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end" >
                                                                <IconButton size="small" ><SearchIcon fontSize="inherit" /></IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={7}>
                                                <TextField size="small" label='' // onChange={onChangeHandle} name='autoTrip' 
                                                    fullWidth />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </DialogContent >
                    <DialogActions>
                        <Button onClick={handleCloseWork}>บันทึก</Button>
                        <Button onClick={handleCloseWork}>ยกเลิก</Button>
                    </DialogActions>
                </Dialog >


                {/* //////////////////////////////////////////////////////////// */}

                <DialogBooking open={openFormDialog.dialogBooking} handleClose={() => { handleCloseForm('dialogBooking') }} />
                <DialogGroupWork open={openFormDialog.dialogGroupWork} handleClose={() => { handleCloseForm('dialogGroupWork') }} />
                <DialogCustomer open={openFormDialog.dialogCustomer} onChangeCusHandleSelect={onChangeCusHandleSelect} handleClose={() => { handleCloseForm('dialogCustomer') }} />
                <DialogLocation open={openFormDialog.dialogLocation} dialogFieldName={dialogFieldName} onChangeHandleSelect={onChangeHandleSelect} handleClose={() => { handleCloseForm('dialogLocation') }} />
                <DialogVehicle open={openFormDialog.dialogVehicle} handleClose={() => { handleCloseForm('dialogVehicle') }} />


                {/* //////////////////////////////////////////////////////////// */}
            </Box >
        </LocalizationProvider>
    )
}


