import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, IconButton, Button, Grid, TextField, Box } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';

export default function UpdateModelGps({ data }) {
    const [open, setOpen] = useState(false)
    const [modelData, setModelData] = useState(data)
    useEffect(() => {
        setModelData(data)
    }, [data])

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Box>
            <IconButton size="small" onClick={handleOpen}>
                <EditNoteOutlinedIcon fontSize="small" />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    แก้ไขข้อมูล Model
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseOutlinedIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box justifyContent="center">
                        <Grid container spacing={1}>
                            <Grid item sm={12}>
                                <TextField size="small" value={modelData?.gpsmodel.trim()} label="รุ่นGPS" fullWidth />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField size="small" value={modelData?.gpstype.trim()} label="ประเภท" fullWidth />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField size="small" value={modelData?.gpslicense.trim()} label="เลขที่ใบอนุญาต" fullWidth />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField size="small" value={modelData?.mcrmodel.trim()} label="รุ่นเครื่องอ่านบัตร" fullWidth />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField size="small" value={modelData?.mcrtype.trim()} label="ประเภทเครื่องอ่านบัตร" fullWidth />
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button size="small" onClick={() => { }}> บันทึก </Button>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
