import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import CreateCarsType from './CreateCarsType';
import UpdateCarsType from './UpdateCarsType';

export default function CarsType() {
    const carsType = [
        {
            "make": "Toyota",
            "model": "RAV4",
            "year": 2022,
            "color": "Blue",
            "license_plate": "XYZ5678",
            "chassis_number": "JTMBF1DV4C5098765",
            "options": {
                "trim_level": "XLE",
                "package": "Premium Package",
                "features": ["Panoramic sunroof", "Blind spot monitoring", "Navigation system"]
            },
            "engine": {
                "fuel_type": "Gasoline",
                "horsepower": 203,
                "cylinders": 4
            },
            "transmission": "Automatic",
            "seating_capacity": 5
        },
        {
            "make": "Honda",
            "model": "CR-V",
            "year": 2023,
            "color": "Silver",
            "license_plate": "DEF9012",
            "chassis_number": "JHLRW2H88KX002345",
            "options": {
                "trim_level": "EX",
                "package": "Tech Package",
                "features": ["Wireless phone charging", "Lane keeping assist", "Apple CarPlay"]
            },
            "engine": {
                "fuel_type": "Hybrid",
                "horsepower": 212,
                "cylinders": 4
            },
            "transmission": "CVT",
            "seating_capacity": 5
        },
        {
            "make": "Ford",
            "model": "Explorer",
            "year": 2022,
            "color": "Black",
            "license_plate": "GHI3456",
            "chassis_number": "1FM5K8HC2NGB87654",
            "options": {
                "trim_level": "Limited",
                "package": "Driver Assist Package",
                "features": ["Adaptive cruise control", "Parking sensors", "Panoramic vista roof"]
            },
            "engine": {
                "fuel_type": "Gasoline",
                "horsepower": 300,
                "cylinders": 6
            },
            "transmission": "Automatic",
            "seating_capacity": 7
        }
    ]


    const navigate = useNavigate()

    const onClickCreate = () => {
        navigate("create")
    }
    const onClickUpdate = (data) => {
        navigate("sub", { state: data })
    }


    const [openCreate, setOpenCreate] = useState(false)

    const onHandleOpenNew = () => {
        setOpenCreate(true)
    }
    const onHandleCloseNew = () => {
        setOpenCreate(true)
    }

    const [openUpdate, setOpenUpdate] = useState(false)

    const onHandleOpenUpdate = () => {
        setOpenCreate(true)
    }
    const onHandleCloseUpdate = () => {
        setOpenCreate(true)
    }




    return (
        <div style={{ padding: "10px" }}>
            <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ประเภทงาน</Typography></Box>
            <Stack spacing={1} direction="row" justifyContent="space-between">
                <Box>
                    <TextField size="small" label='ค้นหา'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton size="small"  ><SearchIcon fontSize="inherit" /></IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Box>
                    <CreateCarsType />
                </Box>
            </Stack>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>ยี่ห้อ</TableCell>
                        <TableCell>ชื่อ</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        carsType.map((car, index) => {
                            return (
                                <TableRow>
                                    <TableCell>{(index + 1)}</TableCell>
                                    <TableCell>{car.make}</TableCell>
                                    <TableCell>{car.model}</TableCell>
                                    <TableCell align="right">
                                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                            <IconButton size="small" onClick={() => onClickUpdate(car)} >
                                                <FindInPageIcon />
                                            </IconButton>
                                            <UpdateCarsType data={car} />
                                            <IconButton size="small" >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>

        </div >
    )
}
