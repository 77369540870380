
import React from 'react'
import { Box } from '@mui/material'
import { styled } from '@mui/styles';
import { fDateTo } from '../../utils/formatTime';

export default function ReplayLabel({ markerSelect }) {
  return (
    <ReplayLabelStyle>
      <BoxLabelRow>
        <Box>วันที่ : </Box>
        <Box>
          {fDateTo(markerSelect?.datetime_gps, "dd-MM-yyyy HH:mm:ss")}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ความเร็ว : </Box>
        <Box>
          {markerSelect?.speed}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>driver : </Box>
        <Box>
          {markerSelect?.driver}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>status : </Box>
        <Box>
          {markerSelect?.status}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>odometer : </Box>
        <Box>
          {markerSelect?.odometer}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>engine_status : </Box>
        <Box>
          {markerSelect?.engine_status}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad0 : </Box>
        <Box>
          {markerSelect?.ad0}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad1 : </Box>
        <Box>
          {markerSelect?.ad1}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad2 : </Box>
        <Box>
          {markerSelect?.ad2}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad3 : </Box>
        <Box>
          {markerSelect?.ad3}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>ad4 : </Box>
        <Box>
          {markerSelect?.ad4}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>gps : </Box>
        <Box>
          {markerSelect?.gps}
        </Box>
      </BoxLabelRow>
      <BoxLabelRow>
        <Box>gsm : </Box>
        <Box>
          {markerSelect?.gsm}
        </Box>
      </BoxLabelRow>
    </ReplayLabelStyle>
  )
}

const BoxLabelRow = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between"
}));

const ReplayLabelStyle = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: "50px",
  top: "60px",
  [theme.breakpoints.up('md')]: {
    width: "250px",
    // height: "172px",
  },
  [theme.breakpoints.down('md')]: {
    left: "50px",
    height: "172px",
  },
  overflow: "auto",
  fontSize: "16px",
  borderColor: "white",
  borderWidth: "10px",
  borderStyle: "solid",
  borderRadius: "10px",
  zIndex: 999,
  backgroundColor: "white"
}));
