import { Box, IconButton, Menu, MenuItem, Typography, Badge } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip, ZoomControl } from 'react-leaflet'
import ListTileLayer from '../../layouts/ListTileLayer';
import LayersIcon from '@mui/icons-material/Layers';
import DashboardMarker from './DashboardMarker';
import { divIcon } from 'leaflet';
import DashboardSelect from './DashboardSelect';

import FmdGoodIcon from '@mui/icons-material/FmdGood';
import RouteIcon from '@mui/icons-material/Route';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';

import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import { useSnackbar } from 'notistack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PolylineUtil from 'polyline-encoded';
// import { useRecoilState } from 'recoil';
// import { mapState } from './DashboardStore';

import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import L from "leaflet";
import { Icon } from '@iconify/react';

const MapContainerStyle = styled(MapContainer)(({ theme }) => ({

    height: '100vh',
    // width: '100vw',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
}));
const center = [13.839660198254604, 100.63469639884744];
const zoom = 6;


export default function DashboardMap({ open, stationsDashboard = [], setMapDashboard, listDevices, userId, selectDevice, setSelectDevice, groupRoute = [], setView }) {
    const [map, setMap] = useState(null);
    // const [map, setMap] = useRecoilState(mapState);
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")
    const [routeMapStyle, setRouteMapStyle] = useState(localStorage.getItem("routeMapStyle") === "false" ? false : true)
    const [busStopMapStyle, setBusStopMapStyle] = useState(localStorage.getItem("busStopMapStyle") === "false" ? false : true)
    const [busStationsStyle, setBusStationsStyle] = useState(localStorage.getItem("busStationsStyle") === "false" ? false : true)
    const [openDropDown, setDropDown] = useState(false);
    const refDropDownMapStyle = useRef(null);

    const refMapStyle = useRef(null);
    const listTileLayer = ListTileLayer


    const GroupRouteMarkerIcon = ({ icon, w = 30, h = 30, x = 10, y = 10 }) => L.icon({
        iconUrl: icon,
        iconSize: [w, h],
        iconAnchor: [(w / 2 + x), ((h / 2) + y)],
        popupAnchor: [0, -10],
        shadowAnchor: [10, 10]
    });


    // const GroupRouteMarkerIcon = ({ icon }) => {
    //     return divIcon({
    //         className: "",
    //         html: `
    //   <img src="${icon}" width="30" />
    //       `,
    //         iconSize: [30, 30],
    //         iconAnchor: [(30 / 2 + 10), ((30 / 2) + 10)],
    //         popupAnchor: [0, -10],
    //         shadowAnchor: [10, 10]
    //     })
    // }

    const IconMarker = ({ className, icon, bearing }) => {
        return divIcon({
            className: className,
            html: `
      <img src="${icon}" width="20" class="icon-car-center" style="
      transform: rotate(${(360 - bearing)}deg);
      " />
          `,
            iconSize: [40, 40],
            iconAnchor: [19.5, 20],
            popupAnchor: [19.5, -20]
        })
    }
    const LabelMaker = ({ className, name }) => {

        return divIcon({
            className: className,
            html: `
            <div style="background-color: white;text-align: center;border-radius: 10px;border: 2px solid whitesmoke;height: 35px;font-weight: bold;padding-top:8px;white-space: nowrap;padding-left:8px;padding-right:8px;">${name}</div>
          `,
            iconSize: 'auto',
            // iconSize: [145, 40],
            iconAnchor: [((name.length * 9) / 2), 55],
            // popupAnchor: [70, -40]
        })
    }
    const LabelMakerP = ({ className, name }) => {

        return divIcon({
            className: className,
            html: `
            <div style="background-color: white;text-align: center;border-radius: 10px;border: 2px solid whitesmoke;height: 35px;font-weight: bold;padding-top:8px;white-space: nowrap;padding-left:8px;padding-right:8px;">${name}</div>
          `,
            iconSize: 'auto',
            // iconSize: [145, 40],
            iconAnchor: [((name.length * 7) / 2), 55],
            // popupAnchor: [70, -40]
        })
    }



    const getDataIndex = () => {
        const index = listDevices.findIndex(values => values.device === selectDevice)
        return index
    }

    const getDataStation = (device) => {
        const data = listDevices.find(values => values.device === device)
        return data
    }



    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbarWithAction = ({ message, variant, autoHideDuration = 1000, horizontal = "right", vertical = "bottom" }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: autoHideDuration,
                anchorOrigin: {
                    horizontal: horizontal,
                    vertical: vertical
                },
                variant: variant,
                action: (key) => (
                    <IconButton
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                )
            });
    }


    const routeReverse = (direction, stations) => {

        if (direction === "DEPARTURE") {
            return [...stations]
        }

        if (direction === "ARRIVAL") {
            return [...stations].reverse()
        }

        return []

        // return direction === "DEPARTURE" ? [...stations] : [...stations].reverse()
    }


    return (
        <Box sx={{
            display: setView ? "unset" : "none"
        }}>
            {
                busStationsStyle && stationsDashboard?.length > 0 && (
                    <Box style={
                        {
                            position: "absolute",
                            display: 'block',
                            // marginRight: "50px",
                            marginTop: "5px",
                            zIndex: 2,
                            backgroundColor: "white",
                            maxWidth: open ? "calc(100% - 400px - 55px)" : "calc(100% - 102px - 55px)",
                            minWidth: open ? "calc(100% - 400px - 55px)" : "calc(100% - 102px - 55px)",
                            marginLeft: open ? "7px" : "107px",
                            borderRadius: "5px",
                            overflow: "hidden",
                            // maxHeight: "120px",
                            // overflowY: "auto"
                        }
                    }
                    >
                        {
                            stationsDashboard?.map((routes, route_index) => {
                                return (
                                    <Box
                                        key={`isroutes` + route_index}
                                    >
                                        <Box sx={{
                                            paddingLeft: "20px",
                                            paddingTop: "10px",
                                        }}>
                                            <Typography>{routes.direction}</Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                height: "80px",
                                                flexDirection: "row",
                                                display: "flex",
                                                overflow: "auto"

                                            }}
                                        >
                                            <Box sx={{
                                                position: "relative",
                                                // height: "5px",
                                                backgroundColor: routes.color,
                                                marginTop: "40px",
                                                marginRight: "25px"
                                                // marginBottom: "40px",
                                            }}
                                            >
                                                <Box
                                                    style={{
                                                        position: "absolute",
                                                        top: "-12px",
                                                        width: "30px",
                                                        height: "30px",
                                                        borderRadius: "50%",
                                                        backgroundColor: "whitesmoke",
                                                        // borderColor: "red",
                                                        borderWidth: "1px",
                                                        borderStyle: "solid",
                                                        paddingLeft: "2px",
                                                        paddingTop: "2px",
                                                        paddingBottom: "3px",
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                        // marginLeft: `${20}px`,
                                                        zIndex: 2,
                                                    }} >
                                                    {
                                                        routes.direction === "DEPARTURE" && (
                                                            <NavigateNextIcon fontSize="medium" color="info" />
                                                        )
                                                    }
                                                    {
                                                        routes.direction === "ARRIVAL" && (
                                                            <NavigateBeforeIcon fontSize="medium" color="info" />
                                                        )
                                                    }

                                                </Box>
                                            </Box>
                                            {
                                                routeReverse(routes.direction, routes?.stations).map((value, index) => (
                                                    value.name !== "" ?
                                                        <Box sx={{
                                                            position: "relative",
                                                            height: "5px",
                                                            backgroundColor: routes.color,
                                                            marginTop: "40px",
                                                            // marginBottom: "40px",
                                                            paddingLeft: "75px",
                                                            paddingRight: "75px",
                                                            minWidth: `${175}px`,
                                                            maxWidth: `${175}px`
                                                        }}
                                                            key={`index_route` + index}
                                                        >
                                                            <Box
                                                                style={{
                                                                    position: "absolute",
                                                                    top: "-10px",
                                                                    width: "25px",
                                                                    height: "25px",
                                                                    borderRadius: value.devices.length > 0 ? "0px" : "50%",
                                                                    // backgroundColor: value.color,
                                                                    backgroundColor: value.devices.length > 0 ? "white" : value.color,
                                                                    // borderColor: "red",
                                                                    // borderWidth: "1px",
                                                                    // borderStyle: "solid",
                                                                    paddingLeft: "2px",
                                                                    paddingBottom: "3px",
                                                                    fontSize: "12px",
                                                                    fontWeight: "bold",
                                                                    color: "white",
                                                                    // marginLeft: `${20}px`,
                                                                    zIndex: 2,
                                                                }} >
                                                                <label style={{
                                                                    position: "absolute",
                                                                    // top: "-20px",
                                                                    color: "black",
                                                                    display: "inline-block",
                                                                    textAlign: "center",
                                                                    transform: "translate3d(-40%, -20px, 0px)",
                                                                    whiteSpace: "nowrap",
                                                                    maxWidth: "175px",
                                                                    // width: "130px",
                                                                    textOverflow: "ellipsis",
                                                                    overflow: "hidden"
                                                                }}>
                                                                    {value.name}
                                                                </label>
                                                                {
                                                                    value.devices.length > 0 && (
                                                                        <Badge anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                            sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 }, cursor: value.devices.length > 0 ? "pointer" : "default" }}
                                                                            color="error" overlap="circular" showZero={false} badgeContent={value.devices.length}
                                                                            onClick={() => {
                                                                                if (value.devices.length > 0) {
                                                                                    const deviceFrist = value.devices[0]
                                                                                    setSelectDevice(deviceFrist.device)
                                                                                    const dataDevice = getDataStation(deviceFrist.device)
                                                                                    map.setView([dataDevice.latitude, dataDevice.longitude], 19, {
                                                                                        "animate": true,
                                                                                        "duration": 1,
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            {/* <DirectionsBusIcon fontSize="small" /> */}
                                                                            <img style={{ marginLeft: "-5px", marginTop: "-5px" }} src={value.devices[0].icon} alt='iconcarroute' width="30px" height="30px" />
                                                                        </Badge>
                                                                    )
                                                                }
                                                                {/* <span>{value.devices.length}</span> */}

                                                            </Box>
                                                        </Box>
                                                        :
                                                        <Box sx={{
                                                            position: "relative",
                                                            height: "5px",
                                                            backgroundColor: routes.color,
                                                            marginTop: "40px",
                                                            paddingLeft: "15px",
                                                            paddingRight: "15px",
                                                            minWidth: `${40}px`,
                                                            maxWidth: `${40}px`
                                                        }}
                                                            key={`index_route` + index}
                                                        >
                                                            {
                                                                value.devices.length > 0 && (
                                                                    <Box
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "-14px",
                                                                            zIndex: 2,
                                                                            backgroundColor: "white"
                                                                        }}
                                                                    >
                                                                        <Badge anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                            sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 }, cursor: value.devices.length > 0 ? "pointer" : "default" }}
                                                                            color="error" overlap="circular" showZero={false} badgeContent={value.devices.length}
                                                                            onClick={() => {
                                                                                if (value.devices.length > 0) {
                                                                                    const deviceFrist = value.devices[0]
                                                                                    setSelectDevice(deviceFrist.device)
                                                                                    const dataDevice = getDataStation(deviceFrist.device)
                                                                                    map.setView([dataDevice.latitude, dataDevice.longitude], 19, {
                                                                                        "animate": true,
                                                                                        "duration": 1,
                                                                                    })
                                                                                }
                                                                            }}
                                                                        >
                                                                            {/* <DirectionsBusIcon fontSize="small" /> */}
                                                                            <img style={{ marginLeft: "-5px", marginTop: "-5px" }} src={value.devices[0].icon} alt='iconcarroute' width="30px" height="30px" />
                                                                        </Badge>
                                                                    </Box>
                                                                )
                                                            }
                                                            {
                                                                value.devices.length === 0 && (
                                                                    <Box
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "-2.5px",
                                                                            width: "10px",
                                                                            height: "10px",
                                                                            borderRadius: "50%",
                                                                            backgroundColor: value.color,
                                                                            // borderColor: "red",
                                                                            borderWidth: "1px",
                                                                            borderStyle: "solid",
                                                                            paddingLeft: "5px",
                                                                            paddingBottom: "4px",
                                                                            fontSize: "12px",
                                                                            fontWeight: "bold",
                                                                            color: "white",
                                                                            // left: `${15}px`,
                                                                            zIndex: 2,
                                                                        }} >
                                                                        <label style={{
                                                                            position: "absolute",
                                                                            // top: "-20px",
                                                                            color: "black",
                                                                            display: "block",
                                                                            textAlign: "center",
                                                                            transform: "translate3d(-40%, -20px, 10px)"
                                                                        }}>
                                                                        </label>
                                                                        {/* <span>{index}</span> */}
                                                                    </Box>
                                                                )
                                                            }
                                                        </Box>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                )
            }
            <MapContainerStyle
                center={center}
                zoom={zoom}
                minZoom={3}

                //////////////////

                contextmenu={true}
                contextmenuItems={[
                    {
                        text: "latlng",
                        callback: (e) => {
                            handleSnackbarWithAction({ message: `Copied @${e.latlng.lat} , ${e.latlng.lng}`, autoHideDuration: 3000, horizontal: "center" })
                            navigator.clipboard.writeText([e.latlng.lat, e.latlng.lng])
                        },
                    }
                ]}

                //////////////////

                zoomControl={false}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                attributionControl={false}
                // whenCreated={setMap}
                // whenReady={
                //     () => {
                //         if (selectDevice !== null && map !== null) {
                //             map.setView([listDevices[getDataIndex()].latitude, listDevices[getDataIndex()].longitude], map.getZoom(), {
                //                 "animate": true,
                //                 "duration": 1,
                //             })
                //         }
                //     }
                // }
                whenCreated={maps => {
                    setTimeout(() => { maps.invalidateSize() }, 100)
                    setMap(maps)
                    setMapDashboard(maps)
                    if (selectDevice !== null) {
                        maps.setView([listDevices[getDataIndex()].latitude, listDevices[getDataIndex()].longitude], 19, {
                            "animate": true,
                            "duration": 1,
                        })
                    }
                }}

            >
                <TileLayer ref={refMapStyle}
                    url={listTileLayer.find(value => value.id === selectedMapStyle).url}
                    maxNativeZoom={19}
                    maxZoom={22}
                />
                <ZoomControl position='topright' />


                {
                    listDevices.map((_, index) =>
                    (
                        <React.Fragment key={'markersFragment' + index}>
                            <DashboardMarker key={'marker' + index} onClick={() => {
                                setSelectDevice(null)
                                map.flyTo([_.latitude, _.longitude], 19, {
                                    "animate": true,
                                    "duration": 1,
                                })
                                setSelectDevice(_.device)
                            }}
                                icon={index === getDataIndex() ? IconMarker({ className: "icon-car-focus", icon: _.icon, bearing: _.bearing }) : new Date(_.datetime) < new Date().setMinutes(new Date().getMinutes() - 5) ? IconMarker({ className: 'icon-car-offline', icon: _.icon, bearing: _.bearing }) : IconMarker({ className: 'icon-car', icon: _.icon, bearing: _.bearing })}
                                data={_}
                                selected={index === getDataIndex()}
                            />
                            <Marker
                                key={'markers' + index}
                                eventHandlers={{
                                    click: () => {
                                        setSelectDevice(null)
                                        map.flyTo([_.latitude, _.longitude], 19, {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                        setSelectDevice(_.device)
                                    }
                                }}
                                icon={LabelMaker({ className: index === getDataIndex() ? 'label-maker-focus' : 'label-maker', name: _.name })}
                                position={[_.latitude, _.longitude]}
                            >

                            </Marker>
                        </React.Fragment>
                    ))
                }

                {/* {
                    groupRoute?.encode !== undefined && (
                        <Polyline positions={PolylineUtil.decode(groupRoute?.encode)} weight={8} color="#42bd41" />
                    )
                } */}
                {
                    groupRoute.map((x, i) => (
                        <React.Fragment key={'polylineGroup' + i}>
                            {
                                routeMapStyle && (
                                    <Polyline positions={PolylineUtil.decode(x?.encode)} weight={x?.width} color={x?.color}

                                        eventHandlers={{
                                            click: () => {

                                            }
                                        }}

                                    // className="sss"

                                    />
                                )
                            }
                            {
                                busStopMapStyle && (
                                    <>
                                        {
                                            x.markers.map((_, _i) => (
                                                <React.Fragment key={_i}>
                                                    {
                                                        _.style !== null && (
                                                            <Marker
                                                                key={'markersroute' + _i}
                                                                zIndexOffset={-9999}
                                                                // icon={GroupRouteMarkerIcon({ icon: _.icon })}
                                                                icon={GroupRouteMarkerIcon({ icon: _.style.icon, w: _.style.w, h: _.style.h, x: _.style.x, y: _.style.y })}
                                                                // icon={GroupRouteMarkerIcon({ icon: _.style.icon, w: _.style.w, h: _.style.h, x: _.style.x, y: _.style.y })}
                                                                position={[_.latitude, _.longitude]}
                                                            >
                                                                {/* 
                                                            <Popup>
                                                                {
                                                                    _.name
                                                                }
                                                            </Popup>
                                                             */}

                                                                <Tooltip permanent direction="top" offset={[0, -15]} >
                                                                    {
                                                                        _.name
                                                                    }
                                                                </Tooltip>
                                                            </Marker>
                                                        )
                                                    }
                                                    {/* <Marker
                                                        key={'L' + _i}
                                                        icon={LabelMakerP({ className: 'label-maker', name: _.name })}
                                                        position={[_.latitude, _.longitude]}
                                                    /> */}
                                                </React.Fragment>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </React.Fragment>
                    ))
                }

            </MapContainerStyle>
            <Box style={
                {
                    position: 'fixed',
                    display: 'block',
                    backdropFilter: 'blur(10px)',
                    border: "2px solid rgba(0,0,0,0.2)",
                    borderRadius: 5,
                    top: 95,
                    right: 10,
                    width: 32,
                    height: 32,
                    zIndex: 2,
                }
            }
                ref={refDropDownMapStyle}
            >
                <IconButton edge="end" size="small"
                    style={{ backgroundColor: 'white', borderRadius: 5, }}
                    onClick={() => {
                        setDropDown(!openDropDown)
                    }}>
                    <LayersIcon fontSize="inherit" />
                </IconButton>
                <Menu
                    open={openDropDown}
                    anchorEl={refDropDownMapStyle.current}
                    onClose={() => setDropDown(!openDropDown)}
                    PaperProps={{
                        sx: { width: 200, maxWidth: '100%' }
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    {
                        listTileLayer.map((value, index) =>
                            <MenuItem
                                sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                                onClick={
                                    () => {
                                        setDropDown(!openDropDown)
                                        if (refMapStyle.current) {
                                            refMapStyle.current.setUrl(value.url);
                                            setSelectedMapStyle(value.id)
                                            localStorage.setItem('selectedMapStyle', value.id)
                                        }
                                    }
                                }
                                key={'MenuItem' + index}
                            >
                                {
                                    value.name
                                }
                            </MenuItem>
                        )
                    }
                </Menu>
            </Box>
            <Box style={
                {
                    position: 'fixed',
                    display: 'block',
                    backdropFilter: 'blur(10px)',
                    border: "2px solid rgba(0,0,0,0.2)",
                    borderRadius: 5,
                    top: 130,
                    right: 10,
                    width: 32,
                    height: 32,
                    zIndex: 2,
                }
            }
            >
                <IconButton
                    edge="end" size="small"
                    style={{ backgroundColor: 'white', borderRadius: 5, }}
                    onClick={() => {
                        setRouteMapStyle(!routeMapStyle)
                        localStorage.setItem('routeMapStyle', String(!routeMapStyle))
                    }}
                >
                    <RouteIcon color={routeMapStyle ? 'action' : "disabled"} fontSize="inherit" />
                </IconButton>
            </Box>
            <Box style={
                {
                    position: 'fixed',
                    display: 'block',
                    backdropFilter: 'blur(10px)',
                    border: "2px solid rgba(0,0,0,0.2)",
                    borderRadius: 5,
                    top: 165,
                    right: 10,
                    width: 32,
                    height: 32,
                    zIndex: 2,
                }
            }
            >
                <IconButton
                    edge="end" size="small"
                    style={{ backgroundColor: 'white', borderRadius: 5, }}
                    onClick={() => {
                        setBusStopMapStyle(!busStopMapStyle)
                        localStorage.setItem('busStopMapStyle', String(!busStopMapStyle))
                    }}
                >
                    <FmdGoodIcon color={busStopMapStyle ? "action" : "disabled"} fontSize="inherit" />
                </IconButton>
            </Box>
            <Box style={
                {
                    position: 'fixed',
                    display: 'block',
                    backdropFilter: 'blur(10px)',
                    border: "2px solid rgba(0,0,0,0.2)",
                    borderRadius: 5,
                    top: 200,
                    right: 10,
                    width: 32,
                    height: 32,
                    zIndex: 2,
                }
            }
            >
                <IconButton
                    edge="end" size="small"
                    style={{ backgroundColor: 'white', borderRadius: 5, }}
                    onClick={() => {
                        setBusStationsStyle(!busStationsStyle)
                        localStorage.setItem('busStationsStyle', String(!busStationsStyle))
                    }}
                >
                    <MultipleStopIcon color={busStationsStyle ? "action" : "disabled"} fontSize="inherit" />
                </IconButton>
            </Box>
            {/* ---------------------------------------------------------------------- */}
            {
                selectDevice !== null && (
                    <Box sx={theme => (
                        {
                            position: 'absolute',
                            bottom: 5,
                            zIndex: 2,
                            left: "2%",
                            right: "2%",
                            [theme.breakpoints.up('md')]: {
                                bottom: 10,
                                left: "410px",
                                right: "10px",
                            },
                            // [theme.breakpoints.up('lg')]: {
                            //     bottom: 10,
                            //     left: "30%",
                            //     right: 420,
                            // },
                        }
                    )}>
                        <div
                            style={{
                                // margin: "auto",
                                minWidth: "100%",
                                // backdropFilter: 'blur(4px)',
                                backgroundColor: "white",
                                borderRadius: 5
                            }}
                        >
                            <DashboardSelect
                                onClick={
                                    () => {
                                        map.setView([listDevices[getDataIndex()].latitude, listDevices[getDataIndex()].longitude], map.getZoom(), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                }
                                onClose={
                                    () => {
                                        setSelectDevice(null)
                                    }
                                }
                                centerFocus={true}
                                data={listDevices[getDataIndex()]}
                                mapControl={map}
                                userId={userId}
                            />
                        </div>
                    </Box>
                )
            }
            {/* ---------------------------------------------------------------------- */}
        </Box >
    )
}
