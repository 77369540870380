import React, { useEffect, useState } from 'react'
import { styled } from '@mui/styles';
import { Box, IconButton, Slider } from '@mui/material'
import { fDateTo } from '../../utils/formatTime';
import PlayCircleTwoToneIcon from '@mui/icons-material/PlayCircleTwoTone';
import PauseCircleFilledTwoToneIcon from '@mui/icons-material/PauseCircleFilledTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';

const PlayBackStyle = styled(Box)(({ theme }) => ({
    // position: "relative",
    // left: 10,
    // right: 10,
    // bottom: 10,
    // backgroundColor: "transparent",
    // zIndex: 1,
    // [theme.breakpoints.down('md')]: {
    //     bottom: 10,
    // },
}));

export default function PlayReplay({ listTables, setMarkerSelect, setMove, sliderValue, setSliderValue, markerSelect, virtuoso }) {

    const itemLength = listTables.length

    const [play, setPlay] = useState(false)
    const [duration, setDuration] = useState(1000);
    const [durationSpeed, setDurationSpeed] = useState('1x');

    

    useEffect(() => {
        var intervalId
        if (play && sliderValue < itemLength - 1) {
            intervalId = setInterval(() => {
                // setMove(false)
                const isReplay = listTables[sliderValue + 1]
                setMarkerSelect(isReplay)
                setSliderValue(sliderValue + 1);
                // const element = document.getElementById("tableRow" + (sliderValue + 1));
                // element?.scrollIntoView({ block: "end" })
                virtuoso.current?.scrollToIndex({
                    index: (sliderValue + 1),
                    align: "start",
                    behavior: "smooth"
                });
            }, duration);
        }
        return () => clearInterval(intervalId);
    }, [play, sliderValue, listTables]);
    const stopNplay = (p) => {
        setPlay(p)
        if (p) {
            if (markerSelect === null) {
                // const element = document.getElementById("tableRow" + sliderValue);
                // element.scrollIntoView({ block: "end" })
                virtuoso.current?.scrollToIndex({
                    index: sliderValue,
                    align: "start",
                    behavior: "smooth"
                });
                // setMove(false)
                const isReplay = listTables[sliderValue]
                setMarkerSelect(isReplay)
                setSliderValue(sliderValue);
            } else {
                // const element = document.getElementById("tableRow" + (sliderValue + 1));
                // element.scrollIntoView({ block: "end" })
                virtuoso.current?.scrollToIndex({
                    index: (sliderValue + 1),
                    align: "start",
                    behavior: "smooth"
                });
                // setMove(false)
                const isReplay = listTables[sliderValue + 1]
                setMarkerSelect(isReplay)
                setSliderValue(sliderValue + 1);
            }
        }
    }



    return (
        <PlayBackStyle>
            {
                itemLength !== 0 && (
                    <Box sx={{
                        height: "40px",
                        backgroundColor: "#333333",
                        borderRadius: "10px",
                        display: "flex",
                        flexDirection: "row",
                        color: "white",
                        padding: "5px",
                        position: "relative"
                    }}>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            {
                                itemLength - 1 === sliderValue ?
                                    <IconButton
                                        size="small"
                                        onClick={() => {
                                            // const element = document.getElementById("tableRow" + 0);
                                            // element.scrollIntoView({ block: "end" })
                                            setSliderValue(0)
                                        }}
                                    >
                                        <ReplayTwoToneIcon />
                                    </IconButton>
                                    :
                                    play ?
                                        <IconButton
                                            size="small"
                                            onClick={
                                                () => {
                                                    stopNplay(false)
                                                }
                                            }
                                        >
                                            <PauseCircleFilledTwoToneIcon />
                                        </IconButton>
                                        :
                                        <IconButton
                                            size="small"
                                            onClick={
                                                () => {
                                                    stopNplay(true)
                                                }
                                            }
                                        >
                                            <PlayCircleTwoToneIcon />
                                        </IconButton>
                            }
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px"
                        }}>
                            <IconButton
                                size="small"
                                onClick={
                                    () => {
                                        if (duration === 1000) {
                                            setDuration(500)
                                            setDurationSpeed('2x')
                                        }
                                        if (duration === 500) {
                                            setDuration(250)
                                            setDurationSpeed('4x')

                                        }
                                        if (duration === 250) {
                                            setDuration(125)
                                            setDurationSpeed('8x')
                                        }
                                        if (duration === 125) {
                                            setDuration(1000)
                                            setDurationSpeed('1x')
                                        }
                                    }
                                }
                            >
                                <span style={{
                                    fontSize: '1rem',
                                    fontWeight: '300'
                                }}>
                                    {
                                        durationSpeed
                                    }
                                </span>
                            </IconButton>
                        </Box>
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px"
                        }}>
                            <Slider
                                size="small"
                                max={itemLength - 1}
                                aria-label="Temperature"
                                defaultValue={0}
                                value={sliderValue}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => {
                                    return fDateTo(listTables[value].datetime_gps, "dd/MM/yyyy HH:mm:ss")
                                }}
                                onChange={(_, value) => {
                                    if (value < itemLength) {
                                        // const element = document.getElementById("tableRow" + value);
                                        // element.scrollIntoView({ block: "end" })
                                        virtuoso.current?.scrollToIndex({
                                            index: value,
                                            align: "start",
                                            behavior: "smooth"
                                        });
                                        setSliderValue(value)
                                        setMarkerSelect(listTables[value])
                                        // setMove(false)
                                    }
                                }}
                                color="secondary"
                            />
                        </Box>
                    </Box>
                )
            }
        </PlayBackStyle >
    )
}