import React from 'react'
import { Box, Typography, Badge } from '@mui/material';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default function BusStations({ onClickSelectDivce, subStations }) {


    // {
    //     busStationsStyle && stationsDashboard?.length > 0 && (
    const routeReverse = (direction, stations) => {

        if (direction === "DEPARTURE") {
            return [...stations]
        }

        if (direction === "ARRIVAL") {
            return [...stations].reverse()
        }

        return []

    }


    // const getDataStation = (device) => {
    //     const data = listDevices.find(values => values.device === device)
    //     return data
    // }
    return (
        <Box style={
            {
                position: "absolute",
                display: 'block',
                // marginRight: "50px",
                marginTop: "5px",
                zIndex: 2,
                backgroundColor: "white",
                maxWidth: "calc(100% - 102px)",
                minWidth: "calc(100% - 102px)",
                marginLeft: "50px",
                borderRadius: "5px",
                overflow: "hidden",
                top: "2px"
            }
        }
        >
            {
                subStations?.map((routes, route_index) => {
                    return (
                        <Box
                            key={`isroutes` + route_index}
                        >
                            <Box sx={{
                                paddingLeft: "20px",
                                paddingTop: "10px",
                            }}>
                                <Typography>{routes.direction}</Typography>
                            </Box>
                            <Box
                                sx={{
                                    height: "80px",
                                    flexDirection: "row",
                                    display: "flex",
                                    overflow: "auto"

                                }}
                            >
                                <Box sx={{
                                    position: "relative",
                                    // height: "5px",
                                    backgroundColor: routes.color,
                                    marginTop: "40px",
                                    marginRight: "25px"
                                }}
                                >
                                    <Box
                                        style={{
                                            position: "absolute",
                                            top: "-12px",
                                            width: "30px",
                                            height: "30px",
                                            borderRadius: "50%",
                                            backgroundColor: "whitesmoke",
                                            borderWidth: "1px",
                                            borderStyle: "solid",
                                            paddingLeft: "2px",
                                            paddingTop: "2px",
                                            paddingBottom: "3px",
                                            fontSize: "12px",
                                            fontWeight: "bold",
                                            color: "white",
                                            zIndex: 2,
                                        }} >
                                        {
                                            routes.direction === "DEPARTURE" && (
                                                <NavigateNextIcon fontSize="medium" color="info" />
                                            )
                                        }
                                        {
                                            routes.direction === "ARRIVAL" && (
                                                <NavigateBeforeIcon fontSize="medium" color="info" />
                                            )
                                        }

                                    </Box>
                                </Box>
                                {
                                    routeReverse(routes.direction, routes?.stations).map((value, index) => (
                                        value.name !== "" ?
                                            <Box sx={{
                                                position: "relative",
                                                height: "5px",
                                                backgroundColor: routes.color,
                                                marginTop: "40px",
                                                paddingLeft: "75px",
                                                paddingRight: "75px",
                                                minWidth: `${175}px`,
                                                maxWidth: `${175}px`
                                            }}
                                                key={`index_route` + index}
                                            >
                                                <Box
                                                    style={{
                                                        position: "absolute",
                                                        top: "-10px",
                                                        width: "25px",
                                                        height: "25px",
                                                        borderRadius: value.devices.length > 0 ? "0px" : "50%",
                                                        backgroundColor: value.devices.length > 0 ? "white" : value.color,
                                                        paddingLeft: "2px",
                                                        paddingBottom: "3px",
                                                        fontSize: "12px",
                                                        fontWeight: "bold",
                                                        color: "white",
                                                        zIndex: 2,
                                                    }} >
                                                    <label style={{
                                                        position: "absolute",
                                                        color: "black",
                                                        display: "inline-block",
                                                        textAlign: "center",
                                                        transform: "translate3d(-40%, -20px, 0px)",
                                                        whiteSpace: "nowrap",
                                                        maxWidth: "175px",
                                                        textOverflow: "ellipsis",
                                                        overflow: "hidden"
                                                    }}>
                                                        {value.name}
                                                    </label>
                                                    {
                                                        value.devices.length > 0 && (
                                                            <Badge anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                                sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 }, cursor: value.devices.length > 0 ? "pointer" : "default" }}
                                                                color="error" overlap="circular" showZero={false} badgeContent={value.devices.length}
                                                                onClick={() => {
                                                                    if (value.devices.length > 0) {
                                                                        const deviceFrist = value.devices[0]
                                                                        onClickSelectDivce({ device: deviceFrist.device, isZoom: true })
                                                                        // console.log('deviceFrist.device', deviceFrist.device)
                                                                    }
                                                                }}
                                                            >
                                                                {/* <DirectionsBusIcon fontSize="small" /> */}
                                                                <img style={{ marginLeft: "-5px", marginTop: "-5px" }} src={value.devices[0].icon} alt='iconcarroute' width="30px" height="30px" />
                                                            </Badge>
                                                        )
                                                    }

                                                </Box>
                                            </Box>
                                            :
                                            <Box sx={{
                                                position: "relative",
                                                height: "5px",
                                                backgroundColor: routes.color,
                                                marginTop: "40px",
                                                paddingLeft: "15px",
                                                paddingRight: "15px",
                                                minWidth: `${40}px`,
                                                maxWidth: `${40}px`
                                            }}
                                                key={`index_route` + index}
                                            >
                                                {
                                                    value.devices.length > 0 && (
                                                        <Box
                                                            style={{
                                                                position: "absolute",
                                                                top: "-14px",
                                                                zIndex: 2,
                                                                backgroundColor: "white"
                                                            }}
                                                        >
                                                            <Badge anchorOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'right',
                                                            }}
                                                                sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15 }, cursor: value.devices.length > 0 ? "pointer" : "default" }}
                                                                color="error" overlap="circular" showZero={false} badgeContent={value.devices.length}
                                                                onClick={() => {
                                                                    if (value.devices.length > 0) {
                                                                        const deviceFrist = value.devices[0]
                                                                        onClickSelectDivce({ device: deviceFrist.device, isZoom: true })
                                                                        // console.log('deviceFrist.device', deviceFrist.device)
                                                                    }
                                                                }}
                                                            >
                                                                <img style={{ marginLeft: "-5px", marginTop: "-5px" }} src={value.devices[0].icon} alt='iconcarroute' width="30px" height="30px" />
                                                            </Badge>
                                                        </Box>
                                                    )
                                                }
                                                {
                                                    value.devices.length === 0 && (
                                                        <Box
                                                            style={{
                                                                position: "absolute",
                                                                top: "-2.5px",
                                                                width: "10px",
                                                                height: "10px",
                                                                borderRadius: "50%",
                                                                backgroundColor: value.color,
                                                                borderWidth: "1px",
                                                                borderStyle: "solid",
                                                                paddingLeft: "5px",
                                                                paddingBottom: "4px",
                                                                fontSize: "12px",
                                                                fontWeight: "bold",
                                                                color: "white",
                                                                zIndex: 2,
                                                            }} >
                                                            <label style={{
                                                                position: "absolute",
                                                                color: "black",
                                                                display: "block",
                                                                textAlign: "center",
                                                                transform: "translate3d(-40%, -20px, 10px)"
                                                            }}>
                                                            </label>
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                    ))
                                }
                            </Box>
                        </Box>
                    )
                })
            }
        </Box>
    )
}
