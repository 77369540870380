import styled from '@emotion/styled';
import { Autocomplete, Box, Collapse, RadioGroup, FormControlLabel, Radio, Container, Divider, Grid, List, ListItemButton, Button, ListItemText, TextField, Typography } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Api } from '../../components/api';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { format } from 'date-fns';


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto'
}));

export default function EditAccount({ account }) {



    const navigate = useNavigate();
    const location = useLocation()
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }
    const [open, setOpen] = useState({ required: true, attributes: false })
    const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;

    const DeviceSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last Name is required'),
        birthday: Yup.string().required('Birthday is required'),
        phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required')
    })
    const { state } = location



    useEffect(() => {
        if (state === null) {
            navigate('/settings/accounts')
        }
    }, [state])


    const formik = useFormik({
        initialValues: {
            cogcode: state?.cogcode,
            cocode: state?.cocode,
            id: state?.id,
            contype: state?.contype,
            concode: state?.concode,
            prefixid: state?.prefixid,
            firstname: state?.firstname,
            lastname: state?.lastname,
            sex: state?.sex,
            PassportID: state?.PassportID,
            ThaiID: state?.ThaiID,
            membercard: state?.membercard,
            phone: state?.phone,
            status: state?.status,
            birthday: new Date(state?.birthday),
            email: state?.email,
            c_coname: state?.c_coname,
            c_cobcode: state?.c_cobcode,
            c_cobname: state?.c_cobname,
            taxid: state?.taxid,
            positionid: state?.positionid
        },
        validationSchema: DeviceSchema,
        onSubmit: async (values) => {
            const data = JSON.stringify({
                ...values,
                birthday: format(
                    new Date(values.birthday),
                    'yyyy-MM-dd'
                )
            });
            await Api.put('v0/if/Contact', data)
                .then(res => {
                    navigate('/settings/accounts')
                    handleSnackbar({ variant: 'success', message: 'success!' })
                }
                ).catch(error => {
                    const { message } = error
                    handleSnackbar({ variant: 'error', message: message });
                }
                )
        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;
    const handleClick = (name) => {
        setOpen({ ...open, [name]: !open[name] });
    };
    const handleOnClose = () => {
        navigate('/settings/accounts')
    }
    return (
        <Page>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Account</Typography>
                </Box>
                <Divider />
                <MainStyle>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                                <Box width={400} margin="auto" padding={2}
                                // border={1} borderRadius={1}
                                >
                                    <List
                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        <ListItemButton onClick={() => {
                                            handleClick("required")
                                        }} >
                                            <ListItemText primary="required" />
                                            {
                                                open.required ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />
                                            }
                                        </ListItemButton>
                                        <Collapse in={open.required} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="ชื่อ"
                                                            {...getFieldProps('firstname')}
                                                            error={Boolean(touched.firstname && errors.firstname)}
                                                            helperText={touched.firstname && errors.firstname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="นามสกุล"
                                                            {...getFieldProps('lastname')}
                                                            error={Boolean(touched.lastname && errors.lastname)}
                                                            helperText={touched.lastname && errors.lastname}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MobileDatePicker
                                                            disableFuture={true}
                                                            label="วันเกิด"
                                                            allowKeyboardControl={false}
                                                            value={values.birthday}
                                                            inputFormat="dd/MM/yyyy"
                                                            format="dd/MM/yyyy"
                                                            onChange={(val) => {
                                                                setFieldValue('birthday', val);
                                                            }}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    size="small"
                                                                    type="text"
                                                                    placeholder="DD/MM/YYYY"
                                                                    autoComplete="off"
                                                                    {...getFieldProps('birthday')}
                                                                    error={Boolean(touched.birthday && errors.birthday)}
                                                                    helperText={touched.birthday && errors.birthday}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <RadioGroup
                                                            {...getFieldProps('sex')}
                                                            row
                                                        >
                                                            <FormControlLabel value="M" control={<Radio size="small" />} label="ชาย" />
                                                            <FormControlLabel value="F" control={<Radio size="small" />} label="หญิง" />
                                                            <FormControlLabel value={''} control={<Radio size="small" />} label="อื่นๆ" />
                                                        </RadioGroup>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="Email"
                                                            // inputMode="email"
                                                            {...getFieldProps('email')}
                                                            error={Boolean(touched.email && errors.email)}
                                                            helperText={touched.email && errors.email}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            inputMode="tel"
                                                            label="Phone"
                                                            {...getFieldProps('phone')}
                                                            error={Boolean(touched.phone && errors.phone)}
                                                            helperText={touched.phone && errors.phone}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        {/* <ListItemButton onClick={() => {
                                            handleClick("attributes")
                                        }} >
                                            <ListItemText primary="Attributes" />
                                            {
                                                open.attributes ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.attributes} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="ชื่อ"
                                                            {...getFieldProps('name')}
                                                            error={Boolean(touched.name && errors.name)}
                                                            helperText={touched.name && errors.name}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} />
                                        <ListItemButton onClick={() => {
                                            handleClick("extra")
                                        }} >
                                            <ListItemText primary="Extra" />
                                            {
                                                open.extra ?
                                                    <ExpandLess />
                                                    :
                                                    <ExpandMore />

                                            }
                                        </ListItemButton>
                                        <Collapse in={open.extra} timeout="auto" unmountOnExit>
                                            <Box padding={2} >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            size="small"
                                                            type="text"
                                                            label="extra"
                                                            {...getFieldProps('extra')}
                                                            error={Boolean(touched.extra && errors.extra)}
                                                            helperText={touched.extra && errors.extra}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Collapse>
                                        <Divider style={{ marginTop: 10 }} /> */}
                                        <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                                            <Grid item xs={6} justifyContent="center">
                                                <Button
                                                    fullWidth
                                                    onClick={handleOnClose}
                                                    size="medium"
                                                    variant="outlined"
                                                    color="info"
                                                // fullWidth
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6} justifyContent="center">
                                                <LoadingButton
                                                    fullWidth
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    color="info"
                                                    loading={isSubmitting}
                                                >
                                                    Save
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </List>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </MainStyle>
            </Container>
        </Page>
    )
}
