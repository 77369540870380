import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Box, Card, CardHeader, Container, Button,ListItemText, Backdrop, Stack, TextField, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { fDateTo } from '../../utils/formatTime';
import * as Yup from 'yup';
import { Navigate, useLocation } from 'react-router-dom';
import { Api } from '../../components/api';
import { useSnackbar } from 'notistack';

import "./chartDownloadPng.css"
import moment from 'moment';
import HasPermission from '../../haspermission/HasPermission';

export default function SpeedChartMonth({ userId, account, permissions }) {
    const [devices, setDevices] = useState([])
    const [loading, setLoading] = useState(true)
    const [defaultDevice, setDefaultDevice] = useState(null)
    const location = useLocation()

    useEffect(() => {
        Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
            var devices_ = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
            setDevices(devices_);
            if (location.state?.defaultValue !== undefined) {
                setDefaultDevice(devices_.find(d => d.device === location.state.defaultValue))
            }
            setLoading(loading => !loading)
        }
        ).catch(e => {
            console.log('error', e)
            setLoading(loading => !loading)
        })

        // return () => {
        //     second
        // }
    }, [userId])


    return (
        <>
            {!loading && (
                <Chart account={account} defaultDevice={defaultDevice} devices={devices} permissions={permissions} />
            )}
        </>
    )
}


function Chart({ account, defaultDevice, devices, permissions }) {


    const listTableHead = [
        {
            title: 'วันที่',
        },
        {
            title: 'รหัสบัตร',
        },
        {
            title: 'ชื่อคนขับ',
        },
        {
            title: '61-69',
        },
        {
            title: '70-79',
        },
        {
            title: '80-99',
        },
        {
            title: '100-120',
        },
        {
            title: '> 120',
        },

    ]



    const [speed, setspeed] = useState([])
    const [dataRes, setDataRes] = useState(null)
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const option = {
        chart: {
            height: 350,
            // stacked: true,
            // type: 'area',
            selection: {
                enabled: false
            },
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    reset: true,
                    pan: true,
                    // zoomin: false,
                    // zoomout: false,
                    zoom: {
                        enabled: true,
                        show: false
                    }
                }
            },
            export: {
                csv: {
                    dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                    }
                }
            },
            // sparkline: {
            //     enabled: true
            // }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            // lineCap: 'butt',
            // colors: undefined,
            width: 2,
            // dashArray: 0,
        },
        xaxis: {
            // type: 'date',
            type: 'datetime',
            tickAmount: 31,
            categories: speed.map(values => values.time),
            labels: {
                format: 'dd/MM/yyyy',
                datetimeUTC: false,

            }
        },
        tooltip: {
            enabled: false,
            x: {
                format: 'dd/MM/yyyy HH:mm:ss'
            },
        },
        legend: {
            show: false,
            position: "top",
            horizontalAlign: "left"
        },
        annotations: {
            yaxis: [{
                y: 80,
                borderColor: '#FF0000',
                strokeDashArray: 0,
                borderWidth: 2,
            }]
        },
    }
    const series = [{ name: "speed", data: speed.map(values => values.speed) }]

    const selectedTime = new Date();
    const schema = Yup.object().shape({
        deviceid: Yup.object().shape({
            device: Yup.string().required('Device is required'),
        }).nullable().required('Device is required'),
    });
    const formik = useFormik({
        initialValues: {
            deviceid: defaultDevice,
            date_s: selectedTime.setHours(0, 0, 0),
            date_e: selectedTime.setHours(23, 59, 59),
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { deviceid, date_s, date_e } = values
            const { cocode } = account;
            Api.get('/Tracking/GpsSpeedByMonth', {
                params: {
                    device: deviceid.device,
                    dateStart: fDateTo(date_s, 'yyyy-MM-dd HH:mm'),
                    dateEnd: fDateTo(date_e, 'yyyy-MM-dd HH:mm')
                }
            })
                .then(res => {
                    var list = res.data;
                    const speeds = list.graph.map(values => {
                        return { speed: values.speedmax, time: values.datetime }
                    })
                    setspeed(speeds)
                    setSubmitting()
                    setDataRes(list)
                }
                ).catch(e => {
                    setSubmitting()
                    setspeed([])
                    handleSnackbar({ message: "Not Found Data", variant: "error" })
                });
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Box padding={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <Autocomplete
                                        id="deviceid"
                                        name="deviceid"
                                        options={
                                            devices
                                        }
                                        defaultValue={values.deviceid}
                                        size="small"
                                        getOptionLabel={(option) => option.vehicle_id}
                                        isOptionEqualToValue={(option, value) => option.device === value.device}
                                        fullWidth
                                        renderOption={(props, item) => (
                                            <li {...props} key={item.key}>
                                              <ListItemText>{item.vehicle_id}</ListItemText>
                                            </li>
                                          )}
                                          filterOptions={(options, state) => {
                                            return options.filter(opt => opt.vehicle_id.toUpperCase().includes(state.inputValue.toUpperCase()))
                                          }}
                                        onChange={(e, value) => {
                                            setFieldValue("deviceid", value);
                                            setspeed([])
                                            setDataRes(null)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                label="เลือกอุปกรณ์ GPS"
                                                fullWidth
                                                autoComplete="off"
                                                name="deviceid"
                                                {...params}
                                                {...getFieldProps('deviceid')}
                                                error={Boolean(touched.deviceid && errors.deviceid)}
                                            />
                                        )}
                                    />
                                    <MobileDateTimePicker
                                        ampm={false}
                                        disableFuture={true}
                                        label="วันเวลาเริ่ม"
                                        allowKeyboardControl={false}
                                        value={values.date_s}
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        format="dd/MM/yyyy HH:mm"
                                        onChange={(val) => {
                                            setFieldValue('date_s', val);
                                            setspeed([])

                                            // if (values.date_s > values.date_e) {
                                            //   setFieldValue('date_e', fDateTo(val, 'yyyy-MM-dd HH:mm'));
                                            // }
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            {...params}
                                            {...getFieldProps('date_r')}
                                            fullWidth
                                            placeholder="DD/MM/YYYY"
                                            autoComplete="off"
                                            error={Boolean(touched.date_s && errors.date_s)}
                                            helperText={touched.date_s && errors.date_s} />}
                                    />
                                    <MobileDateTimePicker
                                        minDate={new Date(values.date_s)}
                                        ampm={false}
                                        disableFuture={true}
                                        label="ถึงวันเวลา"
                                        allowKeyboardControl={false}
                                        value={values.date_e}
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        format="dd/MM/yyyy HH:mm"
                                        onChange={(val) => {
                                            setFieldValue('date_e', val);
                                            setspeed([])

                                            // setFieldValue('date_e', fDateTo(val, 'yyyy-MM-dd HH:mm'));
                                            // if (values.date_e < values.date_s) {
                                            //   setFieldValue('date_s', fDateTo(fDateTo(val, 'yyyy-MM-dd') + ' 00:00', 'yyyy-MM-dd HH:mm'));
                                            // }
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            {...params}
                                            {...getFieldProps('date_e')}
                                            fullWidth
                                            placeholder="DD/MM/YYYY"
                                            autoComplete="off"
                                            error={Boolean(touched.date_e && errors.date_e)}
                                            helperText={touched.date_e && errors.date_e} />}
                                    />
                                    <LoadingButton
                                        style={{ height: 40 }}
                                        fullWidth={false}
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        ค้นหา
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </LocalizationProvider>
            </Box>
            {
                speed.length > 0 && (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <HasPermission permissions={permissions} scope={["print"]}>
                                <Button
                                    onClick={() => {
                                        window.print();
                                    }}
                                    variant="contained">print</Button>
                            </HasPermission>
                        </Box>
                        <Box elevation={0}
                            sx={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <Box
                                sx={{
                                    // border: .5,
                                    // borderStyle: "solid",
                                    // borderColor: "#cdcdcd",
                                    // marginBottom: "56px"
                                }}
                            >
                                <Box elevation={0} minWidth="210mm" width="210mm" minHeight="280mm" className="section-to-print">
                                    <CardHeader sx={{
                                        padding: 0,
                                        paddingLeft: "40px"
                                    }} title="รายงานความเร็ว"
                                        subheader={`${values.deviceid?.name} วันที่ ${fDateTo(values.date_s, "dd-MM-yyyy")} ถึง ${fDateTo(values.date_e, "dd-MM-yyyy")} `}
                                    />

                                    <Box sx={{
                                        paddingTop: "20px",
                                        paddingLeft: "40px",
                                        paddingRight: "40px",
                                        paddingBottom: "40px"
                                    }}>
                                        <ReactApexChart
                                            type="area"
                                            options={option}
                                            series={series}
                                            height="200px"
                                            width="100%"
                                        />
                                        <Box>
                                            <TableContainer sx={{
                                                paddingTop: "5px",
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                paddingBottom: "10px"
                                            }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            {
                                                                listTableHead.map((item, index) => {
                                                                    return <TableCell style={{ textTransform: 'capitalize', whiteSpace: "nowrap", padding: "5px", fontSize: "12px" }} key={index} >{item.title}</TableCell>
                                                                })
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody
                                                    >
                                                        {
                                                            dataRes?.data.map((v, i) => {
                                                                const {
                                                                    date, driver_id, name, SV61_69, SV70_79, SV80_99, SV100_120, SV120
                                                                } = v;
                                                                return (
                                                                    <TableRow
                                                                        hover
                                                                        key={i}
                                                                        tabIndex={-1}
                                                                    >
                                                                        <TableCell style={{ whiteSpace: "nowrap", padding: "3px", fontSize: "10px" }}>{fDateTo(new Date(moment(date, "DD-MM-YYYY")), 'dd/MM/yyyy')}</TableCell>
                                                                        <TableCell style={{ whiteSpace: "nowrap", padding: "3px", fontSize: "10px" }}>{driver_id}</TableCell>
                                                                        <TableCell style={{ textOverflow: "ellipsis", padding: "3px", fontSize: "10px" }}>{name}</TableCell>
                                                                        <TableCell style={{ whiteSpace: "nowrap", padding: "3px", textAlign: "center", fontSize: "10px" }}>{SV61_69}</TableCell>
                                                                        <TableCell style={{ whiteSpace: "nowrap", padding: "3px", textAlign: "center", fontSize: "10px" }}>{SV70_79}</TableCell>
                                                                        <TableCell style={{ whiteSpace: "nowrap", padding: "3px", textAlign: "center", fontSize: "10px" }}>{SV80_99}</TableCell>
                                                                        <TableCell style={{ whiteSpace: "nowrap", padding: "3px", textAlign: "center", fontSize: "10px" }}>{SV100_120}</TableCell>
                                                                        <TableCell style={{ whiteSpace: "nowrap", padding: "3px", textAlign: "center", fontSize: "10px" }}>{SV120}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </>

                )
            }
        </Box>
    )
}

