import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'

export default function EditGroup(props) {
    const { onClose, open, handleSubmit, handleDataGroup } = props;
    const _onClose = () => {
        onClose()
        formik.resetForm()
    };



    const formSchema = yup.object().shape({
        group_id: yup.string().required('Id required'),
        name: yup.string().required('Name required'),
    });
    const formik = useFormik({
        initialValues: {
            ...handleDataGroup
        },
        enableReinitialize: true,
        validationSchema: formSchema,
        onSubmit: (values) => {
            const data = {
                ...values
            }
            handleSubmit(data);
            formik.resetForm()
        },
    });



    return (
        <Dialog onClose={_onClose} open={open}>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                    <DialogTitle>Edit Group</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} direction="column">
                            <Grid item sm={12}>
                                <TextField size="small" fullWidth label="ID"
                                    onChange={formik.handleChange}
                                    error={formik.touched.group_id && Boolean(formik.errors.group_id)}
                                    helperText={formik.touched.group_id && formik.errors.group_id}
                                    name="group_id"
                                    value={formik.values.group_id}
                                    disabled
                                />
                            </Grid>
                            <Grid item sm={12}>
                                <TextField size="small" fullWidth label="ชื่อ"
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    name="name"
                                    value={formik.values.name}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="info" type="submit">submit</Button>
                        <Button variant="contained" color="primary" onClick={_onClose} >Close</Button>
                    </DialogActions>
                </Form>
            </FormikProvider>
        </Dialog>
    );
}