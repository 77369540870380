import React, { useRef, useState } from 'react'
import { Box, Divider, Stack, Button, Grid, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment, InputLabel, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import HelpdeskGetDevice from './HelpdeskGetDevice';
export default function HelpdeskRepair() {
    const [open, setOpen] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setSelectedFile(null)
    }


    const handleFileChange = (event) => {
        if (event.target.files[0] !== undefined) {
            const file = event.target.files[0];
            // const newFilename = prompt('filename:', file.name);
            // const newFile = new File([file], "filename", { type: file.type });
            // setSelectedFile(newFile);
            setSelectedFile(file);
        }
    };
    const handleTriggerUpload = (event) => {
        event.preventDefault();
        fileInputRef.current.click()
    };

    const handleFileUpload = () => {
        console.log('Uploading file:', selectedFile);
    };

    return (
        <>
            <Button sx={{ height: "80px", width: "200px" }} variant="outlined" onClick={handleOpen}>เปิดงาน</Button>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>เปิดงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Stack spacing={1}>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="ผู้เปิดงาน" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="ผู้เปิดงาน" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="สาขา" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="เบอร์โทร" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="เรื่อง" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="หัวข้อ" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="ชื่ออุปกรณ์"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <HelpdeskGetDevice />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            {
                                process.env.REACT_APP_ID === "sitgps" && (
                                    <>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="รุ่น GPS" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="เลข IMEI" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="Sim card" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="เลขตัวถัง" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="ชื่ออุปกรณ์" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="ยี่ห้อ" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="ป้ายทะเบียน" />
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="สถานที่" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="บริเวณ/อาคาร/ชั้น" />
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography fontStyle="italic" >อธิบายลักษณะ</Typography>
                        </Box>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <TextField size="small" fullWidth label="หมายเหตุ" rows={4} multiline />
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography fontStyle="italic" >แนบไฟล์</Typography>
                        </Box>
                        <Box>
                            <input
                                type="file"
                                accept='image/*'
                                hidden
                                onChange={handleFileChange}
                                ref={fileInputRef}
                            />
                        </Box>
                        <Box>
                            <Stack spacing={1} direction="row">
                                <Box>
                                    {
                                        selectedFile !== null && (
                                            <img src={URL.createObjectURL(selectedFile)} width={"100px"} style={{ aspectRatio: "auto 16/9", borderRadius: "5px" }} />
                                        )
                                    }
                                </Box>
                                <Box>
                                    <Stack spacing={1} direction="row">
                                        <TextField InputProps={{ readOnly: true }} size="small" type="type" placeholder='choose file' value={selectedFile !== null ? selectedFile?.name : ""} />
                                        <Button size="small" onClick={handleTriggerUpload} variant="outlined">Choose</Button>
                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleFileUpload}>ยกเลิก</Button>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}