import { Box, Dialog, DialogContent, IconButton, Menu, MenuItem, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer, ZoomControl } from 'react-leaflet';
import LayersIcon from '@mui/icons-material/Layers';
import ListTileLayer from '../../layouts/ListTileLayer';
import { styled, useTheme } from '@mui/styles';

const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
    height: '500px',
    width: '500px',
    // width: '100vw',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
}));
const center = [13.839660198254604, 100.63469639884744];
const zoom = 6;

export default function DialogMap({ open, handleClose, dialogMapData, children }) {
    // const [map, setMap] = useState(null);
    const refDropDownMapStyle = useRef(null);
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")

    const [openDropDown, setDropDown] = useState(false);
    const refMapStyle = useRef(null);

    const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (

        <Dialog
            // fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            maxWidth="md"
        >
            <DialogContent>
                <Box sx={{
                    position: "relative"
                }}>
                    <MapContainerStyle
                        center={center}
                        zoom={zoom}
                        minZoom={3}
                        zoomControl={false}
                        scrollWheelZoom={true}
                        doubleClickZoom={false}
                        attributionControl={false}
                        whenCreated={maps => {
                            // setInterval(() => { maps.invalidateSize() }, 100)
                            // setMap(maps)
                            if (dialogMapData !== null) {
                                maps.setView([dialogMapData.latitude, dialogMapData.longitude], 19, {
                                    "animate": true,
                                    "duration": 1,
                                })
                            }
                        }}
                    >
                        <TileLayer ref={refMapStyle}
                            url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
                            maxNativeZoom={19}
                            maxZoom={22}
                        />
                        <ZoomControl position='topright' />

                        {
                            children
                        }

                    </MapContainerStyle>
                    <Box style={
                        {
                            position: "absolute",
                            display: 'block',
                            backdropFilter: 'blur(10px)',
                            border: "2px solid rgba(0,0,0,0.2)",
                            borderRadius: 5,
                            top: 95,
                            right: 10,
                            width: 32,
                            height: 32,
                            zIndex: 2,
                        }
                    }
                        ref={refDropDownMapStyle}
                    >
                        <IconButton edge="end" size="small"
                            style={{ backgroundColor: 'white', borderRadius: 5, }}
                            onClick={() => {
                                setDropDown(!openDropDown)
                            }}>
                            <LayersIcon fontSize="inherit" />
                        </IconButton>
                        <Menu
                            open={openDropDown}
                            anchorEl={refDropDownMapStyle.current}
                            onClose={() => setDropDown(!openDropDown)}
                            PaperProps={{
                                sx: { width: 200, maxWidth: '100%' }
                            }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            {
                                ListTileLayer.map((value, index) =>
                                    <MenuItem
                                        sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                                        onClick={
                                            () => {
                                                setDropDown(!openDropDown)
                                                if (refMapStyle.current) {
                                                    refMapStyle.current.setUrl(value.url);
                                                    setSelectedMapStyle(value.id)
                                                    localStorage.setItem('selectedMapStyle', value.id)
                                                }
                                            }
                                        }
                                        key={'MenuItem' + index}
                                    >
                                        {
                                            value.name
                                        }
                                    </MenuItem>
                                )
                            }
                        </Menu>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}
