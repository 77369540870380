import { Autocomplete, Box, Card, CardHeader, Container, Backdrop, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

export default function SpeedChart({ speed = [] }) {

    const option = {
        chart: {
            height: 180,
            // stacked: true,
            // type: 'area',
            toolbar: {
                // show: false,
                tools: {
                    download: false,
                    reset: true,
                    pan: true,
                    // zoomin: false,
                    // zoomout: false,
                    zoom: {
                        enabled: true,
                        show: false
                    }
                }
            },
            export: {
                csv: {
                    dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: speed.map(values => values.time),
            labels: {
                datetimeUTC: false,
            }
        },
        tooltip: {
            x: {
                format: 'dd/MM/yyyy HH:mm:ss'
            },
        },
        legend: {
            show: false,
            position: "top",
            horizontalAlign: "left"
        },
    }
    const series = [{ name: "speed", data: speed.map(values => values.speed) }]


    return (
        <Box sx={{ overflow: 'hidden' }}>
            {
                speed.length > 0 && (
                    <Box elevation={0} >
                        <Box padding={"10px"} minHeight="180px"
                            className="apexcharts-custom"
                        >
                            <ReactApexChart
                                type="area"
                                options={option}
                                series={series}
                                height="100%"
                            />
                        </Box>
                    </Box>
                )
            }
        </Box>
    )
}
