import crypto from 'crypto-js';

export function UseAesCbc() {
    const utf8 = crypto.enc.Utf8;
    // const base64 = crypto.enc.Base64;
    const aes = crypto.AES;
    const cbc = crypto.mode.CBC;
    const secretKey = utf8.parse(process.env.REACT_APP_SECRET_KEY);
    const iv = utf8.parse(process.env.REACT_APP_IV);
    const cfg = {
        mode: cbc,
        keySize: 256,
        iv: iv
    };

    return {
        encrypt,
        decrypt,
    }

    function encrypt(str) {
        const encrypt = aes.encrypt(str, secretKey, cfg);
        return encrypt.toString();
    }

    function decrypt(encoded) {
        const bytes = aes.decrypt(encoded, secretKey, cfg);
        return bytes.toString(utf8);
        // return decoded;
    }

}