import React, { useEffect, useState } from 'react'
import { Box, Collapse, Backdrop, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import FindInPageIcon from '@mui/icons-material/FindInPage';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CreateContype from './CreateCompany';
import { Api } from '../../../components/api';
import UpdateConcode from './UpdateCompany';
import CircularProgress from '@mui/material/CircularProgress';
import HasPermission from '../../../haspermission/HasPermission';
// import ButtonDialogContype from './ButtonDialogContype';

export default function Company({ permissions, account }) {

    const { cocode, cogcode } = account;


    const [cnames, setcnames] = useState([])
    const [loading, setloading] = useState(true)

    useEffect(() => {
        Api.get(`/v0/if/Cnames`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setcnames(res.data)
                setloading(false)
            })
            .catch((err) => {
                console.log('err', err)
                setloading(false)

            })
    }, [])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, data, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            // style: { bgcolor: "whitesmoke !important" },
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };



    const onHandleDel = (d_data) => {
        const data = JSON.stringify({ ...d_data })
        // console.log('data', data)
        Api.delete(`/v0/if/Cname`, { data: data, headers: { CompanyGroup: cogcode } })
            .then((res) => {
                console.log('res', res)
                handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
                onGetcnames()
            })
            .catch((err) => {
                console.log('err', err)
                handleClickVariant("ลบข้อมูลไม่เสร็จสิ้น !", "error")
            })
    }


    const onGetcnames = () => {
        Api.get(`/v0/if/Cnames`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setcnames(res.data)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }




    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{ padding: "10px" }}>
                <Box>
                    <Stack direction="row" justifyContent="space-between">
                        <Box>
                            <Typography variant="h6" >Company</Typography>
                        </Box>
                        <HasPermission permissions={permissions} scope={["create"]}>
                            <CreateContype onGet={onGetcnames} account={account} />
                        </HasPermission>
                    </Stack>
                </Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>No.</TableCell>
                            <TableCell>Code name</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            cnames.map((concode, i) => {
                                return (
                                    <TableRow key={`contype${i}`}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{concode.c_coname}</TableCell>
                                        <TableCell>{concode.fullname}</TableCell>
                                        <TableCell>
                                            <Stack spacing={1} direction="row" justifyContent="flex-end">
                                                <Box>
                                                    <HasPermission permissions={permissions} scope={["update"]}>
                                                        <UpdateConcode data={concode} onGet={onGetcnames} account={account} />
                                                    </HasPermission>
                                                </Box>
                                                <Box>
                                                    <HasPermission permissions={permissions} scope={["delete"]}>
                                                        <IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูล " + concode.c_coname + "?", data: concode, onHandle: () => onHandleDel(concode) }) }}><DeleteOutlineIcon fontSize="small" /></IconButton>
                                                    </HasPermission>
                                                </Box>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </div>
        </div >
    )
}
