import { Form, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Button, Dialog, DialogActions, IconButton, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { useState } from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { Api } from '../../components/api';

export default function CreateGroup({ onGetGroups, account }) {
    const { c_coname } = account
    const [open, setOpen] = useState(false)
    const onClose = () => {
        setOpen(false)
        formik.resetForm()
    };
    const onOpen = () => {
        setOpen(true)
    };



    const formSchema = yup.object().shape({
        group_id: yup.string().required('Id required'),
        name: yup.string().required('Name required'),
    });
    const formik = useFormik({
        initialValues: {
            "group_id": "",
            "name": "",
        },
        validationSchema: formSchema,
        onSubmit: (values) => {
            const data = {
                ...values,
                cname: c_coname
            }
            Api.post(`/Tracking/poigeocodegroup`, data)
                .then(
                    (res) => {
                        onGetGroups()
                        formik.resetForm()
                        onClose()
                    }
                )

        },
    });

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).replaceAll(' ', '').toUpperCase();
    };

    return (
        <>
            <IconButton size="small" onClick={onOpen}>
                <AddBoxIcon fontSize="inherit" />
            </IconButton>
            <Dialog onClose={onClose} open={open}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
                        <DialogTitle>New Group</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} direction="column">
                                <Grid item sm={12}>
                                    <TextField size="small" fullWidth label="ID"
                                        onChange={formik.handleChange}
                                        error={formik.touched.group_id && Boolean(formik.errors.group_id)}
                                        helperText={formik.touched.group_id && formik.errors.group_id}
                                        name="group_id"
                                        value={formik.values.group_id}
                                        onInput={toInputUppercase}
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField size="small" fullWidth label="ชื่อ"
                                        onChange={formik.handleChange}
                                        error={formik.touched.name && Boolean(formik.errors.name)}
                                        helperText={formik.touched.name && formik.errors.name}
                                        name="name"
                                        value={formik.values.name}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="info" type="submit">submit</Button>
                            <Button variant="contained" color="primary" onClick={onClose} >Close</Button>
                        </DialogActions>
                    </Form>
                </FormikProvider>
            </Dialog>
        </>
    );
}