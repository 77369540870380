import React, { useEffect } from 'react'
import { useState } from 'react';
import { Outlet, Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Stack, Paper, AppBar, Toolbar, IconButton, Typography, Drawer, useMediaQuery } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { MHidden } from '../components/@material-extend';
import NavSection from '../components/NavSection';
import SidebarAccountConfig from './SidebarConfig/SidebarAccountConfig';
import BottomNavigationBar from './BottomNavigationBar';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  [theme.breakpoints.down('md')]: {
    paddingTop: 66,
    paddingBottom: 56,
  }
}));


const AppBarStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  width: 0,
  maxHeight: 64,
  [theme.breakpoints.down('md')]: {
    // width: `calc(100% - ${350 + 1}px)`
    width: "100%"
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  WebkitBoxAlign: 'center',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  minHeight: '64px',
}));
const DrawerStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    width: 320
  },
  width: 0,
}));

export default function AccountLayout() {
  const [open, setOpen] = useState(false);
  const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (isFullScreen) {
      setOpen(false)
    }
  }, [isFullScreen]);
  return (
    <RootStyle>
      <AppBarStyle elevation={3} >
        <ToolbarStyle>
          <IconButton onClick={() => setOpen(!open)} sx={{ mr: 1, color: 'text.primary' }}>
            <MenuRoundedIcon />
          </IconButton>
        </ToolbarStyle>
      </AppBarStyle>
      <DrawerStyle >
        <MHidden width="mdUp">
          <Drawer
            open={open}
            onClose={() => setOpen(!open)}
            // variant="permanent"
            PaperProps={{
              sx: { width: 320 }
            }}
          >
            <Box sx={{ px: 1, py: 1 }}>
              <Toolbar style={{ minHeight: '64px', paddingLeft: 35, paddingRight: 35 }}>
                <Typography variant='h4'>Account</Typography>
                <Box sx={{ flexGrow: 1 }} />
              </Toolbar>
            </Box>
            <NavSection onClick={() => setOpen(!open)} navConfig={SidebarAccountConfig} />
          </Drawer>
        </MHidden>
        <MHidden width="mdDown">
          <Drawer
            open={true}
            variant="permanent"
            PaperProps={{
              sx: { width: 320, height: 'calc(100% - 56px)', bottom: 56, padding: '10px' }
            }}
          >
            <Box sx={{ px: 1, py: 1 }}>
              <Toolbar style={{ minHeight: '64px', paddingLeft: 30, paddingRight: 30 }}>
                <Typography variant='h4'>Account</Typography>
                <Box sx={{ flexGrow: 1 }} />
              </Toolbar>
            </Box>
            <NavSection navConfig={SidebarAccountConfig} />
            <Paper sx={{ position: 'fixed', width: 320, bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
              <BottomNavigationBar ative="account" />
            </Paper>
          </Drawer>
        </MHidden>
      </DrawerStyle>
      <MainStyle>
        <Outlet />

        <MHidden width="mdUp">
          <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
            <BottomNavigationBar ative="account" />
          </Paper>
        </MHidden>

      </MainStyle>
    </RootStyle >
  )
}

// const DrawerCustom = ()=>{
//   return 
// }