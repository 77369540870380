import React from 'react';
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import { fDateTo } from '../../utils/formatTime';
import _ from 'lodash';

const Calendar = ({ car_type, sDate, eDate, onClick }) => {



    const initialcars = [
        {
            "car_type": "BUS-EV", "car_id": "EV-001", "licensePlate": "33-0001",
            start: new Date("2024-06-10"),
            end: new Date("2024-06-12"),
        },
        {
            "car_type": "BUS-EV", "car_id": "EV-002", "licensePlate": "33-0002",
            start: new Date("2024-06-11"),
            end: new Date("2024-06-12"),
        },
        {
            "car_type": "BUS-EV", "car_id": "EV-003", "licensePlate": "33-0003",
            start: new Date("2024-06-25"),
            end: new Date("2024-06-25"),
        },
        {
            "car_type": "BUS-44", "car_id": "B44-001", "licensePlate": "32-0001",
            start: new Date("2024-06-16"),
            end: new Date("2024-06-18"),
        },
        {
            "car_type": "BUS-44", "car_id": "B44-002", "licensePlate": "32-0002",
            start: new Date("2024-06-20"),
            end: new Date("2024-06-21"),
        },
        {
            "car_type": "BUS-44", "car_id": "B44-003", "licensePlate": "32-0003",
            start: new Date("2024-06-20"),
            end: new Date("2024-06-25"),
        },
        {
            "car_type": "BUS-44", "car_id": "B44-004", "licensePlate": "32-0004",
            start: new Date("2024-06-11"),
            end: new Date("2024-06-11"),
        },
    ]



    const days = [];
    const startDate = new Date(sDate);
    const endDates = new Date(eDate);
    const timeDiff = Math.abs(endDates.getTime() - startDate.getTime());
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    const endDate = new Date(eDate);

    let currentDate = new Date(startDate);
    let currentEndDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() - 1)
    currentEndDate.setDate(endDate.getDate() + 1)

    // endDate.setDate(endDate.getDate() + 3)
    // startDate.setDate(startDate.getDate() - 3)

    // endDate.setDate(startDate.getDate() + daysDiff > 0 ? daysDiff - 1 : daysDiff + 1); // Calculate the end date
    // console.log('daysDiff', daysDiff)
    while (currentDate <= currentEndDate) {
        days.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }


    const isDateIncluded = (targetDate, startDate, endDate) => {
        const targetTime = targetDate.getTime();
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();

        return targetTime >= startTime && targetTime <= endTime;
    };

    const isDateCurrent = (targetDate, startDate, endDate) => {
        const targetTime = targetDate.getTime();
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();
        if (targetTime >= startTime && targetTime <= endTime) {
            return false
        }

        return true;
    };

    const handleEventClick = (event) => {
        onClick({
            "car_type": event.car_type, "car_id": event.car_id, "licensePlate": event.licensePlate
        })
    };

    return (
        <TableContainer component={Paper}>
            <Table size="small" style={{ border: "none" }} >
                <TableHead>
                    <TableRow>
                        <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, background: '#569CA8', color: 'white', zIndex: 1 }}>ทะเบียนรถ</TableCell>
                        {days.map((day, index) => (
                            <TableCell style={{ whiteSpace: "nowrap", justifyItems: "center", justifyContent: "center" }}>
                                <Stack textAlign="center">
                                    <Box>
                                        {day.toLocaleDateString('en-US', { weekday: 'long' })}
                                    </Box>
                                    <Box sx={{ fontSize: "12px" }}>
                                        {fDateTo(day, "dd/MM/yyyy")}
                                    </Box>
                                </Stack>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        _.filter(initialcars, (item) => {
                            return item.car_type === car_type;
                        }).map((car, index) => (
                            <TableRow onClick={() => handleEventClick(car)} style={{ cursor: "pointer" }}>
                                <TableCell style={{ position: 'sticky', whiteSpace: "nowrap", left: 0, background: '#569CA8', color: 'white', zIndex: 1 }}>{car.licensePlate}</TableCell>
                                {days.map((day, index) => (
                                    <TableCell style={{ whiteSpace: "nowrap", height: "50px", textAlign: "center", backgroundColor: isDateCurrent(day, startDate, endDate) ? "whitesmoke" : isDateIncluded(day, car.start, car.end) ? "skyblue" : "white" }}>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer >
    );
};

export default Calendar;
