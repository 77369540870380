import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import { Box, Card, CardContent, IconButton, Stack, Container, Divider, Grid, Menu, TextField, ListItemText, ListItemButton, ListItem, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { styled, alpha } from '@mui/material/styles';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import OrdersTable from './OrdersTable';
import { fDateTo } from '../../utils/formatTime';
import { Api } from '../../components/api';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import _ from 'lodash';


export default function MainWorkByUser() {

    const [works, setWorks] = useState([])

    useEffect(() => {
        Api.get(`/Works/works?userid=00000000010011&workstart=2024-06-15 00:00:00.000&workstop=2024-12-31 00:00:00.000`)
            .then(res => {
                setWorks(res.data)
            }
            )
            .catch(err => {
                console.log('err', err)
            })

    }, [])


    return (
        <Page>
            <Divider />
            <MainStyle>
                <Stack spacing={1}>
                    {
                        _.orderBy(works, ["workstart"], ["desc"]).map((works, index) => {

                            return (
                                <Box>
                                    <Box sx={{ borderRadius: "5px" }}>
                                        <Box sx={{ padding: "5px" }}>
                                            <Box sx={{
                                                padding: "5px",
                                                borderRadius: "5px",
                                                // backgroundColor: "green"
                                            }}>
                                                <Typography fontSize="15px" fontWeight="bold">
                                                    เวลาเริ่ม : {fDateTo(works.workstart, "dd/MM/yyy HH:mm:ss")}
                                                </Typography>
                                                <Typography fontSize="15px" fontWeight="bold">
                                                    เวลาออก : {fDateTo(works.workstop, "dd/MM/yyy HH:mm:ss")}
                                                </Typography>
                                                {/* <Typography fontSize="15px" fontWeight="bold" textAlign="end">
                                                เวลาออก : {fDateTo(works.workstop, "dd/MM/yyy HH:mm:ss")}
                                            </Typography> */}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Divider sx={{ borderStyle: "dashed"}} />
                                </Box>
                            )
                        })
                    }
                </Stack>
            </MainStyle>
        </Page >
    )
}


const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10
}));


const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 170,
        maxHeight: "calc(100vh - 100px)",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));