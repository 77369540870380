import React, { useEffect, useRef, useState } from 'react'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import { Stack, IconButton, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Box, Grid, Button, Backdrop, FormControlLabel, Checkbox } from '@mui/material'
import sit_stamp from '../../img/sit-stamp.png'
import supoj_signature from '../../img/supoj-signature.png'
import { fDateTo } from '../../utils/formatTime';
import { makeStyles } from '@mui/styles';
import { isSafari } from 'react-device-detect';
import CloseIcon from '@mui/icons-material/Close';
import { usePDF } from 'react-to-pdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const useStyles = makeStyles((theme) =>
({
    contentPage: {
        margin: '1cm auto',
        maxWidth: '21cm',
        // minHeight: '29.7cm',
        overflow: 'hidden',
        // border: '1px #D3D3D3 solid',
        background: 'white',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        padding: '1cm'
    },
    botttomPrint: {
        margin: '1cm auto',
    },
    contentPrint: {
        width: '18.4cm',
        marginLeft: '.4cm',
        // marginRight: '50px',
        minHeight: '24.5cm',
        color: 'black'
    },
    [`@media print`]: {
        // [`@media print`]: { margin: 0 },
        // body: { margin: '1.6cm' },
        contentPage: {
            // display: 'none'
            margin: 'initial',
            marginLeft: isSafari ? 'initial' : '.5cm',
            width: 'initial',
            minHeight: 'initial',
            border: 'initial',
            background: 'initial',
            boxShadow: 'initial',
            padding: 'initial'
        },
        contentPrint: {
            width: '18.4cm',
            marginLeft: isSafari ? 'initial' : '.8cm',
            // marginRight: '50px',
            minHeight: '24.5cm',
        },
        botttomPrint: {
            display: 'none'
        },
    },
}))

export default function DltDownloadPDF({ dltCers = [] }) {

    useEffect(() => {
        function handleKeyDown(e) {
            if ((e.metaKey || e.ctrlKey) && e.key === 'p') {
                e.preventDefault();
            }
        }
        document.addEventListener('keydown', handleKeyDown);
    }, [document])

    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [dltCer, setDltCer] = useState(dltCers)

    useEffect(() => {
        setDltCer(dltCers)
    }, [dltCers])

    const onClickOpen = () => {
        setOpen(true)
    }
    const onClickClose = () => {
        setOpen(false)
    }


    const componentRef = useRef();
    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });

    const { toPDF, targetRef } = usePDF();
    const downloadPdf = () => toPDF(
        {
            filename: `${`DLT_CER`}.pdf`,
            // page: {
            //     margin: 20
            // }
        }
    )

    return (
        <div>
            <IconButton size="small" onClick={onClickOpen} disabled={dltCers.length === 0}>
                <FileDownloadIcon fontSize="small" />
            </IconButton>
            <Dialog
                fullScreen
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack spacing={1} direction="row">
                        <Typography variant="h6">ข้อมูลใบรับรอง</Typography>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent >
                    <Box
                        // ref={componentRef}
                        sx={{
                            background: "white",
                            paddingX: "0.5cm",
                            paddingTop: "0.5cm",
                            fontFamily: 'Sarabun',
                        }}
                        // className="section-to-print"
                        name={`DLT_CER`}
                    >

                        <Box ref={targetRef}>
                            {
                                dltCer.map((cer, index) => {

                                    return (
                                        <React.Fragment key={`cer${index}`} >
                                            <PagePDF cer={cer} />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Box>
                    </Box >
                </DialogContent>
            </Dialog>
        </div >
    )
}

const PagePDF = ({ cer, }) => {
    const classes = useStyles();
    const { toPDF, targetRef } = usePDF();
    const downloadPdf = ({ name }) => toPDF(
        {
            filename: `${name}.pdf`,
            // page: {
            //     margin: 20
            // }
        }
    )

    return (
        <Box >
            <div className={classes.contentPage} id={`container${cer.vehicle_chassis_no}`} ref={targetRef}>
                <div className={classes.contentPrint}
                >
                    <div
                        style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            marginTop: "0.8cm",
                            marginBottom: "0.8cm",
                            // fontSize : '16px'
                        }}
                    >
                        หนังสือรับรองการติดตั้งเครื่องบันทึกข้อมูลการเดินทางของรถ
                    </div>
                    <div style={{
                        border: '1px black solid',
                        padding: '0.3cm',
                        // maxHeight : '200mm',
                        fontSize: '14px'
                    }}>
                        <div style={{
                            textAlign: 'center'
                        }}>
                            หนังสือรับรองการติดตั้งเครื่องบันทึกข้อมูลการเดินทาง
                        </div>
                        <p>
                            เลขที่หนังสือ {cer.docno}
                        </p>
                        <dd style={{ fontWeight: 'bold' }}>
                            {cer.vender_name} <br />
                            ที่อยู่ {cer.vender_add} <br />
                            เบอร์โทรศัพท์ {cer.vender_tel}
                        </dd>
                        <p>
                            ได้ติดตั้งเครื่องบันทึกข้อมูลการเดินทางของรถรายละเอียดดังนี้
                        </p>
                        <dd style={{ fontWeight: 'bold' }}>
                            <p >การรับรองจากกรมการขนส่งทางบกเลขที่
                                <label style={{ marginLeft: '20px', fontWeight: 'normal' }}>
                                    {cer.gpslicense}
                                </label>
                            </p>
                            <p>
                                ชนิด
                                <label style={{ width: '345px', display: 'inline-block', marginLeft: '20px', fontWeight: 'normal' }}>
                                    {cer.gpsmodel}
                                </label>
                                แบบ
                                <label style={{ width: '80px', display: 'inline-block', marginLeft: '20px', fontWeight: 'normal' }}>
                                    {cer.gpstype}
                                </label>
                            </p>
                            <p>
                                หมายเลขเครื่อง
                                <label style={{ marginLeft: '20px', fontWeight: 'normal' }}>
                                    {cer.unit_id}
                                </label>
                            </p>
                            <p>
                                เครื่องอ่านบัตรชนิดแถบแม่เหล็ก ชนิด
                                <label style={{ width: '150px', display: 'inline-block', marginLeft: '30px', fontWeight: 'normal' }}>
                                    {cer.mcrmodel}
                                </label>
                                แบบ
                                <label style={{ width: '80px', display: 'inline-block', marginLeft: '20px', fontWeight: 'normal' }}>
                                    {cer.mcrtype}
                                </label>
                            </p>
                            <p>
                                วันที่ติดตั้ง
                                <label style={{ marginLeft: '20px', fontWeight: 'normal' }}>
                                    {fDateTo(cer.installdate, 'dd/MM/yyyy')}
                                </label>
                            </p>
                            <p>
                                ชื่อผู้ประกอบการขนส่ง / เจ้าของรถ
                                <label style={{
                                    marginLeft: '20px', fontWeight: 'normal'
                                }}>
                                    {cer.customer_name}
                                </label>
                            </p>
                            <p>
                                ที่อยู่
                                <label style={{
                                    marginLeft: '20px', fontWeight: 'normal'
                                }}>
                                    {cer.customer_add}
                                </label>
                            </p>
                            <p>
                                เบอร์โทร
                                <label style={{
                                    marginLeft: '20px', fontWeight: 'normal'
                                }}>
                                    {cer.customer_tel}
                                </label>
                            </p>
                            <p>
                                เลขทะเบียน
                                <label style={{
                                    marginLeft: '20px', width: '120px', display: "inline-block", fontWeight: 'normal'
                                }}>
                                    {cer.vehicle_id}
                                </label>
                                จังหวัด
                                <label style={{
                                    marginLeft: '20px', width: '150px', display: "inline-block", fontWeight: 'normal'
                                }}>
                                    {cer.vehicle_province}
                                </label>
                                ยี่ห้อ
                                <label style={{
                                    marginLeft: '20px', fontWeight: 'normal'
                                }}>
                                    {cer.vehicle_type}
                                </label>
                            </p>
                            <p>
                                หมายเลขคัสซี <label style={{
                                    marginLeft: '20px', fontWeight: 'normal'
                                }}>
                                    {cer.vehicle_chassis_no}
                                </label>
                            </p>
                            <p>
                                หมายเหตุ
                                <label style={{
                                    marginLeft: '20px', marginRight: '20px', fontWeight: 'normal', borderBottom: '1px black dotted',
                                    width: '80%', display: "inline-block"
                                }}>

                                </label>
                            </p>
                        </dd>
                        <div style={{ fontWeight: 'initial', fontSize: '10.7px' }}>
                            <dd>
                                <p>
                                    ขอรับรองว่าเครื่องบันทึกข้อมูลการเดินทางของรถดังกล่าวข้างต้นมีคุณลักษนะและระบบการทำงานที่ได้รับรองจากกรมการขนส่งทางบก
                                </p>
                                <p>
                                    กรณีเครื่องบันทึกข้อมูลการเดินทางของรถมีคุณลักษณะหรือระบบการทำงานไม่เป็นไปตามที่กรมขนส่งทางบกได้ให้การรับรองหรือมีการรายงานข้อมูลไม่ตรง
                                </p>
                            </dd>
                            <p>
                                กับข้อเท็จจริงหรือไม่สามารถรายงานข้อมูลได้ตามที่กรมการขนส่งทางบกกำหนด {cer.vender_name} ยินยอมรับความผิดชอบ
                            </p>
                            <p>
                                ความเสียหายที่เกิดขึ้นต่อเจ้าของรถหรือผู้ประกอบการขนส่งได้ซื้อหรือให้บริการเครื่องบันทึกข้อมูลการเดินทางของรถดังกล่าว
                            </p>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <p>
                                        ออกให้ ณ วันที่ {fDateTo(cer.issuedate, 'dd/MM/yyy')}
                                    </p>
                                </Grid>
                                <Grid item xs={4}
                                    style={
                                        {
                                            position: "relative"
                                        }
                                    }
                                >
                                    <img
                                        style={{
                                            position: "absolute",
                                            right: "202px",
                                            bottom: -23
                                        }}
                                        src={`${sit_stamp}`}
                                        width={"145px"}
                                        alt={'logo_dlt'}
                                    />
                                    <p>
                                        ลงชื่อ <label style={{
                                            fontWeight: 'normal', borderBottom: '1px black dotted',
                                            width: '80%', display: "inline-block",
                                            textAlign: "center",
                                            position: "relative"
                                        }}>
                                            <img
                                                src={`${supoj_signature}`}
                                                style={{
                                                    position: "absolute",
                                                    left: "5px",
                                                    bottom: -5
                                                }}
                                                height={"50px"}
                                                // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                alt={'logo_dlt'}
                                            // loading="lazy"
                                            />
                                        </label>
                                        <p
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            ( {cer.issueby} )
                                        </p>
                                        <p
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            {cer.vender_name}
                                        </p>
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div style={{ fontWeight: 'initial', fontSize: '8px' }}>
                        <p>
                            หมายเหตุ :
                        </p>
                        <p>
                            1.ชนิดและแบบของเครื่องบันทึกข้อมูลการเดินทางของรถและเครื่องอ่านบัตรชนิดแถบแม่เหล็ก ให้เป็นไปตามรายละเอียดที่ได้รับการรับรองจากกรมการขนส่งทางบก
                        </p>
                        <p>
                            2.กรณีเป็นการติดตั้งเครื่องใหม่ทดแทนของเดิมให้ระบุรายละเอียดของเครื่องบันทึกข้อมูลการเดินทางของรถเครื่องเดิมในช่องหมายเหตุ เช่น ผู้ให้บริการเดิมชนิดและแบบเดิมหมายเลขเดิม
                        </p>
                    </div>

                </div >
            </div >
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Button
                    style={{
                        margin: '5px'
                    }}
                    variant="contained"
                    color="info"
                    onClick={() => downloadPdf({ name: cer.vehicle_chassis_no })}>Download PDF
                </Button>
            </Box>
        </Box>
    )
}