import ListTileLayer from "../../layouts/ListTileLayer"
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import RouteIcon from '@mui/icons-material/Route';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import GrainOutlinedIcon from '@mui/icons-material/GrainOutlined';
import FilterCenterFocusRoundedIcon from '@mui/icons-material/FilterCenterFocusRounded';
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import NavigationRoundedIcon from '@mui/icons-material/NavigationRounded';

import { useRef, useState } from "react";
import { fDate, fDateTo } from "../../utils/formatTime";

export const ControlLayerMap = ({ onClick, selected }) => {

    const [open, setOpen] = useState(false)
    const ref = useRef(null);

    const onOpen = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }
    const _onClick = (val) => {
        onClose()
        onClick(val)
    }
    return (
        <Box style={
            {
                position: 'fixed',
                display: 'block',
                backdropFilter: 'blur(10px)',
                border: "2px solid rgba(0,0,0,0.2)",
                borderRadius: 5,
                top: 95,
                right: 10,
                width: 32,
                height: 32,
                zIndex: 999,
            }
        }
            ref={ref}
        >
            <IconButton edge="end" size="small"
                style={{ backgroundColor: 'white', borderRadius: 5, }}
                onClick={onOpen}>
                <LayersIcon fontSize="inherit" />
            </IconButton>
            <Menu
                open={open}
                anchorEl={ref.current}
                onClose={onClose}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {
                    ListTileLayer.map((value, index) =>
                        <MenuItem
                            sx={{ color: 'text.default', fontWeight: selected === value.id ? 600 : 300 }}

                            onClick={() => { _onClick(value) }}
                            key={'MenuItem' + index}
                        >
                            {
                                value.name
                            }
                        </MenuItem>
                    )
                }
            </Menu>
        </Box>
    )
}


export const ControlRoute = ({ show, onClick }) => {

    return (
        <Box style={
            {
                position: 'fixed',
                display: 'block',
                backdropFilter: 'blur(10px)',
                border: "2px solid rgba(0,0,0,0.2)",
                borderRadius: 5,
                top: 130,
                right: 10,
                width: 32,
                height: 32,
                zIndex: 999,
            }
        }
        >
            <IconButton
                edge="end" size="small"
                style={{ backgroundColor: 'white', borderRadius: 5, }}
                onClick={onClick}
            >
                <RouteIcon color={show ? 'action' : "disabled"} fontSize="inherit" />
            </IconButton>
        </Box>
    )

}

export const ControlBusStop = ({ show, onClick }) => {

    return (<Box style={
        {
            position: 'fixed',
            display: 'block',
            backdropFilter: 'blur(10px)',
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: 5,
            top: 165,
            right: 10,
            width: 32,
            height: 32,
            zIndex: 999,
        }
    }
    >
        <IconButton
            edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={onClick}
        >
            <FmdGoodIcon color={show ? "action" : "disabled"} fontSize="inherit" />
        </IconButton>
    </Box>
    )
}


export const ControlStations = ({ show, onClick }) => {

    return (<Box style={
        {
            position: 'fixed',
            display: 'block',
            backdropFilter: 'blur(10px)',
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: 5,
            top: 200,
            right: 10,
            width: 32,
            height: 32,
            zIndex: 999,
        }
    }
    >
        <IconButton
            edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={onClick}
        >
            <MultipleStopIcon color={show ? "action" : "disabled"} fontSize="inherit" />
        </IconButton>
    </Box>
    )
}


export const ControlCluster = ({ show, onClick }) => {

    return (<Box style={
        {
            position: 'fixed',
            display: 'block',
            backdropFilter: 'blur(10px)',
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: 5,
            top: 235,
            right: 10,
            width: 32,
            height: 32,
            zIndex: 999,
        }
    }
    >
        <IconButton
            edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={onClick}
        >
            <GrainOutlinedIcon color={show ? "action" : "disabled"} fontSize="inherit" />
        </IconButton>
    </Box>
    )
}



export const ControlCenterFocus = ({ show, onClick }) => {

    return (<Box style={
        {
            position: 'fixed',
            display: 'block',
            backdropFilter: 'blur(10px)',
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: 5,
            top: 270,
            right: 10,
            width: 32,
            height: 32,
            zIndex: 999,
        }
    }
    >
        <IconButton
            edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={onClick}
        >
            {/* <FilterCenterFocusRoundedIcon color={show ? "action" : "disabled"} fontSize="inherit" /> */}
            {
                show ?
                    <FilterCenterFocusRoundedIcon color="success" fontSize="inherit" />
                    :
                    <CenterFocusWeakRoundedIcon color="error" fontSize="inherit" />
            }
        </IconButton>
    </Box>
    )
}

export const ControlPoi = ({ show, onClick }) => {

    return (<Box style={
        {
            position: 'fixed',
            display: 'block',
            backdropFilter: 'blur(10px)',
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: 5,
            top: 305,
            right: 10,
            width: 32,
            height: 32,
            zIndex: 999,
        }
    }
    >
        <IconButton
            edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={onClick}
        >
            {/* {
                show ?
                    <RadioButtonUncheckedIcon color="success" fontSize="inherit" />
                    :
                    <RadioButtonUncheckedIcon color="error" fontSize="inherit" />
            } */}
            <RadioButtonUncheckedIcon color="success" fontSize="inherit" />

        </IconButton>
    </Box>
    )
}
export const ControlNavigation = ({ onClick }) => {

    return (<Box style={
        {
            position: 'fixed',
            display: 'block',
            backdropFilter: 'blur(10px)',
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: 5,
            top: 340,
            right: 10,
            width: 32,
            height: 32,
            zIndex: 999,
        }
    }
    >
        <IconButton
            edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={onClick}
        >
            {/* {
                show ?
                    <RadioButtonUncheckedIcon color="success" fontSize="inherit" />
                    :
                    <RadioButtonUncheckedIcon color="error" fontSize="inherit" />
            } */}
            <NavigationRoundedIcon color="info" fontSize="inherit" />

        </IconButton>
    </Box>
    )
}

export const CardNavigation = ({ duration = 0, distance = 0 }) => {
    var km = distance / 1000;
    var date = new Date()
    var dateSetSeconds = new Date()
    dateSetSeconds.setSeconds(date.getSeconds() + duration);


    const sDate = date
    const eDate = dateSetSeconds
    var diff = eDate.getTime() - sDate.getTime();
    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    // msec -= mm * 1000 * 60;
    // var ss = Math.floor(msec / 1000);
    // msec -= ss * 1000
    return (<Box style={
        {
            position: 'fixed',
            display: 'block',
            backdropFilter: 'blur(10px)',
            border: "2px solid rgba(0,0,0,0.2)",
            borderRadius: 5,
            top: 380,
            right: 10,
            width: 120,
            height: 50,
            zIndex: 999,
            backgroundColor: "white"
        }
    }
    >
        <Box sx={{
            textAlign: "center",
            margin: "auto",
            verticalAlign: "middle",
            fontSize: "15px"
        }}>
            {`อีก ${km.toFixed(1)} Km `}
            <br />
            {`ถึงใน ${hh !== 0 ? hh + ' ชม.' : ''} ${mm} น.`}
            {/* {`ถึงใน ${fDateTo(dateSetSeconds, "HH:mm")}`} */}
        </Box>
    </Box>
    )
}