import React, { useState } from 'react'
import { Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
import CloseIcon from '@mui/icons-material/Close';

export default function HelpdeskUpdate() {
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <IconButton size="small" onClick={handleOpen}><SubtitlesOutlinedIcon fontSize="small" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>อัพเดทงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
