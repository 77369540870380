import styled from '@emotion/styled';
import { DatePicker, DateTimePicker, LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Stack, Divider, Grid, TextField, Typography, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination, IconButton, Dialog, DialogContent, useMediaQuery, TableSortLabel } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';
import { Api } from '../../components/api';
import { fDateTo } from '../../utils/formatTime';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import TreeViewGroup from './TreeViewGroup';
import { useReactToPrint } from 'react-to-print';
import HasPermission from '../../haspermission/HasPermission';
import _ from 'lodash';

export default function Offlinedevice({ account, userId, permissions }) {
    const navigate = useNavigate()
    const [devices, setDevices] = useState([])
    const [poiLogs, setPoiLogs] = useState([])

    useEffect(() => {
        Api.get(`/Tracking/DeviceByUsers?userid=${userId}`).then((res) => {
            var list = res.data.map(d => {
                return {
                    value: d.group_id,
                    label: d.group_name,
                    children: d.device.map(c => {
                        return {
                            value: c.id,
                            label: c.name
                        }
                    })
                }
            });
            setDevices(list)
        }
        ).catch(e => {
            console.log('error', e)
        })
    }, [userId])

    const schema = Yup.object().shape({
        date: Yup.date().required('minute is required'),
    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            date: fDateTo(new Date(), "yyyy-MM-dd HH:mm:ss")
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { date } = values
            const { cocode } = account;
            const req = {
                date: `${date}`,
                devices: checked
            }
            Api.post('/Tracking/deviceoffline', req).then((res) => {
                const { data } = res
                setPoiLogs(data)
                setSubmitting()
            }).catch((e) => {
                setPoiLogs([])
                setSubmitting()
            })
        }
    })
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;
    const [checked, setChecked] = useState([])
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onChangeDate = (value, name) => {
        if (String(value) === "Invalid Date") {
            setFieldValue(name, value)
        } else {
            setFieldValue(name, fDateTo(value, "yyyy-MM-dd HH:mm:ss"))
        }
    }


    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('vehicle_id');

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    return (
        <Page title="Offline device">
            <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Event : Offline device</Typography>
                </Box>
                <Box flexGrow={1} padding={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} >
                                        <TreeViewGroup
                                            nodes={devices}
                                            checked={checked}
                                            setChecked={setChecked}
                                            maxHeight={"180px"}
                                            height={"180px"}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} justifyContent="flex-end" justifyItems="center">
                                                {/* <TextField
                                                size="small"
                                                fullWidth
                                                label="นาที"
                                                {...getFieldProps('minute')}
                                                error={Boolean(touched.minute && errors.minute)}
                                                helperText={touched.minute && errors.minute}
                                            /> */}
                                                <MobileDateTimePicker
                                                    ampm={false}
                                                    allowKeyboardControl={false}
                                                    onChange={(e) => { onChangeDate(e, "date") }}
                                                    value={values.date}
                                                    inputFormat="dd/MM/yyyy HH:mm:ss"
                                                    format="yyyy/MM/dd HH:mm:ss"
                                                    // {...getFieldProps('date')}
                                                    disableFuture
                                                    // error={Boolean(touched.date && errors.date)}
                                                    // helperText={touched.date && errors.date}
                                                    slotProps={{
                                                        textField: {
                                                            required: true,
                                                        },
                                                    }}
                                                    renderInput={(params) => <TextField size="small" required placeholder="DD/MM/YYYY" fullWidth  {...params} />}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                >
                                                    <LoadingButton
                                                        style={{ height: 40, width: 80 }}
                                                        // fullWidth={true}
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        loading={isSubmitting}
                                                    >
                                                        ค้นหา
                                                    </LoadingButton>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-start"
                                                    alignItems="center"
                                                >
                                                    <Button
                                                        style={{ height: 40, width: 80 }}
                                                        // fullWidth={true}
                                                        size="small"
                                                        type="submit"
                                                        variant="contained"
                                                        color="info"
                                                        onClick={() => {
                                                            navigate('/report/event')
                                                        }}

                                                    >
                                                        กลับ
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </Box>
            </Container>
            <MainStyle>
                <Container sx={{
                    padding: 3,
                    // justifyContent: "center", display: "flex"
                }} >
                    <Box>
                        <Stack justifyContent="space-between" direction="row" spacing={1} >
                            <Box></Box>
                            <Box>
                                {poiLogs.length !== 0 && (

                                    <Stack direction="row" spacing={1}>
                                        <HasPermission permissions={permissions} scope={["print"]}>
                                            <Button onClick={handlePrint} variant="outlined">พิมพ์</Button>
                                        </HasPermission>
                                        <HasPermission permissions={permissions} scope={["download"]}>
                                            <Button
                                                as={CSVLink}
                                                filename={`${fDateTo(values.date, 'dd_MM_yyyy_HH_mm')}.csv`} data={
                                                    _.orderBy(poiLogs, orderBy, order).map((value, index) => {
                                                        const {
                                                            device, vehicle_id, vehicle_chassis_no, datetime, poi_name, poi_id
                                                        } = value;
                                                        return {
                                                            "เลขที่อุปกรณ์": `=""${device}""`,
                                                            "ทะเบียนรถ": `=""${vehicle_id}""`,
                                                            "หมายเลขตัวถัง": `=""${vehicle_chassis_no}""`,
                                                            "วันที่": `=""${fDateTo(new Date(datetime), 'dd/MM/yyyy HH:mm:ss')}""`,
                                                            "รหัสสถานที่": `=""${poi_id}""`,
                                                            "สถานที่": `=""${poi_name}""`
                                                        }
                                                    }
                                                    )
                                                }
                                                variant="contained"
                                                style={{
                                                    textDecorationLine: 'none',
                                                }} >
                                                Download
                                            </Button>
                                        </HasPermission>
                                    </Stack>
                                )}

                            </Box>
                        </Stack>
                    </Box>

                    <Box ref={componentRef} className="section-to-print">
                        <Box sx={{
                            marginLeft: "0.5cm", marginRight: "0.5cm"
                        }}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={200}>
                                                <TableSortLabel
                                                    active={orderBy === 'device'}
                                                    direction={orderBy === 'device' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('device')}
                                                >
                                                    เลขที่อุปกรณ์
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell width={200}>
                                                <TableSortLabel
                                                    active={orderBy === 'vehicle_id'}
                                                    direction={orderBy === 'vehicle_id' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('vehicle_id')}
                                                >
                                                    ทะเบียนรถ
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell width={200}>
                                                <TableSortLabel
                                                    active={orderBy === 'vehicle_chassis_no'}
                                                    direction={orderBy === 'vehicle_chassis_no' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('vehicle_chassis_no')}
                                                >
                                                    หมายเลขตัวถัง
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell width={200}>
                                                <TableSortLabel
                                                    active={orderBy === 'datetime'}
                                                    direction={orderBy === 'datetime' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('datetime')}
                                                >
                                                    วันที่
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell width={200}>
                                                <TableSortLabel
                                                    active={orderBy === 'poi_name'}
                                                    direction={orderBy === 'poi_name' ? order : 'asc'}
                                                    onClick={() => handleRequestSort('poi_name')}
                                                >
                                                    สถานที่
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            _.orderBy(poiLogs, orderBy, order).map((value, index) => {
                                                return (
                                                    <TableRow sx={{ padding: "10px" }} key={index}>
                                                        <TableCell>{value.device}</TableCell>
                                                        <TableCell>{value.vehicle_id}</TableCell>
                                                        <TableCell>{value.vehicle_chassis_no}</TableCell>
                                                        <TableCell>{fDateTo(value.datetime, "dd/MM/yyyy HH:mm:ss")}</TableCell>
                                                        <TableCell>{value.poi_name}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                </Container>
            </MainStyle>
        </Page >
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));