import { styled } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react'
import { Circle, MapContainer, Marker, Polygon, Polyline, Rectangle, TileLayer, Tooltip, ZoomControl, useMapEvents } from 'react-leaflet';
import { Box, IconButton } from '@mui/material';
import ListTileLayer from '../../layouts/ListTileLayer';
import { CardNavigation, ControlBusStop, ControlCenterFocus, ControlCluster, ControlLayerMap, ControlNavigation, ControlPoi, ControlRoute, ControlStations } from './ControlMap';
import CardDetail from './CardDetail';
import CarMarker from './CarMarker';
import PolylineUtil from 'polyline-encoded';
import L, { divIcon } from 'leaflet'
import BusStations from './BusStations';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import PoiDialogs from './PoiDialogs';
import axios from 'axios';
import locationIcon from '../../img/location.png'
import MarkerWithLabel from './MarkerWithLabel';
import ReplayLabel from './ReplayLabel';
import { fDateTo } from '../../utils/formatTime';


const MapContainerStyle = styled(MapContainer)(({ theme, menuWidth }) => ({
    overflow: 'hidden',
    height: '100vh',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
        height: "calc(100vh - 124px)",
        // height: '50vh'
    },
    [theme.breakpoints.up('md')]: {
        // width: `${width}px`
        // width: `calc(100vw - ${menuWidth}px)`
    },
}));
const center = [13.839660198254604, 100.63469639884744];
const zoom = 13;
const GroupRouteMarkerIcon = ({ icon, w = 30, h = 30, x = 10, y = 10 }) => L.icon({
    iconUrl: icon,
    iconSize: [w, h],
    iconAnchor: [(w / 2 + x), ((h / 2) + y)],
    popupAnchor: [0, -10],
    shadowAnchor: [10, 10]
});
const createClusterCustomIcon = function (cluster) {
    return new divIcon({
        html: `<span class="cluster-icon-text">${cluster.getChildCount() / 2}</span>`,
        className: "cluster-icon",
        iconSize: L.point(33, 33, true)
    });
};

export default function ContainerMapDash({
    map,
    setMap,
    refMapStyle,
    selectedMapStyle,
    setSelectedMapStyle,
    routeMapShow,
    setRouteMapShow,
    busStopMapShow,
    setBusStopMapShow,
    stationsMapShow,
    setStationsMapShow,
    devicesSub,
    filter,
    selectSub,
    userId,
    onClickCloseSideDash,
    onClickSelectDivce,
    display,
    groupRoute,
    subStations,
    isClickZoom,
    setIsClickZoom,
    cConame,
    cocode,
    menuWidth,
    permissions
}) {

    const onClickLayer = (value) => {
        if (refMapStyle.current) {
            refMapStyle.current.setUrl(value.url);
            setSelectedMapStyle(value.id)
            localStorage.setItem('selectedMapStyle', value.id)
        }
    }


    const [clusterShow, setClusterShow] = useState(
        localStorage.getItem("clusterShow") === "false" ? false : true
    )


    const RenderCluster = () => {
        const isCluster = clusterShow
        return (
            <MarkerClusterGroup
                chunkedLoading={true}
                iconCreateFunction={createClusterCustomIcon}
                spiderfyOnMaxZoom={false}
                disableClusteringAtZoom={filter.length > 100 ? isCluster ? 15 : 3 : 3}
                animateAddingMarkers={false}
                animate={false}
                showCoverageOnHover={false}
                maxClusterRadius={100}
                chunkDelay
            >
                {
                    filter.map((marker, index) => {

                        const select = selectSub?.device === marker.device
                        // const className = select ? "icon-car-focus" :
                        //     new Date(marker?.datetime) < new Date().setMinutes(new Date().getMinutes() - 5) ? "icon-car-offline" :
                        //         "icon-car"

                        return (
                            <MemoizedMarkerRotated
                                data={marker}
                                onClick={() => {
                                    onClickSelectDivce({ device: marker.device, latitude: marker.latitude, longitude: marker.longitude, isZoom: false })
                                }}
                                select={select}
                                // className={className}
                                key={'markers' + index}
                            />
                        )
                    })
                }
            </MarkerClusterGroup>
        )
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleSnackbarWithAction = ({ message, variant, autoHideDuration = 1000, horizontal = "right", vertical = "bottom" }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: autoHideDuration,
                anchorOrigin: {
                    horizontal: horizontal,
                    vertical: vertical
                },
                variant: variant,
                action: (key) => (
                    <IconButton
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                        }}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                )
            });
    }

    const [centerFocus, setCenterFocus] = useState(localStorage.getItem("centerFocus") === "false" ? false : true)

    const [openPoi, setOpenPoi] = useState(false);


    const handleCenterFocus = () => {
        const update = !centerFocus
        setCenterFocus(update)
        localStorage.setItem('centerFocus', String(update))
    }

    const [listPoiShow, setListPoiShow] = useState([]);
    const [locFinder, setLocFinder] = useState(false);
    const [navigatorLoc, setNavigatorLoc] = useState(null);
    const [naviLoc, setNaviLoc] = useState(null);

    const handlePoiOpen = () => setOpenPoi(true);
    const handlePoiClose = () => setOpenPoi(false);
    const controller = new AbortController();


    const LocationFinder = () => {
        const map = useMapEvents({
            click(e) {
                if (selectSub) {
                    const { latitude, longitude } = selectSub
                    const { lat, lng } = e.latlng
                    const drivingUrl = `https://router.project-osrm.org/route/v1/driving/${longitude},${latitude};${lng},${lat}?overview=full`
                    axios.get(drivingUrl)
                        .then(res => {
                            setNaviLoc(e.latlng)
                            setNavigatorLoc(res.data)
                            setLocFinder(false)
                            document.getElementById('MapContainer').style.cursor = ''
                        }
                        ).catch((e) => {
                            // setLoading(false)
                            setLocFinder(false)
                            document.getElementById('MapContainer').style.cursor = ''
                        })
                } else {
                    setNaviLoc(e.latlng)
                    setLocFinder(false)
                    document.getElementById('MapContainer').style.cursor = ''
                }
            },
            keydown(e) {
                if (e.originalEvent.key === "Escape") {
                    // setNaviLoc(e.latlng)
                    setLocFinder(false)
                    document.getElementById('MapContainer').style.cursor = ''
                }
            }
        });
        return null;
    };


    useEffect(() => {
        if (naviLoc && selectSub) {
            const { latitude, longitude } = selectSub
            const { lat, lng } = naviLoc
            const drivingUrl = `https://router.project-osrm.org/route/v1/driving/${longitude},${latitude};${lng},${lat}?overview=full`
            // alert(`${c}`);
            // setLocFinder(false)
            // document.getElementById('MapContainer').style.cursor = ''
            axios.get(drivingUrl, { signal: controller.signal })
                .then(res => {
                    // const decode = PolylineUtil.decode(res.data?.routes[0]?.geometry)
                    // console.log('decode', decode)
                    setNavigatorLoc(res.data)
                    // setLocFinder(false)
                    // document.getElementById('MapContainer').style.cursor = ''
                }
                ).catch((e) => {
                    setNavigatorLoc(null)
                    // setNaviLoc(null)
                    // setLoading(false)
                    // setLocFinder(false)
                    // document.getElementById('MapContainer').style.cursor = ''
                })
        }

    }, [naviLoc, selectSub])





    const [isPolyline, setIsPolyline] = useState(null);
    const [markerSelect, setMarkerSelect] = useState(null)
    const [positionEvents, setPositionEvents] = useState(null);

    const onClickEvent = (value) => {
        if (value !== null) {
            map.flyTo([value.latitude, value.longitude], 18, { duration: 1 })
            setPositionEvents(value)
        } else {
            setPositionEvents(value)
        }
    }


    useEffect(() => {
        if (menuWidth && map) {
            map.invalidateSize()
        }
        // const maps = map.current;
        // if (maps) {
        //     maps.invalidateSize();
        // }
    }, [menuWidth]);

    return (
        <Box
            sx={{
                position: "relative",
                // display: display,
                // height: display ? "auto" : "0px"
            }}
        >
            <MapContainerStyle
                id='MapContainer'
                preferCanvas={true}
                menuWidth={menuWidth}
                whenCreated={(ref) => {
                    setMap(ref)
                    if (selectSub) {
                        ref.setView([selectSub.latitude, selectSub.longitude], 16, {
                            "animate": true,
                            "duration": 1,
                        })
                    }
                }}
                center={center}
                zoom={zoom}
                minZoom={3}
                zoomControl={false}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                attributionControl={false}
                contextmenu={true}
                contextmenuItems={[
                    {
                        text: "latlng",
                        callback: (e) => {
                            handleSnackbarWithAction({ message: `Copied @${e.latlng.lat} , ${e.latlng.lng}`, autoHideDuration: 3000, horizontal: "center" })
                            navigator.clipboard.writeText([e.latlng.lat, e.latlng.lng])
                        }
                    }
                ]}
            // onClick={(e)=>{
            //     alert(e.latlng)
            // }}
            >
                <TileLayer
                    ref={refMapStyle}
                    url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
                    maxNativeZoom={19}
                    maxZoom={22}
                />
                {/* <ZoomControl position="topright" /> */}
                <ZoomControl position="topleft" />
                <ControlLayerMap onClick={onClickLayer} selected={selectedMapStyle} />
                <ControlRoute show={routeMapShow}
                    onClick={() => {
                        setRouteMapShow(!routeMapShow)
                        localStorage.setItem('routeMapShow', String(!routeMapShow))
                    }}
                />
                <ControlBusStop show={busStopMapShow}
                    onClick={() => {
                        setBusStopMapShow(!busStopMapShow)
                        localStorage.setItem('busStopMapShow', String(!busStopMapShow))
                    }}
                />
                <ControlStations show={stationsMapShow}
                    onClick={() => {
                        setStationsMapShow(!stationsMapShow)
                        localStorage.setItem('stationsMapShow', String(!stationsMapShow))
                    }}
                />
                <ControlCluster
                    show={clusterShow}
                    onClick={() => {
                        setClusterShow(!clusterShow)
                        localStorage.setItem('clusterShow', String(!clusterShow))
                    }}
                />
                {
                    <RenderCluster />
                }


                <ControlCenterFocus show={centerFocus} onClick={handleCenterFocus} />
                <ControlPoi onClick={handlePoiOpen} />
                <ControlNavigation
                    onClick={() => {
                        controller.abort()
                        setLocFinder(true)
                        setNaviLoc(null)
                        setNavigatorLoc(null)
                        document.getElementById('MapContainer').style.cursor = 'crosshair'
                    }} />
                {
                    locFinder && (
                        <LocationFinder />
                    )
                }
                {
                    navigatorLoc && (
                        <>
                            <Polyline
                                positions={PolylineUtil.decode(navigatorLoc?.routes[0]?.geometry)}
                                weight={5}
                                color='#141E46'
                            // color={"blue"}
                            />
                            <CardNavigation duration={navigatorLoc?.routes[0]?.duration} distance={navigatorLoc?.routes[0]?.distance} />
                        </>
                    )
                }

                {
                    naviLoc && (
                        <Marker
                            position={[naviLoc.lat, naviLoc.lng]}
                            icon={new L.Icon({
                                iconUrl: locationIcon,
                                iconRetinaUrl: locationIcon,
                                popupAnchor: [0, -15],
                                iconSize: [30, 30],
                                iconAnchor: [15, 30]
                            })}
                            eventHandlers={{
                                click: () => {
                                    controller.abort()
                                    setNaviLoc(null)
                                    setNavigatorLoc(null)
                                }
                            }}
                        // radius={10}
                        // color="red"
                        />
                    )
                }


                {/* {
                    selectSub !== null && (
                        <MemoizedMarkerRotated
                            data={selectSub}
                            onClick={() => {
                                onClickSelectDivce({ device: selectSub.device, latitude: selectSub.latitude, longitude: selectSub.longitude })
                            }}
                            select={select}
                            className={"icon-car-focus"}
                            key={'markers'}
                        />
                    )
                } */}

                {
                    groupRoute.map((x, i) => (
                        <React.Fragment key={'polylineGroup' + i}>
                            {
                                routeMapShow && (
                                    <Polyline
                                        positions={PolylineUtil.decode(x?.encode)}
                                        weight={x?.width}
                                        color={x?.color}
                                    />
                                )
                            }
                            {
                                busStopMapShow && (
                                    <>
                                        {
                                            x.markers.map((_, _i) => (
                                                <React.Fragment key={_i}>
                                                    {
                                                        _.style !== null && (
                                                            <Marker
                                                                key={'markersroute' + _i}
                                                                zIndexOffset={-9999}
                                                                icon={GroupRouteMarkerIcon({ icon: _.style.icon, w: _.style.w, h: _.style.h, x: _.style.x, y: _.style.y })}
                                                                position={[_.latitude, _.longitude]}
                                                            >
                                                                <Tooltip permanent direction="top" offset={[0, -15]} >
                                                                    {
                                                                        _.name
                                                                    }
                                                                </Tooltip>
                                                            </Marker>
                                                        )
                                                    }
                                                </React.Fragment>
                                            ))
                                        }
                                    </>
                                )
                            }
                        </React.Fragment>
                    )
                    )
                }


                {
                    listPoiShow.map((geopoi, id_geopoi) => (
                        <React.Fragment key={"geopoiId" + id_geopoi}>
                            {geopoi.poi_data?.geometry.type === "Circle" && (
                                // <MemoizedMarkerWithText center={[geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]]} radius={geopoi.poi_data.properties.radius} children={<Tooltip direction="center" permanent>dwwwdwd</Tooltip>} />
                                <Circle
                                    center={[geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]]}
                                    radius={geopoi.poi_data.properties.radius}
                                    // displayName={"dwdwdwd"}
                                    // children={<Tooltip direction="center" permanent={map.getZoom() > 13}>{geopoi.poi_name}</Tooltip>}
                                    children={<Tooltip direction="center">{geopoi.poi_name}</Tooltip>}

                                />

                            )}
                            {geopoi.poi_data?.geometry.type === "Polyline" && (
                                <Polyline
                                    positions={geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                        return [polylineGeo[1], polylineGeo[0]];
                                    })}
                                    children={<Tooltip direction="center">{geopoi.poi_name}</Tooltip>}

                                />
                            )}
                            {geopoi.poi_data?.geometry.type === "Rectangle" && (
                                <Rectangle
                                    bounds={
                                        geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        })
                                    }
                                    children={<Tooltip direction="center">{geopoi.poi_name}</Tooltip>}

                                // children={<Tooltip>dwwwdwd</Tooltip>}
                                />
                            )}
                            {geopoi.poi_data?.geometry.type === "Polygon" && (
                                <Polygon
                                    positions={
                                        geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        })
                                    }
                                    children={<Tooltip direction="center">{geopoi.poi_name}</Tooltip>}

                                />
                            )}

                        </React.Fragment>
                    ))
                }

                {
                    isPolyline && (
                        <Polyline positions={isPolyline} weight={10} />
                    )
                }
                {
                    markerSelect && (
                        <>
                            <MarkerWithLabel
                                position={[markerSelect?.latitude, markerSelect?.longitude]}
                                rotationAngle={markerSelect?.bearing}
                                rotationOrigin="center"
                            />
                            <ReplayLabel markerSelect={markerSelect} />
                        </>
                    )
                }
                {
                    positionEvents !== null && (
                        // <Circle center={[positionEvents.latitude, positionEvents.longitude]} radius={5} weight={5} />
                        <MarkerWithLabel
                            position={[positionEvents.latitude, positionEvents.longitude]}
                            label={
                                <Box>
                                    <Box>
                                        วันที่: {`${fDateTo(new Date(positionEvents.date_gps), 'dd/MM/yyyy HH:mm:ss')}`}
                                    </Box>
                                    <Box>
                                        รหัสเหตุการณ์:{`${positionEvents.event_id}`}
                                    </Box>
                                    <Box>
                                        ชื่อเหตุการณ์: {`${positionEvents.event_name}`}
                                    </Box>
                                    <Box>
                                        ข้อมูลเหตุการณ์: {`${positionEvents.event_value}`}
                                    </Box>
                                    <Box>
                                        มาตรวัดระยะทาง: {`${positionEvents.odometer}`}
                                    </Box>
                                </Box>
                            }
                        />
                    )
                }

            </MapContainerStyle>
            {
                selectSub && (
                    <CardDetail
                        permissions={permissions}
                        data={selectSub}
                        onClose={() => {
                            onClickCloseSideDash()
                            setLocFinder(false)
                            // document.getElementById('MapContainer').style.cursor = ''
                            setNavigatorLoc(null)
                            // setNaviLoc(null)
                        }}
                        userId={userId}
                        centerFocus={centerFocus}
                        onMove={() => {
                            if (selectSub && map !== null) {
                                // if (_zoom) {
                                //     return map.setView([selectSub.latitude, selectSub.longitude], 16, {
                                //         "animate": true,
                                //         "duration": 1,
                                //     })
                                // }
                                map.setView([selectSub.latitude, selectSub.longitude], isClickZoom ? 16 : map.getZoom(), {
                                    "animate": true,
                                    "duration": 1,
                                })
                                setIsClickZoom(false)
                            }
                        }}
                        setIsPolyline={setIsPolyline}
                        map={map}
                        setMarkerSelect={setMarkerSelect}
                        markerSelect={markerSelect}
                        cocode={cocode}
                        onClickEvent={onClickEvent}
                    />
                    // <>{JSON.stringify(selectSub)}</>
                )
            }
            {
                stationsMapShow && (
                    <BusStations map={map} onClickSelectDivce={onClickSelectDivce} subStations={subStations} />
                )
            }
            <PoiDialogs open={openPoi} handleClose={handlePoiClose} cConame={cConame}
                onChanges={(val) => {
                    setListPoiShow(val)
                    // console.log('val', val)
                }} />
        </Box >
    )
}


const ItemMarkerRotated = ({ data, select, onClick }) => {

    return <CarMarker
        data={data}
        onClick={onClick}
        select={select}
    // className={className}
    />;
};

const ItemMarkerWithText = ({ center, radius, children }) => {

    return <Circle
        center={center}
        radius={radius}
        children={children}

    />;
};

const MemoizedMarkerRotated = React.memo(ItemMarkerRotated);

const MemoizedMarkerWithText = React.memo(ItemMarkerWithText);