import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';

export default function CreateCarsType() {
    const [open, setOpen] = useState(false)

    const onHandleOpen = () => {
        setOpen(true)
    }
    const onHandleClose = () => {
        setOpen(false)
    }
    return (
        <div>
            <IconButton onClick={onHandleOpen} ><PostAddOutlinedIcon /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={onHandleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>สร้างประเภทงาน</DialogTitle>
                <DialogContent>
                    <Box sx={{ padding: "10px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" label="รหัสประเภทงาน" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" label="ชื่อประเภทงาน" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} marginBottom="10px" justifyContent="center">
                                    <Button size="small" variant="contained">บันทึก</Button>
                                    <Button size="small" variant="contained">ยกเลิก</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
