import React, { useState } from 'react'
import { Api } from '../../../components/api'
import { Box, IconButton, Grid, Stack, Button, Dialog, DialogTitle, DialogContent, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';

export default function UsersGroupPermission({ groupid, account, id }) {
    const { cocode, c_coname, cogcode } = account;

    const [openGroupUsers, setOpenGroupUsers] = useState(false)
    const [openGroup, setOpenGroup] = useState(false)
    const [usersGroup, setUsersGroup] = useState([])
    const [userId, setUserID] = useState("")
    const [groups, setGroups] = useState([])


    const handleOpenGroup = () => {
        Api.get(`/v0/if/Permission_groups?cname=${c_coname}`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setGroups(res.data)
                setOpenGroup(true)
            })
            .catch((err) => {
                handleClickVariant("ไม่พบ บัญชีเข้าสู่ระบบ", "error")
            })

    }
    const handleCloseGroup = () => {
        setOpenGroup(false)
    }

    const onGetUserID = () => {
        Api.get(`/v0/app/GetUserID?id=${id}`)
            .then((res) => {
                const { userid } = res.data
                setUserID(userid)
                onGetGroupsUser({ userid: userid })
            })
            .catch((err) => {
                handleClickVariant("ไม่พบ บัญชีเข้าสู่ระบบ", "error")
            })
    }

    const handleOpenGroupUsers = () => {
        onGetUserID()

    }
    const handleCloseGroupUsers = () => {
        setOpenGroupUsers(false)
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, data, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            // style: { bgcolor: "whitesmoke !important" },
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };


    const onGetGroupsUser = ({ userid }) => {
        Api.get(`/v0/if/GPermission_getgroup?cname=${c_coname}&userid=${userid}`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setOpenGroupUsers(true)
                setUsersGroup(res.data)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }


    const onPostUserGroups = ({ groupid }) => {
        const data = {
            "cogcode": cogcode,
            "cocode": cocode,
            "c_coname": c_coname,
            "groupid": groupid,
            "userid": userId
        }
        Api.post(`/v0/if/GPermission_adduser`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                onGetGroupsUser({ userid: userId })
                handleCloseGroup()
                handleClickVariant("เพิ่มข้อมูลเสร็จสิ้น", "success")
            })
            .catch((err) => {
                console.log('err', err)
            })
    }
    const onDelUserGroups = ({ user }) => {
        const data = user
        Api.post(`/v0/if/GPermission_deluser`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                onGetGroupsUser({ userid: user?.userid })
                handleClickVariant("ลบข้อมูลเสร็จสิ้น", "success")
                // setgroups(res.data)
                // console.log('res', res)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }


    return (
        <>
            <IconButton size="small" onClick={() => { handleOpenGroupUsers({ groupid: groupid }) }}>
                <GroupsOutlinedIcon fontSize="small" />
            </IconButton>

            <Dialog
                open={openGroupUsers}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseGroupUsers}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    รายการกลุ่มผู้ใช้งาน
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseGroupUsers}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>groupid</TableCell>
                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                    <IconButton size="small" onClick={() => {
                                        handleOpenGroup()
                                    }}>
                                        <AddBoxRoundedIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                usersGroup.map((user, index) => {
                                    return (
                                        <TableRow key={`user${index}`}>
                                            <TableCell>{user.groupid}</TableCell>
                                            <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                <IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการลบ?", onHandle: () => { onDelUserGroups({ user: user }) } }) }}>
                                                    <DeleteOutlineIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openGroup}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseGroup}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    กลุ่มผู้ใช้งานทั้งหมด
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseGroup}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>groupid</TableCell>
                                <TableCell>groupname</TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                groups.map((group, index) => {
                                    return (
                                        <TableRow key={`user${index}`}>
                                            <TableCell>{group.groupid}</TableCell>
                                            <TableCell>{group.groupname}</TableCell>
                                            <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                <IconButton size="small" onClick={() => {
                                                    onPostUserGroups({ groupid: group.groupid })
                                                }}>
                                                    {/* <GroupAddOutlinedIcon fontSize="small" /> */}
                                                    <AddBoxRoundedIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    )
}
