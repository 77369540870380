import { useTheme } from '@mui/styles';
import React from 'react'
import { useMediaQuery, Box, Dialog, DialogTitle, TextField, DialogContent, DialogActions, Button } from '@mui/material';
import { useFormik, FormikProvider, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

export default function PlaylistSave({
    openSave,
    handleSave,
    onSubmit
}) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const handleDialog = () => {
        handleSave()
    }
    const Schema = Yup.object().shape({
        groupId: Yup.string().required('group id is required'),
        groupName: Yup.string().required('group Name is required'),
    });
    const formik = useFormik({
        initialValues: {
            groupId: '',
            groupName: ''
        },
        validationSchema: Schema,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(false)
            onSubmit(values)

        }
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openSave}
            onClose={handleDialog}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="md"
            sx={{
                ".MuiBackdrop-root": {
                    backgroundColor: "rgba(111, 126, 140, 0.2)",
                },
            }}
        >
            <DialogTitle id="scroll-dialog-title">
                {/* <Typography > Save custom group </Typography> */}
                Save custom group
            </DialogTitle>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
                    <DialogContent
                        dividers={true}
                        sx={{
                            // width: theme => theme.breakpoints.up("md") ? "600px" : "100%",
                            width: !fullScreen ? "600px" : "auto",
                            height: "500px",
                            textAlign: "center",
                            padding: "5px",
                            // overflow: "hidden"
                        }}
                    >
                        <Box sx={{
                            padding: "10px"
                        }}>
                            <TextField
                                fullWidth
                                size="small"
                                label={"GROUP ID"}
                                autoFocus
                                {...getFieldProps('groupId')}
                                error={Boolean(touched.groupId && errors.groupId)}
                                helperText={touched.groupId && errors.groupId}
                                autoComplete='off'
                            />
                        </Box>
                        <Box sx={{
                            padding: "10px"
                        }}>
                            <TextField
                                fullWidth
                                size="small"
                                label={"GROUP NAME"}
                                {...getFieldProps('groupName')}
                                error={Boolean(touched.groupName && errors.groupName)}
                                helperText={touched.groupName && errors.groupName}
                                autoComplete='off'
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {/* <Typography >{listSelect.length}</Typography> */}

                        <Button onClick={handleDialog}>Cancel</Button>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>

                </Form>
            </FormikProvider>
        </Dialog >
    )
}