import React, { useEffect, useRef } from 'react'
import L from 'leaflet';


import markerIcon from '../../img/arrow_circle_up_black.svg'
import { Marker, Popup, Tooltip } from 'react-leaflet';
import RotatedMarker from '../map/RotatedMarker';

const markersIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    popupAnchor: [0, -15],
    iconSize: [30, 30],
    iconAnchor: [15, 15],
});

export default function MarkerRotatedWithLabel({ position, rotationAngle, rotationOrigin, label, showLabel = true }) {
    const leafletRef = useRef();
    useEffect(() => {
        leafletRef.current.openPopup();
    }, [label])
    return (
        <RotatedMarker
            position={position}
            rotationAngle={rotationAngle}
            rotationOrigin={rotationOrigin}
            icon={markersIcon}
            ref={leafletRef}
        >
            {label && (
                // <Tooltip
                //     className='tooltip-label'
                //     direction="top" offset={[0, -15]} opacity={1} permanent={showLabel}>
                //     {label}
                // </Tooltip>
                <Popup
                    className='label-popup'
                    closeButton={false}
                    closeOnClick={false}



                >
                    {label}
                </Popup>
            )}
        </RotatedMarker>
    )
}
