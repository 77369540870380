import { BottomNavigation, BottomNavigationAction, Menu, MenuItem } from '@mui/material';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import { useRef, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

export default function BottomNavigationBar({ ative, client }) {
    const navigate = useNavigate();
    const refDropDownAccount = useRef(null);
    const [open, setOpen] = useState(false)

    const handleOpenDropDown = () => {
        setOpen(!open)
    }
    const handleNav = (route) => {
        if (client) {
            client.end();
        }
        navigate(route)
    }
    // const { pathname } = useLocation();
    // const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

    // console.log('match', match('/report'))

    return (
        <BottomNavigation

            value={ative}
            showLabels={true}
            onChange={(event, newValue) => { }}
            sx={{
                '& .Mui-selected': {
                    '& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label': {
                        color: 'blue'
                    }
                }
            }}
        >
            <BottomNavigationAction label='Map' value="map" onClick={() => handleNav('/')} icon={<MapRoundedIcon />} />
            <BottomNavigationAction label="Report" value="report" onClick={() => handleNav('/report')} icon={<DescriptionIcon />} />
            <BottomNavigationAction label="Settings" value="settings" onClick={() => handleNav('/settings')} icon={<SettingsIcon />} />
            <BottomNavigationAction label="Account" value="account" onClick={() => handleNav('/account')} icon={<PersonIcon />} />

            {/* <BottomNavigationAction label="Account" value="account" onClick={handleOpenDropDown} icon={<PersonIcon ref={refDropDownAccount} />} />

            <Menu
                keepMounted={false}
                open={open}
                anchorEl={refDropDownAccount.current}
                onClose={handleOpenDropDown}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' }
                }}
                anchorOrigin={{ vertical: -90, horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem
                    sx={{ color: 'text.default' }}
                    onClick={() => {
                        handleNav('/settings/account')
                    }}
                >
                    Account
                </MenuItem>
                <MenuItem
                    sx={{ color: 'text.default' }}
                    onClick={() => {
                        handleNav('/login')
                    }}
                >
                    Logout
                </MenuItem>
            </Menu> */}
        </BottomNavigation>
    )
}