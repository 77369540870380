import { Icon } from '@iconify/react';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LockResetIcon from '@mui/icons-material/LockReset';
// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarSettingsConfig = [
    {
        title: 'account',
        path: '/account/profile',
        icon: <SettingsIcon />
    },
    {
        title: 'change password',
        path: '/account/changepass',
        icon: <LockResetIcon />
    },
    {
        title: 'logout',
        path: '/login',
        icon: <ExitToAppIcon />
    },
];

export default SidebarSettingsConfig;
