const ListTileLayer = [
    {
        id: 'GoogleMap',
        name: 'GoogleMap',
        url: 'https://mt.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'
    },
    {
        id: 'GoogleMapTraffic',
        name: 'GoogleMapTraffic',
        url: 'http://mt.google.com/vt/lyrs=m,traffic&hl=th&x={x}&y={y}&z={z}'
    },
    {
        id: 'GoogleMapSatellite',
        name: 'GoogleMapSatellite',
        url: 'https://mt.google.com/vt/lyrs=y&hl=th&x={x}&y={y}&z={z}'
    },
    {
        id: 'GoogleMapRoadMap',
        name: 'GoogleMapRoadMap',
        url: 'http://mts0.google.com/vt/lyrs=m@289000001&hl=th&src=app&x={x}&y={y}&z={z}&s=Gal&&apistyle=s.t%3A2%7Cs.e%3Ag%7Cp.c%3A%23ffD7DBDD%2Cs.t%3A6%7Cs.e%3Ag.f%7Cp.c%3A%23ffb6cfdb%2Cs.t%3A3%7Cs.e%3Al.t.s%7Cp.v%3Aoff%2Cs.t%3A51%7Cs.e%3Ag%7Cp.c%3A%23ffffffff%2Cs.t%3A50%7Cs.e%3Ag%7Cp.c%3A%23ffffffff%2Cs.t%3A49%7Cs.e%3Ag%7Cp.c%3A%23ffffffff%2Cs.t%3A51%7Cs.e%3Al%7Cp.v%3Aon%2Cs.t%3A2%7Cs.e%3Al%7Cp.v%3Aoff%2Cs.t%3A4%7Cp.v%3Aoff%2Cs.t%3A5%7Cp.c%3A%23ffebebeb%2Cs.t%3A1%7Cp.c%3A%23ffc4c4c4%7Cp.v%3Aoff%2Cs.t%3A33%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A34%7Cp.il%3Atrue%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A35%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A39%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A1%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A49%7Cp.c%3A%23fff0e3bb'
    },
    {
        id: 'OpenStreetMap',
        name: 'OpenStreetMap',
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    },
    {
        id: 'LongdoMap',
        name: 'LongdoMap',
        url: 'https://ms.longdo.com/mmmap/img.php?zoom={z}&x={x}&y={y}&mode=icons&key=4662e77a04f55c45284cc8d0b76221d3&proj=epsg3857&HD=1'
    },
    {
        id: 'PowerMap',
        name: 'PowerMap',
        url: 'https://maps.powermap.live/api/v2/map/vtile/thailand_th/{z}/{x}/{y}.png?access_token=971ca5c57f81c3235092d623a8e66c5de8d0d154'
    },
    // {
    //     id: 'DeeMap',
    //     name: 'DeeMap',
    //     url: 'https://www.deemap.com/DeeMap/gwc/service/tms/1.0.0/DeeMap2_THA_Map_EN@EPSG%3A900913@png8/{z}/{x}/{-y}.png8'
    // }
]

export default ListTileLayer;
