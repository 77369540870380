import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import { Api } from '../../../components/api'
import { useSnackbar } from 'notistack';

export default function UpdateCompany({ data, onGet, account }) {
    const { cocode, c_coname, cogcode } = account;
    const [open, setOpen] = useState(false)
    const [company, setCompany] = useState(data)

    useEffect(() => {
        setCompany(data)
    }, [data])



    const onHandleOpen = () => {
        setOpen(true)
    }
    const onHandleClose = () => {
        setOpen(false)
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setCompany(e => {
            return {
                ...e,
                [name]: value
            }
        })
    }

    const onSubmit = () => {
        const data = JSON.stringify({
            // ...values,
            ...company
        });
        Api.put(`/v0/if/Cname`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                onHandleClose()
                handleClickVariant("บันทึกสำเร็จ", "success")
                onGet()
                console.log('res', res)
            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
                onHandleClose()
                console.log('err', err)
            })
    }

    return (
        <div>
            <IconButton onClick={onHandleOpen} size="small" ><EditNoteIcon fontSize="small" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={onHandleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>แก้ไขข้อมูลบริษัท</DialogTitle>
                <DialogContent>
                    <Box sx={{ padding: "10px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" disabled label="code" fullWidth name="c_coname" value={company.c_coname} onChange={handleOnChange} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" label="fullname" fullWidth name="fullname" value={company.fullname} onChange={handleOnChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} marginBottom="10px" justifyContent="center">
                                    <Button size="small" variant="contained" onClick={onSubmit}>บันทึก</Button>
                                    <Button size="small" variant="contained" onClick={onHandleClose}>ยกเลิก</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
