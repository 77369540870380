import React, { useEffect, useState } from "react"

import { Checkbox, FormControlLabel, Grid, Box, TextField } from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { TreeItem, TreeView } from "@mui/lab"
import { makeStyles } from "@mui/styles"
import { Virtuoso } from 'react-virtuoso'

const useCondensedCheckBoxStyles = makeStyles(theme => ({
    condensed: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(0.5),
        paddingTop: 0,
        paddingBottom: 0
    }
}))

function leafIds(node) {
    return !node.children || node.children.length === 0
        ? [node.value]
        : node.children.map(leafIds).flat()
}

export default function TreeViewGroup({ nodes, checked, setChecked, maxHeight = "150px", height = "150px" }) {
    const classes = useCondensedCheckBoxStyles()

    const [expanded, setExpanded] = useState([])
    const [search, setSearch] = useState('')
    const [searchTextField, setSearchTextField] = useState('')


    useEffect(() => {
        const timeout = setTimeout(() => {
            setSearch(searchTextField)
        }, 50);

        return () => {
            clearTimeout(timeout)
        }
    }, [searchTextField])


    const handleCheck = (node, newValue) => {
        const value = checked.includes(node.value)
        if (!node.children || node.children.length === 0) {
            if (value === newValue) return
            setChecked(
                newValue
                    ? [...checked, node.value]
                    : checked.filter(id => id !== node.value)
            )
        } else {
            const ids = leafIds(node)
            const remaining = checked.filter(id => !ids.includes(id))
            setChecked(newValue ? [...remaining, ...ids] : remaining)
        }
    }

    function TreeNode({ node }) {
        const isChecked = leafIds(node).every(id => checked.includes(id))
        const isIndeterminate =
            !isChecked && leafIds(node).some(id => checked.includes(id))
        const onChange = () => {
            handleCheck(node, !isChecked)
        }

        return (
            <TreeItem
                key={node.value}
                nodeId={node.value}
                label={
                    <FormControlLabel
                        color="white"
                        style={{ display: "flex", alignItems: "center" }}
                        control={
                            <Checkbox
                                className={classes.condensed}
                                checked={isChecked}
                                onChange={onChange}
                                onClick={e => e.stopPropagation()}
                                indeterminate={isIndeterminate}
                                size="medium"
                            />
                        }
                        label={node.label}
                    // onClick={e => e.stopPropagation()}
                    />
                }
            >
                {node.children && <TreeNodes nodes={node.children} />}
            </TreeItem>
        )
    }

    function TreeNodes({ nodes }) {
        return (
            <>
                {nodes.map((node, index) => (
                    <TreeNode node={node} key={node.label + index} />
                ))}
            </>
        )
    }

    function deepFilter(_nodes, cb) {

        const _result = _nodes.map(node => {
            if (cb(node)) return node;
            let children = deepFilter(node.children || [], cb);
            return children.length && { ...node, children };
        }).filter(Boolean);

        return _result
    }

    const result = deepFilter(nodes, node => node.label.toUpperCase().includes(search.toUpperCase()));

    return (
        <Box paddingX="5px">
            <Box>
                <TextField placeholder="search"
                    onChange={({ target: { value } }) => {
                        setSearchTextField(value)
                    }}
                    fullWidth
                    size="small"
                    // InputProps={{
                    //     endAdornment: (< ExpandMoreIcon />)
                    // }}
                />
            </Box>
            <Box
                sx={{
                    marginTop: "10px",
                    height: height,
                    maxHeight: maxHeight,
                    // overflow: "auto",
                    borderStyle: "solid",
                    borderRadius: "8px",
                    borderColor: "#0000003b",
                    borderWidth: "1px"
                }}
            >
                <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    defaultEndIcon={<ChevronRightIcon style={{ visibility: "hidden" }} />}
                    expanded={search !== '' ? result.map(v => v.value) : expanded}
                    onNodeToggle={(_, nodes) => setExpanded(nodes)}
                >
                    <Virtuoso
                        style={{ height: nodes.length > 0 ? height : "5px" }}
                        totalCount={result.length}
                        // itemContent={index => <>{JSON.stringify(result[index])}</>}
                        itemContent={index => <TreeNode node={result[index]} key={result[index].label + index} />}
                    />
                    {/* <TreeNodes nodes={result} /> */}
                </TreeView>
            </Box>
        </Box>
    )
}
