import * as Yup from 'yup';
// import React from 'react';
import React, { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// import { Icon } from '@iconify/react';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Stack,
  TextField,
  FormControlLabel,
  Divider,
  styled,
  Switch,
  Autocomplete
  // MenuItem
} from '@mui/material';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import { useNavigate } from "react-router-dom";

import { Api } from '../components/api';
import { useSnackbar } from 'notistack';
// import { format } from 'date-fns';
// import { useRecoilValue } from 'recoil';
// import { userAtom } from '../components/_state';
// import FormRadioGroup from '../../FormRadioGroup';

// ----------------------------------------------------------------------
const SwitchContent = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
export default function DeviceEdit({ selectedDevice, onCloseModal }) {
  const [listProvince, setListProvince] = useState([])
  const [listVehicleType, setListVehicleType] = useState([])
  const [listVehicleRegisterType, setListVehicleRegisterType] = useState([])
  const [selectProvince, setSelectProvince] = useState({})
  const [selectVehicleType, setSelectVehicleType] = useState({})
  const [selectVehicleRegisterType, setSelectVehicleRegisterType] = useState({})
  const [selectProvinceAddress, setSelectProvinceAddress] = useState({})


  const { enqueueSnackbar } = useSnackbar();
  const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;
  const deviceSchema = Yup.object().shape({
    vender_id: Yup.string().required('Operator Gps is required'),
    // model_gps: Yup.string().required('Model Gps is required'),
    user_id: Yup.string().required('user_id is required'),
    // imei: Yup.string().required('IMEI is required'),
    // unit_id: Yup.string().required('unit Id is required'),
    vehicle_id: Yup.string().required('License Plate is required'),
    province_code: Yup.string().required('Vehicle province is required'),
    sim: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('GPS Phone is required'),
  });
  const handleSnackbar = ({ variant, message }) => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
  };
  useEffect(() => {
    Api.get('provinces')
      .then(res => {
        setListProvince(res.data)
      }
      )
    Api.get('car_brands')
      .then(res => {
        setListVehicleType(res.data)
      }
      )
    Api.get('car_type')
      .then(res => {
        setListVehicleRegisterType(res.data)
      }
      )
  }, []);
  const formik = useFormik({
    initialValues: {
      vender_id: selectedDevice.vender_id,
      vender_name: selectedDevice.vender_name,
      model_gps: selectedDevice.model_gps,
      model_gps_name: selectedDevice.model_gps_name,
      user_id: selectedDevice.user_id,
      imei: selectedDevice.imei,
      unit_id: selectedDevice.unit_id,
      vehicle_id: selectedDevice.vehicle_id,
      province_code: String(selectedDevice.province_code),
      province: selectedDevice.province,
      vehicle_type: selectedDevice.vehicle_type,
      vehicle_chassis_no: selectedDevice.vehicle_chassis_no,
      vehicle_register_type: String(selectedDevice.vehicle_register_type),
      vehicle_register_name: selectedDevice.vehicle_register_name,
      date_r: new Date(selectedDevice.date_r),
      date_e: new Date(selectedDevice.date_e),
      fueluse: selectedDevice.fueluse,
      sim: selectedDevice.sim,
      book_id: selectedDevice.book_id,
      date_p: new Date(selectedDevice.date_p),
      business_name: selectedDevice.business_name,
      address: selectedDevice.address,
      province_code_address: String(selectedDevice.province_code_address),
      province_address: selectedDevice.province_address,
      tel: selectedDevice.tel,
      fuelfull: selectedDevice.fuelfull,
      fuelmin: selectedDevice.fuelmin,
      card_reader: selectedDevice.card_reader,
      status_vehicle: selectedDevice.status_vehicle,
      status_dlt: selectedDevice.status_dlt,
      status_gps: selectedDevice.status_gps
    },
    validationSchema: deviceSchema,
    onSubmit: async (values) => {
      const data = JSON.stringify({
        ...selectedDevice,
        ...values
      });
      await Api.put('device', data)
        .then(res =>
          onCloseModal()
        ).catch(error => {
          console.log('error', error)
          const { message } = error
          handleSnackbar({ variant: 'error', message: message });
        }
        )
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;


  useEffect(() => {
    const selectedProvince = listProvince.find((x) => x._id.toString() === values.province_code)
    const selectedProvinceAddress = listProvince.find((x) => x._id.toString() === values.province_code_address)
    const selectedVehicleType = listVehicleType.find((x) => x._id.toString() === values.vehicle_type)
    const selectedVehicleRegisterType = listVehicleRegisterType.find((x) => x._id.toString() === values.vehicle_register_type)
    setSelectProvince(selectedProvince)
    setSelectVehicleType(selectedVehicleType)
    setSelectVehicleRegisterType(selectedVehicleRegisterType)
    setSelectProvinceAddress(selectedProvinceAddress)
  }, [selectedDevice, listProvince, listVehicleType, listVehicleRegisterType, values]);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="ผู้ประกอบการ GPS"
                disabled
                value={values.vender_id + " : " + values.vender_name}
              />

              <TextField
                fullWidth
                label="เลือกรุ่น GPS"
                disabled
                value={values.model_gps_name + " [" + values.model_gps + "]"}
              // select
              >
              </TextField>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
                autoComplete="off"
                disabled
                type="text"
                label="เลข Device ID"
                {...getFieldProps('unit_id')}
                error={Boolean(touched.unit_id && errors.unit_id)}
                helperText={touched.unit_id && errors.unit_id}
              // onChange={(e) => console.log(e.target.value)}
              />

              <TextField
                fullWidth
                autoComplete="off"
                disabled
                type="text"
                label="เลข IMEI"
                {...getFieldProps('imei')}
                error={Boolean(touched.imei && errors.imei)}
                helperText={touched.imei && errors.imei}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เลือกผู้ใช้งาน"
                {...getFieldProps('user_id')}
                error={Boolean(touched.user_id && errors.user_id)}
                helperText={touched.user_id && errors.user_id}
              // onChange={(e) => console.log(e.target.value)}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ป้ายทะเบียน"
                {...getFieldProps('vehicle_id')}
                error={Boolean(touched.vehicle_id && errors.vehicle_id)}
                helperText={touched.vehicle_id && errors.vehicle_id}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Autocomplete
                id="province_code"
                name="province_code"
                options={listProvince}
                getOptionLabel={(option) => option.name}
                fullWidth
                // value={listProvince.find((x) => x._id.toString() === values.province_code)}
                value={selectProvince}
                onChange={(e, value) => {
                  const province_code = value !== null ? value._id.toString() : '';
                  const province = value !== null ? value.name : '';
                  setFieldValue("province_code", province_code);
                  setFieldValue("province", province);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="province_code"
                    {...params}
                    type="text"
                    label="จังหวัด"
                    {...getFieldProps('province_code')}
                    error={Boolean(touched.province_code && errors.province_code)}
                    helperText={touched.province_code && errors.province_code}
                  />
                )}
              />

              <Autocomplete
                id="vehicle_type"
                name="vehicle_type"
                options={listVehicleType}
                getOptionLabel={(option) => option.name}
                fullWidth
                // value={listVehicleType.find((x) => x._id === values.vehicle_type)}
                value={selectVehicleType}
                onChange={(e, value) => {
                  const vehicle_type = value !== null ? value._id : '';
                  setFieldValue("vehicle_type", vehicle_type);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="vehicle_type"
                    {...params}
                    type="text"
                    label="ยี่ห้อรถ"
                    {...getFieldProps('vehicle_type')}
                    error={Boolean(touched.vehicle_type && errors.vehicle_type)}
                    helperText={touched.vehicle_type && errors.vehicle_type}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เลขตัวถัง"
                {...getFieldProps('vehicle_chassis_no')}
                error={Boolean(touched.vehicle_chassis_no && errors.vehicle_chassis_no)}
                helperText={touched.vehicle_chassis_no && errors.vehicle_chassis_no}
              // onChange={(e) => console.log(e.target.value)}
              />

              <Autocomplete
                id="vehicle_register_type"
                name="vehicle_register_type"
                options={listVehicleRegisterType}
                getOptionLabel={(option) => option.name}
                fullWidth
                // value={listVehicleRegisterType.find((x) => x._id.toString() === values.vehicle_id)}
                value={selectVehicleRegisterType}
                onChange={(e, value) => {
                  const vehicle_register_type = value !== null ? value._id.toString() : '';
                  const vehicle_register_name = value !== null ? value.name : '';
                  setFieldValue("vehicle_register_type", vehicle_register_type);
                  setFieldValue("vehicle_register_name", vehicle_register_name);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="vehicle_register_type"
                    {...params}
                    type="text"
                    label="ประเภทรถ"
                    {...getFieldProps('vehicle_register_type')}
                    error={Boolean(touched.vehicle_register_type && errors.vehicle_register_type)}
                    helperText={touched.vehicle_register_type && errors.vehicle_register_type}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <MobileDatePicker
                disableFuture={true}
                // allowSameDateSelection
                label="วันติดตั้ง"
                allowKeyboardControl={false}
                value={values.date_r}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(val) => {
                  setFieldValue('date_r', val);
                }}
                renderInput={(params) => <TextField
                  {...params}
                  {...getFieldProps('date_r')}
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  autoComplete="off"
                  error={Boolean(touched.date_r && errors.date_r)}
                  helperText={touched.date_r && errors.date_r} />}
              />

              <MobileDatePicker
                // disableFuture={false}  
                disablePast={true}
                // allowSameDateSelection
                label="วันหมดอายุ"
                allowKeyboardControl={false}
                value={values.date_e}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(val) => {
                  setFieldValue('date_e', val);
                }}
                renderInput={(params) => <TextField
                  {...params}
                  {...getFieldProps('date_e')}
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  autoComplete="off"
                  error={Boolean(touched.date_e && errors.date_e)}
                  helperText={touched.date_e && errors.date_e} />}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="อัตราสิ้นเปลือง"
                {...getFieldProps('fueluse')}
                error={Boolean(touched.fueluse && errors.fueluse)}
                helperText={touched.fueluse && errors.fueluse}
              // onChange={(e) => console.log(e.target.value)}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เบอร์โทร GPS"
                {...getFieldProps('sim')}
                error={Boolean(touched.sim && errors.sim)}
                helperText={touched.sim && errors.sim}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Divider />
            <h4 style={{ color: 'red' }} >** ข้อมูลส่วนนี้จะอยู่บนเอกสารรับรอง ***</h4>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เลขที่หนังสือ"
                {...getFieldProps('book_id')}
                error={Boolean(touched.book_id && errors.book_id)}
                helperText={touched.book_id && errors.book_id}
              // onChange={(e) => console.log(e.target.value)}
              />

              <MobileDatePicker
                disableFuture={true}
                // allowSameDateSelection
                label="วันที่ออกใบรับรอง"
                allowKeyboardControl={false}
                value={values.date_p}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(val) => {
                  setFieldValue('date_p', val);
                }}
                renderInput={(params) => <TextField
                  {...params}
                  {...getFieldProps('date_p')}
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  autoComplete="off"
                  error={Boolean(touched.date_p && errors.date_p)}
                  helperText={touched.date_p && errors.date_p} />}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ชื่อผู้ประกอบการ/เจ้าของ"
                {...getFieldProps('business_name')}
                error={Boolean(touched.business_name && errors.business_name)}
                helperText={touched.business_name && errors.business_name}
              // onChange={(e) => console.log(e.target.value)}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ที่อยู่"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Autocomplete
                id="province_code_address"
                name="province_code_address"
                options={listProvince}
                getOptionLabel={(option) => option.name}
                fullWidth
                // value={listProvince.find((x) => x._id.toString() === values.province_code_address)}
                value={selectProvinceAddress}
                onChange={(e, value) => {
                  const province_code_address = value !== null ? value._id.toString() : '';
                  const province_address = value !== null ? value.name : '';
                  setFieldValue("province_code_address", province_code_address);
                  setFieldValue("province_address", province_address);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="province_code_address"
                    {...params}
                    type="text"
                    label="จังหวัด"
                    {...getFieldProps('province_code_address')}
                    error={Boolean(touched.province_code_address && errors.province_code_address)}
                    helperText={touched.province_code_address && errors.province_code_address}
                  />
                )}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เบอร์โทร"
                {...getFieldProps('tel')}
                error={Boolean(touched.tel && errors.tel)}
                helperText={touched.tel && errors.tel}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Divider />
            <h4 style={{ color: 'red' }} >** ถ้าไม่ได้ติดตัววัดระดับน้ำมันให้กรอก 0 ***</h4>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ค่าไฟเมื่อน้ำมันเต็ม"
                {...getFieldProps('fuelfull')}
                error={Boolean(touched.fuelfull && errors.fuelfull)}
                helperText={touched.fuelfull && errors.fuelfull}
              // onChange={(e) => console.log(e.target.value)}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ค่าไฟเมื่อน้ำมันหมดถัง"
                {...getFieldProps('fuelmin')}
                error={Boolean(touched.fuelmin && errors.fuelmin)}
                helperText={touched.fuelmin && errors.fuelmin}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Divider />
            <FormControlLabel
              control={<SwitchContent sx={{ m: 1 }}
                {...getFieldProps('card_reader')}
                onChange={(event) => {
                  setFieldValue('card_reader', event.target.checked.toString());
                }}
                checked={values.card_reader === "true"} />}
              label="มีตัวอ่านบัตร"
            />
            <FormControlLabel
              control={<SwitchContent sx={{ m: 1 }}
                {...getFieldProps('status_vehicle')}
                onChange={(event) => {
                  setFieldValue('status_vehicle', event.target.checked === true ? "1" : "0");
                }}
                checked={values.status_vehicle === "1"} />}
              label="การใช้งาน"
            />
            <FormControlLabel
              control={<SwitchContent sx={{ m: 1 }}
                {...getFieldProps('status_dlt')}
                onChange={(event) => {
                  setFieldValue('status_dlt', event.target.checked === true ? "1" : "0");
                }}
                checked={values.status_dlt === "1"} />}
              label="เชื่อมต่อ DLT"
            />

            <FormControlLabel
              control={<SwitchContent sx={{ m: 1 }}
                {...getFieldProps('status_gps')}
                onChange={(event) => {
                  setFieldValue('status_gps', event.target.checked === true ? "1" : "0");
                }}
                checked={values.status_gps === "1"} />}
              label="การใช้งาน GPS"
            />
          </Stack>
          <Stack direction="column" alignItems="center" sx={{ my: 2 }}>
            <LoadingButton
              style={{ width: 220 }}
              fullWidth={false}
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              บันทึก
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </LocalizationProvider>
  );
}
