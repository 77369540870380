// const DrawControl = ({ editMode, setEditMode, editRef, drawCircle }) => {

import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { Box, IconButton, Grid, TextField, MenuItem, Button } from '@mui/material';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import { Api, Apimongodb } from '../../components/api';
import { useSnackbar } from 'notistack';
import { polygon, booleanClockwise, rewind, lineString } from '@turf/turf';
import axios from 'axios';

export default function CreatePOI({
    handleEditData,
    setHandleEditData,
    setPoiSelect,
    onGetPoigeoByCname,
    onChangeSelect,
    listGroupPoi,
    c_coname,
    setPoigeoByCname
}) {
    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };
    const handleChange = (e) => {
        const { name, value } = e.target
        const dataHand = {
            ...handleEditData,
            [name]: value
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)

    }

    const handleChangeRadius = (e) => {
        const { name, value } = e.target

        const dataHand = {
            ...handleEditData,
            properties: {
                ...handleEditData?.properties,
                [name]: value.replace(/[^0-9]/g, ''),
            }
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)
    }

    const handleChangeLat = (e) => {
        const { name, value } = e.target
        const dataHand = {
            ...handleEditData,
            geometry: {
                ...handleEditData?.geometry,
                coordinates: [handleEditData.geometry.coordinates[0], value],

            }
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)

    }
    const handleChangeLng = (e) => {
        const { name, value } = e.target
        const dataHand = {
            ...handleEditData,
            geometry: {
                ...handleEditData?.geometry,
                coordinates: [value, handleEditData.geometry.coordinates[1]],

            }
        }
        setHandleEditData(dataHand)
        setPoiSelect(dataHand)

    }

    const onClose = () => {
        setHandleEditData(null)
        setPoiSelect(null)
    }

    const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""


    const handleSubmit = e => {
        e.preventDefault();
        // setPoigeoByCname
        Apimongodb.get(`/Tracking/poigeocodeall?cname=${c_coname}`)
            .then(respoiAll => {
                const result = respoiAll.data.result
                const filter = result.filter(v => v.poi_id === handleEditData?.poi_id)
                if (filter.length > 0) {
                    setPoigeoByCname(result)
                    handleClickVariant({ message: "ID POI ซ้ำกรุณาเปลี่ยน ID", variant: "error" })
                } else {
                    const req = {
                        "cname": handleEditData?.cname,
                        "poi_id": handleEditData?.poi_id,
                        "poi_name": handleEditData?.poi_name,
                        "group_id": handleEditData?.group_id,
                        "poi_data": {
                            "type": "Feature",
                            "geometry": {
                                "type": capitalize(handleEditData?.properties.shape),
                                "coordinates": handleEditData?.properties.shape === "circle" ? [handleEditData?.geometry.coordinates] : handleEditData?.geometry.coordinates
                            },
                            "properties": {
                                ...handleEditData?.properties
                            }
                        }
                    }
                    Apimongodb.post(`/Tracking/poigeocode`, req)
                        .then((res) => {
                            handleClickVariant({ message: "บันทึกข้อมูลสําเร็จ", variant: "success" })
                            onGetPoigeoByCname()
                            onChangeSelect({ mode: "select", select: req.poi_id })
                            onClose()
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }


            })
    };

    const toInputUppercase = e => {
        e.target.value = ("" + e.target.value).replaceAll(' ', '').toUpperCase();
    };
    return (
        <Box
            sx={{
                zIndex: 999,
                position: "absolute",
                top: "10px",
                right: "40px",
                backgroundColor: "white",
                borderRadius: "5px"

            }}
        >
            <Box sx={{
                padding: "10px"
            }}>
                <form onSubmit={handleSubmit} autoComplete="off" >
                    <Grid container spacing={1} direction="column">
                        <Grid item sm={12} >
                            <TextField size="small" onChange={handleChange} name="poi_id" value={handleEditData?.poi_id} fullWidth label="ID" InputLabelProps={{ shrink: true }}
                                required
                                onInput={toInputUppercase}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField size="small" onChange={handleChange} name="poi_name" value={handleEditData?.poi_name} fullWidth label="ชื่อ" InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <TextField select size="small" onChange={handleChange} name="group_id" value={handleEditData?.group_id} fullWidth label="กลุ่ม" InputLabelProps={{ shrink: true }}>
                                {
                                    listGroupPoi.map((value, id) => (
                                        <MenuItem value={value.group_id} key={"G" + id}>{value.name}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                        {handleEditData?.properties.shape === "circle" && (
                            <>
                                <Grid item sm={12}>
                                    <TextField size="small" onChange={handleChangeLat} name="lat" value={handleEditData?.geometry.coordinates[1]} fullWidth label="lat" InputLabelProps={{ shrink: true }}
                                        required
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField size="small" onChange={handleChangeLng} name="lng" value={handleEditData?.geometry.coordinates[0]} fullWidth label="lng" InputLabelProps={{ shrink: true }}
                                        required
                                        type="number"
                                    />
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField size="small" onChange={handleChangeRadius} name="radius" value={handleEditData?.properties.radius} fullWidth label="radius(m)" InputLabelProps={{ shrink: true }}
                                        required
                                    />
                                </Grid>
                            </>
                        )
                        }
                        < Box
                            sx={{
                                display: 'flex',
                                justifyContent: "space-between",
                                padding: "10px",
                                marginLeft: "5px"
                            }}
                        >

                            <Button variant="contained" type="submit" color="success" fullWidth style={{ marginRight: "5px" }}>
                                Save
                            </Button>
                            <Button variant="contained" color="error" fullWidth style={{ marginLeft: "5px" }}
                                onClick={onClose}
                            >
                                Close
                            </Button>

                        </Box>
                    </Grid>
                </form>
            </Box>
        </Box >
    )
}