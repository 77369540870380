import { Container, Fab, List, ListItem, ListItemButton, Button, ListItemIcon, IconButton, ListItemText, Box, Divider, Typography, TableCell, TableRow, TableBody, TableContainer, TableHead, Table, Paper, TextField, Stack, Menu, MenuItem } from '@mui/material'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import Page from '../../components/Page'
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
// import DeleteIcon from '@mui/icons-material/Delete';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Api } from '../../components/api';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { FilterAlt } from '@mui/icons-material';
const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 16,
    right: 16,
    [theme.breakpoints.down('md')]: {
        bottom: 60
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 60,
    overflow: 'auto'
}));
export default function MainAccount({ account }) {
    const [listAccounts, setAccounts] = useState([]);
    const navigate = useNavigate()



    const [search, setSearch] = useState('');

    const handleChange = ({ target: { value, name } }) => {
        setSearch(value)
    }




    const getAccounts = useCallback(() => {
        const { cocode, c_coname, contype } = account;
        const pCanme = (contype === "00" || contype === "01") ? `` : `&cname=${c_coname}`
        Api.get(`v0/if/ContactByContype?contype=${"21"}${pCanme}`)
            .then(res => {
                var listAccounts = res.data;
                setAccounts(listAccounts);
            }
            ).catch(e => {
                console.log('error', e)
            })
    }, [account]);


    useEffect(() => {
        getAccounts()
    }, [getAccounts])

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleClickWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };
    const handleLock = (item) => {
        const data = JSON.stringify({
            ...item
            // status: item.status === null ? '0' : null
        });
        Api.put('v0/if/Contact', data)
            .then(res => {
                getAccounts()
                handleSnackbar({ variant: 'success', message: 'success!' })
            }
            ).catch(error => {
                const { message } = error
                handleSnackbar({ variant: 'error', message: message });
            }
            )
    };


    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Page>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Stack spacing={1} direction="row">
                        <TextField sx={{ backgroundColor: "white" }} placeholder='Search' size="small" onChange={handleChange} value={search} />
                        <IconButton
                            onClick={() => { navigate("new") }}
                        >
                            <PersonAddIcon />
                        </IconButton>
                        <IconButton
                            // onClick={() => { navigate("new") }}
                            onClick={handleClick}
                        >
                            <FilterAlt />
                        </IconButton>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={handleClose} value={"00"}>Super Admin</MenuItem>
                            <MenuItem onClick={handleClose} value={"10"}>Admin</MenuItem>
                            <MenuItem onClick={handleClose} value={"11"}>User</MenuItem>
                            <MenuItem onClick={handleClose} value={"21"}>Customer</MenuItem>
                        </Menu>
                        {/* <Button></Button> */}
                    </Stack>
                </Box>
                <Divider />
                <MainStyle>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell width={80} align="left">ชื่อ</TableCell>
                                    <TableCell width={80} align="left">นามสกุล</TableCell>
                                    <TableCell width={100} align="left">อีเมล</TableCell>
                                    <TableCell width={80} align="center"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listAccounts.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" align="left" scope="row">
                                            {row.firstname}
                                        </TableCell>
                                        <TableCell align="left">{row.lastname}</TableCell>
                                        <TableCell align="left">{row.email}</TableCell>
                                        <TableCell align="center">
                                            <Box>
                                                <IconButton edge="start" size="small" aria-label="Edit" style={{ margin: 1 }}
                                                    onClick={() => {
                                                        navigate(`/settings/accounts/edit`, { state: row })
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                                <IconButton edge="start" size="small" aria-label="delete" style={{ margin: 1 }}
                                                    onClick={() => {
                                                        closeSnackbar()
                                                        handleClickWithAction({
                                                            message: `${row.status === "U" ? "Lock" : "Unlock"} ${row.firstname} ${row.lastname}?`,
                                                            handle: () => {
                                                                handleLock(row)
                                                            }
                                                        })
                                                    }}
                                                >
                                                    {
                                                        row.status === null ?
                                                            <LockIcon fontSize="small" />
                                                            :
                                                            <LockOpenIcon fontSize="small" />
                                                    }
                                                </IconButton>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* <List style={{ paddind: 10 }}>
                        {listAccounts.map((e, i) => (
                            <Fragment
                                key={'account' + i}
                            >
                                <ListItem
                                    disablePadding
                                    secondaryAction={
                                        <Box paddingX={1}>
                                            <IconButton edge="start" aria-label="Edit" style={{ margin: 1 }}
                                                onClick={() => {
                                                    navigate(`/settings/accounts/edit`, { state: e })
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="start" aria-label="delete" style={{ margin: 1 }}
                                                onClick={() => {
                                                    closeSnackbar()
                                                    handleClickWithAction({
                                                        message: `${e.status === null ? "Lock" : "Unlock"} ${e.firstname} ${e.lastname}?`,
                                                        handle: () => {
                                                            handleLock(e)
                                                        }
                                                    })
                                                }}
                                            >
                                                {
                                                    e.status === null ?
                                                        <LockIcon />
                                                        :
                                                        <LockOpenIcon />
                                                }
                                            </IconButton>
                                        </Box>
                                    }>
                                    <ListItemButton>
                                        <ListItemText
                                            primary={e.firstname + ' ' + e.lastname}
                                            secondary={e.cocode}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                {i + 1 !== listAccounts.length ? <Divider /> : null}
                            </Fragment>
                        ))}
                    </List> */}
                </MainStyle>
                {/* <FabStyle color="primary" aria-label='Add' onClick={() => {
                    navigate('/settings/accounts/new')
                }}>
                    <AddIcon />
                </FabStyle> */}
            </Container>
        </Page >
    )
}
