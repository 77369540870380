import React, { useRef, useState } from 'react'
import { Button, TextField, Box, Grid, Stack, IconButton, InputAdornment, Divider, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import CloseIcon from '@mui/icons-material/Close';


export default function HelpdeskGetRepair() {
    const [open, setOpen] = useState(false)
    const [selectedFile, setSelectedFile] = useState("null");
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    function isFile(value) {
        return value instanceof File;
    }


    return (
        <>
            <IconButton size="small" onClick={handleOpen}><EditNoteOutlinedIcon fontSize="small" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>รับงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Stack spacing={1}>
                        <Grid container spacing={1}>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="ผู้เปิดงาน" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="ผู้เปิดงาน" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="สาขา" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="เบอร์โทร" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="เรื่อง" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="หัวข้อ" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="ชื่ออุปกรณ์" />
                            </Grid>
                            {
                                process.env.REACT_APP_ID === "sitgps" && (
                                    <>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="รุ่น GPS" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="เลข IMEI" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="Sim card" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="เลขตัวถัง" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="ชื่ออุปกรณ์" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="ยี่ห้อ" />
                                        </Grid>
                                        <Grid item md={6}>
                                            <TextField size="small" fullWidth label="ป้ายทะเบียน" />
                                        </Grid>
                                    </>
                                )
                            }
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="สถานที่" />
                            </Grid>
                            <Grid item md={6}>
                                <TextField size="small" fullWidth label="บริเวณ/อาคาร/ชั้น" />
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography fontStyle="italic" >อธิบายลักษณะ</Typography>
                        </Box>
                        <Divider sx={{ borderStyle: "dashed" }} />
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <TextField size="small" fullWidth label="หมายเหตุ" rows={4} multiline />
                            </Grid>
                        </Grid>
                        <Box>
                            <Typography fontStyle="italic" >แนบไฟล์</Typography>
                        </Box>
                        <Box>
                            <Stack spacing={1} direction="row">
                                <Box>
                                    {
                                        isFile(selectedFile) && (
                                            <img src={URL.createObjectURL(selectedFile)} width={"100px"} style={{ aspectRatio: "auto 16/9", borderRadius: "5px" }} />
                                        )
                                    }
                                    {
                                        !isFile(selectedFile) && (
                                            <img src={selectedFile} width={"100px"} style={{ aspectRatio: "auto 16/9", borderRadius: "5px" }} />
                                        )
                                    }
                                </Box>
                            </Stack>
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
