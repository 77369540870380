import React, { useState } from 'react'
import { Dialog, Tooltip, IconButton, DialogTitle, Table, TableHead, Checkbox, TableRow, TableBody, TableCell, DialogContent, DialogActions, Button, Box } from '@mui/material'
import { Api } from '../../components/api'
import _ from 'lodash';
import WidgetsIcon from '@mui/icons-material/Widgets';
import { useSnackbar } from 'notistack';
export default function WidgetDevice({ deviceid, devicename }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const [widgetSetlectd, setWidgetSetlectd] = useState([])
    const [widgetDevice, setWidgetDevice] = useState([])
    const [widgetAll, setWidgetAll] = useState([])
    const [open, setOpen] = useState(false)
    const onClickOpen = () => {
        setOpen(true)
        getWidgetDevice()
        getWidgetAll()
    }
    const onClickClose = () => {
        setOpen(false)
        setWidgetSetlectd([])
        setWidgetDevice([])
        setWidgetAll([])
    }
    const getWidgetDevice = () => {
        Api.get(`/widget/device_widget?device=${deviceid}`)
            .then(res => {
                setWidgetDevice(res.data)
                setWidgetSetlectd(res.data)
            })
            .catch(err => {
                setWidgetDevice([])
                setWidgetSetlectd([])
            })
    }
    const getWidgetAll = () => {
        Api.get(`/widget/get_all`)
            .then(res => {
                setWidgetAll(res.data)
            })
            .catch(err => {
                setWidgetAll([])
            })
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setWidgetDevice(widgetAll);
        } else {
            setWidgetDevice([]);
        }
    };
    const handleCheck = (row) => {
        const selected = isSelected(row.id);
        if (selected) {
            const newSelectedRows = widgetDevice.filter((rowDvice) => rowDvice.id !== row.id);
            setWidgetDevice(newSelectedRows);
        } else {
            const newSelectedRows = widgetDevice.concat(row);
            setWidgetDevice(newSelectedRows);
        }
    }
    const isSelected = (id) => {
        const rowSelected = widgetDevice.filter((v) => v.id === id)
        return rowSelected.length > 0
    }


    const isPost = (id) => {
        const rowSelected = widgetSetlectd.filter((v) => v.id === id)
        return rowSelected.length === 0
    }
    const isDel = (id) => {
        const rowSelected = widgetDevice.filter((v) => v.id === id)
        return rowSelected.length === 0
    }



    const submitForm = () => {
        const dataPost = widgetDevice.filter((v) => isPost(v.id))
        const dataDel = widgetSetlectd.filter((v) => isDel(v.id))
        if (dataPost.length > 0) {
            const data = { device: deviceid, data: dataPost.map((v) => { return { id: v.id, name: v.name, icon: v.icon } }) }
            Api.post(`/widget/device_widget`, data)
                .then(res => {
                    if (dataDel.length > 0) {
                        const data = { device: deviceid, ids: dataDel.map((v) => { return v.id }) }
                        Api.delete(`/widget/device_widget`, { data: data })
                            .then(res => {
                                handleSnackbar({ message: "แก้ไขข้อมูลสำเร็จ", variant: "success" })
                                getWidgetDevice()
                            })
                            .catch(err => {
                                handleSnackbar({ message: "แก้ไขข้อมูลไม่สำเร็จ", variant: "error" })
                            })
                    } else {
                        handleSnackbar({ message: "แก้ไขข้อมูลสำเร็จ", variant: "success" })
                        getWidgetDevice()
                    }
                })
                .catch(err => {
                    handleSnackbar({ message: "แก้ไขข้อมูลไม่สำเร็จ", variant: "error" })
                })
        }
        if ((dataPost.length === 0 && dataDel.length > 0)) {
            const data = { device: deviceid, ids: dataDel.map((v) => { return v.id }) }
            Api.delete(`/widget/device_widget`, { data: data })
                .then(res => {
                    handleSnackbar({ message: "แก้ไขข้อมูลสำเร็จ", variant: "success" })
                    getWidgetDevice()
                })
                .catch(err => {
                    handleSnackbar({ message: "แก้ไขข้อมูลไม่สำเร็จ", variant: "error" })
                })
        }
    }



    return (
        <Box>
            <Tooltip title="จัดการวิดเจ็ต / update widget" placement="bottom">
                <IconButton size="small" onClick={onClickOpen}><WidgetsIcon fontSize="small" /></IconButton>
            </Tooltip>

            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>แก้ไข widget {`(${devicename})`}</DialogTitle>
                <DialogContent dividers>
                    <Box sx={{ overflow: "auto" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ whiteSpace: "nowrap" }} padding="checkbox">
                                        <Checkbox
                                            indeterminate={widgetDevice.length > 0 && widgetDevice.length < widgetAll.length}
                                            checked={widgetAll.length > 0 && widgetDevice.length === widgetAll.length}
                                            onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ whiteSpace: "nowrap" }}>id</TableCell>
                                    <TableCell sx={{ whiteSpace: "nowrap" }}>name</TableCell>
                                    <TableCell sx={{ whiteSpace: "nowrap" }}>icon</TableCell>
                                    <TableCell sx={{ whiteSpace: "nowrap" }}>type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.orderBy(widgetAll, ["id", "name"], ["asc", "asc"]).map((row, index) => {
                                        return (
                                            <TableRow key={`row-${index}`} sx={{ backgroundColor: row.status !== "confirm" ? "white" : "whitesmoke" }}>
                                                <TableCell sx={{ whiteSpace: "nowrap" }} padding="checkbox">
                                                    <Checkbox
                                                        checked={isSelected(row.id)}
                                                        onChange={(event) => handleCheck(row)}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.id}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.name}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.icon && (<img src={row.icon} width={"20px"} height={"20px"} />)}</TableCell>
                                                <TableCell sx={{ whiteSpace: "nowrap" }}>{row.type}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" color="primary" onClick={submitForm}>save</Button>
                    <Button size="small" variant="outlined" color="success" onClick={onClickClose}>cancel</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
