import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
// import './map.css';
import { Button, Stack } from '@mui/material';
import { BugReportOutlined, RefreshOutlined, RotateLeftOutlined, RotateRightOutlined, ThreeSixtyOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined } from '@mui/icons-material';

export default function MapGl() {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(100.523186);
    const [lat] = useState(13.736717);
    const [zoom] = useState(14);
    const maplibre = `https://demotiles.maplibre.org/style.json`
    const basemaps = `https://basemaps.cartocdn.com/gl/dark-matter-gl-style/style.json`
    const basemapspositron = ` https://basemaps.cartocdn.com/gl/positron-gl-style/style.json`

    const longdomaps = `https://msv.longdo.com/vector/longdo_normal.json`;
    const stadiamaps = `https://tiles.stadiamaps.com/styles/osm_bright.json`
    const googlemaps = `https://tile.googleapis.com/v1/3dtiles/root.json?key=AIzaSyAQ2yvTPmQ3iJZBKHrf4zD7yk9qamfr_3o`
    // let markers = [];


    const [mapStyle, setMapStyle] = useState(longdomaps);
    const [markers, setMarkers] = useState([]);

    const ss = [
        {
            lat: 13.742157200362342,
            lng: 100.5135729628916
        },
        {
            lat: 13.736717,
            lng: 100.523186,
        },
        {
            lat: 13.738717,
            lng: 100.523186,
        }
    ]
    useEffect(() => {
        if (map.current) return; // stops map from intializing more than once

        map.current = new maplibregl.Map({
            container: mapContainer.current,
            style: mapStyle,
            center: [lng, lat],
            zoom: zoom,
            // attributionControl: false,
            pitch: 85,
            bearing: 0,
            antialias: true
        });
        map.current.addControl(new maplibregl.NavigationControl(), 'top-right');
        map.current.addControl(new maplibregl.GeolocateControl(), 'top-right');
        map.current.addControl(new maplibregl.ScaleControl(), 'bottom-right');
        map.current.on('load', () => {
            ss.map(v => {
                var marker = new maplibregl.Marker({ color: "#FF0000" })
                    .setLngLat([v.lng, v.lat])
                    // .setRotation(-45)
                    .setDraggable(true)
                    .on('dragend', (e) => {
                        console.log('e', e.target.getLngLat())
                    })
                    // .on('mousedown', () => {
                    //     console.log('click')
                    // })
                    .addTo(map.current);

                marker.getElement().addEventListener('click', () => {
                    alert(JSON.stringify({ lat: v.lat, lng: v.lng }));
                });
                marker.getElement().addEventListener('mouseenter', (e) => {
                    e.target.style.cursor = 'pointer'
                });
                marker.getElement().id = v.lat + '' + v.lng
                setMarkers(m => m.concat(marker))
            })
            // new maplibregl.Marker({ color: "#FF0000" })
            //     .setLngLat([lng, lat])
            //     .addTo(map.current);

            // map.current.on('load', () => {
            //     // Insert the layer beneath any symbol layer.
            //     const layers = map.current.getStyle().layers;

            //     let labelLayerId;
            //     for (let i = 0; i < layers.length; i++) {
            //         if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
            //             labelLayerId = layers[i].id;
            //             break;
            //         }
            //     }

            //     map.current.addLayer(
            //         {
            //             'id': '3d-buildings',
            //             'source': 'openmaptiles',
            //             'source-layer': 'building',
            //             'filter': ['==', 'extrude', 'true'],
            //             'type': 'fill-extrusion',
            //             'minzoom': 15,
            //             'paint': {
            //                 'fill-extrusion-color': '#aaa',

            //                 // use an 'interpolate' expression to add a smooth transition effect to the
            //                 // buildings as the user zooms in
            //                 'fill-extrusion-height': [
            //                     'interpolate',
            //                     ['linear'],
            //                     ['zoom'],
            //                     15,
            //                     0,
            //                     15.05,
            //                     ['get', 'height']
            //                 ],
            //                 'fill-extrusion-base': [
            //                     'interpolate',
            //                     ['linear'],
            //                     ['zoom'],
            //                     15,
            //                     0,
            //                     15.05,
            //                     ['get', 'min_height']
            //                 ],
            //                 'fill-extrusion-opacity': 0.6
            //             }
            //         },
            //         labelLayerId
            //     )
            // })
            // console.log('maplibregl.Marker.length', map.current.remove())

            //////////??

            //     map.current.addSource('maine', {
            //         'type': 'geojson',
            //         'data': {
            //             'type': 'Feature',
            //             'geometry': {
            //                 'type': 'Polygon',
            //                 'coordinates': [
            //                     [
            //                         [-67.13734351262877, 45.137451890638886],
            //                         [-66.96466, 44.8097],
            //                         [-68.03252, 44.3252],
            //                         [-69.06, 43.98],
            //                         [-70.11617, 43.68405],
            //                         [-70.64573401557249, 43.090083319667144],
            //                         [-70.75102474636725, 43.08003225358635],
            //                         [-70.79761105007827, 43.21973948828747],
            //                         [-70.98176001655037, 43.36789581966826],
            //                         [-70.94416541205806, 43.46633942318431],
            //                         [-71.08482, 45.3052400000002],
            //                         [-70.6600225491012, 45.46022288673396],
            //                         [-70.30495378282376, 45.914794623389355],
            //                         [-70.00014034695016, 46.69317088478567],
            //                         [-69.23708614772835, 47.44777598732787],
            //                         [-68.90478084987546, 47.184794623394396],
            //                         [-68.23430497910454, 47.35462921812177],
            //                         [-67.79035274928509, 47.066248887716995],
            //                         [-67.79141211614706, 45.702585354182816],
            //                         [-67.13734351262877, 45.137451890638886]
            //                     ]
            //                 ]
            //             }
            //         }
            //     });
            //     map.current.addSource('mainet', {
            //         'type': 'geojson',
            //         'data': {
            //             'type': 'Feature',
            //             'geometry': {
            //                 'type': 'Polygon',
            //                 'coordinates': [
            //                     [
            //                         [-67.13734351262877, 45.137451890638886],
            //                         [-66.96466, 44.8097],
            //                         [-68.03252, 44.3252],
            //                         [-69.06, 43.98],
            //                         [-70.11617, 43.68405],
            //                         [-70.64573401557249, 43.090083319667144],
            //                         [-70.75102474636725, 43.08003225358635],
            //                         [-70.79761105007827, 43.21973948828747],
            //                         [-70.98176001655037, 43.36789581966826],
            //                         [-70.94416541205806, 43.46633942318431],
            //                         [-71.08482, 45.3052400000002],
            //                         [-70.6600225491012, 45.46022288673396],
            //                         [-70.30495378282376, 45.914794623389355],
            //                         [-70.00014034695016, 46.69317088478567],
            //                         [-69.23708614772835, 47.44777598732787],
            //                         [-68.90478084987546, 47.184794623394396],
            //                         [-68.23430497910454, 47.35462921812177],
            //                         [-67.79035274928509, 47.066248887716995],
            //                         [-67.79141211614706, 45.702585354182816],
            //                         [-67.13734351262877, 45.137451890638886]
            //                     ]
            //                 ]
            //             }
            //         }
            //     });
            //     map.current.addLayer({
            //         'id': 'maine',
            //         'type': 'fill',
            //         'source': 'maine',
            //         'layout': {},
            //         'paint': {
            //             'fill-color': '#088',
            //             'fill-opacity': 0.8
            //         }
            //     });
            //     map.current.addLayer({
            //         'id': 'mainet',
            //         'type': 'fill',
            //         'source': 'maine',
            //         'layout': {},
            //         'paint': {
            //             'fill-color': '#058',
            //             'fill-opacity': 0.8
            //         }
            //     });
        })

    }, [mapStyle, lng, lat, zoom, markers]);


    // useEffect(() => {
    //     // const lngLat = new maplibregl.LngLat()
    //     let lastZoom = map.current.getZoom();
    //     let LngLat = map.current.getCenter();

    //     // let lastLngLat = map.current.getLngLat();

    //     map.current = new maplibregl.Map({
    //         container: mapContainer.current,
    //         style: mapStyle,
    //         center: [LngLat.lng, LngLat.lat],
    //         zoom: lastZoom,
    //         attributionControl: false
    //     });
    //     map.current.addControl(new maplibregl.ScaleControl(), 'bottom-right');
    //     // new maplibregl.Marker({ color: "#FF0000" })
    //     //     .setLngLat([lng, lat])
    //     //     .addTo(map.current);
    // }, [mapStyle]);

    const rotateTo = ({ bearing, duration }) => {
        map.current.easeTo({ bearing: bearing, duration: duration })
    }

    const tiltTo = ({ pitch, duration }) => {
        map.current.easeTo({ pitch: pitch, duration: duration })
        // map.current.easeTo({ bearing: map.current.getBearing(), pitch: pitch, duration: duration })
    }





    return (
        <div style={{
            position: "relative",
            width: "100%",
            height: "calc(100vh)"
        }}>
            <div
                style={{
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    margin: "5px",
                    zIndex: 9
                }}
            >
                <Stack spacing={.5} >
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            rotateTo({ bearing: map.current.getBearing() - 5, duration: 1000 })
                            // map.current.setBearing(map.current.getBearing() + 0.1)
                            // console.log('map.current', map.current)
                        }}
                    >
                        <RotateRightOutlined fontSize="small" />
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            rotateTo({ bearing: map.current.getBearing() + 5, duration: 1000 })
                        }}
                    >
                        <RotateLeftOutlined fontSize="small" />
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            // map.current.setPitch(0)
                            rotateTo({ bearing: 0, duration: 1000 })
                            // map.current.jumpTo({ pitch: map.current.getPitch() - 25 })

                        }}
                    >
                        <ThreeSixtyOutlined fontSize="small" />
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            // map.current.setPitch(map.current.getPitch() + 5, { duration: 1000 })
                            // map.current.easeTo({ bearing: map.current.getBearing(), pitch: map.current.getPitch() + 5, duration: 1000 })
                            tiltTo({ pitch: map.current.getPitch() - 5, duration: 1000 })

                        }}
                    >
                        <VerticalAlignTopOutlined fontSize="small" />
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            // map.current.setPitch(map.current.getPitch() - 5, { duration: 1000 })
                            // map.current.jumpTo({ pitch: map.current.getPitch() - 25 })
                            // map.current.easeTo({ bearing: map.current.getBearing(), pitch: map.current.getPitch() - 5, duration: 1000 })

                            tiltTo({ pitch: map.current.getPitch() + 5, duration: 1000 })
                        }}
                    >
                        <VerticalAlignBottomOutlined fontSize="small" />
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            // map.current.setPitch(0)
                            tiltTo({ pitch: 0, duration: 1000 })

                            // map.current.jumpTo({ pitch: map.current.getPitch() - 25 })

                        }}
                    >
                        <RefreshOutlined fontSize="small" />
                    </Button>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            console.log('markers', markers)
                            // if (map.current.getLayer('maine')) {
                            //     map.current.removeLayer('maine')

                            // }
                            // map.current.removeLayer('maine')
                            // map.current.removeSource('maine')

                            // markers.find(m => m._element.id === "13.738717100.523186").remove()
                            // var marker = new maplibregl.Marker({ color: "#FF0000" })
                            //     .setLngLat([100.5138729628916, 13.722157200362342])
                            //     .setDraggable(true)
                            //     .on('dragend', (e) => {
                            //         console.log('e', e.target.getLngLat())
                            //     })
                            //     .addTo(map.current);

                            // marker.getElement().addEventListener('click', () => {
                            //     alert(JSON.stringify({ lat: 13.722157200362342, lng: 100.5138729628916 }));
                            // });
                            // marker.getElement().addEventListener('mouseenter', (e) => {
                            //     e.target.style.cursor = 'pointer'
                            // });
                            // marker.getElement().id = '100.513872962891613.722157200362342'
                            // markers.push(marker)



                            // markers.forEach(marker => {
                            //     // marker.remove()
                            //     if (marker._element.id === "13.738717100.523186") {
                            //         // console.log('marker', marker)
                            //         marker.remove()
                            //     }
                            // });
                            // markers = []
                            // if (mapStyle === stadiamaps) {
                            //     setMapStyle(longdomaps)
                            //     map.current.setStyle(longdomaps)
                            //     // map.current.setBearing(5)
                            //     // map.current = new maplibregl.Map({
                            //     //     container: mapContainer.current,
                            //     //     style: longdomaps,
                            //     //     center: [lng, lat],
                            //     //     zoom: zoom,
                            //     //     // attributionControl: false
                            //     // });
                            // } else {
                            //     setMapStyle(stadiamaps)
                            //     map.current.setStyle(stadiamaps)
                            //     // map.current.setBearing(18)
                            //     // map.current = new maplibregl.Map({
                            //     //     container: mapContainer.current,
                            //     //     style: stadiamaps,
                            //     //     center: [lng, lat],
                            //     //     zoom: zoom,
                            //     //     // attributionControl: false
                            //     // });
                            // }
                        }}
                    ><BugReportOutlined fontSize="small" /></Button>
                </Stack>
            </div>
            <div ref={mapContainer} style={{
                position: "absolute",
                width: "100%",
                height: "100%"
            }} />
        </div >
    );
}