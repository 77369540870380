import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogDriver from './DialogDriver';
import DialogBushostess from './DialogBushostess';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';
import { DesktopDatePicker, TimePicker } from '@mui/lab';
import { fDateTo } from '../../utils/formatTime';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import _ from 'lodash';
import CarsDialog from './CarsDialog';

export default function ButtonDialogWork({ disabled, onHandle, data, car_type, employees }) {
    const cars = _.orderBy(data.cars_control, ["cars_control_id", ["asc"]]).map((ct, ict) => {
        return { "car_type": ct.car_type, "car_name": ct.car_name }
    })
    const initialForm = {
        "works_id": "",
        "car_id": "",
        "car_type": car_type,
        "work_type": "",
        "passenger_capacity": 1,
        "pickup_date": data.pickup_date,
        "pickup_location": data.dropoff_location,
        "pickup_time": data.pickup_time,
        "dropoff_date": data.dropoff_date,
        "dropoff_location": data.dropoff_location,
        "dropoff_time": data.dropoff_time,
        "licensePlate": "",
        "status": "",
        "remark": "",
        "drivers": [
        ],
        "bushostess": []
    }
    const [work, setWork] = useState(initialForm)

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = () => {
        setOpen(false)
        onHandle({ ...work, works_id: Date.now(), car_id: Date.now() })
        setWork(initialForm)
    }

    const handleDelDriver = (id) => {
        const { drivers } = work
        setWork(w => { return ({ ...w, drivers: drivers.filter(d => d.employee_id !== id) }) })

    }

    const handleChangeDriver = (drivers) => {
        // const { drivers } = work
        setWork(w => { return ({ ...w, drivers: drivers }) })
    }

    const handleDelBushostess = (id) => {
        const { bushostess } = work
        setWork(w => { return ({ ...w, bushostess: bushostess.filter(d => d.employee_id !== id) }) })

    }

    const handleChangeBushostess = (bushostess) => {
        // const { drivers } = work
        setWork(w => { return ({ ...w, bushostess: bushostess }) })
    }



    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setWork(e => {
            return ({ ...e, [name]: value })
        })
    }

    const onChangeBookingDate = (name, value) => {
        setWork(b => {
            return ({
                ...b,
                [name]: value
            })
        })
    }
    const handleChangeCars = (cars) => {
        setWork(e => {
            return ({ ...e, ...cars })
        })
    }
    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><AddOutlinedIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>รายละเอียด ใบงาน</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <Grid container spacing={1} >
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='เลขที่' placeholder='เลขที่' onChange={handleChange} value={work.works_id} name='works_id' fullWidth />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='ประเภท' placeholder='ประเภท' onChange={handleChange} value={work?.work_type} name='work_type' fullWidth />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField size="small" label='สถานที่รับ' onChange={handleChange} value={work?.pickup_location} name='pickup_location' fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={() => {
                                                    }}><SearchIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <DesktopDatePicker
                                        disablePast
                                        label="วันที่"
                                        inputFormat="dd/MM/yyyy"
                                        value={work.pickup_date}
                                        name='pickup_date'
                                        onChange={(value) => { onChangeBookingDate("pickup_date", value) }}
                                        renderInput={(params) => <TextField size="small" label='วันที่' name='pickup_date' fullWidth {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TimePicker
                                        ampm={false}
                                        label="รับเวลา"
                                        value={fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " " + work?.pickup_time), "yyyy-MM-dd HH:mm")}
                                        name='pickup_time'
                                        onChange={(value) => { onChangeBookingDate("pickup_time", fDateTo(value, "HH:mm")) }}
                                        renderInput={(params) => <TextField size="small" label='รับเวลา' name='pickup_time' fullWidth {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField size="small" label='flight' placeholder='XX-XXXXX' onChange={handleChange} value={work?.pickup_flight} name='pickup_flight' fullWidth />
                                </Grid>

                                <Grid item xs={3} md={3}>
                                    <TextField size="small" label='สถานที่ส่ง' onChange={handleChange} value={work?.dropoff_location} name='dropoff_location' fullWidth
                                        // InputLabelProps={{ shrink: formValue.localEnd ? true : false }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={() => {
                                                        // handleOpenForm("dialogLocation")
                                                        // setDialogFieldName("localEnd")
                                                    }}><SearchIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <DesktopDatePicker
                                        disablePast
                                        label="ส่งวันที่"
                                        inputFormat="dd/MM/yyyy"
                                        value={work.dropoff_date}
                                        name='dropoff_date'
                                        onChange={(value) => { onChangeBookingDate("dropoff_date", value) }}
                                        renderInput={(params) => <TextField size="small" label='ส่งวันที่' name='dropoff_date' fullWidth {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TimePicker
                                        ampm={false}
                                        label="รับเวลา"
                                        value={fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " " + work?.dropoff_time), "yyyy-MM-dd HH:mm")}
                                        name='dropoff_time'
                                        onChange={(value) => { onChangeBookingDate("dropoff_time", fDateTo(value, "HH:mm")) }}
                                        renderInput={(params) => <TextField size="small" label='รับเวลา' name='dropoff_time' fullWidth {...params} />}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField size="small" label='flight' placeholder='XX-XXXXX' onChange={handleChange} value={work?.dropoff_flight} name='dropoff_flight' fullWidth />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    {/* <FormControl sx={{ minWidth: 120 }} fullWidth size="small">
                                        <InputLabel id="demo-dialog-select-label">ประเภทรถ</InputLabel>
                                        <Select
                                            labelId="demo-dialog-select-label"
                                            id="demo-dialog-select"
                                            value={work.car_type}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="ประเภทรถ" />}
                                            name="car_type"
                                        >
                                            <MenuItem value="">
                                                <em>None</em>
                                            </MenuItem>
                                            {
                                                cars.map((car, id) => {
                                                    return (
                                                        <MenuItem key={"W" + id} value={car.car_type}>{car.car_type}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl> */}
                                    <TextField size="small" label='ประเภทรถ' placeholder='ประเภทรถ' onChange={handleChange} value={work?.car_type} name='car_type' fullWidth
                                        InputProps={{
                                            readOnly: true
                                            // endAdornment: (
                                            //     <InputAdornment position="end" >
                                            //         <IconButton size="small" onClick={() => {
                                            //             // handleOpenForm("dialogLocation")
                                            //             // setDialogFieldName("localStart")
                                            //         }}><SearchIcon fontSize="inherit" /></IconButton>
                                            //     </InputAdornment>
                                            // )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='ทะเบียนรถ' placeholder='ทะเบียนรถ' onChange={handleChange} value={work?.licensePlate} name='licensePlate' fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <CarsDialog onHandle={(e) => handleChangeCars(e)} car_type={work?.car_type} sDate={work.pickup_date} eDate={work.dropoff_date} />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4} md={4}>
                                    <TextField size="small" label='เลขที่รถ' placeholder='เลขที่รถ' onChange={handleChange} value={work?.car_id} name='car_id' fullWidth />
                                </Grid>


                                <Grid item xs={12} md={12}>
                                    <DialogDriver data={work.drivers}
                                        disabled={Number(employees?.driver_capacity) === work?.drivers.length}
                                        onChange={(drivers) => {
                                            handleChangeDriver(drivers)
                                        }}
                                    />
                                </Grid>
                                {/* ************************พนักงานขับรถ************************ */}
                                {
                                    work?.drivers.map((driver, index) => {
                                        return (
                                            <Grid item xs={12} md={12} key={index}>
                                                <Grid container spacing={1} >
                                                    <Grid item xs={5} md={5}>
                                                        <TextField size="small" disabled label='ชื่อนามสกุล' placeholder='ชื่อนามสกุล' value={driver.name} name='name' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6} md={6}>
                                                        <TextField size="small" disabled label='เบอร์โทร' placeholder='เบอร์โทร' value={driver.phone} name='dateStart' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={1} md={1}>
                                                        <Button variant="contained" onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลพนักงานขับรถ?", onHandle: () => { handleDelDriver(driver?.employee_id) } }) }} ><DeleteOutlineIcon /></Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                {/* ************************พนักงานขับรถ************************ */}

                                <Grid item xs={12} md={12}>
                                    <DialogBushostess
                                        data={work.bushostess}
                                        // disabled={employees?.bushostess_capacity === work?.bushostess.length}
                                        disabled={Number(employees?.bushostess_capacity) === work?.bushostess.length}
                                        onChange={(bushostess) => {
                                            handleChangeBushostess(bushostess)
                                        }} />
                                </Grid>

                                {/* ************************พนักงานต้อนรับ************************ */}
                                {
                                    work?.bushostess.map((bushost, index) => {
                                        return (
                                            <Grid item xs={12} md={12} key={index}>
                                                <Grid container spacing={1} >
                                                    <Grid item xs={5} md={5}>
                                                        <TextField size="small" disabled label='ชื่อนามสกุล' placeholder='ชื่อนามสกุล' value={bushost.name} name='name' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={6} md={6}>
                                                        <TextField size="small" disabled label='เบอร์โทร' placeholder='เบอร์โทร' value={bushost.phone} name='dateStart' fullWidth />
                                                    </Grid>
                                                    <Grid item xs={1} md={1}>
                                                        <Button variant="contained" onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลพนักงานต้อนรับ?", onHandle: () => { handleDelBushostess(bushost?.employee_id) } }) }}  ><DeleteOutlineIcon /></Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                {/* ************************พนักงานต้อนรับ************************ */}
                                <Grid item xs={12} md={12}>
                                    <TextField size="small" label='หมายเหตุ' value={``} multiline maxRows={3} minRows={3} name='remake' fullWidth />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}