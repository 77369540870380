import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import CreateWorkType from './CreateWorkType';
import UpdateWorkType from './UpdateWorkType';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function WorkType() {
    const worksType = [
        {
            "id": 1,
            "name": "Software Development",
            "description": "Development of software applications and systems.",
            "subtypes": [
                {
                    "id": 101,
                    "name": "Web Development",
                    "description": "Development of web-based applications and websites."
                },
                {
                    "id": 102,
                    "name": "Mobile App Development",
                    "description": "Development of applications for mobile devices."
                },
                {
                    "id": 103,
                    "name": "Database Management",
                    "description": "Management and optimization of databases."
                }
            ]
        },
        {
            "id": 2,
            "name": "Graphic Design",
            "description": "Creation of visual content for various purposes.",
            "subtypes": [
                {
                    "id": 201,
                    "name": "Logo Design",
                    "description": "Designing logos and brand identities."
                },
                {
                    "id": 202,
                    "name": "Print Design",
                    "description": "Designing materials for print such as brochures and flyers."
                },
                {
                    "id": 203,
                    "name": "UI/UX Design",
                    "description": "Designing user interfaces and user experiences."
                }
            ]
        }
    ]

    const navigate = useNavigate()

    const onClickCreate = () => {
        navigate("create")
    }
    const onClickUpdate = (data) => {
        navigate("sub", { state: data })
    }


    const [openCreate, setOpenCreate] = useState(false)

    const onHandleOpenNew = () => {
        setOpenCreate(true)
    }
    const onHandleCloseNew = () => {
        setOpenCreate(true)
    }

    const [openUpdate, setOpenUpdate] = useState(false)

    const onHandleOpenUpdate = () => {
        setOpenCreate(true)
    }
    const onHandleCloseUpdate = () => {
        setOpenCreate(true)
    }



    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, data, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            // style: { bgcolor: "whitesmoke !important" },
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    console.log('data', data)
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };



    const onHandleDel = ({ }) => {
        handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
    }

    return (
        <div style={{ padding: "10px" }}>
            <Box><Typography variant="h6" sx={{ textAlign: "center" }} >ประเภทงาน</Typography></Box>
            <Stack spacing={1} direction="row" justifyContent="space-between">
                <Box>
                    <TextField size="small" label='ค้นหา'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" >
                                    <IconButton size="small"  ><SearchIcon fontSize="inherit" /></IconButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Box>
                <Box>
                    <CreateWorkType />
                </Box>
            </Stack>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>ชื่อ</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        worksType.map((work, index) => {

                            return (
                                <TableRow>
                                    <TableCell>{work.id}</TableCell>
                                    <TableCell>{work.name}</TableCell>
                                    <TableCell align="right">
                                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                            <Box>
                                                <IconButton size="small" onClick={() => onClickUpdate(work)} >
                                                    <FindInPageIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                            <UpdateWorkType data={work} />
                                            <Box>
                                                <IconButton size="small"
                                                    onClick={() => {
                                                        handleSnackWithAction({ message: "ต้องการลบข้อมูลประเภท?", data: work, onHandle: onHandleDel })
                                                    }}
                                                >
                                                    <DeleteOutlineIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>

        </div >
    )
}
