import React from 'react'
import { Box, Card, Divider, Typography, Badge } from '@mui/material';
import { fDateTo } from '../../utils/formatTime';


import ImageNotSupportedRoundedIcon from '@mui/icons-material/ImageNotSupportedRounded';
import Gauge from './Guage';

export default function CardSideDash({ data, onClick }) {
    return (
        <Card
            sx={{
                borderRadius: '0px',
                marginY: '5px',
            }}
            elevation={3}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    padding: '5px',
                }}
                onClick={onClick}

                style={{ cursor: 'pointer' }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '3px'
                    }}
                >
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <Typography fontWeight={600} >{data.name}</Typography>
                    </Box>
                    <Box flexGrow={1} sx={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        textAlign: "center",
                        // backgroundColor: "gray",
                        marginInline: 1,
                        borderRadius: 1
                    }}>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}>
                        <Typography >{fDateTo(data.datetime, "dd-MM-yy HH:mm:ss")}</Typography>
                    </Box>
                </Box>
                <Divider style={{
                    marginTop: "5px",
                    // borderColor: '#5C5CFF'
                }} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        paddingY: "3px",
                    }}
                >
                    <Box sx={{
                        justifyContent: "center",
                        textAlign: "center",
                        marginInline: '5px',
                        paddingTop: '10px',
                        borderRadius: 50,
                        // border: "3px solid #dbdbe7",
                        minWidth: 50,
                        minHeight: 50,
                        maxWidth: 50,
                        maxHeight: 50,
                        display: "flex",
                        marginTop: "8px",
                        alignItems: 'flex-start',
                        background: 'rgb(182,33,254)',
                        background: 'linear-gradient(90deg, rgba(30, 136, 229, 1) 0%, rgba(33, 150, 243, 1) 49%, rgba(187, 222, 251, 1) 100%)',
                    }}>

                        <Badge
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            variant="dot"
                            badgeContent=' '
                            // color="#C5C5C5"
                            sx={{
                                "& .MuiBadge-badge": {
                                    color: "lightgreen",
                                    backgroundColor:
                                        // data.date_gps === null || new Date(data.date_gps) < new Date().setMinutes(new Date().getMinutes() - 5) ? "#C5C5C5" : "#00FF00",
                                        data?.datetime === null || new Date(data.datetime) < new Date().setMinutes(new Date().getMinutes() - 5) ? "#FF0000" : "#00FF00",
                                    width: '15px',
                                    height: '15px',
                                    borderRadius: '15px',
                                }
                            }}
                            color="info"
                        >
                            {
                                data?.icon !== null && (
                                    <img src={data.icon} alt='MainImgIcon' width={30} height={30} />
                                )
                            }
                            {

                                data?.icon === null && (
                                    <ImageNotSupportedRoundedIcon style={{
                                        fontSize: "35px",
                                        color: "#dbdbe7"
                                    }} />
                                )
                            }

                        </Badge>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        overflowX: 'auto',
                        // backgroundColor: "gray",
                        borderRadius: 1,
                        justifyContent: 'space-between',
                        width: "calc(100% - 80px)",
                        alignItems: 'flex-start'
                    }}>

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: "row",
                                justifyContent: 'space-around',
                                paddingTop: '20px'
                                // flexWrap: 'wrap',
                            }}
                        >
                            {
                                data.widget
                                    .map((values, index) => (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: "row",
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                justifyContent: 'end'
                                                // flexWrap: 'wrap',
                                            }}
                                            key={index}
                                        >
                                            <Box sx={{ marginRight: '10px', }}>
                                                <img src={values.icon} alt='imgIcon' width={25} height={25} />
                                                {/* <Typography noWrap={true} margin="0px" fontSize="14px" marginLeft="5px" paddingTop="0px" textOverflow="unset" overflow="unset" >
                                            {
                                                values.name
                                            }
                                        </Typography> */}
                                            </Box>
                                            <Box sx={{ margin: 'auto' }}>
                                                <Typography noWrap={true} margin="0px" marginLeft="5px" paddingTop="0px" textOverflow="unset" overflow="unset" >
                                                    {
                                                        values.value
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                    ))
                            }
                        </Box>
                    </Box>
                    <Box sx={{
                        justifyContent: "center",
                        textAlign: "center",
                        marginInline: '5px'
                    }}>
                        <Gauge width={55} value={data.speed || 0} showValue={true} units="KM/h" fontSize={"0.7em"} fontSizeUnits={"0.7em"} max={200} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: 'flex-start',
                        textAlign: 'end',
                        // flexWrap: 'wrap',
                    }}
                >
                    <img src={data.widget_bar.icon} alt='imgIcon' width={20} height={20} style={{ marginRight: '10px', marginLeft: '10px' }} />
                    <Typography noWrap={true} margin="0px" paddingTop="0px" textOverflow="ellipsis" overflow="ellipsis" >
                        {
                            data.widget_bar.value
                        }
                    </Typography>
                </Box>
            </Box >
        </Card>
    )
}
