import { Box, Button, Slide, Grid, MenuList, MenuItem, ListItemText, TextField, ListItemIcon, Paper, Toolbar, OutlinedInput, InputAdornment, IconButton, Menu, Typography, useMediaQuery } from '@mui/material'
import { makeStyles, styled } from '@mui/styles';
import React, { useEffect, useRef, useState } from 'react'
import BottomNavigationBar from '../../layouts/BottomNavigationBar'
import ViewListIcon from '@mui/icons-material/ViewList';
import TuneIcon from '@mui/icons-material/Tune';
import AddIcon from '@mui/icons-material/Add';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from 'react-router-dom';
import ItemDashboard from './ItemDashboard';
import { Api } from '../../components/api';
import Check from '@mui/icons-material/Check';
import { set } from 'date-fns';
import { fDate, fDateTime } from '../../utils/formatTime';

const MenuBottomNav = styled(Paper)(({ theme }) => (
    {
        [theme.breakpoints.up('md')]: {
            width: 400,
            // margin: 0,
            margin: 5,

        },
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10000,
        // position: 'absolute',
        position: 'fixed',

    }));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: 64,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2, 1, 2)
}));
const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: '100%',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    },
    "& legend": {
        visibility: "visible"
    }
}));

const useStyles = makeStyles(theme => ({
    SlideContainer: {
        top: 0,
        left: 0,
        margin: 5,
        position: 'fixed',
        zIndex: 3
    },
    MenuContainer: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 56,
        [theme.breakpoints.up('md')]: {
            width: 400,
            margin: 5,
            bottom: 56,
        },
        display: 'flex',
        position: 'fixed',
        transition: 'transform .5s ease',
        flexDirection: 'column',
        backgroundColor: 'white',
        // backgroundColor: 'whitesmoke',
        // overflowY: 'auto',
    },
}));
export default function SlideBarMap(
    {
        dataList = [], userId, onSelect, map, client,
        isFullScreen, setFilterGroup, filterGroup,
        setQuery
    }
) {
    const navigate = useNavigate();
    const refDropDownTune = useRef(null);

    const [open, setOpen] = useState(true)
    const [openDropDown, setOpenDropDown] = useState(false)
    const [firstLoad, setFirstLoad] = useState(true)
    const [deviceGroup, setDeviceGroup] = useState([])
    const [search, setSearch] = useState("");
    const classes = useStyles();

    useEffect(() => {
        if (firstLoad) {
            Api.get(`/Tracking/DeviceGroup?userid=${userId}`)
                .then(res => {
                    var list = res.data;
                    setFirstLoad(false)
                    setDeviceGroup(list)
                }
                ).catch(e => {
                    console.log('error', e)
                })
        }

        const timeOutId = setTimeout(() => {
            setQuery(search)
        }, 300);

        return () => clearTimeout(timeOutId);

    }, [search, firstLoad])



    const handleOpenDropDown = () => {
        setOpenDropDown(!openDropDown)
    }
    const handleOpenClick = () => {
        setOpen(!open)
    }
    const handleNav = (route) => {
        if (client) {
            client.end();
        }
        navigate(route)
    }
    const handleSort = (group) => {
        setFilterGroup(group)
        handleOpenDropDown()
        onSelect(null)
    }
    const handleChange = (event) => {
        const { value } = event.target
        onSelect(null)
        setSearch(value)
    }
    // const list = filterGroup !== '' ?
    //     listMenu.filter((val) => {
    //         const isFind = val.group.filter(a => a.group_id === filterGroup)
    //         if (isFind.length > 0) {
    //             return val
    //         }
    //         return
    //     })
    //     : listMenu

    // onSetListFilter(list)

    const offLine = dataList.filter(value => {
        const dateNow = new Date()
        const minOffLine = dateNow.setMinutes(dateNow.getMinutes() - 5)
        return value.datetime === null || new Date(value.datetime) < minOffLine
    })
    return (
        <Box className={classes.SlideContainer}>
            <Button variant="contained"
                style={{ backgroundColor: "white", borderRadius: 0, color: "black" }}
                startIcon={<ViewListIcon />}
                onClick={() => {
                    setOpen(!open)
                }}>
                Device
            </Button>
            <Slide direction="right" in={open} >
                <Box className={classes.MenuContainer}>
                    <Box>
                        <ToolbarStyle>
                            <TextField
                                fullWidth
                                size="small"
                                label={filterGroup === '' ? "All" : filterGroup}
                                value={search}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        < InputAdornment position='end' >
                                            <IconButton edge="end" ref={refDropDownTune} onClick={() => { handleOpenDropDown() }}>
                                                <TuneIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <IconButton onClick={() => {
                                handleNav('/settings/devices/device')
                            }}>
                                <AddIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                                setOpen(!open)
                            }}>
                                <CloseRoundedIcon />
                            </IconButton>
                        </ToolbarStyle>
                        <Menu
                            anchorEl={refDropDownTune.current}
                            open={openDropDown}
                            onClose={() => { handleOpenDropDown() }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            {/* <Typography sx={{ p: 2 }}>The content.</Typography> */}
                            <MenuList dense
                                style={{
                                    width: 320
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        handleSort('')
                                    }}>
                                    <ListItemIcon>
                                        {
                                            filterGroup === '' && (
                                                <Check />
                                            )
                                        }
                                    </ListItemIcon>
                                    <ListItemText>All</ListItemText>
                                </MenuItem>
                                {
                                    deviceGroup.map((value, index) => (
                                        <MenuItem key={index}
                                            onClick={() => {
                                                handleSort(value.group_id)
                                            }}>
                                            <ListItemIcon>
                                                {
                                                    filterGroup === value.group_id && (
                                                        <Check />
                                                    )
                                                }
                                            </ListItemIcon>
                                            <ListItemText>{value.name}</ListItemText>
                                        </MenuItem>
                                    ))
                                }
                            </MenuList>
                        </Menu>

                    </Box>
                    <Box sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px'
                    }}>
                        <Grid spacing={1} container justifyContent="center">
                            <Grid item xs={4} justifyContent="center">
                                <Typography textAlign="center" variant="h6">{dataList.length}</Typography>
                                <Typography textAlign="center" color="#A9A9A9">DEVICE</Typography>
                            </Grid>
                            <Grid item xs={4} justifyContent="center">
                                <Typography textAlign="center" variant="h6">{dataList.length - offLine.length}</Typography>
                                <Typography textAlign="center" color="#A9A9A9">ONLINE</Typography>
                            </Grid>
                            <Grid item xs={4} justifyContent="center">
                                <Typography textAlign="center" variant="h6">{offLine.length}</Typography>
                                <Typography textAlign="center" color="#A9A9A9">OFFLINE</Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box paddingX={'5px'} overflow="auto" sx={{ backgroundColor: 'whitesmoke' }}>
                        {
                            dataList.map((_, index) => (
                                <ItemDashboard onClick={
                                    () => {
                                        if (isFullScreen) {
                                            handleOpenClick()
                                        }
                                        // onSelect(index)
                                        onSelect(_.device)
                                        map.setView([_.latitude, _.longitude], 19, {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                }
                                    data={_}
                                    index={index}
                                    key={index}
                                />
                            ))
                        }

                    </Box>
                </Box>
            </Slide >

            <MenuBottomNav elevation={3}>
                <BottomNavigationBar ative="map" client={client} />
            </MenuBottomNav>
        </Box >
    )
}
