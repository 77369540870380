import {
    Badge, Box, Button, Stack, Card, Container, Divider, Fab, IconButton, List, ListItem, ListItemButton, ListItemText, Typography,
    Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import Page from '../components/Page'

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import styled from '@emotion/styled';
import { Api } from '../components/api';



import ImageNotSupportedRoundedIcon from '@mui/icons-material/ImageNotSupportedRounded';
import GroupsEdit from './GroupEdit';
import GroupsNew from './GroupsNew';
import UserGroup from './UserGroup';
import HasPermission from '../haspermission/HasPermission';


export default function Groups({ userId, account, permissions }) {
    const [masterGroup, setMasterGroup] = useState([])
    const navigate = useNavigate()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();


    useEffect(() => {
        Api.get(`Tracking/Group?cname=${account.c_coname}`)
            .then(res => {
                var list = res.data;
                setMasterGroup(list)
            }
            ).catch(e => {
                console.log('error', e)
            })

        // return () => {
        //     second
        // }
    }, [])





    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const handleSnackWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            // handleDel(item)
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </Fragment>
            )
        });
    };


    const handleDel = (group_id) => {
        const { c_coname } = account
        const data = {
            cname: c_coname,
            group_id: group_id
        }
        Api.delete('Tracking/Group', { data })
            .then(res => {
                handleGetData()
                handleSnackbar({ variant: 'success', message: 'success!' })
            }
            ).catch(error => {
                handleSnackbar({ variant: 'error', message: error.response.data.Message });
            }
            )
    };

    const handleGetData = () => {
        Api.get(`Tracking/Group?cname=${account.c_coname}`)
            .then(res => {
                var list = res.data;
                setMasterGroup(list)
            }
            ).catch(e => {
                console.log('error', e)
            })
    }

    return (
        <Page>
            <Container maxWidth={false} style={{ padding: 0 }}>
                <Box flexGrow={1} padding={1}>
                    <Stack spacing={1} direction="row" justifyContent="space-between">
                        <Typography variant="h4">Groups</Typography>
                        <HasPermission permissions={permissions} scope={["create"]}>
                            <GroupsNew account={account} handleGetData={handleGetData} />
                        </HasPermission>
                    </Stack>
                </Box>
                <Divider />
                <MainStyle>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>group id</TableCell>
                                <TableCell>group name</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                masterGroup.map((group, index) => (
                                    <TableRow key={`group${index}`}>
                                        <TableCell >
                                            <Box
                                                sx={{
                                                    justifyContent: "center",
                                                    textAlign: "center",
                                                    paddingTop: '3px',
                                                    borderRadius: 30,
                                                    minWidth: 30,
                                                    minHeight: 30,
                                                    maxWidth: 30,
                                                    maxHeight: 30,
                                                    display: "flex",
                                                    alignItems: 'flex-start',
                                                    background: 'rgb(182,33,254)',
                                                    background: 'linear-gradient(90deg, rgba(30, 136, 229, 1) 0%, rgba(33, 150, 243, 1) 49%, rgba(187, 222, 251, 1) 100%)',
                                                }}
                                            >
                                                {
                                                    group.icon !== "" && (
                                                        <img src={group.icon} alt='MainImgIcon' width={25} height={25} />
                                                    )
                                                }
                                                {
                                                    group.icon === "" && (
                                                        <ImageNotSupportedRoundedIcon sx={{
                                                            fontSize: "25px"
                                                        }} />
                                                    )
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>{group.group_id}</TableCell>
                                        <TableCell>{group.group_name}</TableCell>
                                        <TableCell>
                                            <Box sx={{ justifyContent: "end", display: "flex" }}>
                                                <Stack spacing={1} direction="row" >
                                                    <HasPermission permissions={permissions} scope={["share"]}>
                                                        <UserGroup account={account} gorup_state={group} />
                                                    </HasPermission>
                                                    <HasPermission permissions={permissions} scope={["update"]}>
                                                        <GroupsEdit account={account} gorup_state={group} handleGetData={handleGetData} />
                                                    </HasPermission>
                                                    <HasPermission permissions={permissions} scope={["delete"]}>
                                                        <IconButton size="small"
                                                            onClick={() => {
                                                                closeSnackbar()
                                                                handleSnackWithAction({
                                                                    message: "Remove item?",
                                                                    handle: () => {
                                                                        handleDel(group.group_id)
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            <DeleteIcon color="error" fontSize="small" />
                                                        </IconButton>
                                                    </HasPermission>
                                                </Stack>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </MainStyle>
            </Container>
        </Page>
    )
}



const FabStyle = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: 5,
    right: 5,
    [theme.breakpoints.down('md')]: {
        bottom: 60,
    }
}));
const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 65,
    overflow: 'auto'
}));