import React from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, Button, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/system';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        // padding: theme.breakpoints.down('md') ? theme.spacing(0) : theme.spacing(2),
        position: 'absolute',
        top: theme.breakpoints.down('md') ? theme.spacing(0) : theme.spacing(5)
    },
    dialogTitle: {
        paddingRight: '0px'
    }
}))
PopupDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    // maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'xl', 'lg'])
};
export default function PopupDialog({ title, children, open, handleClose }) {
    const classes = useStyles();
    const theme = useTheme();
    const isFullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            open={open}
            maxWidth="md"
            classes={{ paper: classes.dialogWrapper }}
            fullScreen={isFullScreen}
            aria-labelledby="responsive-dialog-title"
            PaperProps={{ sx: { width: "100%" } }}
        // dividers={dividers}
        >
            <DialogTitle
                className={classes.dialogTitle}
            >
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <div style={{ marginLeft: 5, marginRight: 5, }} >
                        <Button
                            color="secondary"
                            onClick={handleClose}>
                            <CloseIcon />
                        </Button>
                    </div>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
        </Dialog>
    )
}