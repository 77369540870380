import { styled } from '@mui/styles';
import React, { useRef, useState } from 'react'
import { CircleMarker, MapContainer, Marker, TileLayer } from 'react-leaflet';
import ListTileLayer from '../../layouts/ListTileLayer';



import { Box } from '@mui/material';
import { useSubscribe } from './SubscribeInterval';
import { Api } from '../../components/api';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { divIcon, point } from 'leaflet';
import MarkerRotated from './MarkerRotated';



const center = [13.839660198254604, 100.63469639884744];
const zoom = 13;



const createClusterCustomIcon = function (cluster) {
    return new divIcon({
        html: `<span class="cluster-icon-text">${cluster.getChildCount()}</span>`,
        className: "cluster-icon",
        iconSize: point(33, 33, true)
    });
};

const IconMarker = ({ className, icon, bearing }) => {
    return divIcon({
        className: className,
        html: `
            <img 
            src="${icon}" 
            width="20" 
            class="icon-car-center" 
            style="transform: rotate(${(360 - bearing)}deg);"
            />
      `,
        iconSize: [40, 40],
        iconAnchor: [19.5, 20],
        popupAnchor: [19.5, -20]
    })
}

export default function TheNewMain({ userId }) {


    const [states, setStates] = useState({
        subscribe: [],
        map: null,
        backdrop: false,
        int: 5000,
        subscribeSelect: '',
        selectedMapStyle: localStorage.getItem("selectedMapStyle") || "LongdoMap"
    })
    const refTileLayer = useRef(null)

    const setMap = (event) => {
        setStates(state => {
            return { ...state, map: event }
        })
    }

    const handleChangeStates = ({ name, value }) => {
        setStates(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    // const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap");


    const { subscribes } = useSubscribe(
        {
            urls: states.subscribe.length > 0 ? `/Tracking/DevicesByList` : ``,
            // urls: states.subscribe.length > 0 && mapView ? `/Tracking/DevicesByList` : ``,
            args: states.subscribe,
            userId: userId,
            setBackdrop: (event) => {
                handleChangeStates({ name: 'blackdrop', event })
            },
            setInt: (event) => {
                handleChangeStates({ name: 'int', value: event })
            },
            options: { refreshInterval: states.int, revalidateOnFocus: true },
            select: states.subscribeSelect
        }
    )


    const renderMap = () => {

        // const maxPage = subscribes.length % 30

    }


    const GetAll = () => {
        setTimeout(() => {
            Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
                const list = res.data;
                const _listDevice = list.map(v => v.device)
                setStates(prevState => { return { ...prevState, subscribe: _listDevice, groupSelect: { group_id: '', cname: '' } } })
            }).catch(e => {
                console.log('error', e)
            })

        }, 50);
    }
    return (
        <div>
            <Box>
                <button
                    onClick={GetAll}
                >
                    GET ALL
                </button>
            </Box>
            <MapContainers
                preferCanvas={true}
                whenCreated={(ref) => {
                    setMap(ref)
                }}
                center={center}
                zoom={zoom}
                minZoom={3}
                zoomControl={false}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                attributionControl={false}
            >
                <TileLayer
                    ref={refTileLayer}
                    url={ListTileLayer.find(value => value.id === states.selectedMapStyle).url}
                    maxNativeZoom={19}
                    maxZoom={22}
                />
                <MarkerClusterGroup
                    chunkedLoading={true}
                    // chunkInterval={400}
                    iconCreateFunction={createClusterCustomIcon}
                    spiderfyOnMaxZoom={false}
                    disableClusteringAtZoom={12}
                    animateAddingMarkers={false}
                    animate={false}
                    maxClusterRadius={100}
                    showCoverageOnHover={false}

                >
                    {
                        subscribes.map((v, i) => {
                            return (
                                // <MarkerRotated
                                //     rotationAngle={v?.bearing}
                                //     rotationOrigin="center"
                                //     position={[v.latitude, v.longitude]}
                                //     icon={IconMarker({ className: 'icon-car', icon: v?.icon, bearing: v?.bearing })}
                                //     key={`CircleMarker${i}`}
                                // />

                                <MemoizedMarkerRotated
                                    bearing={v?.bearing}
                                    position={[v?.latitude, v?.longitude]}
                                    icon={IconMarker({ className: 'icon-car', icon: v?.icon, bearing: v?.bearing })}
                                    key={`CircleMarker${i}`}
                                />
                            )
                        })
                    }
                </MarkerClusterGroup>

            </MapContainers>
        </div>
    )
}


const MapContainers = styled(MapContainer)(({ theme }) => ({
    overflow: 'hidden',
    height: '100vh',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
        height: "calc(100vh - 124px)",
        // height: '50vh'
    },
}));


const ItemMarkerRotated = ({ bearing, icon, position }) => {

    return <MarkerRotated
        rotationAngle={bearing}
        rotationOrigin="center"
        position={position}
        icon={icon}
    />;
};

const MemoizedMarkerRotated = React.memo(ItemMarkerRotated);
