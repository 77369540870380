import React, { useState } from 'react'
import { Container, Stack, MenuItem, Grid, Button, TextField, Box, Divider, Typography, TableContainer } from '@mui/material'
import Page from '../../components/Page'

export default function MainCommand() {
    const [devices, setDevices] = useState(["custom"])
    const [device, setDevice] = useState("")
    const [commands, setCommands] = useState(["custom", "custom"])
    const [command, setCommand] = useState("")
    const [commandCustom, setCommandcommand] = useState("")

    const onChangeSelectDevice = (e) => { setDevice(e.target.value) }
    const onChangeSelect = (e) => { setCommand(e.target.value) }
    const onChangeInput = (e) => { setCommandcommand(e.target.value) }
    return (
        <Page>
            <Box padding={2}>
                <Stack spacing={1}>
                    <Box>
                        <Typography variant="h6">Commands</Typography>
                    </Box>
                    <Box>
                        <TextField select fullWidth size="small" onChange={onChangeSelectDevice} value={device} >
                            {
                                devices.map(value => (
                                    <MenuItem value={value}>{value}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Box>
                    <Box>
                        <TextField select fullWidth size="small" onChange={onChangeSelect} value={command} >
                            {
                                commands.map(value => (
                                    <MenuItem value={value} >{value}</MenuItem>
                                ))
                            }
                        </TextField>
                    </Box>
                    {
                        command === "custom" && (
                            <Box>
                                <TextField multiline rows={5} fullWidth placeholder='Command' value={commandCustom} onChange={onChangeInput} />
                            </Box>
                        )
                    }
                    <Box>
                        <Button variant="outlined" >ส่ง</Button>
                    </Box>
                </Stack>
            </Box>
        </Page>
    )
}
