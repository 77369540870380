import { Box, Container, Grid } from '@mui/material'
import { styled } from '@mui/styles';
import React from 'react'
import { MapContainer, TileLayer } from 'react-leaflet';
import Page from '../components/Page'
const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));
const ContentStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    // paddingBottom: theme.spacing(10)
}));
const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
    height: '100vh',
    [theme.breakpoints.down('md')]: {
        height: '83vh',
    },
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
}));

export default function Home() {
    return (
        <RootStyle>
            {/* <Container maxWidth={false} style={{ padding: 0 }}>
                <Page>
                    <MapContainerStyle
                        center={[13.839660198254604, 100.63469639884744]}
                        minZoom={3}
                        zoom={6}
                        attributionControl={false}
                    >
                        <TileLayer url={'https://mt.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}'}
                        />
                    </MapContainerStyle>
                </Page>
            </Container> */}
            <ContentStyle>
                <Page>
                    <Container maxWidth={false} style={{ padding: 0 }}>
                        <Grid container spacing={0}
                            direction="column"
                            alignItems="center"
                            justify="center">
                            <Grid item xs={12} >
                                <h1>SIT GPS</h1>
                            </Grid>
                            <Grid container spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center">
                                <Box
                                    width={"100%"}
                                    component="img"
                                    alt={"GPS"}
                                    borderRadius={5}
                                    src={"https://www.whatphone.net/wp-content/uploads/2018/08/GPS-on-Smartphone.jpg"}
                                />
                            </Grid>
                            <Grid item xs={12}
                                alignItems="center"
                                justify="center"
                                justifyItems={"center"}
                                textAlign="center"
                            >
                                <h1>GPS คืออะไร?</h1>
                                <p>
                                    GPS คือ ระบบระบุตำแหน่งบนพื้นโลก ย่อมาจากคำว่า Global Positioning System ซึ่งจีพีเอสทำงานโดยการรับสัญญาณจากดาวเทียมแต่ละดวง (NAVSTAR, Galileo และ GLONASS) โดยสัญญาณดาวเทียมนี้ประกอบไปด้วยข้อมูลที่ระบุตำแหน่งและเวลาขณะส่งสัญญาณ ตัวเครื่องรับสัญญาณ GPS อาทิ สมาร์ทโฟน จะต้องประมวลผลความแตกต่างของเวลาในการรับสัญญาณเทียบกับเวลาจริง ณ ปัจจุบันเพื่อแปรเป็นระยะทางระหว่างเครื่องรับสัญญาณกับดาวเทียมแต่ละดวง ซึ่งได้ระบุมีตำแหน่งของมันมากับสัญญาณดังกล่าวข้างต้น
                                    โดยความแม่นจำของ GPS นั้นจะขึ้นอยู่กับสภาพแวดล้อมด้วย ยิ่งอยู่ในสถานที่โล่งแจ้งก็สามารถทำให้รับสัญญาณจากดาวเทียมได้แม่นยำขึ้น แต่ถ้าอยู่ในอาคารหรือสภาพแวดล้อมที่มีคลื่นแทรกมาก ๆ ก็จะทำให้ประสิทธิภาพในการรับสัญญาณลดลงเช่นกัน
                                </p>
                            </Grid>
                        </Grid>
                    </Container>
                </Page>
            </ContentStyle>
        </RootStyle>
    )
}
