import { atom } from 'recoil';
import { UseAesCbc } from '../_crypto/AesCbc';

const aesCbc = UseAesCbc();

// const userAtom = atom({
//     key: 'user',
//     default: null
// });
const userAtom = atom({
    key: 'user',
    default: localStorage.getItem('user') != null ? JSON.parse(aesCbc.decrypt(localStorage.getItem('user'))) : null
});
const listModelAtom = atom({
    key: 'listModel',
    default: localStorage.getItem('listModel') != null ? JSON.parse(aesCbc.decrypt(localStorage.getItem('listModel'))) : []
});
const dltVenderAtom = atom({
    key: 'dltVender',
    default: localStorage.getItem('dltVender') != null ? JSON.parse(aesCbc.decrypt(localStorage.getItem('dltVender'))) : {}
});
const listVehicleTypeAtom = atom({
    key: 'listVehicleType',
    default: localStorage.getItem('listVehicleType') != null ? JSON.parse(aesCbc.decrypt(localStorage.getItem('listVehicleType'))) : []
});
const listProvinceAtom = atom({
    key: 'listProvince',
    default: localStorage.getItem('listProvince') != null ? JSON.parse(aesCbc.decrypt(localStorage.getItem('listProvince'))) : []
});
const listVehicleRegisterTypeAtom = atom({
    key: 'listVehicleRegisterType',
    default: localStorage.getItem('listVehicleRegisterType') != null ? JSON.parse(aesCbc.decrypt(localStorage.getItem('listVehicleRegisterType'))) : []
});

const permissionAtom = atom({
    key: 'listVehicleRegisterType',
    default: localStorage.getItem('permission') != null ? JSON.parse(aesCbc.decrypt(localStorage.getItem('permission'))) : {}
});
// const userAtom = atom({
//     key: 'user',
//     default: JSON.parse(aesCbc.decrypt(localStorage.getItem('user')))
// });

export {
    userAtom,
    listModelAtom,
    dltVenderAtom,
    listVehicleTypeAtom,
    listProvinceAtom,
    listVehicleRegisterTypeAtom,
    permissionAtom
};

// const hello = async () => localStorage.getItem('user');