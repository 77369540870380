import styled from '@emotion/styled';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Divider, Grid, TextField, Typography, ListItemText, Autocomplete, Button, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, TablePagination } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Page from '../../components/Page'
import * as Yup from 'yup';
import { Api } from '../../components/api';
import { fDateTo } from '../../utils/formatTime';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import HasPermission from '../../haspermission/HasPermission';

const listTableHead = [
    {
        title: 'ทะเบียนรถ',
    },
    {
        title: 'หมายเลขรถ',
    },
    {
        title: 'imei',
    },
    {
        title: 'วันที่',
    },
    {
        title: 'ละติจูด',
    },
    {
        title: 'ลองจิจูด',
    },
    {
        title: 'ความเร็ว',
    },
    {
        title: 'คนขับ',
    },

]

export default function OverspeedByDevice({ account, userId, permissions }) {
    const navigate = useNavigate()
    const [devices, setDevices] = useState([])
    const [overspeeds, setOverspeeds] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useEffect(() => {
        Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
            var list = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
            setDevices(list)
        }
        ).catch(e => {
            console.log('error', e)
        })
    }, [userId])

    const schema = Yup.object().shape({
        deviceid: Yup.object().shape({
            device: Yup.string().required('Device is required'),
        }).nullable().required('Device is required'),
        speed: Yup.number('speed min 50').required().min(50, 'speed min 50')
    });
    const selectedTime = new Date();
    const formik = useFormik({
        initialValues: {
            deviceid: null,
            speed: 60,
            date_s: selectedTime.setHours(0, 0, 0),
            date_e: selectedTime.setHours(23, 59, 59),
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { deviceid, date_s, date_e, speed } = values
            const { cocode } = account;

            Api.get('/tracking/overspeed', {
                params: {
                    // cocode: cocode,
                    device: deviceid.device,
                    dateStart: fDateTo(date_s, 'yyyy-MM-dd HH:mm'),
                    dateEnd: fDateTo(date_e, 'yyyy-MM-dd HH:mm'),
                    speed: speed
                }
            }).then((res) => {
                const { data } = res
                setOverspeeds(data)
                setPage(0)
                setSubmitting()
            }).catch((e) => {
                setOverspeeds([])
                setPage(0)
                setSubmitting()
            })
        }
    })


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;


    // const devicesfilter = devices.filter(opt => opt.vehicle_id.toUpperCase().includes(inputValue.toUpperCase()))
    // console.log('devicesfilter', devicesfilter)
    return (
        <Page>
            <Container>
                <Box flexGrow={1} padding={1}>
                    <Typography variant="h4">Event : Overspeed by device</Typography>
                </Box>
            </Container>
            <Divider />
            <MainStyle>
                <Container sx={{ padding: 3 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} sm={3}>
                                        <Autocomplete
                                            id="deviceid"
                                            name="deviceid"
                                            options={devices}
                                            size="small"
                                            getOptionLabel={(option) => option.vehicle_id}
                                            isOptionEqualToValue={(option, value) => option.device === value.device}
                                            fullWidth
                                            renderOption={(props, item) => (
                                                <li {...props} key={item.key}>
                                                    <ListItemText>{item.vehicle_id}</ListItemText>
                                                </li>
                                            )}
                                            filterOptions={(options, state) => {
                                                return options.filter(opt => opt.vehicle_id.toUpperCase().includes(state.inputValue.toUpperCase()))
                                            }}
                                            onChange={(e, value) => {
                                                setFieldValue("deviceid", value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    label="เลือกอุปกรณ์ GPS"
                                                    fullWidth
                                                    autoComplete="off"
                                                    name="deviceid"
                                                    {...params}
                                                // {...getFieldProps('deviceid')}
                                                // error={Boolean(touched.deviceid && errors.deviceid)}
                                                // helperText={touched.deviceid && errors.deviceid}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <TextField
                                            size="small"
                                            {...getFieldProps('speed')}
                                            fullWidth
                                            type="number"
                                            min={50}
                                            label="ความเร็ว"
                                            autoComplete="off"
                                            error={Boolean(touched.speed && errors.speed)}
                                            helperText={touched.speed && errors.speed}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <MobileDateTimePicker
                                            ampm={false}
                                            disableFuture={true}
                                            label="วันเวลาเริ่ม"
                                            allowKeyboardControl={false}
                                            value={values.date_s}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            format="dd/MM/yyyy HH:mm"
                                            onChange={(val) => {
                                                setFieldValue('date_s', val);
                                                setFieldValue('date_e', new Date(val).setHours(23, 59, 59));
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_r')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_s && errors.date_s)}
                                                helperText={touched.date_s && errors.date_s} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={3}>
                                        <MobileDateTimePicker
                                            minDate={new Date(values.date_s)}
                                            maxDate={new Date(values.date_s).setDate(new Date(values.date_s).getDate() + 7)}
                                            ampm={false}
                                            disableFuture={true}
                                            label="ถึงวันเวลา"
                                            allowKeyboardControl={false}
                                            value={values.date_e}
                                            inputFormat="dd/MM/yyyy HH:mm"
                                            format="dd/MM/yyyy HH:mm"
                                            onChange={(val) => {
                                                setFieldValue('date_e', val);
                                            }}
                                            renderInput={(params) => <TextField
                                                size="small"
                                                {...params}
                                                {...getFieldProps('date_e')}
                                                fullWidth
                                                placeholder="DD/MM/YYYY"
                                                autoComplete="off"
                                                error={Boolean(touched.date_e && errors.date_e)}
                                                helperText={touched.date_e && errors.date_e} />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} justifyContent="flex-end" justifyItems="center">
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                        >
                                            <LoadingButton
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitting}

                                            >
                                                ค้นหา
                                            </LoadingButton>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box
                                            display="flex"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Button
                                                style={{ height: 40, width: 80 }}
                                                // fullWidth={true}
                                                size="small"
                                                type="submit"
                                                variant="contained"
                                                color="info"
                                                onClick={() => {
                                                    navigate('/report/event')
                                                }}

                                            >
                                                กลับ
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Form>
                        </FormikProvider>
                    </LocalizationProvider>
                </Container>
                {
                    overspeeds.length > 0 && (
                        <Container sx={{ padding: 3 }}>
                            <Box sx={{ padding: 1 }}
                                display="flex"
                                justifyContent="flex-end"
                            >
                                <HasPermission permissions={permissions} scope={["download"]}>
                                    <Button as={CSVLink} filename={`${fDateTo(values.date_s, 'dd_MM_yyyy_HH:mm')}-${fDateTo(values.date_e, 'dd_MM_yyyy_HH:mm')}.csv`} data={
                                        overspeeds.map((value, index) => {
                                            const {
                                                vehicle_id, car_id, imei, datetime_gps, latitude, longitude,
                                                speed, driver
                                            } = value;
                                            return {
                                                "ทะเบียนรถ": `=""${vehicle_id}""`,
                                                "หมายเลขรถ": `=""${car_id}""`,
                                                "imei": `=""${imei}""`,
                                                "วันที่": `=""${fDateTo(new Date(datetime_gps), 'dd/MM/yyyy')}""`,
                                                "เวลา": `=""${fDateTo(new Date(datetime_gps), 'HH:mm:ss')}""`,
                                                "ละติจูด": `=""${latitude}""`,
                                                "ลองจิจูด": `=""${longitude}""`,
                                                "ความเร็ว": `=""${speed}""`,
                                                "คนขับ": `=""${driver}""`
                                            }
                                        }
                                        )
                                    } variant="contained" style={{
                                        textDecorationLine: 'none',
                                    }} >
                                        Download
                                    </Button>
                                </HasPermission>
                            </Box>
                            <TableContainer>
                                <Table>
                                    <TableHead >
                                        <TableRow>
                                            {
                                                listTableHead.map((item, index) => {
                                                    return <TableCell style={{ textTransform: 'capitalize' }} key={index} >{item.title}</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            overspeeds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => {
                                                const {
                                                    vehicle_id, car_id, imei, datetime_gps, latitude, longitude,
                                                    speed, driver
                                                } = value;
                                                return (
                                                    <TableRow
                                                        hover
                                                        key={index}
                                                        tabIndex={-1}
                                                    >
                                                        <TableCell>{vehicle_id}</TableCell>
                                                        <TableCell>{car_id}</TableCell>
                                                        <TableCell>{imei}</TableCell>
                                                        <TableCell>{fDateTo(datetime_gps, 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                                                        <TableCell>{latitude}</TableCell>
                                                        <TableCell>{longitude}</TableCell>
                                                        <TableCell>{speed}</TableCell>
                                                        <TableCell>{String(driver)}</TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[
                                    { value: 10, label: '10' },
                                    { value: 20, label: '20' },
                                    { value: 30, label: '30' },
                                    { value: 50, label: '50' },
                                    // { value: 100, label: 100 },
                                    // { value: listLog.length, label: 'All' }
                                ]}
                                component="div"
                                count={overspeeds.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Container>
                    )}
            </MainStyle>
        </Page>
    )
}

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    overflow: 'auto',
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 10,
    paddingRight: 10
}));