import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, MenuItem, Avatar, IconButton, Typography, Divider } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//
// import account from '../../_mocks_/account';

import { useUserActions } from '../../components/_actions/userActions';
// ----------------------------------------------------------------------
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../components/_state';
import PopupDialog from '../../components/PopupDialog';
import ProfileForm from '../../components/ProfileForm';
import DashboardRoundedIcon from '@iconify/icons-ic/round-dashboard';
// ----------------------------------------------------------------------

export default function AccountPopover() {

  const account = useRecoilValue(userAtom);

  const MENU_OPTIONS = [
    {
      label: 'Dashboard',
      icon: DashboardRoundedIcon,
      linkTo: '/Dashboard',
      onClick: () => {
        handleClose()
      }
    },
    {
      label: 'Profile',
      icon: personFill,
      linkTo: '#',
      onClick: () => {
        handleClose()
        handleOpenModal()
      }
    },
    {
      label: 'Settings',
      icon: settings2Fill,
      linkTo: '#',
      onClick: () => {
        handleClose()
      }
    }
  ];



  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const userActions = useUserActions();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={null} alt="photoURL" />
        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }} />
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap style={{ textTransform: 'capitalize' }}>
            {account.firstname + ' ' + account.lastname}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ my: 1.5 }} >
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={option.onClick}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={option.icon}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />

              {option.label}
            </MenuItem>
          ))}
        </Box>

        <Box sx={{ p: 1, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => userActions.logout()} >
            Logout
          </Button>
        </Box>
      </MenuPopover>
      <PopupDialog
        title="Profile"
        open={openModal}
        handleClose={handleCloseModal}
      >
        <ProfileForm
          account={account}
          handleClose={handleCloseModal}
        />
      </PopupDialog>
    </>
  );
}
