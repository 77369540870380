import React, { useEffect, useState } from 'react'
import { Stack, IconButton, TextField, MenuItem, Switch, Divider, Typography, Dialog, DialogContent, DialogActions, DialogTitle, Box, Grid, Button, Backdrop, FormControlLabel, Checkbox, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material'
import axios from 'axios'
import { Api } from '../../components/api'
import CloseIcon from '@mui/icons-material/Close';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { fDateTo } from '../../utils/formatTime';
import { DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useSnackbar } from 'notistack';

export default function EditDeviceCerDlt({ cer, getCer, account }) {
    const { cogcode, cocode } = account

    const [open, setOpen] = useState(false)
    const [dltCer, setDltCer] = useState(cer)

    useEffect(() => {
        setDltCer({ ...cer })
    }, [cer])


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };
    const [provinceState, setProvinceState] = useState([])
    const [dltVenderState, setDltVenderState] = useState([])
    const [vehicleTypesState, setVehicleTypesState] = useState([])
    const [vehicleRegisterState, setVehicleRegisterState] = useState([])
    const [modelState, setModelState] = useState([])
    const getDataMaster = () => {

        Api.get(`/v0/gps/province?cocode=${cocode}`)
            .then(res => {
                setProvinceState(res.data)
            }
            )
        // Api.get(`/v0/gps/dlt_vender?cocode=${cocode}`)
        //     .then(res => {
        //         // setDltVenderState(res.data)
        //         const { vender_contact_name, vender_name_th, vender_add, vender_tel } = res.data

        //         setDltCer({
        //             ...dltCer,
        //             "vender_name": vender_name_th,
        //             "vender_add": vender_add,
        //             "vender_tel": vender_tel,
        //             "issueby": vender_contact_name
        //         })
        //     }
        //     )
        Api.get(`/v0/gps/vehicle_types?cocode=${cocode}`)
            .then(res => {
                setVehicleTypesState(res.data)

            }
            )
        Api.get(`/v0/gps/vehicle_register?cocode=${cocode}`)
            .then(res => {
                setVehicleRegisterState(res.data)

            }
            )
        // Api.get(`/v0/gps/model?cocode=${cocode}`)
        Api.get(`v0/gps/gpsmodels?cocode=${cocode}`)
            .then(res => {
                setModelState(res.data)
            }
            )

    }

    const onClickOpen = () => {
        getDataMaster()
        setOpen(true)
    }
    const onClickClose = () => {
        setOpen(false)
    }

    const onChangeDate = (value, name) => {
        if (String(value) === "Invalid Date") {
            setDltCer({ ...dltCer, [name]: value })
            // setFieldValue(name, value)
        } else {
            setDltCer({ ...dltCer, [name]: fDateTo(value, "yyyy-MM-dd") })
            // setFieldValue(name, fDateTo(value, "yyyy-MM-dd"))
        }
    }
    const onCancelDate = (name) => {
        setDltCer({ ...dltCer, [name]: null })
    }
    const onChange = (e) => {
        const { name, value } = e.target
        setDltCer({ ...dltCer, [name]: value })
    }
    const onSubmit = () => {
        const data = JSON.stringify(dltCer)
        Api.put(`v0/gps/certificates`, data)
            .then((res) => {
                handleSnackbar({ message: "แก้ไขข้อมูลสำเร็จ", variant: "success" })
                getCer()
                onClickClose()
            })
            .catch((err) => {
                handleSnackbar({ variant: 'error', message: "แก้ไขข้อมูไม่สำเร็จ" });
            })
    }
    const onChangeModel = (model) => {
        const { gpslicense, gpsmodel, gpstype, mcrmodel, mcrtype } = model
        setDltCer({
            ...dltCer,
            gpslicense: gpslicense.trim(),
            gpsmodel: gpsmodel.trim(),
            gpstype: gpstype.trim(),
            mcrmodel: mcrmodel.trim(),
            mcrtype: mcrtype.trim()
        })
    }
    return (
        <div>
            <IconButton onClick={onClickOpen} size="small" ><EditNoteIcon fontSize="small" /></IconButton>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={onClickClose}
            >
                <DialogTitle>
                    <Stack spacing={1} direction="row">
                        <Typography variant="h6">ข้อมูลใบรับรอง</Typography>
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClickClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box padding={"10px"}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Box>
                                <Box marginBottom={"10px"}>
                                    <Typography>ข้อมูลการติดตั้ง</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                                <Grid container spacing={1}>
                                    <Grid item md={3}>เลขที่หนังสือ</Grid>
                                    <Grid item md={9}><TextField value={dltCer.docno} name="docno" fullWidth size="small" onChange={onChange} disabled /></Grid>
                                    <Grid item md={3}>ชื่อผู้ให้บริการ</Grid>
                                    <Grid item md={9}><TextField value={dltCer.vender_name} name="vender_name" fullWidth size="small" onChange={onChange} disabled /></Grid>
                                    <Grid item md={3}>ที่อยู่</Grid>
                                    <Grid item md={9}><TextField value={dltCer.vender_add} multiline rows={3} name="vender_add" fullWidth size="small" onChange={onChange} disabled /></Grid>
                                    <Grid item md={3}>เบอร์โทรศัพท์</Grid>
                                    <Grid item md={9}><TextField value={dltCer.vender_tel} name="vender_tel" fullWidth size="small" onChange={onChange} disabled /></Grid>
                                    <Grid item md={3}>วันที่ติดตั้ง</Grid>
                                    <Grid item md={9}>
                                        <DatePicker
                                            onChange={(e) => { onChangeDate(e, "installdate") }}
                                            value={dltCer.installdate}
                                            inputFormat="dd/MM/yyyy"
                                            format="yyyy/MM/dd"
                                            renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY"  {...params} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider sx={{ marginTop: "10px" }} />
                            <Box marginTop={"10px"} marginBottom={"10px"}>
                                <Typography>เครื่องบันทึกข้อมูลการเดินทาง</Typography>
                            </Box>
                            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                            <Grid container spacing={1}>
                                <Grid item md={3}>ชนิด</Grid>
                                <Grid item md={3}>
                                    <TextField value={dltCer.gpsmodel} name="gpsmodel" fullWidth size="small" select>
                                        {
                                            modelState.map((model, index) => {
                                                return (
                                                    <MenuItem value={model.gpsmodel}
                                                        onClick={() => {
                                                            onChangeModel(model)
                                                        }}
                                                        key={`gpsmodel${index}`}
                                                    >{`${model.gpsmodel}/${model.gpstype}`}</MenuItem>
                                                )
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item md={2}>แบบ</Grid>
                                <Grid item md={4}>
                                    <TextField value={dltCer.gpstype} name="gpstype" fullWidth size="small" onChange={onChange} />
                                </Grid>

                                <Grid item md={3}>เลขที่กรมการขนส่งทางบก</Grid>
                                <Grid item md={3}><TextField value={dltCer.gpslicense} name="gpslicense" fullWidth size="small" onChange={onChange} /></Grid>
                                <Grid item md={2}>หมายเลขเครื่อง</Grid>
                                <Grid item md={4}><TextField value={dltCer.unit_id} name="unit_id" fullWidth size="small" onChange={onChange} /></Grid>


                            </Grid>
                            <Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box marginTop={"10px"} marginBottom={"10px"}>
                                    <Typography>เครื่องอ่านบัตรชนิดแถบแม่เหล็ก</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                                <Grid container spacing={1}>

                                    <Grid item md={3}>ชนิด</Grid>
                                    <Grid item md={3}>
                                        <TextField value={dltCer.mcrmodel} name="mcrmodel" fullWidth size="small" onChange={onChange} />
                                    </Grid>
                                    <Grid item md={2}>แบบ</Grid>
                                    <Grid item md={4}>
                                        <TextField value={dltCer.mcrtype} name="mcrtype" fullWidth size="small" onChange={onChange} />
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Divider sx={{ marginTop: "10px" }} />
                                <Box marginTop={"10px"} marginBottom={"10px"}>
                                    <Typography>ข้อมูลผู้ประกอบการขนส่ง</Typography>
                                </Box>
                                <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
                                <Grid container spacing={1}>
                                    <Grid item md={3}>ชื่อ</Grid>
                                    <Grid item md={9}><TextField value={dltCer.customer_name} name="customer_name" fullWidth size="small" onChange={onChange} /></Grid>
                                    <Grid item md={3}>ที่อยู่</Grid>
                                    <Grid item md={9}><TextField value={dltCer.customer_add} multiline rows={3} name="customer_add" fullWidth size="small" onChange={onChange} /></Grid>
                                    <Grid item md={3}>เบอร์โทร</Grid>
                                    <Grid item md={9}><TextField value={dltCer.customer_tel} name="customer_tel" fullWidth size="small" onChange={onChange} /></Grid>

                                    <Grid item md={3}>เลขทะเบียน</Grid>
                                    <Grid item md={3}><TextField value={dltCer.vehicle_id} name="vehicle_id" fullWidth size="small" onChange={onChange} /></Grid>
                                    <Grid item md={2}>จังหวัด</Grid>
                                    <Grid item md={4}>
                                        <TextField value={dltCer.vehicle_province.trim()} name="vehicle_province" fullWidth size="small" select onChange={onChange} >
                                            {
                                                provinceState.map((value, index) => {
                                                    return (
                                                        <MenuItem value={value.province_name} key={`province_name${index}`}>{value.province_name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>

                                    <Grid item md={3}>ยี่ห้อ</Grid>
                                    <Grid item md={3}>
                                        <TextField value={dltCer.vehicle_type} name="vehicle_type" fullWidth size="small" select onChange={onChange} >
                                            {
                                                vehicleTypesState.map((value, index) => {
                                                    return (
                                                        <MenuItem value={value.vehicle_type} key={`vehicle_type${index}`}>{value.vehicle_type}</MenuItem>
                                                    )
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item md={2}>หมายเลขคัสซี</Grid>
                                    <Grid item md={4}><TextField value={dltCer.vehicle_chassis_no} name="vehicle_chassis_no" fullWidth size="small" onChange={onChange} /></Grid>

                                    <Grid item md={3}>ออกให้ ณ วันที่</Grid>
                                    <Grid item md={3}>
                                        <DatePicker
                                            onChange={(e) => { onChangeDate(e, "installdate") }}
                                            value={dltCer.issuedate}
                                            inputFormat="dd/MM/yyyy"
                                            format="yyyy/MM/dd"
                                            renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY"  {...params} />}
                                        />
                                    </Grid>
                                    <Grid item md={2}>ผู้ลงชื่อ</Grid>
                                    <Grid item md={4}><TextField disabled value={dltCer.issueby} name="issueby" fullWidth size="small" onChange={onChange} /></Grid>

                                    <Grid item md={3}>สถานะ ยกเลิกวันที่</Grid>
                                    <Grid item md={3}>
                                        {/* <Switch inputProps={{ 'aria-label': 'สถานะ' }} onChange={handleChangeSwitch} name="revokedate" checked={dltCer.revokedate === null ? true : false} /> */}
                                        {/* <SwitchRevokedate handleChangeSwitch={handleChangeSwitch} onChangeDate={onChangeDate} value={dltCer.revokedate} name="revokedate" checked={dltCer.revokedate !== null ? true : false} /> */}
                                        <Stack spacing={1} direction="row">
                                            <DatePicker
                                                onChange={(e) => { onChangeDate(e, `revokedate`) }}
                                                value={dltCer.revokedate}
                                                inputFormat="dd/MM/yyyy"
                                                format="yyyy/MM/dd"
                                                renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY" fullWidth {...params} />}
                                            />
                                            {
                                                dltCer.revokedate && (
                                                    <Box>
                                                        <CancelOutlinedIcon sx={{ marginTop: "10px" }} color="error" fontSize="small" onClick={() => { onCancelDate(`revokedate`) }} />
                                                    </Box>
                                                )
                                            }
                                        </Stack>
                                    </Grid>
                                    <Grid item md={2}>วันที่ตรวจสภาพรถ</Grid>
                                    <Grid item md={4}>
                                        <Stack spacing={1} direction="row">
                                            <DatePicker
                                                onChange={(e) => { onChangeDate(e, "dltdate") }}
                                                value={dltCer.dltdate}
                                                inputFormat="dd/MM/yyyy"
                                                format="yyyy/MM/dd"
                                                renderInput={(params) => <TextField size="small" placeholder="DD/MM/YYYY"  {...params} fullWidth />}
                                            />
                                            {
                                                dltCer.dltdate && (
                                                    <Box>
                                                        <CancelOutlinedIcon sx={{ marginTop: "10px" }} color="error" fontSize="small" onClick={() => { onCancelDate(`dltdate`) }} />
                                                    </Box>
                                                )
                                            }
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </LocalizationProvider>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onSubmit}>บันทึก</Button>
                </DialogActions>
            </Dialog >

        </div >
    )
}