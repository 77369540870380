import * as Yup from 'yup';
import React, { useState, Fragment } from 'react';
// material
import {
    Button, Divider, TextField, Container,
    Collapse, ListItemButton, List, ListSubheader, ListItemText, Grid, Stack,
    Dialog, DialogTitle, DialogContent, DialogActions, IconButton,
    Table, TableHead, TableBody, TableRow, TableCell
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/system';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Api } from '../components/api';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import AddUserToGroup from './AddUserToGroup';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------



export default function UserGroup({ account, gorup_state }) {
    const { c_coname } = account
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('')


    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackbar = ({ variant, message }) => {
        enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <Fragment>
                    <Stack direction="row" spacing={1}>
                        <Button
                            size='small'
                            color="error"
                            variant="contained"
                            onClick={() => {
                                closeSnackbar(key)
                                onHandle()
                            }}
                        >
                            <CheckRoundedIcon fontSize="small" />
                        </Button>
                        <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                            <CloseRoundedIcon fontSize="small" />
                        </Button>
                    </Stack>
                </Fragment>
            )
        });
    };


    const onGetUserGroup = () => {
        Api.get(`Tracking/GroupCnameByUser?cname=${c_coname}&group_id=${gorup_state.group_id}`)
            .then(res => {
                setUsers(res.data)
                setOpen(true)
            })
            .catch(error => {
                setUsers([])
                setOpen(true)
            })
    }


    const onClickOpen = () => {
        onGetUserGroup()
    }
    const onClickClose = () => {
        setOpen(false)
        setSearch('')
    }

    const handleDel = (item) => {
        var data = {
            ...item
        }
        Api.delete('Tracking/GroupCnameByUser', { data: data })
            .then(res => {
                handleSnackbar({ message: "ลบข้อมูลสำเร็จ", variant: "success" })
                onGetUserGroup();
            })
            .catch(error => {
                const { message } = error
                handleSnackbar({ variant: 'error', message: "ลบข้อมูลไม่สำเร็จ" });
            })
    };
    return (
        <Box>
            <IconButton size="small" onClick={onClickOpen}>
                <FolderSharedOutlinedIcon fontSize="small" />
            </IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={onClickClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de",
                            opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>ผู้ใช้งาน</DialogTitle>
                <DialogContent dividers>
                    <Box margin="auto" padding={1} >
                        <Box>
                            <TextField size="small" placeholder="ค้นหาผู้ใช้งาน" value={search} onChange={(e) => { setSearch(e.target.value) }} autoComplete="off" />
                        </Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>userid</TableCell>
                                    <TableCell sx={{ justifyContent: "end", display: "flex" }} >
                                        <AddUserToGroup account={account} gorup_state={gorup_state} onGetUserGroup={onGetUserGroup} />
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    users.filter(v => v?.user_id?.toUpperCase().includes(search.toUpperCase())).map((usersRow, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{usersRow.user_id}</TableCell>
                                                <TableCell sx={{ justifyContent: "end", display: "flex" }}><IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการที่จะลบ?", onHandle: () => { handleDel(usersRow) } }) }}><DeleteOutlinedIcon fontSize="small" /></IconButton></TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClickClose}>ยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </Box >
    );
}
