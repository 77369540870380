import { Grid, Slide, List, ListItem, ListItemButton, ListItemText, Container, Slider, Backdrop, Typography, Box, Paper, OutlinedInput, Menu, MenuItem, TextField, Select, IconButton, Button, FormControl, InputLabel, Autocomplete } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import { Circle, MapContainer, Marker, Polyline, TileLayer, Tooltip, ZoomControl } from 'react-leaflet'
import { icon } from 'leaflet'
// import ic from '../../img/car.png'
import ic from '../../img/car_icon_focus.svg'

// components
import Page from '../../components/Page';
import { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from '@mui/system';
import LayersIcon from '@mui/icons-material/Layers';

// import { makeStyles } from '@mui/styles';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import { Api } from '../../components/api';

import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { fDateTo } from '../../utils/formatTime';

import CircularProgress from '@mui/material/CircularProgress';


import RotatedMarker from '../map/RotatedMarker';
import { useSnackbar } from 'notistack';
import ListTileLayer from '../../layouts/ListTileLayer';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@mui/styles';
import MarkerWithLabel from '../event/MarkerWithLabel';


const IconMarker = icon({
  iconUrl: ic,
  iconSize: [40, 40],
  iconAnchor: [19.5, 20],
  popupAnchor: [19.5, -20]
});
const useStyles = makeStyles(theme => ({
  SlideContainer: {
    top: 0,
    left: 0,
    margin: '5px auto',
    position: 'fixed',
    zIndex: 3
  },
  PlayContainer: {
    // position: 'absolute',
    top: 0,
    left: '5px',
    margin: '5px auto',
    width: 343,
    // right: "40%",
    // margin: '5px auto',
    position: 'fixed',
    zIndex: 2,
    justifyContent: 'center',
  },
  MenuContainer: {
    top: 0,
    left: 0,
    right: 40,
    padding: 20,
    margin: 5,
    [theme.breakpoints.up('md')]: {
      width: 320,
    },
    display: 'flex',
    position: 'fixed',
    transition: 'transform .5s ease',
    flexDirection: 'column',
    backgroundColor: 'white',
    // backgroundColor: 'whitesmoke',
    // overflowY: 'auto',
  },
  Events: {
    bottom: 56,
    left: 0,
    right: 0,
    margin: 5,
    [theme.breakpoints.up('md')]: {
      width: 320,
      height: "calc(100vh - 276px)"
    },
    height: 180,
    display: 'flex',
    position: 'fixed',
    transition: 'transform .5s ease',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  LayerControl: {
    position: 'fixed',
    display: 'block',
    backdropFilter: 'blur(10px)',
    border: "2px solid rgba(0,0,0,0.2)",
    borderRadius: 5,
    top: 90,
    right: 10,
    width: 32,
    height: 32,
    zIndex: 2,
  }
}));

const MenuBottomNav = styled(Paper)(({ theme }) => (
  {
    [theme.breakpoints.up('md')]: {
      width: 320,
      margin: 5,

    },
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    // position: 'absolute',
    position: 'fixed',

  }));
const InputStyle = styled(OutlinedInput)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));
const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
  height: '100vh',
  MozUserSelect: "none",
  WebkitUserSelect: "none",
  msUserSelect: "none",
  userSelect: "none",
  zIndex: 1,
}));

export default function Events({ userId, account }) {
  const [devices, setDevices] = useState([])
  const [loading, setLoading] = useState(true)
  const [defaultDevice, setDefaultDevice] = useState(null)
  const location = useLocation()

  useEffect(() => {
    Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
      var devices_ = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
      setDevices(devices_);
      if (location.state?.defaultValue !== undefined) {
        setDefaultDevice(devices_.find(d => d.device === location.state.defaultValue))
      }
      setLoading(loading => !loading)
    }
    ).catch(e => {
      console.log('error', e)
      setLoading(loading => !loading)
    })

    // return () => {
    //     second
    // }
  }, [userId])


  return (
    <>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
        onClick={() => { setLoading(false) }}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      {!loading && (
        <Event account={account} defaultDevice={defaultDevice} devices={devices} Loading={loading} />
      )}
    </>
  )
}
function Event({ account, defaultDevice, devices, Loading }) {
  const navigate = useNavigate()
  const [listEvents, setListEvents] = useState([]);
  const [positionEvents, setPositionEvents] = useState(null);
  const [loading, setLoading] = useState(Loading)



  // const CartoBasemaps = "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png";
  const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap")
  const center = [13.839660198254604, 100.63469639884744];
  const zoom = 6;
  const [openDropDown, setDropDown] = useState({ mapStyle: false });
  const [map, setMap] = useState(null);


  const handleOpenDropDown = (name) => {
    setDropDown({
      ...openDropDown,
      [name]: !openDropDown[name]
    });
  };

  const refMapStyle = useRef(null);
  const refDropDownMapStyle = useRef(null);

  const classes = useStyles();

  const EventsSchema = Yup.object().shape({
    deviceid: Yup.object().shape({
      device: Yup.string().required('Device is required'),
    }).nullable().required('Device is required'),
  });
  const selectedTime = new Date();
  const formik = useFormik({
    initialValues: {
      deviceid: defaultDevice,
      date_s: selectedTime.setHours(0, 0, 0),
      date_e: selectedTime.setHours(23, 59, 59),
    },
    validationSchema: EventsSchema,
    // enableReinitialize: deviceState !== null,
    onSubmit: (values, { setSubmitting }) => {
      const { deviceid, date_s, date_e } = values
      const { cocode } = account;
      Api.get('/tracking/events', {
        params: {
          // cocode: cocode,
          device: deviceid.device,
          dateStart: fDateTo(date_s, 'yyyy-MM-dd HH:mm:ss'),
          dateEnd: fDateTo(date_e, 'yyyy-MM-dd HH:mm:ss')
        }
      }).then((res) => {
        const data = res.data;
        if (data.length > 0) {
          setListEvents(data);
          setPositionEvents(null)
        } else {
          handleSnackbar({ message: 'Not found', variant: 'error' })
          setListEvents([]);
          setPositionEvents(null)
        }
        // handleSnackbar({ message: 'Not found', variant: 'error' })
        setSubmitting()
      }).catch((e) => {
        setListEvents([]);
        setPositionEvents(null)
        setSubmitting()
      });
    }
  });

  useEffect(() => {

  }, []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleSnackbar = ({ message, variant }) => {
    // const handleClick = useCallback((button) => () => {
    enqueueSnackbar(message,
      {
        autoHideDuration: 1000,
        anchorOrigin: {
          horizontal: "right",
          vertical: 'bottom'
        },
        variant: variant
      });
    // }, [enqueueSnackbar]);
  }

  const { errors, touched, isSubmitting, values, setFieldValue, handleSubmit, getFieldProps } = formik;

  const listTileLayer = ListTileLayer
  return (
    <Page>
      <Container maxWidth={false} style={{ padding: 0 }}>
        {/* ---------------------------------------------------------------------- */}
        <Box className={classes.SlideContainer}>
          {/* <Slide direction="right" in={listEvents === null} > */}
          <Box className={classes.MenuContainer}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={1} >
                      <Grid item xs={6} sm={4} md={12}>
                        <Autocomplete
                          id="deviceid"
                          name="deviceid"
                          options={
                            devices
                          }
                          defaultValue={values.deviceid}
                          size="small"
                          getOptionLabel={(option) => option.vehicle_id}
                          isOptionEqualToValue={(option, value) => option.device === value.device}
                          fullWidth
                          renderOption={(props, item) => (
                            <li {...props} key={item.key}>
                              <ListItemText>{item.vehicle_id}</ListItemText>
                            </li>
                          )}
                          filterOptions={(options, state) => {
                            return options.filter(opt => opt.vehicle_id.toUpperCase().includes(state.inputValue.toUpperCase()))
                          }}
                          onChange={(e, value) => {
                            setFieldValue("deviceid", value);
                            setListEvents([]);
                            setPositionEvents(null)
                          }}

                          renderInput={(params) => (
                            <TextField
                              label="เลือกอุปกรณ์ GPS"
                              fullWidth
                              autoComplete="off"
                              name="deviceid"
                              {...params}
                              {...getFieldProps('deviceid')}
                              error={Boolean(touched.deviceid && errors.deviceid)}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4} md={12}>
                        <MobileDateTimePicker
                          ampm={false}
                          disableFuture={true}
                          label="วันเวลาเริ่ม"
                          allowKeyboardControl={false}
                          value={values.date_s}
                          inputFormat="dd/MM/yyyy HH:mm"
                          format="dd/MM/yyyy HH:mm"
                          onChange={(val) => {
                            setFieldValue('date_s', val);
                            setListEvents([]);
                            setPositionEvents(null)
                          }}
                          renderInput={(params) => <TextField
                            size="small"
                            {...params}
                            {...getFieldProps('date_r')}
                            fullWidth
                            placeholder="DD/MM/YYYY"
                            autoComplete="off"
                            error={Boolean(touched.date_s && errors.date_s)}
                            helperText={touched.date_s && errors.date_s} />}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4} md={12}>
                        <MobileDateTimePicker
                          minDate={new Date(values.date_s)}
                          ampm={false}
                          disableFuture={true}
                          label="ถึงวันเวลา"
                          allowKeyboardControl={false}
                          value={values.date_e}
                          inputFormat="dd/MM/yyyy HH:mm"
                          format="dd/MM/yyyy HH:mm"
                          onChange={(val) => {
                            setFieldValue('date_e', val);
                            setListEvents([]);
                            setPositionEvents(null)
                          }}
                          renderInput={(params) => <TextField
                            size="small"
                            {...params}
                            {...getFieldProps('date_e')}
                            fullWidth
                            placeholder="DD/MM/YYYY"
                            autoComplete="off"
                            error={Boolean(touched.date_e && errors.date_e)}
                            helperText={touched.date_e && errors.date_e} />}
                        />
                      </Grid>
                      <Grid item xs={6} sm={12} md={12}>
                        <LoadingButton
                          fullWidth
                          // size="small"
                          type="submit"
                          variant="contained"
                          color="info"
                          loading={isSubmitting}
                        >
                          แสดง
                        </LoadingButton>
                      </Grid>
                    </Grid >
                  </Form>
                </FormikProvider>
              </LocalizationProvider>
            </Box>
          </Box>
          {/* </Slide> */}

          <Box
            className={classes.Events}
          >
            {
              listEvents.length > 0 && (
                <Box
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "20px"
                  }}
                >
                  <CSVLink style={{
                    textDecoration: "none",
                  }} filename={`${fDateTo(values.date_s, 'dd_MM_yyyy_HH:mm')}-${fDateTo(values.date_e, 'dd_MM_yyyy_HH:mm')}.csv`} data={
                    listEvents.map((value, index) => {
                      const {
                        event_id, event_name, event_value, date_gps, latitude, longitude, odometer
                      } = value;
                      return {
                        // date_gps: `=""${fDateTo(new Date(date_gps), 'dd/MM/yyyy')}""`,
                        // time_gps: `=""${fDateTo(new Date(date_gps), 'HH:mm:ss')}""`,
                        // event_id: `${event_id}`,
                        // event_name: `${event_name}`,
                        // event_value: `=""${event_value}""`,
                        // latitude: `=""${latitude}""`,
                        // longitude: `=""${longitude}""`,
                        // odometer: `=""${odometer}""`,
                        "วันที่": `=""${fDateTo(new Date(date_gps), 'dd/MM/yyyy')}""`,
                        "เวลา": `=""${fDateTo(new Date(date_gps), 'HH:mm:ss')}""`,
                        "รหัสเหตุการณ์": `=""${event_id}""`,
                        "ชื่อเหตุการณ์": `=""${event_name}""`,
                        "ข้อมูลเหตุการณ์": `=""${event_value}""`,
                        "ละติจูด": `=""${latitude}""`,
                        "ลองจิจูด": `=""${longitude}""`,
                        "มาตรวัดระยะทาง": `=""${odometer}""`,
                      }
                    }
                    )
                  }  >
                    <Button fullWidth variant="contained" >Download</Button>
                  </CSVLink>
                </Box>
              )
            }
            <List sx={{
              width: '100%', bgcolor: 'background.paper',
              overflow: "auto",
            }}>
              {
                listEvents.map((value, index) => {

                  return (
                    <ListItem disablePadding key={index}>
                      <ListItemButton selected={positionEvents === value}
                        onClick={() => {
                          map.flyTo([value.latitude, value.longitude], 18, { duration: 1 })
                          setPositionEvents(value)
                        }}

                      >
                        <ListItemText primary={
                          <Box sx={
                            {
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between"
                            }
                          }>
                            <Typography component={'span'}>
                              {
                                fDateTo(value.date_gps, "dd/MM/yy HH:mm:ss")
                              }
                            </Typography>
                          </Box>
                        }
                          secondary={
                            <>
                              <Typography component={'span'} fontSize="13px">
                                {
                                  value.event_name
                                }
                              </Typography>
                              <br />
                              <Typography component={'span'} fontSize="14px">
                                {
                                  `${value.event_value}`
                                }
                              </Typography>
                              <br />
                              <Typography component={'span'} fontSize="14px">
                                {
                                  `มาตรวัดระยะทาง : ${value.odometer}`
                                }
                              </Typography>
                            </>
                          }
                        />
                      </ListItemButton>

                    </ListItem>
                  )
                })
              }
            </List>
          </Box>
          <MenuBottomNav elevation={3}>
            <BottomNavigationBar ative="report" />
          </MenuBottomNav>
        </Box>
        {/* ---------------------------------------------------------------------- */}
        <Box className={classes.LayerControl} sx={{ justifyContent: 'center' }}
          ref={refDropDownMapStyle}
        >
          <IconButton edge="end" size="small"
            style={{ backgroundColor: 'white', borderRadius: 5, }}
            onClick={() => {
              handleOpenDropDown('mapStyle')
            }}>
            <LayersIcon fontSize="inherit" />
          </IconButton>
          <Menu
            open={openDropDown.mapStyle}
            anchorEl={refDropDownMapStyle.current}
            onClose={() => handleOpenDropDown('mapStyle')}
            PaperProps={{
              sx: { width: 200, maxWidth: '100%' }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Menu
              open={openDropDown.mapStyle}
              anchorEl={refDropDownMapStyle.current}
              onClose={() => handleOpenDropDown('mapStyle')}
              PaperProps={{
                sx: { width: 200, maxWidth: '100%' }
              }}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {
                listTileLayer.map((value, index) =>
                  <MenuItem
                    sx={{ color: 'text.default', fontWeight: selectedMapStyle === value.id ? 600 : 300 }}
                    onClick={
                      () => {
                        handleOpenDropDown('mapStyle')
                        if (refMapStyle.current) {
                          refMapStyle.current.setUrl(value.url);
                          setSelectedMapStyle(value.id)
                          localStorage.setItem('selectedMapStyle', value.id)
                        }
                      }
                    }
                    key={index}
                  >
                    {
                      value.name
                    }
                  </MenuItem>
                )
              }
            </Menu>
          </Menu>
        </Box>
        {/* ---------------------------------------------------------------------- */}
        {/* ---------------------------------------------------------------------- */}
        <MapContainerStyle
          center={center}
          zoom={zoom}
          minZoom={3}
          zoomControl={false}
          scrollWheelZoom={true}
          doubleClickZoom={false}
          attributionControl={false}
          whenCreated={setMap}

        >
          <TileLayer ref={refMapStyle}
            url={listTileLayer.find(value => value.id === selectedMapStyle).url}
            maxNativeZoom={19}
            maxZoom={22}
          />
          <ZoomControl position='topright' />
          {
            positionEvents !== null && (
              // <Circle center={[positionEvents.latitude, positionEvents.longitude]} radius={5} weight={5} />
              <MarkerWithLabel
                position={[positionEvents.latitude, positionEvents.longitude]}
                label={
                  <Box>
                    <Box>
                      วันที่: {`${fDateTo(new Date(positionEvents.date_gps), 'dd/MM/yyyy HH:mm:ss')}`}
                    </Box>
                    <Box>
                      รหัสเหตุการณ์:{`${positionEvents.event_id}`}
                    </Box>
                    <Box>
                      ชื่อเหตุการณ์: {`${positionEvents.event_name}`}
                    </Box>
                    <Box>
                      ข้อมูลเหตุการณ์: {`${positionEvents.event_value}`}
                    </Box>
                    <Box>
                      มาตรวัดระยะทาง: {`${positionEvents.odometer}`}
                    </Box>
                  </Box>
                }
              />
              // <Marker icon={markersIcon} position={[positionEvents.latitude, positionEvents.longitude]} >
              //   <Tooltip direction="top" offset={[0, -40]} opacity={1} permanent>

              //   </Tooltip>
              // </Marker>
            )
          }
          {/* <Circle center={[50.5, 30.5]} radius={5} weight={5} /> */}
          {/* {
            positionEvents !== null && (
              <RotatedMarker
                icon={IconMarker}
                position={[positionEvents.latitude, positionEvents.longitude]}
                rotationAngle={positionEvents.bearing}
                rotationOrigin="center"
              />
            )
          } */}
        </MapContainerStyle>
      </Container >
    </Page >
  );
}
