import * as Yup from 'yup';
import PropTypes from 'prop-types';
// import React, { useState } from 'react';
import React from 'react';
// material
import {
  Button,
  Stack,
  TextField,
  Container,
  // InputAdornment,
  // IconButton,
  // Grid,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Api } from './api';
// import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
// import { useUserActions } from './_actions/userActions';
// import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
// import { Icon } from '@iconify/react';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// components
import { useRecoilValue } from 'recoil';
import { userAtom } from './_state';
// import Page from '../components/Page';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

ChangePasswordForm.propTypes = {
  handleClose: PropTypes.func
};


export default function ChangePasswordForm({ handleClose }) {
  const account = useRecoilValue(userAtom);

  // const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  // const userActions = useUserActions();
  const handleSnackbar = ({ variant, message }) => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
  };
  // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string().min(6, 'must be at least 6 characters').required('Password is required'),

    // password: Yup.string()
    //   .min(6, 'must be at least 6 characters')
    //   .required('Password is required')
    //   .matches(
    //     passwordRegex,
    //     "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    //   ),
    confirmPassword: Yup.string().min(6, "must be at least 6 characters").required('Password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });
  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: async (values) => {
      const data = JSON.stringify({
        id: account.id,
        userid: localStorage.getItem('userId'),
        password: values.password
      });
      await Api.put('v0/app/resetpassword', data)
        .then(res => {
          // navigate('../')
          handleOnClose()
          handleSnackbar({ variant: 'success', message: 'success!' })
          // userActions.setAuthInfo({ dataAuth: res.data })
        }
        ).catch(error => {
          handleSnackbar({ variant: 'error', message: error.response.data.Message });
        }
        )
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;
  const handleOnClose = () => {
    handleClose();
    setErrors({});
    resetForm();
  }
  // const handleShowPassword = () => {
  //   setShowPassword((show) => !show);
  // };
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Container maxWidth={false}>
            <Stack spacing={2}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  // type={showPassword ? 'text' : 'password'}
                  type={'password'}
                  label="New Password"
                  {...getFieldProps('password')}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton onClick={handleShowPassword} edge="end">
                  //         <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   )
                  // }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />

                <TextField
                  fullWidth
                  autoComplete="off"
                  // type={showPassword ? 'text' : 'password'}
                  type={'password'}
                  label="Confirm Password"
                  {...getFieldProps('confirmPassword')}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <IconButton onClick={handleShowPassword} edge="end">
                  //         <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  //       </IconButton>
                  //     </InputAdornment>
                  //   )
                  // }}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Stack>
              <Stack direction={{ xs: 'row' }} spacing={2} justifyContent={'end'} >
                <Button
                  onClick={handleOnClose}
                  size="medium"
                  disabled={isSubmitting}
                // fullWidth
                >
                  Cancel
                </Button>
                <LoadingButton
                  // fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </Container>
        </Form>
      </FormikProvider>
    </>
  );
}
