import L from 'leaflet';
import ic from './car_icon.svg'
import ReactDOMServer from 'react-dom/server';

const iconHTML = ReactDOMServer.renderToString(
  <div style={{
    backgroundImage: `url(${ic})`
  }} >

  </div>
)

const iconCar = new L.divIcon({
  html: iconHTML,
  iconSize: [40, 40],
  iconAnchor: [19.5, 20],
  popupAnchor: [19.5, -20]
});

export { iconCar };