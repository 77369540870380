import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Switch, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import { Api } from '../../../components/api'
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { MainScopes, SCOPES_CRUD, SCOPES_DASHBOARD, SCOPES_POI, poi_permission } from '../../permission/SCOPESPAGES';
import PermissionsGate from '../../permission/PermissionsGate';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import _ from 'lodash';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';

export default function UpdateGroupPermission({ data, onGet, account, userId }) {
    const { groupid } = data
    const { c_coname } = account

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    }

    const [openGroupPermission, setOpenGroupPermission] = useState(false)
    const [permissionGroup, setPermissionGroup] = useState([])

    // useEffect(() => {
    //     Api.get(`/v0/if/Permission_g?groupid=${groupid}`)
    //         .then((res) => {
    //             setPermissionGroup(_.orderBy(res.data, ["name"], ["asc"]))
    //         })
    // }, [groupid])

    const onHandleGetGroupPermisson = () => {
        Api.get(`/v0/if/Permission_g?groupid=${groupid}&cname=${c_coname}`)
            .then((res) => {
                setPermissionGroup(_.orderBy(res.data, ["name"], ["asc"]))
            })
            .catch(err => {
                setPermissionGroup([])
            })
    }



    const handleOpenGroupPermission = () => {
        setPermissionGroup([])
        setOpenGroupPermission(true)
        onHandleGetGroupPermisson()
    }
    const handleCloseGroupPermission = () => {
        setOpenGroupPermission(false)
        setSearch('')
    }


    const [openInheritedFromUser, setOpenInheritedFromUser] = useState(false)
    const [inheritPermission, setInheritPermission] = useState([])
    const [inheritMode, setInheritMode] = useState(true)
    const [openInherit, setOpenInherit] = useState(false)
    const [inheritPost, setInheritPost] = useState({
        "appid": "sitgps",
        "groupid": groupid,
        "permission": "",
        "name": "",
    })

    useEffect(() => {
        setInheritPost({ ...inheritPost, "groupid": groupid })
    }, [groupid])


    const [inheritListPost, setInheritListPost] = useState([])


    const onGetFromAdmin = ({ opt, mode }) => {
        const { name, value, permission } = opt
        Api.get(`/v0/if/permission?userid=${userId}&permission=${permission}`)
            .then((res) => {
                const permissionInherit = res.data
                handleOpenGetFromAdmin({ opt: opt, mode: mode, permissionInherit })
            })
    }

    const handleOpenGetFromAdmin = ({ opt, mode, permissionInherit }) => {
        const { name, value, permission } = opt
        const adminp = JSON.parse(permissionInherit.value)
        const accp = JSON.parse(value)
        const permissionparse = adminp.map(admin => {
            const isfind = accp.find(v => v.id === admin.id)
            if (isfind !== undefined) {
                return { ...isfind }
            } else {
                return { ...admin, value: false }
            }
        })

        setInheritMode(mode)
        setInheritPost(inherit => {
            return {
                ...inherit, name: name, permission: permission
            }
        })
        setInheritListPost(permissionparse)
        handleCloseInheritedFromUser()
        setOpenInherit(true)
    }


    const handleOpenInherit = ({ opt, mode }) => {
        const { name, value, permission } = opt
        setInheritMode(mode)
        setInheritPost(inherit => {
            return { ...inherit, name: name, permission: permission }
        })
        setInheritListPost(JSON.parse(value))
        handleCloseInheritedFromUser()
        setOpenInherit(true)
    }

    const handleInheritedFromUser = () => {
        setOpenInheritedFromUser(true)
    }
    const handleCloseInheritedFromUser = () => {
        setOpenInheritedFromUser(false)
        setSearch('')
    }
    const onClickInheritedFromUser = () => {
        Api.get(`/v0/if/permission?userid=${userId}`)
            .then((res) => {
                setInheritPermission(_.orderBy(res.data, ["name"], ["asc"]))
                handleInheritedFromUser()
            })
    }
    const handleCloseInherit = () => {
        setOpenInherit(false)
        if (inheritMode) {
            handleInheritedFromUser()
        }
    }
    const handleChangeSwitch = (index, event) => {
        const { name, checked } = event.target
        const newData = [...inheritListPost];
        newData[index][name] = checked
        setInheritListPost(newData)

    };
    const handleOnSaveInherit = () => {
        const dataTopost = {
            "appid": inheritPost.appid,
            "permission": inheritPost.permission,
            "name": inheritPost.name,
            "groupid": inheritPost.groupid,
            // "value": JSON.stringify(inheritListPost)
            "value": JSON.stringify(inheritListPost.filter(item => item.value === true))
        }
        Api.post(`/v0/if/permission_g`, dataTopost)
            .then((res) => {
                setOpenInherit(false)
                onHandleGetGroupPermisson()
                handleClickVariant("บันทึกสำเร็จ", "success")
            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
            })
    }
    const handleDeletePermisson = (dataTopost) => {
        Api.delete(`/v0/if/permission_g`, { data: dataTopost })
            .then((res) => {
                handleClickVariant("ลบสำเร็จ", "success")
                onHandleGetGroupPermisson()
            })
            .catch((err) => {
                handleClickVariant("ลบไม่สำเร็จ", "error")
            })
    }

    const [search, setSearch] = useState('')

    const onChangeSearch = (e) => {
        const { value } = e.target
        setSearch(value)

    }
    return (
        <>
            <IconButton size="small" onClick={handleOpenGroupPermission}>
                <AdminPanelSettingsOutlinedIcon fontSize="small" />
            </IconButton>
            {/* //////////////////// */}
            <Dialog
                open={openGroupPermission}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseGroupPermission}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1}>
                        <Box sx={{ marginTop: "8px" }}>
                            กำหนดสิทธิ์
                        </Box>
                        <TextField size="small" onChange={onChangeSearch} value={search} placeholder='search' />
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseGroupPermission}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ justifyContent: "end", display: "flex", width: "100%" }}>
                        <IconButton size="small"
                            onClick={onClickInheritedFromUser}
                        >
                            <AddBoxRoundedIcon />
                        </IconButton>
                    </Box>
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>permission</TableCell>
                                    <TableCell>name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    permissionGroup.filter(v => v?.name?.toLowerCase().includes(search.toLowerCase())).map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{item.permission}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                    <Stack spacing={1} direction="row">
                                                        <IconButton size="small" onClick={() => {
                                                            // handleOpenInherit({ opt: item, mode: false })
                                                            onGetFromAdmin({ opt: item, mode: false })
                                                        }}>
                                                            <SettingsApplicationsRoundedIcon />
                                                        </IconButton>
                                                        <IconButton size="small" onClick={() => {
                                                            handleSnackWithAction({ message: "ต้องการลบ?", onHandle: () => handleDeletePermisson(item) })
                                                        }}>
                                                            <DeleteOutlineRoundedIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent>
            </Dialog >


            {/* //////////////////// */}
            <Dialog
                open={openInheritedFromUser}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseInheritedFromUser}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1}>
                        <Box sx={{ marginTop: "8px" }}>
                            เลือกสิทธิ์
                        </Box>
                        <TextField size="small" onChange={onChangeSearch} value={search} placeholder='search' />
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseInheritedFromUser}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>permission</TableCell>
                                    <TableCell>name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    inheritPermission.filter(v => v?.name?.toLowerCase().includes(search.toLowerCase())).map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>{item.permission}</TableCell>
                                                <TableCell>{item.name}</TableCell>
                                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                    <IconButton size="small" onClick={() => { handleOpenInherit({ opt: item, mode: true }) }}>
                                                        <SettingsApplicationsRoundedIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Box>
                </DialogContent >
            </Dialog >

            {/* //////////////////// */}
            <Dialog
                open={openInherit}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseInherit}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    กำหนดสิทธิ์
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseInherit}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Grid container>
                            {
                                inheritListPost.map((opt, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid item xs={8}>{opt.name}</Grid>
                                            <Grid item xs={4} justifyContent="end" display="flex">
                                                <Switch
                                                    name='value'
                                                    checked={opt.value}
                                                    onChange={(e) => handleChangeSwitch(index, e)}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            </Grid>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleOnSaveInherit}>บันทึก</Button>
                </DialogActions>
            </Dialog >

        </>
    )
}