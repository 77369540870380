import axios from "axios";
import qs from 'qs';
import { UseAesCbc } from '../_crypto/AesCbc';
import moment from 'moment';

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;

const aesCbc = UseAesCbc();

export const Apiv2 = axios.create({
    baseURL: baseURL
});
Apiv2.defaults.headers.common['Appid'] = 'sitgps';
Apiv2.interceptors.request.use(async config => {
    const token = await getTokenV2().then(function (response) {
        return response;
    });
    config.headers.Authorization = 'Bearer ' + token;
    config.data = aesCbc.encrypt(config.data)
    return config;
}, error => {
    return Promise.reject(error);
});
Apiv2.interceptors.response.use(
    response => {
        var testIfJson = response.data;
        if (typeof testIfJson == "object") {
            return { ...response }
        } else {
            var data = aesCbc.decrypt(response.data)
            return { ...response, data: data }
        }
    }
)
export const getTokenV2 = async () => {

    let token = ''
    const data = JSON.stringify({
        username: "supoj@hotmail.com",
        password: "Sit@2021",
        grant_type: "password",
        reqDtm: moment(new Date(),).format('yyyy-MM-DD HH:mm:ss'),
    });
    const config = {
        method: 'post',
        headers: {
            'Content-Type': 'text/plain'
        },
    };
    await axios.post(baseURL + '/v2/Token', aesCbc.encrypt(data), config)
        .then(response => {
            token = response.data.access_token;
            return token;
        })
        .catch(error => {
            console.log(error);
            // return '';
        });
    return token;
}