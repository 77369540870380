import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { DesktopDatePicker, TimePicker } from '@mui/lab';
import { fDateTo } from '../../utils/formatTime';
import CarsTypeDialog from './CarsTypeDialog';

export default function DialogTrip({ onHandleSubmit, pickup_date, dropoff_date }) {

    const initialForm = {
        "trip_id": "",
        "trip_type": "",
        "pickup_location": "",
        "pickup_date": pickup_date ? pickup_date : fDateTo(new Date(), "yyyy-MM-dd"),
        "pickup_time": fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " 00:00"), "yyyy-MM-dd HH:mm"),
        "dropoff_location": "",
        "dropoff_date": dropoff_date ? dropoff_date : fDateTo(new Date(), "yyyy-MM-dd"),
        "dropoff_time": fDateTo(new Date(fDateTo(new Date(), "yyyy-MM-dd") + " 00:00"), "yyyy-MM-dd HH:mm"),
        "remark": "",
        "employees": { "driver_capacity": "", "bushostess_capacity": "" },
        "trips_cars": [
            {
                "trip_car_id": `TC${Date.now()}`,
                "car_type": "",
                "car_name": "",
                "car_capacity": ""
            }
        ],
        "cars_control": [],
        "payment": {
            "payment_method": "",
            "amount_paid": 0,
            "payment_status": ""
        },
        "additional_info": {
            "notes": "",
            "special_instructions": ""
        }
    }

    const [open, setOpen] = useState(false)
    const [trip, setTrip] = useState(initialForm)

    const handleChangeTrip = (e) => {
        const { name, value } = e.target
        setTrip(e => {
            return ({ ...e, [name]: value })
        })
    }

    const handleAddCarsType = () => {
        setTrip(e => {
            return ({
                ...e, trips_cars: [...e.trips_cars, {
                    "trip_car_id": `TC${Date.now()}`,
                    "car_type": "",
                    "car_name": "",
                    "car_capacity": ""
                }]
            })
        })
    }
    const onHandleDelCarsType = (trip_car_id) => {
        setTrip(e => {
            return ({
                ...e, trips_cars: [...e.trips_cars.filter(v => v.trip_car_id !== trip_car_id)]
            })
        })
        handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
    }
    const handleTripCar = (e, tripid) => {

        const { name, value } = e.target
        const gtc = trip.trips_cars.find(v => v.trip_car_id === tripid)
        const ntc = trip.trips_cars.filter(v => v.trip_car_id !== tripid)

        setTrip(e => {
            return ({
                ...e, trips_cars: [...ntc, {
                    ...gtc,
                    [name]: value
                }]
            })
        })
    }
    const handleEmployees = (e) => {

        const { name, value } = e.target
        setTrip(e => {
            return ({
                ...e, employees: { ...e.employees, [name]: value }
            })
        })
    }

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = () => {
        setOpen(false)

        var v_cct = []
        const d = Date.now()
        trip.trips_cars.map((v, iv) => {
            for (let i = 0; i < v.car_capacity; i++) {
                v_cct.push(
                    {
                        "cars_control_id": `CT${d}-${iv}${i}`,
                        "car_type": v.car_type,
                        "car_name": v.car_name,
                        "works": []
                    }
                )
            }
            return v_cct
        })
        const trip_submit = {
            ...trip,
            pickup_date: fDateTo(trip.pickup_date, "yyyy-MM-dd"),
            pickup_time: fDateTo(trip.pickup_time, "HH:mm"),
            dropoff_time: fDateTo(trip.dropoff_time, "HH:mm"),
            dropoff_date: fDateTo(trip.dropoff_date, "yyyy-MM-dd"),
            cars_control: v_cct
        }
        onHandleSubmit(trip_submit)

        setTrip(initialForm)
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };

    const onChangeBookingDate = (name, value) => {
        setTrip(b => {
            return ({
                ...b,
                [name]: value
            })
        })
    }

    const onChangeCars = (car, tripid) => {

        const gtc = trip.trips_cars.find(v => v.trip_car_id === tripid)
        const ntc = trip.trips_cars.filter(v => v.trip_car_id !== tripid)

        setTrip(e => {
            return ({
                ...e, trips_cars: [...ntc, {
                    ...gtc,
                    ...car
                }]
            })
        })
    }
    return (
        <Box>
            <Stack direction="row" justifyContent="space-between">
                <Box>
                    <Typography>รายละเอียดการเดินทาง</Typography>
                </Box>
                <Box>
                    <Button variant="outlined" size="small" color="error" onClick={handleOpen}>
                        <AddOutlinedIcon fontSize="small" />
                    </Button>
                </Box>
            </Stack >
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>รายละเอียดการเดินทาง</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        <Stack >
                            <Grid container spacing={1} >
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='เลขที่' placeholder='เลขที่' value={trip.trip_id} name='trip_id' fullWidth onChange={handleChangeTrip} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='ประเภท' placeholder='ประเภท' value={trip.trip_type} name='trip_type' fullWidth onChange={handleChangeTrip} />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Box>รถ</Box>
                                        <Box>
                                            <Button variant="outlined" size="small" color="error" onClick={handleAddCarsType}>
                                                <AddOutlinedIcon fontSize="small" />
                                            </Button>
                                        </Box>
                                    </Stack>
                                </Grid>
                                {/* ///////////////// */}
                                {
                                    _.orderBy(trip.trips_cars, ["trip_car_id"], ["asc"]).map((trip_car, index) => {
                                        return (
                                            <Grid item xs={12} md={12} key={index} >
                                                <Grid container spacing={1} >
                                                    <Grid item xs={4} md={4}>
                                                        <TextField size="small" InputProps={{
                                                            disabled: true,
                                                            endAdornment: (
                                                                <InputAdornment position="end" >
                                                                    <CarsTypeDialog onHandle={(car) => { onChangeCars(car, trip_car.trip_car_id) }} />
                                                                </InputAdornment>
                                                            )
                                                        }} label='ประเภทรถ' placeholder='ประเภทรถ' value={trip_car.car_type} name='car_type' fullWidth onChange={(e) => handleTripCar(e, trip_car.trip_car_id)} />
                                                    </Grid>
                                                    <Grid item xs={4} md={4}>
                                                        <TextField size="small" InputProps={{ disabled: true }} label='ชื่อรถ' placeholder='ชื่อรถ' value={trip_car.car_name} name='car_name' fullWidth onChange={(e) => handleTripCar(e, trip_car.trip_car_id)} />
                                                    </Grid>
                                                    <Grid item xs={3} md={3}>
                                                        <TextField size="small" label='จำนวนรถ' placeholder='จำนวนรถ' value={trip_car.car_capacity} name='car_capacity' fullWidth onChange={(e) => handleTripCar(e, trip_car.trip_car_id)} />
                                                    </Grid>
                                                    <Grid item xs={1} md={1}>
                                                        <Button variant="contained" disabled={trip.trips_cars.length <= 1} onClick={() => { handleSnackWithAction({ message: "ต้องการลบข้อมูลรถ?", onHandle: () => { onHandleDelCarsType(trip_car.trip_car_id) } }) }} ><DeleteOutlineIcon /></Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                                {/* ///////////////// */}
                                <Grid item xs={12} md={12} sx={{ marginTop: "15px", marginBottom: "15px" }}>
                                    <Divider />
                                </Grid>

                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='จำนวนพนักงานขับรถ' placeholder='จำนวนพนักงานขับรถ' value={trip.employees.driver_capacity} name='driver_capacity' fullWidth onChange={handleEmployees} />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    <TextField size="small" label='จำนวนพนักงานต้อนรับ' placeholder='จำนวนพนักงานต้อนรับ' value={trip.employees.bushostess_capacity} name='bushostess_capacity' fullWidth onChange={handleEmployees} />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField size="small" label='สถานที่รับ' value={trip.pickup_location} name='pickup_location' fullWidth
                                        onChange={handleChangeTrip}
                                        // InputLabelProps={{ shrink: formValue.localStart ? true : false }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={() => { }}><SearchIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <DesktopDatePicker
                                        label="วันที่"
                                        inputFormat="dd/MM/yyyy"
                                        value={trip.pickup_date}
                                        name='pickup_date'
                                        onChange={(value) => { onChangeBookingDate("pickup_date", value) }}
                                        renderInput={(params) => <TextField size="small" label='วันที่' name='pickup_date' fullWidth {...params} />}
                                    />
                                    {/* <TextField size="small" label='วันที่' value={trip.pickup_date} name='pickup_date' fullWidth
                                        onChange={handleChangeTrip}
                                        placeholder='DD-MM-YYYY'
                                    /> */}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TimePicker
                                        ampm={false}
                                        label="รับเวลา"
                                        value={trip.pickup_time}
                                        name='pickup_time'
                                        onChange={(value) => { onChangeBookingDate("pickup_time", value) }}
                                        renderInput={(params) => <TextField size="small" label='รับเวลา' name='pickup_time' fullWidth {...params} />}
                                    />
                                    {/* <TextField size="small" label='รับเวลา' placeholder='HH:mm' value={trip.pickup_time} name='pickup_time' fullWidth onChange={handleChangeTrip} /> */}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField
                                        label="เที่ยวบิน"
                                        value={trip.pickup_flight}
                                        name='pickup_flight'
                                        onChange={handleChangeTrip}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField size="small" label='สถานที่ส่ง' value={trip.dropoff_location} name='dropoff_location' fullWidth
                                        onChange={handleChangeTrip}
                                        // InputLabelProps={{ shrink: formValue.localEnd ? true : false }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" >
                                                    <IconButton size="small" onClick={() => {
                                                        // handleOpenForm("dialogLocation")
                                                        // setDialogFieldName("localEnd")
                                                    }}><SearchIcon fontSize="inherit" /></IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <DesktopDatePicker
                                        label="วันที่"
                                        inputFormat="dd/MM/yyyy"
                                        value={trip.dropoff_date}
                                        name='dropoff_date'
                                        onChange={(value) => { onChangeBookingDate("dropoff_date", value) }}
                                        renderInput={(params) => <TextField size="small" label='วันที่' name='dropoff_date' fullWidth {...params} />}
                                    />
                                    {/* <TextField size="small" label='ส่งเวลา' value={trip.dropoff_date} placeholder='DD-MM-YYYY' name='dropoff_date' fullWidth onChange={handleChangeTrip} /> */}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TimePicker
                                        ampm={false}
                                        label="ส่งเวลา"
                                        value={trip.dropoff_time}
                                        name='dropoff_time'
                                        onChange={(value) => { onChangeBookingDate("dropoff_time", value) }}
                                        renderInput={(params) => <TextField size="small" label='รับเวลา' name='dropoff_time' fullWidth {...params} />}
                                    />
                                    {/* <TextField size="small" label='ส่งเวลา' value={trip.dropoff_time} placeholder='HH:mm' name='dropoff_time' fullWidth onChange={handleChangeTrip} /> */}
                                </Grid>
                                <Grid item xs={3} md={3}>
                                    <TextField
                                        label="เที่ยวบิน"
                                        value={trip.dropoff_flight}
                                        name='dropoff_flight'
                                        size="small"
                                        onChange={handleChangeTrip}
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField size="small" label='หมายเหตุ' value={trip.remark} multiline maxRows={3} minRows={3} name='remark' fullWidth onChange={handleChangeTrip} />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSave}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </Box >
    )
}
