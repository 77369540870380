import { atom } from "recoil";

export const devicesPageState = atom({
    key: 'devicesPageState',
    default: 0,
});
export const devicesPerPageState = atom({
    key: 'devicesPerPageState',
    default: 10,
});
export const devicesSearchState = atom({
    key: 'devicesSearchState',
    default: "",
});