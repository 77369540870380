import React, { useState } from 'react';
import { List, AutoSizer } from 'react-virtualized';
import HasPermission from '../../haspermission/HasPermission';
import { Box, Stack, IconButton, Button, Divider, TextField, Typography } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Virtuoso } from 'react-virtuoso';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import CreateGroup from './CreateGroup';
import EditGroup from './EditGroup';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Api } from '../../components/api';

const VirtualizedGroupedList = ({ poigeolist, onChangeSelect, poigeolistSelect, onDeletePoi, permissions, onClickMove, onClickMoveEdited, account, listGroupPoi, onGetGroups, groupPoiShow }) => {
    const groupedData = [...(new Set([...listGroupPoi.map(v => {
        const d = ([].concat(v)).concat(poigeolist.filter(x => x.group_id === v.group_id))
        return d
    }).flat(), ...poigeolist]))]
    const onSelectByGroup = ({ mode, group }) => {
        if (group !== '') {
            const data = poigeolist.filter(x => x.group_id === group).map(v => v.poi_id)
            if (mode === "select") {
                groupPoiShow("select", data)
            }
            if (mode === "remove") {
                groupPoiShow("remove", data)
            }
        } else {
            const isNoGroup = (group) => {
                const data = listGroupPoi.filter(x => x.group_id === group)
                return data.length <= 0
            }
            const data = poigeolist.filter(x => (isNoGroup(x.group_id) || x.group_id === "")).map(v => v.poi_id)
            if (mode === "select") {
                groupPoiShow("select", data)
            }
            if (mode === "remove") {
                groupPoiShow("remove", data)
            }
        }
    }

    return (
        <Box>
            <Box sx={{ marginY: "10px" }}>
                <Stack spacing={1} direction="row" justifyContent="space-between">
                    <Typography variant="h6" >Group</Typography>
                    <CreateGroup onGetGroups={onGetGroups} account={account} />
                </Stack>
            </Box>
            <Virtuoso
                style={{ height: `calc(100vh - 164px)` }}
                data={groupedData}
                itemContent={(index, data) => {
                    return (
                        <RowConContent
                            data={data}
                            onClickMove={onClickMove}
                            permissions={permissions}
                            onClickMoveEdited={onClickMoveEdited}
                            poigeolistSelect={poigeolistSelect}
                            onChangeSelect={onChangeSelect}
                            onDeletePoi={onDeletePoi}
                            onGetGroups={onGetGroups}
                            account={account}
                            onSelectByGroup={onSelectByGroup}
                        />
                    )
                }
                }
            />
        </Box>
    );
};

export default VirtualizedGroupedList;


const RowConContent = ({
    data,
    onClickMove,
    permissions,
    onClickMoveEdited,
    poigeolistSelect,
    onChangeSelect,
    onDeletePoi,
    onGetGroups,
    account,
    onSelectByGroup
}) => {
    const numberOfKeys = Object.keys(data).length
    const isGroupHeader = numberOfKeys === 2
    const { c_coname } = account
    const [view, setView] = useState(false)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: "variant", anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    }

    const onDeleteGroup = (data) => {
        const req = { ...data, cname: c_coname }
        Api.delete(`/Tracking/poigeocodegroup`, { data: req })
            .then(res => {
                handleClickVariant("ลบข้อมูลสำเร็จ", "success")
                onGetGroups()
            }
            )
            .catch(err => {
                handleClickVariant("ลบข้อมูลไม่สำเร็จ", "error")
            })
    }



    return (
        <Box>
            {isGroupHeader ? (
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Box sx={{ width: `calc(100% - 60px)`, maxWidth: `calc(100% - 60px)`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", fontWeight: "bold", fontSize: "16px", cursor: "pointer" }}>
                        {data.name}
                    </Box>
                    <Box>
                        <Stack direction="row" spacing={1} justifyContent="end">
                            {
                                view ?
                                    <IconButton size="small" onClick={() => {
                                        setView(false)
                                        onSelectByGroup({ mode: "remove", group: data.group_id })
                                    }} >
                                        <VisibilityOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    :
                                    <IconButton size="small" onClick={() => {
                                        setView(true)
                                        onSelectByGroup({ mode: "select", group: data.group_id })
                                    }} >
                                        <VisibilityOffOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                            }
                            {
                                data?.group_id !== '' && (
                                    <>
                                        <HasPermission permissions={permissions} scope={["update"]}>
                                            <EditGroup onGetGroups={onGetGroups} data={data} account={account} />
                                        </HasPermission>
                                        <HasPermission permissions={permissions} scope={["delete"]}>
                                            <IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการลบ?", onHandle: () => onDeleteGroup(data) }) }}>
                                                <DeleteForeverOutlinedIcon fontSize="inherit" />
                                            </IconButton>
                                        </HasPermission>
                                    </>
                                )
                            }
                        </Stack>
                    </Box>
                </Stack>
            ) :
                (
                    <>
                        <Stack direction="row" spacing={1}>
                            <Box sx={{ width: `calc(100% - 60px)`, maxWidth: `calc(100% - 60px)`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", cursor: "pointer" }}

                                onClick={() => {
                                    onClickMove(data.poi_id)
                                }}
                            >
                                {data.poi_name}
                            </Box>
                            <Box>
                                <Stack direction="row" spacing={1}>
                                    <HasPermission permissions={permissions} scope={["update"]}>
                                        <IconButton size="small" onClick={() => { onClickMoveEdited(data.poi_id) }} >
                                            <EditLocationIcon fontSize="inherit" />
                                        </IconButton>
                                    </HasPermission>
                                    {
                                        poigeolistSelect.indexOf(data.poi_id) >= 0 ?
                                            <IconButton size="small" onClick={() => { onChangeSelect({ mode: "remove", select: data.poi_id }) }} ><VisibilityOutlinedIcon fontSize="inherit" /></IconButton>
                                            :
                                            <IconButton size="small" onClick={() => { onChangeSelect({ mode: "select", select: data.poi_id }) }} ><VisibilityOffOutlinedIcon fontSize="inherit" /></IconButton>
                                    }
                                    <HasPermission permissions={permissions} scope={["delete"]}>
                                        <IconButton size="small" onClick={() => { onDeletePoi(data.poi_id) }}><DeleteForeverOutlinedIcon fontSize="inherit" /></IconButton>
                                    </HasPermission>
                                </Stack>
                            </Box>
                        </Stack>
                    </>
                )
            }
        </Box>
    )
}
