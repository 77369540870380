import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// material
import {
  Button,
  Stack,
  TextField,
  Container,
  Avatar,
  // IconButton,
  // Badge,
  // styled,
  // Icon,
  // styled,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { Api } from './api/';
// import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useUserActions } from './_actions/userActions';
// import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import KeyIcon from '@mui/icons-material/Key';
import PopupDialog from './PopupDialog';
import ChangePasswordForm from './ChangePasswordForm';
// components
// import Page from '../components/Page';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

ProfileForm.propTypes = {
  open: PropTypes.bool,
  filterName: PropTypes.string,
  handleClose: PropTypes.func
};


export default function ProfileForm({ handleClose, account }) {
  const [openModal, setOpenModal] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const userActions = useUserActions();

  // const navigate = useNavigate();
  // const phoneRegExp = /((\+66|0)(\d{1,2}-\d{3}-\d{3,4}))/;
  const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;
  const handleSnackbar = ({ variant, message }) => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
  };
  const ProfileSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('First Name is required'),
    // email: Yup.string().email().required('Email is required'),
    // phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('Phone number is required'),
    // email: Yup.string().email().notRequired(),
    // phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').notRequired(),
  });
  const formik = useFormik({
    initialValues: {
      firstName: account.firstname,
      lastName: account.lastname,
      email: account.email,
      phone: account.phone,
    },
    validationSchema: ProfileSchema,
    onSubmit: async (values) => {
      const data = JSON.stringify({
        ...account,
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
        phone: values.phone,
      });
      await Api.put('v0/if/Contact', data)
        .then(res => {
          // navigate('../')
          handleOnClose()
          handleSnackbar({ variant: 'success', message: 'success!' })
          userActions.setAuthInfo({ dataAuth: res.data })
        }
        ).catch(error => {
          handleSnackbar({ variant: 'error', message: error.response.data.Message });
        }
        )
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;
  const handleOnClose = () => {
    handleClose();
    setErrors({});
    resetForm();
  }
  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
          <Container maxWidth={false}>
            <Stack spacing={2}>
              <Stack spacing={2} justifyContent='center' direction={{ xs: 'row' }} >
                <Avatar sx={{ width: 90, height: 90 }} />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="First name"
                  {...getFieldProps('firstName')}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  label="Last name"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <TextField
                  fullWidth
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  fullWidth
                  label="Phone"
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Button
                  onClick={handleOpenModal}
                  size="medium"
                  startIcon={<KeyIcon />}
                // fullWidth
                >
                  Change Password
                </Button>
              </Stack>
              <Stack direction={{ xs: 'row' }} spacing={2} justifyContent={'end'} >
                <Button
                  onClick={handleOnClose}
                  size="medium"
                  disabled={isSubmitting}
                // fullWidth
                >
                  Cancel
                </Button>
                <LoadingButton
                  // fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </Container>
        </Form>
      </FormikProvider>

      <PopupDialog
        title="Change Password"
        open={openModal}
        handleClose={handleCloseModal}
      >
        <ChangePasswordForm
          account={account}
          handleClose={handleCloseModal}
        />
      </PopupDialog>
    </>
  );
}
