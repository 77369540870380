import React from 'react'

export default function HasPermission({ children,
    RenderError,
    scope,
    permissions
}) {

    function useHasPermission({ scopes }) {
        // const fetchdata = null
        const fetchdata = permissions

        if (!fetchdata) {
            return false
        }
        // console.log('value', JSON.parse(fetchdata.value))

        const list = [...JSON.parse(fetchdata.value)]

        const userPermissions = {
            [fetchdata.permissions]: JSON.parse(fetchdata.value)
        }
        if (scopes) {
            let hasPermission = false
            scopes.forEach((scope) => {
                // const check = list.find(f => f.id === scope.split('.')[1])
                const check = list.find(f => f.id === scope)
                if (check?.value) {
                    hasPermission = true
                }
            });
            return hasPermission
        }
    }
    const hasPermission = useHasPermission({ scopes: scope });

    if (!hasPermission) return RenderError ? <RenderError /> : <></>
    return <>{children}</>;
}