import React, { useEffect, useState } from 'react'
import { Api } from '../../../components/api'
import { Box, IconButton, Grid, Stack, Button, MenuItem, TextField, Dialog, DialogTitle, DialogContent, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function GroupPermissionUsers({ groupid, account }) {
    const { cocode, c_coname, cogcode } = account;

    const [openGroupUsers, setOpenGroupUsers] = useState(false)
    const [openAccounts, setOpenAccounts] = useState(false)
    const [usersGroup, setUsersGroup] = useState([])
    const [usersAccounts, setUsersAccounts] = useState([])
    const [contypes, setContypes] = useState([])
    const [isContype, setIsContype] = useState('21')
    const [search, setSearch] = useState('')


    useEffect(() => {
        if (openAccounts) {
            const { cocode, cogcode, c_coname, contype } = account;
            const pCanme = (contype === "00" || contype === "01") ? `` : `&cname=${c_coname}`
            Api.get(`v0/if/ContactByContype?contype=${isContype}${pCanme}`)
                .then(res => {
                    var listAccounts = res.data;
                    setUsersAccounts(listAccounts);
                })
                .catch(e => {
                    console.log('error', e)
                })
        }
    }, [openAccounts, isContype])

    const handleOpenAccounts = () => {
        setOpenAccounts(true)
        getContypes()
    }
    const handleCloseAccounts = () => {
        setOpenAccounts(false)
    }

    const handleOpenGroupUsers = () => {
        onGetUserGroups({ groupid: groupid })

    }
    const handleCloseGroupUsers = () => {
        setOpenGroupUsers(false)
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, data, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            // style: { bgcolor: "whitesmoke !important" },
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };


    const onGetUserGroups = ({ groupid }) => {
        Api.get(`/v0/if/GPermission_getuser?cname=${c_coname}&groupid=${groupid}`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setOpenGroupUsers(true)
                setUsersGroup(res.data)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }


    const onPostUserGroups = ({ groupid, userid }) => {
        const data = {
            "cogcode": cogcode,
            "cocode": cocode,
            "c_coname": c_coname,
            "groupid": groupid,
            "userid": userid
        }
        Api.post(`/v0/if/GPermission_adduser`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                onGetUserGroups({ groupid: groupid })
                handleClickVariant("เพิ่มข้อมูลเสร็จสิ้น", "success")
                handleCloseAccounts()
            })
            .catch((err) => {
                handleClickVariant("ไม่สําเร็จ/ข้อมูลซ้ำ", "error")
            })
    }
    const onDelUserGroups = ({ user }) => {
        const data = user
        Api.post(`/v0/if/GPermission_deluser`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                onGetUserGroups({ groupid: user?.groupid })
                handleClickVariant("ลบข้อมูลเสร็จสิ้น", "success")
                // setgroups(res.data)
                // console.log('res', res)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }


    const onGetUserID = ({ id }) => {
        Api.get(`/v0/app/GetUserID?id=${id}`)
            .then((res) => {
                const { userid } = res.data
                onPostUserGroups({ groupid: groupid, userid: userid })
            })
            .catch((err) => {
                handleClickVariant("ไม่พบ บัญชีเข้าสู่ระบบ", "error")
            })
    }

    const getContypes = () => {
        const { cocode, cogcode, c_coname } = account;
        Api.get(`/v0/if/Contypes`, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                setContypes(res.data)
            })
    }

    return (
        <>
            <IconButton size="small" onClick={() => { handleOpenGroupUsers({ groupid: groupid }) }}>
                <ManageAccountsRoundedIcon fontSize="small" />
            </IconButton>

            <Dialog
                open={openGroupUsers}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseGroupUsers}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    ผู้ใช้งานในกลุ่ม
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseGroupUsers}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ชื่อผู้ใช้งาน</TableCell>
                                <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                    <IconButton size="small" onClick={() => {
                                        handleOpenAccounts()
                                    }}>
                                        <AddBoxRoundedIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                usersGroup.map((user, index) => {
                                    return (
                                        <TableRow key={`user${index}`}>
                                            <TableCell>{user.userid}</TableCell>
                                            <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                <IconButton size="small" onClick={() => { handleSnackWithAction({ message: "ต้องการลบ?", onHandle: () => { onDelUserGroups({ user: user }) } }) }}>
                                                    <DeleteOutlineIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openAccounts}
                maxWidth="md"
                fullWidth={true}
                onClose={handleCloseAccounts}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1}>
                        {/* {
                            (account?.contype === "00" || account?.contype === "10") && ( */}
                        <TextField size="small" value={isContype} onChange={(e) => { setIsContype(e.target.value) }} select >

                            {/* <MenuItem key={`contypes${"00"}`} value={"01"} >{`ผู้ดูแลระบบ GPS`}</MenuItem> */}

                            {
                                contypes.map((value, index) => {
                                    return (
                                        <MenuItem key={`contypes${index}`} value={value.contype} >{value.contypename}</MenuItem>
                                    )
                                })
                            }
                        </TextField>
                        <TextField size="small" placeholder="ค้นหารายชื่อผู้ใช้งาน" value={search} onChange={(e) => { setSearch(e.target.value) }} />
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseAccounts}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ชื่อ</TableCell>
                                <TableCell>นามสกุล</TableCell>
                                <TableCell>อีเมล</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                usersAccounts.filter(v => v?.firstname?.toUpperCase().includes(search.toUpperCase())).map((user, index) => {
                                    return (
                                        <TableRow key={`user${index}`}>
                                            <TableCell>{user.firstname}</TableCell>
                                            <TableCell>{user.lastname}</TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell sx={{ justifyContent: "end", display: "flex" }}>
                                                <IconButton size="small" onClick={() => {
                                                    onGetUserID({ id: user.id })
                                                }}>
                                                    <AddBoxRoundedIcon fontSize="small" />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    )
}
