import { Box, Typography, Card, Button, TextField, useMediaQuery, Tab, Menu, Tabs, InputAdornment, IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { Api } from '../../components/api'

import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import TaskIcon from '@mui/icons-material/Task';

import lodash from 'lodash';
import { useDashboardByUser } from './RefreshInterval'
import { GroupedVirtuoso, Virtuoso } from 'react-virtuoso';
import { useTheme } from '@mui/styles';

import { styled, alpha } from '@mui/material/styles';
import { Bolt, FileDownloadOutlined, ShareLocationOutlined } from '@mui/icons-material';
import VerticalProgress from './VerticalProgress';
// import SortIcon from '@mui/icons-material/Sort';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import _ from 'lodash';
import AreaChart from './AreaChart';

export default function ContainerDash({ userId, setOpenBackdrop, handleSubscribe, handleClickDashboard }) {
    // useEffect(() => {
    //     setOpenBackdrop(true)
    // }, [])

    const [dashBoardView, setDashBoardView] = useState(localStorage.getItem("dashBoardView") || "dash")

    const [search, setSearch] = useState('')
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const [findPage, setFindPage] = React.useState(0);
    // const [tabPage, setTabPage] = React.useState('');


    const getDashBoardsUrls = (view) => {

        if (view === "dash") {
            return `/Tracking/DashboardByUser?userid=${userId}`
        }
        if (view === "poi") {
            return `/Tracking/PoiDashboard?userid=${userId}`
        }
        if (view === "power") {
            return `/Tracking/PowerDashboard?userid=${userId}`
        }
        if (view === "work") {
            return `/Tracking/WorkDashboard?userid=${userId}`
        }

        return ''

    }

    // urls:
    //         dashBoardView === "dash" ? `/Tracking/DashboardByUser?userid=${userId}` :
    //             dashBoardView === "poi" ? `/Tracking/PoiDashboard?userid=${userId}` :
    //                 `/Tracking/PowerDashboard?userid=${userId}`
    //     ,


    const { dashBoards, isCancel } = useDashboardByUser({
        urls: getDashBoardsUrls(dashBoardView),
        setBackdrop: setOpenBackdrop,
        options: { refreshInterval: 5000, revalidateOnFocus: false },
    })


    const handleClick = (event) => {
        isCancel()
        const up = event
        setDashBoardView(up)
        localStorage.setItem("dashBoardView", String(up))
        ScrollToTop()
        setFindPage(0)
        // setOpenBackdrop(true)
    };

    const handleChangeFindPage = (event) => {
        setFindPage(event);
        ScrollToTop()
        handleClickCloseMenu()
    };

    const ref = useRef(null)
    const dashs = lodash.sortBy(dashBoards[findPage]?.cnameDashboard, 'name')?.filter(d => d.name.toUpperCase().includes(search.toUpperCase()))

    const ScrollToTop = () => {
        ref.current.scrollToIndex({
            index: 0,
            align: "start",
            behavior: "auto"
        });
    }
    const [anchorEl, setAnchorEl] = useState(null);


    const handleClickCloseMenu = () => {
        setAnchorEl(null);
        // setSearchGroup("")
    };
    const openMenu = Boolean(anchorEl);

    const handleClickOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        // setSearchGroup("")
    };

    const [sortBy, setSortBy] = useState("asc");
    const [charging, setCharging] = useState(false);


    return (
        <Box>
            <Box sx={{
                margin: "5px",
                userSelect: "none"
            }}>
                <Button
                    onClick={() => { handleClick("dash") }}
                    variant={dashBoardView === "dash" ? "contained" : "outlined"}
                    startIcon={<DashboardIcon />}
                    sx={{
                        margin: "5px"
                    }}
                >
                    Dashboard
                </Button>
                <Button
                    onClick={() => { handleClick("poi") }}
                    variant={dashBoardView === "poi" ? "contained" : "outlined"}
                    startIcon={<PlaceOutlinedIcon />} sx={{
                        margin: "5px"
                    }}>
                    Stations
                </Button>

                <Button
                    onClick={() => { handleClick("power") }}
                    variant={dashBoardView === "power" ? "contained" : "outlined"}
                    startIcon={<Bolt />} sx={{
                        margin: "5px"
                    }}>
                    Power
                </Button>


                <Button
                    onClick={() => { handleClick("work") }}
                    variant={dashBoardView === "work" ? "contained" : "outlined"}
                    startIcon={<TaskIcon />} sx={{
                        margin: "5px"
                    }}>
                    Work
                </Button>

                {/* <Button
                    onClick={() => { handleClick("tracking") }}
                    variant={dashBoardView === "tracking" ? "contained" : "outlined"}
                    startIcon={<ShareLocationOutlined />} sx={{
                        margin: "5px"
                    }}>
                    Tracking
                </Button> */}
                {/* {
                    dashBoardView === "power" && (
                        <TextField
                            sx={{
                                margin: "5px",
                            }}
                            placeholder={"search"}
                            onChange={({ target: { value } }) => {
                                setSearch(value)
                            }}
                            // autoFocus
                            size="small"
                            label={"search"}
                        />
                    )
                } */}
                <TextField
                    sx={{
                        margin: "5px",
                    }}
                    placeholder={dashBoards[findPage]?.cname === '' ? "Custom" : dashBoards[findPage]?.cname}
                    onChange={({ target: { value } }) => {
                        setSearch(value)
                    }}
                    // autoFocus
                    size="small"
                    label={dashBoards[findPage]?.cname === '' ? "Custom" : dashBoards[findPage]?.cname}
                    InputProps={{
                        style: {
                            height: "36px",
                            width: "200px"
                        },
                        endAdornment: (<InputAdornment position='end' >
                            <IconButton
                                edge="end"
                                onClick={handleClickOpenMenu}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-haspopup="true"
                            >
                                <FilterListIcon />
                            </IconButton>
                        </InputAdornment>)
                    }}
                />
                {
                    dashBoardView === "power" && (
                        <>
                            <IconButton size="medium"
                                onClick={() => {
                                    const sortByUp = sortBy === "asc" ? "desc" : "asc"
                                    setSortBy(sortByUp)
                                }}
                            >
                                {
                                    sortBy === "asc" ?
                                        <SignalCellularAltIcon fontSize="medium" />
                                        :
                                        <SignalCellularAltIcon fontSize="medium" style={{ transform: "scaleX(-1)" }} />
                                }

                            </IconButton>
                            <IconButton size="medium"
                                onClick={() => {
                                    setCharging(!charging)
                                }}
                            >
                                {
                                    charging ?
                                        <Bolt fontSize="medium" color="success" />
                                        :
                                        <Bolt fontSize="medium" color="disabled" />
                                }

                            </IconButton>
                        </>
                    )
                }
                <StyledMenu
                    id="group-menu"
                    MenuListProps={{
                        'aria-labelledby': 'group-button'
                    }}
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClickCloseMenu}
                >
                    <nav aria-label="main">
                        {
                            dashBoards.map((_, indexTab) =>

                                <ListItem
                                    disablePadding
                                    key={"TabPage" + indexTab}
                                >
                                    <ListItemButton
                                        onClick={() => handleChangeFindPage(indexTab)}
                                        selected={findPage === indexTab}

                                    >
                                        <ListItemText primary={_.cname === '' ? "Custom" : _.cname} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        }
                    </nav>
                </StyledMenu>
                {/* <Box>
                    <TextField placeholder="search"
                        onChange={({ target: { value } }) => {
                            setSearch(value)
                        }}
                        fullWidth
                        size="small"
                    />
                </Box> */}
            </Box>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabPage}
                        onChange={handleChangeTabPage}
                        style={{
                            minHeight: "24px",
                            height: "24px"
                        }}
                        variant="scrollable"
                    >
                        {
                            dashBoards.map((_, indexTab) =>
                                <Tab
                                    label={_.cname !== '' ? _.cname : "Custom"}
                                    style={{
                                        minHeight: "24px",
                                        height: "24px"
                                    }}
                                    key={`TabPage${indexTab}`}
                                />
                            )
                        }
                    </Tabs>
                </Box> */}
                <div role="tabpanel">
                    {
                        dashBoardView !== "tracking" && (
                            <Virtuoso
                                ref={ref}
                                style={{ height: mobile ? "calc(100vh - 185px)" : "calc(100vh - 70px)" }}
                                data={dashs}
                                itemContent={(index, dash) => (
                                    <Card
                                        sx={{
                                            borderRadius: "0px",
                                            marginBottom: "10px",
                                            marginLeft: "5px",
                                            marginRight: "5px"
                                        }}
                                        key={"Card_charts" + index}
                                    >
                                        <Box
                                            sx={{
                                                paddingLeft: "20px",
                                                paddingTop: "10px",
                                                justifyContent: "space-between",
                                                display: 'flex'
                                            }}
                                        >
                                            <Typography
                                                noWrap
                                                sx={{
                                                    textOverflow: "unset"
                                                }}
                                                variant="h6"
                                            >
                                                {dash.name}
                                            </Typography>
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                overflowX: "auto"
                                            }}
                                        >
                                            {/* <Grid container > */}
                                            {
                                                _.orderBy(

                                                    charging ?
                                                        _.filter(dash.widgets, (_widgets) => {
                                                            return _widgets.data[0].charging_status !== 0 || _widgets.type === "singledonut"
                                                        })
                                                        :
                                                        dash.widgets

                                                    , ['type', 'data[0].value'], ['desc', sortBy])


                                                    .map((v, i) => {


                                                        // _.orderBy(dash.widgets, ['data[0].value'], [sortBy]).map((v, i) => {
                                                        return (

                                                            <Box sx={{
                                                                padding: "10px",
                                                                width: "125px",
                                                                minWidth: "125px",
                                                                maxWidth: "125px",
                                                            }}
                                                                key={"chart" + i}
                                                            >
                                                                {
                                                                    v.type === "donut" && (
                                                                        <DonutChart dataChart={v} />
                                                                    )
                                                                }
                                                                {
                                                                    v.type === "line" && (
                                                                        <LineChart dataChart={v} />
                                                                    )
                                                                }
                                                                {
                                                                    v.type === "singledonut" && (
                                                                        <Box
                                                                            sx={{
                                                                                cursor: v.type === "singledonut" ? v.data[0].device.length > 0 ? "pointer" : "default" : "default"
                                                                            }}
                                                                            onClick={() => {
                                                                                if (v.data[0].device.length > 0) {
                                                                                    handleSubscribe({ subs: v.data[0].device, groupId: null })
                                                                                    // handleClickDashboard()
                                                                                }
                                                                            }}

                                                                        >
                                                                            <SingleDonutChart
                                                                                dataChart={v}
                                                                                userId={userId}
                                                                                // getDevicesByGroups={getDevicesByGroups}
                                                                                device={v.data[0].device}
                                                                                dataLabels={v.data[0].data[0]}
                                                                                color={(v.title === "Gpslost" && v.data[0].data[0] > 0) ? "#FF5733" : (v.title === "Offline" && v.data[0].data[0] > 0) ? "#FF0000" : "#008ffbd9"}
                                                                            />
                                                                        </Box>
                                                                    )
                                                                }
                                                                {
                                                                    v.type === "barchart" && (
                                                                        <Box
                                                                            sx={{
                                                                                // flexGrow: 1,
                                                                                height: 200,
                                                                                // gap: 1,
                                                                                display: "flex",
                                                                                p: 2
                                                                            }}
                                                                        >
                                                                            <VerticalProgress value={v.data[0].value} name={v.title} charge={v.data[0].charging_status} />
                                                                        </Box>
                                                                    )
                                                                }
                                                                {
                                                                    v.type === "areachart" && (
                                                                        <Box
                                                                            sx={{
                                                                                // flexGrow: 1,
                                                                                height: 200,
                                                                                // gap: 1,
                                                                                display: "flex",
                                                                                p: 2
                                                                            }}
                                                                        >
                                                                            <AreaChart series={[]} categories={[]} />
                                                                        </Box>
                                                                    )
                                                                }

                                                            </Box>
                                                            // </Grid>
                                                        )
                                                    })
                                            }
                                            {/* </Grid> */}
                                        </Box>
                                    </Card>
                                )}
                            />
                        )
                    }
                    {
                        dashBoardView === "tracking" && (
                            <Box>
                                <Box>tracking <Button size="small" variant="contained" onClick={() => { console.log('Download') }} > Download <FileDownloadOutlined fontSize="small" /></Button></Box>
                                <Virtuoso
                                    ref={ref}
                                    style={{ height: mobile ? "calc(100vh - 185px)" : "calc(100vh - 85px)" }}
                                    data={[{ data: "16-6260", name: "1-2E(11)" }, { data: "16-6261", name: "1-2E(12)" }]}
                                    itemContent={(index, item) => (
                                        <Card style={{ margin: "10px", padding: "10px" }} key={`CardTracking${index}`}>
                                            {`ชื่อ : ${item.name}`} <br />
                                            {`ทะเบียน : ${item.data}`} <br />
                                            {`เวลา : 09-06-23 13:57:05`} <br />
                                            {`สถานที่ : อู่ไทยสมายล์บัส สาขารังสิต`}
                                        </Card>
                                    )}
                                />
                            </Box>
                        )
                    }
                    {/* <Box
                        sx={{
                            // flexGrow: 1,
                            height: 200,
                            // gap: 1,
                            display: "flex",
                            p: 2
                        }}
                    >
                        <AreaChart
                            series={[
                                { name: "A", data: [50, 69, 90, 55, 52] },
                                { name: "B", data: [53, 65, 96, 53, 59] },
                                { name: "C", data: [70, 59, 90, 25, 42] }
                            ]}
                            categories={["A", "B", "C", "D", "E"]} />
                    </Box> */}
                </div>
            </Box>
        </Box >
    )
}








const DonutChart = ({ dataChart }) => {
    const series = dataChart.data.map(v => v.data[0])
    const label = dataChart.data.map(v => v.name)
    const options = {
        // colors: ['#F44336', '#E91E63', '#9C27B0'],
        chart: {
            // type: 'area',
            // events: {
            //     dataPointSelection(event, chartContext, config) {
            //         alert(label[config.dataPointIndex] + ':' + series[config.dataPointIndex])
            //     },
            //     click: function (event) {
            //         if ([...event.target.classList].includes('apexcharts-title-text')) {
            //             alert(dataChart.title)
            //         }
            //     }
            // },
        },
        title: {
            floating: false,
            text: dataChart.title,
            align: 'left',
            style: {
                fontSize: '18px'
            }
        },
        labels: label,
        stroke: {
            width: 1
        },
        dataLabels: {
            enabled: false,
            // enabled: false,
            formatter: function (val, opt) {
                // return val
                // return fShortenNumber(series[opt.seriesIndex])
                return series[opt.seriesIndex]
            },
        },

        legend: {
            // show: false,
            // floating: true
        },
        plotOptions: {
            pie: {
                // size: 200,
                donut: {
                    size: '65%',
                    labels: {
                        show: true,
                        // show: false,
                        name: {
                            show: true,
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            color: undefined,
                            offsetY: -10,
                            formatter: function (val) {
                                return val
                            }
                        },
                        value: {
                            show: true,
                            fontSize: '18px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 400,
                            color: undefined,
                            offsetY: 16,
                            formatter: function (val) {
                                return val
                            }
                        },
                        total: {
                            show: true,
                            // label :"-",
                            fontSize: '22px',
                            fontFamily: 'Helvetica, Arial, sans-serif',
                            fontWeight: 600,
                            color: "#000080",
                            formatter: function (w) {
                                return w.globals.seriesTotals.reduce((a, b) => {
                                    return a + b
                                }, 0)
                            }
                        }
                    }
                }
            }
        }
    }


    return (
        <ReactApexChart options={options} series={series} type="donut" height={280} />
    )
}

const LineChart = ({ dataChart }) => {

    const series = dataChart.data
    const options = {
        title: {
            floating: false,
            text: dataChart.title,
            align: 'left',
            style: {
                fontSize: '18px'
            }
        },
        stroke: {
            curve: 'smooth',
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            // size: [4, 7],
            // onClick: function (event) {
            //     console.log('e', event)
            // }
        },
        chart: {

            // events: {
            //     markerClick: function (event, chartContext, { seriesIndex, dataPointIndex, config }) {
            //         alert(dataChart.data[seriesIndex].name + ":" + dataChart.data[seriesIndex].data[dataPointIndex])
            //         // console.log('dataPointIndex', dataPointIndex)
            //     },
            //     click: function (event) {
            //         if ([...event.target.classList].includes('apexcharts-title-text')) {

            //             alert(dataChart.title)
            //         }
            //     }
            // },
            toolbar: {
                show: true,
                tools: {
                    download: false
                }
            }
        },
        tooltip: {
            followCursor: false,
            x: {
                show: false
            },
            onDatasetHover: {
                highlightDataSeries: false
            }
        },
        xaxis: {
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            tooltip: {
                enabled: false
            }
        },
        // tooltip: {
        //     custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        //         return '<div class="arrow_box">' +
        //             '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
        //             '</div>'
        //     }
        // }
    }

    return (
        <ReactApexChart options={options} series={series} type="line" height={280} />
    )
}


const SingleDonutChart = ({ dataChart, dataLabels, color }) => {


    const series = [[dataLabels]]
    const label = dataChart.data.map(v => v.name)
    const options = {
        title: {
            floating: true,
            text: dataChart.title,
            align: 'center',
            offsetY: 125,
            style: {
                fontSize: '16px',
                cursor: "pointer"
            }
        },
        labels: label,
        stroke: {
            width: 0
        },
        dataLabels: {
            enabled: false,
        },

        legend: {
            show: false,
            floating: false

        },
        colors: [color],
        plotOptions: {
            radialBar: {
                track: {
                    background: color,
                    opacity: 1,
                    dropShadow: {
                        enabled: true,
                        blur: 0,
                        opacity: 0.4
                    }
                },
                offsetY: 0,
                startAngle: 0,
                endAngle: 360,
                hollow: {
                    margin: 5,
                    size: '60%',
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: true,
                        title: "",
                    },
                    value: {
                        show: true,
                        offsetY: -5,
                        fontSize: "30px",
                        formatter: function (val, opt) {
                            return val
                            // return _dataLabels
                        }
                    }
                }
            }
        },
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            hover: {
                filter: {
                    // type: 'lighten',
                    type: 'none',
                    value: 0.15,
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    // type: 'darken',
                    type: 'none',
                    value: 0.35,
                }
            },
        },
        labels: [""]
    }

    return (
        <ReactApexChart options={options} series={series} type="radialBar" height={160} />
    )
}






const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 370,
        maxHeight: "calc(100vh - 100px)",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));





