import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';

// chart options
const areaChartOptions = {
  chart: {
    height: 450,
    type: 'area',
    toolbar: {
      show: false
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth',
    width: 2
  },
  grid: {
    strokeDashArray: 0
  },
  fill: {
    type: 'solid',
    colors: ['transparent'],
  }
};
// ['smooth', 'monotoneCubic', 'straight', 'stepline']
// ==============================|| INCOME AREA CHART ||============================== //

const AreaChart = ({ series, categories }) => {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(areaChartOptions);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      chart: {
        events: {
          animationEnd: (chart) => {
            chart.windowResizeHandler();
          }
        }
      },
      // colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        categories: categories,
        labels: {
          style: {
            colors: [
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary,
              secondary
            ]
          }
        },
        axisBorder: {
          show: true,
          color: line
        },
        // tickAmount: slot === 'month' ? 11 : 7
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary]
          }
        }
      },
      grid: {
        borderColor: line
      },
      tooltip: {
        theme: 'light'
      }
    }));
  }, [primary, secondary, line, theme, series, categories]);





  return <ReactApexChart options={options} series={series} type="area" height={200} width={500} />;
};

AreaChart.propTypes = {
  series: PropTypes.object,
  categories: PropTypes.array
};

// data: PropTypes.shape({
//   id: PropTypes.number.isRequired,
//   title: PropTypes.string
// })

export default AreaChart;
