import * as Yup from 'yup';
import React, { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  TextField,
  FormControlLabel,
  Divider,
  styled,
  Switch,
  Autocomplete,
  // Grid
} from '@mui/material';
import { LoadingButton, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from "react-router-dom";

import { Api } from '../../api/';
import { useSnackbar } from 'notistack';
import { UseAesCbc } from '../../_crypto/AesCbc';

// ----------------------------------------------------------------------
const SwitchContent = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
export default function DeviceNewForm() {
  // const account = useRecoilValue(userAtom);
  const [listUsers, setListUsersModel] = useState([])
  const [dlt_vender, setDlt_vender] = useState({})


  const aesCbc = UseAesCbc();
  const listProvince = JSON.parse(aesCbc.decrypt(localStorage.getItem("listProvince")))
  const listVehicleType = JSON.parse(aesCbc.decrypt(localStorage.getItem("listVehicleType")))
  const listVehicleRegisterType = JSON.parse(aesCbc.decrypt(localStorage.getItem("listVehicleRegisterType")))
  const listModel = JSON.parse(aesCbc.decrypt(localStorage.getItem("listModel")))
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;
  const deviceSchema = Yup.object().shape({
    // vender_id: Yup.string().required('Operator Gps is required'),
    gpsmodel: Yup.string().required('Model Gps is required'),
    deviceid: Yup.string().required('device Id is required'),
    imei: Yup.string().required('IMEI is required'),
    telgps: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('GPS Phone is required'),
  });
  const handleSnackbar = ({ variant, message }) => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'center', vertical: 'bottom' } });
  };
  useEffect(() => {
    Api.get(`/v0/gps/dlt_vender?cocode=SITCONSULTANT`)
      .then(res => {
        setDlt_vender(res.data)
      }
      );
    return () => {
      setDlt_vender([]);
    }
    // Api.get('users')
    //   .then(res => {
    //     setListUsersModel(res.data)
    //   }
    //   )

  }, []);

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      vender_id: '',
      devicename: '',
      gpsmodel: '',
      user_id: '',
      imei: '',
      unit_id: '',
      deviceid: '',
      vehicle_id: '',
      province_code: '',
      vehicle_type: '',
      vehicle_chassis_no: '',
      vehicle_register_type: '',
      dateinstall: new Date(),
      dateexp: new Date(),
      fueluse: '',
      telgps: '',
      book_id: '',
      date_p: new Date(),
      business_name: '',
      address: '',
      province_code_address: '',
      tel: '',
      fuelfull: '0',
      fuelmin: '0',
      card_reader: true,
    },
    validationSchema: deviceSchema,
    onSubmit: async (values) => {
      const data = JSON.stringify({
        ...values,
        vender_id: dlt_vender.vender_id,
        cocode: dlt_vender.cocode,
        cobcode: dlt_vender.cobcode,
      });
      await Api.post('/v0/gps/device', data)
        .then(res =>
          navigate('../device')
        ).catch(error => {
          handleSnackbar({ variant: 'error', message: error.response.data.Message });
        }
        )
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;


  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label="ผู้ประกอบการ GPS"
                // {...getFieldProps('vender_id')}
                value={dlt_vender.vender_id + " : " + dlt_vender.vender_name_th}
                disabled
              />
              <Autocomplete
                id="gpsmodel"
                name="gpsmodel"
                size="small"
                options={listModel}
                getOptionLabel={(option) => option.name}
                fullWidth
                onChange={(e, value) => {
                  const gpsmodel = value !== null ? value.gpsmodel.toString() : '';
                  setFieldValue("gpsmodel", gpsmodel);
                }}
                renderInput={(params) => (
                  <TextField
                    label="เลือกรุ่น GPS"
                    fullWidth
                    autoComplete="off"
                    name="gpsmodel"
                    {...params}
                    {...getFieldProps('gpsmodel')}
                    error={Boolean(touched.gpsmodel && errors.gpsmodel)}
                    helperText={touched.gpsmodel && errors.gpsmodel}
                  />
                )}
              />

            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Autocomplete
                id="user_id"
                name="user_id"
                options={listUsers}
                getOptionLabel={(option) => option.user_id}
                fullWidth
                onChange={(e, value) => {
                  const user_id = value !== null ? value.user_id : '';
                  // resetForm("model_gps", "");
                  setFieldValue("user_id", user_id);
                }}
                renderInput={(params) => (
                  <TextField
                    label="เลือกผู้ใช้งาน"
                    fullWidth
                    autoComplete="off"
                    name="user_id"
                    {...params}
                    {...getFieldProps('user_id')}
                    error={Boolean(touched.user_id && errors.user_id)}
                    helperText={touched.user_id && errors.user_id}
                  />
                )}
              />
              <TextField
                fullWidth
                type="text"
                label="เลข IMEI"
                {...getFieldProps('imei')}
                inputProps={{ maxLength: 20 }}
                error={Boolean(touched.imei && errors.imei)}
                helperText={touched.imei && errors.imei}
                onChange={(e) => {
                  const { value } = e.target;
                  setFieldValue('imei', value)
                  setFieldValue('unit_id', values.vender_id + values.gpsmodel + value.padStart(20, "0"))
                }}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เลข Unit ID"
                {...getFieldProps('unit_id')}
                disabled
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="รหัสอุปกรณ์"
                {...getFieldProps('deviceid')}
                error={Boolean(touched.deviceid && errors.deviceid)}
                helperText={touched.deviceid && errors.deviceid}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ชื่ออุปกรณ์"
                {...getFieldProps('devicename')}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เบอร์โทร GPS"
                {...getFieldProps('telgps')}
                error={Boolean(touched.telgps && errors.telgps)}
                helperText={touched.telgps && errors.telgps}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Autocomplete
                id="province_code"
                name="province_code"
                options={listProvince}
                getOptionLabel={(option) => option.province_name}
                fullWidth
                onChange={(e, value) => {
                  const province_code = value !== null ? value.province_code.toString() : '';
                  // const province = value !== null ? value.province_name : '';
                  setFieldValue("province_code", province_code);
                  // setFieldValue("province", province);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="province_code"
                    {...params}
                    type="text"
                    label="จังหวัด"
                    {...getFieldProps('province_code')}
                    error={Boolean(touched.province_code && errors.province_code)}
                    helperText={touched.province_code && errors.province_code}
                  />
                )}
              />

              <Autocomplete
                id="vehicle_type"
                name="vehicle_type"
                options={listVehicleType}
                getOptionLabel={(option) => option.vehicle_type}
                fullWidth
                onChange={(e, value) => {
                  const vehicle_type = value !== null ? value.vehicle_type.toString() : '';
                  setFieldValue("vehicle_type", vehicle_type);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="vehicle_type"
                    {...params}
                    type="text"
                    label="ยี่ห้อรถ"
                    {...getFieldProps('vehicle_type')}
                    error={Boolean(touched.vehicle_type && errors.vehicle_type)}
                    helperText={touched.vehicle_type && errors.vehicle_type}
                  />
                )}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ป้ายทะเบียน"
                {...getFieldProps('vehicle_id')}
                error={Boolean(touched.vehicle_id && errors.vehicle_id)}
                helperText={touched.vehicle_id && errors.vehicle_id}
              // onChange={(e) => console.log(e.target.value)}
              />
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เลขตัวถัง"
                {...getFieldProps('vehicle_chassis_no')}
                error={Boolean(touched.vehicle_chassis_no && errors.vehicle_chassis_no)}
                helperText={touched.vehicle_chassis_no && errors.vehicle_chassis_no}
              // onChange={(e) => console.log(e.target.value)}
              />

            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

              <Autocomplete
                id="vehicle_register_type"
                name="vehicle_register_type"
                options={listVehicleRegisterType}
                getOptionLabel={(option) => option.vehicle_register_name}
                fullWidth
                onChange={(e, value) => {
                  const vehicle_register_type = value !== null ? value.vehicle_register_type.toString() : '';
                  setFieldValue("vehicle_register_type", vehicle_register_type);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="vehicle_register_type"
                    {...params}
                    type="text"
                    label="ประเภทรถ"
                    {...getFieldProps('vehicle_register_type')}
                    error={Boolean(touched.vehicle_register_type && errors.vehicle_register_type)}
                    helperText={touched.vehicle_register_type && errors.vehicle_register_type}
                  />
                )}
              />
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="อัตราสิ้นเปลือง"
                {...getFieldProps('fueluse')}
                error={Boolean(touched.fueluse && errors.fueluse)}
                helperText={touched.fueluse && errors.fueluse}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <MobileDatePicker
                disableFuture={true}
                // allowSameDateSelection
                label="วันติดตั้ง"
                allowKeyboardControl={false}
                value={values.dateinstall}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(val) => {
                  setFieldValue('dateinstall', val);
                }}
                renderInput={(params) => <TextField
                  {...params}
                  {...getFieldProps('dateinstall')}
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  autoComplete="off"
                  error={Boolean(touched.dateinstall && errors.dateinstall)}
                  helperText={touched.dateinstall && errors.dateinstall} />}
              />

              <MobileDatePicker
                // disableFuture={false}  
                disablePast={true}
                // allowSameDateSelection
                label="วันหมดอายุ"
                allowKeyboardControl={false}
                value={values.dateexp}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(val) => {
                  setFieldValue('dateexp', val);
                }}
                renderInput={(params) => <TextField
                  {...params}
                  {...getFieldProps('dateexp')}
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  autoComplete="off"
                  error={Boolean(touched.dateexp && errors.dateexp)}
                  helperText={touched.dateexp && errors.dateexp} />}
              />
            </Stack>

            <Divider />
            <h4 style={{ color: 'red' }} >** ข้อมูลส่วนนี้จะอยู่บนเอกสารรับรอง ***</h4>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เลขที่หนังสือ"
                {...getFieldProps('book_id')}
                error={Boolean(touched.book_id && errors.book_id)}
                helperText={touched.book_id && errors.book_id}
              // onChange={(e) => console.log(e.target.value)}
              />

              <MobileDatePicker
                disableFuture={true}
                // allowSameDateSelection
                label="วันที่ออกใบรับรอง"
                allowKeyboardControl={false}
                value={values.date_p}
                inputFormat="dd/MM/yyyy"
                format="dd/MM/yyyy"
                onChange={(val) => {
                  setFieldValue('date_p', val);
                }}
                renderInput={(params) => <TextField
                  {...params}
                  {...getFieldProps('date_p')}
                  fullWidth
                  placeholder="DD/MM/YYYY"
                  autoComplete="off"
                  error={Boolean(touched.date_p && errors.date_p)}
                  helperText={touched.date_p && errors.date_p} />}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ชื่อผู้ประกอบการ/เจ้าของ"
                {...getFieldProps('business_name')}
                error={Boolean(touched.business_name && errors.business_name)}
                helperText={touched.business_name && errors.business_name}
              // onChange={(e) => console.log(e.target.value)}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ที่อยู่"
                {...getFieldProps('address')}
                error={Boolean(touched.address && errors.address)}
                helperText={touched.address && errors.address}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Autocomplete
                id="province_code_address"
                name="province_code_address"
                options={listProvince}
                getOptionLabel={(option) => option.province_name}
                fullWidth
                onChange={(e, value) => {
                  const province_code_address = value !== null ? value.province_code.toString() : '';
                  // const province_address = value !== null ? value.province_name : '';
                  setFieldValue("province_code_address", province_code_address);
                  // setFieldValue("province_address", province_address);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    name="province_code_address"
                    {...params}
                    type="text"
                    label="จังหวัด"
                    {...getFieldProps('province_code_address')}
                    error={Boolean(touched.province_code_address && errors.province_code_address)}
                    helperText={touched.province_code_address && errors.province_code_address}
                  />
                )}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="เบอร์โทร"
                {...getFieldProps('tel')}
                error={Boolean(touched.tel && errors.tel)}
                helperText={touched.tel && errors.tel}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Divider />
            <h4 style={{ color: 'red' }} >** ถ้าไม่ได้ติดตัววัดระดับน้ำมันให้กรอก 0 ***</h4>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ค่าไฟเมื่อน้ำมันเต็ม"
                {...getFieldProps('fuelfull')}
                error={Boolean(touched.fuelfull && errors.fuelfull)}
                helperText={touched.fuelfull && errors.fuelfull}
              // onChange={(e) => console.log(e.target.value)}
              />

              <TextField
                fullWidth
                autoComplete="off"
                type="text"
                label="ค่าไฟเมื่อน้ำมันหมดถัง"
                {...getFieldProps('fuelmin')}
                error={Boolean(touched.fuelmin && errors.fuelmin)}
                helperText={touched.fuelmin && errors.fuelmin}
              // onChange={(e) => console.log(e.target.value)}
              />
            </Stack>
            <Divider />
            <FormControlLabel
              control={<SwitchContent sx={{ m: 1 }}
                {...getFieldProps('card_reader')}
                onChange={(event) => {
                  setFieldValue('card_reader', event.target.checked);
                }}
                checked={values.card_reader === true} />}
              label="มีตัวอ่านบัตร"
            />
          </Stack>
          <Stack direction="column" alignItems="center" sx={{ my: 2 }}>
            <LoadingButton
              style={{ width: 220 }}
              fullWidth={false}
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              บันทึก
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </LocalizationProvider>
  );
}
