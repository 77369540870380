import { styled } from '@mui/styles';
import React from 'react'
import { Box, Grid, TextField, Divider, Button } from '@mui/material'
import Page from '../../components/Page';
import { LocalizationProvider, LoadingButton, MobileDatePicker } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useNavigate } from 'react-router-dom';

export default function NewDriver() {


  const navigate = useNavigate()

  const schema = Yup.object().shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    id_card: Yup.string().required(),
    sex: Yup.string().required(),
    phone: Yup.string().required(),
    email: Yup.string().required(),
    birthday: Yup.string().required(),

  })
  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      id_card: '',
      sex: '',
      phone: '',
      email: '',
      birthday: new Date()

    },
    validationSchema: schema,
    onSubmit: (values) => {

    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setErrors, resetForm, values } = formik;



  const handleOnClose = () => {
    navigate("/settings/drivers")

  }
  return (
    <Page>
      <MainStyle>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
              <Box sx={{ padding: "10px" }}>
                <Box sx={{ paddingY: "10px", fontWeight: "bold", fontSize: "18px" }}>เพิ่ม พนักงานขับรถ</Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="ชื่อ"
                      {...getFieldProps('firstname')}
                      error={Boolean(touched.firstname && errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                      value={values.firstname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="นามสกุล"
                      {...getFieldProps('lastname')}
                      error={Boolean(touched.lastname && errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                      value={values.lastname}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="เพศ"
                      {...getFieldProps('sex')}
                      error={Boolean(touched.sex && errors.sex)}
                      helperText={touched.sex && errors.sex}
                      value={values.sex}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MobileDatePicker
                      disableFuture={true}
                      label="วันเกิด"
                      allowKeyboardControl={false}
                      value={values.birthday}
                      inputFormat="dd/MM/yyyy"
                      format="dd/MM/yyyy"
                      onChange={(val) => {
                        setFieldValue('birthday', val);
                      }}
                      disableCloseOnSelect={false}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          fullWidth
                          size="small"
                          type="text"
                          placeholder="DD/MM/YYYY"
                          autoComplete="off"
                          {...getFieldProps('birthday')}
                          error={Boolean(touched.birthday && errors.birthday)}
                          helperText={touched.birthday && errors.birthday}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="เลขที่บัตรประชาชน"
                      {...getFieldProps('id_card')}
                      error={Boolean(touched.id_card && errors.id_card)}
                      helperText={touched.id_card && errors.id_card}
                      value={values.id_card}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="เบอร์โทร"
                      {...getFieldProps('phone')}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      value={values.phone}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="email"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      value={values.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="-----"
                    // {...getFieldProps('email')}
                    // error={Boolean(touched.email && errors.email)}
                    // helperText={touched.email && errors.email}
                    // value={values.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="-----"
                    // {...getFieldProps('email')}
                    // error={Boolean(touched.email && errors.email)}
                    // helperText={touched.email && errors.email}
                    // value={values.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="-----"
                    // {...getFieldProps('email')}
                    // error={Boolean(touched.email && errors.email)}
                    // helperText={touched.email && errors.email}
                    // value={values.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="-----"
                    // {...getFieldProps('email')}
                    // error={Boolean(touched.email && errors.email)}
                    // helperText={touched.email && errors.email}
                    // value={values.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      size="small"
                      type="text"
                      label="-----"
                    // {...getFieldProps('email')}
                    // error={Boolean(touched.email && errors.email)}
                    // helperText={touched.email && errors.email}
                    // value={values.email}
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ marginTop: "10px" }} />
              </Box>
              <Box>
                <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                  <Grid item xs={6} justifyContent="end" justifyItems="end" display="flex">
                    <LoadingButton
                      // fullWidth
                      sx={{ width: "140px" }}
                      size="medium"
                      type="submit"
                      variant="contained"
                      color="info"
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} justifyContent="center">
                    <Button
                      // fullWidth
                      sx={{ width: "140px" }}
                      onClick={handleOnClose}
                      size="medium"
                      variant="outlined"
                      color="info"
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          </FormikProvider>
        </LocalizationProvider>
      </MainStyle>
    </Page>
  )
}



const MainStyle = styled(Box)(({ theme }) => ({
  margin: 'auto',
  paddingBottom: 60,
  overflow: 'auto'
}));