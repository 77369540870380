import React, { useEffect, useState } from 'react'
import { Dialog, DialogTitle, Typography, Divider, Stack, DialogContent, DialogActions, IconButton, Button, Grid, TextField, Box, MenuItem, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { Api } from '../../components/api';
import WidgetModelGps from './WidgetModelGps';
import UpdateModelGps from './UpdateModelGps';
import CreateModelGps from './CreateModelGps';


export default function MainModelGps({ account }) {
    const { cocode } = account
    const [modelGps, setModelGps] = useState([])

    useEffect(() => {
        Api.get(`v0/gps/gpsmodels?cocode=${cocode}`)
            .then(res => {
                setModelGps(res.data)
            })
            .catch(err => {
                console.log('err', err)
            })
    }, [])


    return (
        <Box sx={{ padding: "10px" }}>
            <Box sx={{ paddingBottom: "15px" }}>
                <Typography variant="h5"> Model GPS </Typography>
            </Box>
            <Divider />
            <Box>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>รุ่นGPS</TableCell>
                            <TableCell>ประเภท</TableCell>
                            <TableCell>เลขที่ใบอนุญาต</TableCell>
                            <TableCell>รุ่นเครื่องอ่านบัตร</TableCell>
                            <TableCell>ประเภทเครื่องอ่านบัตร</TableCell>
                            <TableCell>
                                <Stack direction="row" justifyContent="end">
                                    <CreateModelGps />
                                </Stack>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            // modelGps.filter(v => v?.firstname?.toUpperCase().includes(search.toUpperCase())).map((accRow, index) => {
                            modelGps.map((modelRow, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>{modelRow.gpsmodel}</TableCell>
                                        <TableCell>{modelRow.gpstype}</TableCell>
                                        <TableCell>{modelRow.gpslicense}</TableCell>
                                        <TableCell>{modelRow.mcrmodel}</TableCell>
                                        <TableCell>{modelRow.mcrtype}</TableCell>
                                        <TableCell>
                                            <Stack direction="row" justifyContent="end">
                                                <UpdateModelGps data={modelRow} />
                                                <WidgetModelGps />
                                                <IconButton size="small" onClick={() => { }}>
                                                    <DeleteOutlineOutlinedIcon fontSize="small" />
                                                </IconButton>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </Box>
        </Box>
    )
}
