import axios from "axios";
import qs from 'qs';
// import { UseAesCbc } from '../_crypto/AesCbc';
// import moment from 'moment';
// import { format } from "date-fns";

const baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL;

const usernameToken = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TOKEN_USERNAME_DEV : process.env.REACT_APP_TOKEN_USERNAME_PROD;
const passwordToken = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TOKEN_PASSWORD_DEV : process.env.REACT_APP_TOKEN_PASSWORD_PROD;

// const appId = process.env.REACT_APP_ID
// const aesCbc = UseAesCbc();

var tokenState = null

export const Api = axios.create({
    baseURL: baseURL,
    headers: { 'Content-Type': 'application/json' },
});
Api.defaults.headers.common['Appid'] = 'sitgps';
Api.interceptors.request.use(async config => {
    // config.logRequests = false;
    const token = await getToken().then(function (response) {
        return response;
    });
    config.headers.Authorization = 'Bearer ' + token;

    return config;
}, error => {
    return Promise.reject(error);
});

export const getToken = async () => {
    let token = ''
    const data = qs.stringify({
        'username': usernameToken,
        'password': passwordToken,
        'grant_type': 'password'
    });
    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    // if ((tokenState === null || tokenState.expires <= new Date())) {
    await axios.post(baseURL + '/Token', data, config)
        .then(response => {
            token = response.data.access_token;
            // const date = new Date()
            // const expires = new Date(date.setMinutes(date.getMinutes() + secondsToMinutes(60)))
            // tokenState = { token: response.data.access_token, expires: expires }
        })
        .catch(error => {
            console.log(error);
            // return '';
        });
    // } else {
    //     token = tokenState.token
    // }
    return token;
}

function secondsToMinutes(seconds) {
    const minutes = seconds / 60;
    return minutes;
}
