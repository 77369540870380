import React, { useEffect, useState } from 'react';
// import PahoMqttClient from 'paho-mqtt';

export default function MonitorHome() {

    const [message, setMessage] = useState('');

    // useEffect(() => {
    //     const client = new PahoMqttClient.Client('your-broker-url', 'your-client-id');
    //     client.onConnectionLost = (responseObject) => {
    //         console.log('Connection lost: ' + responseObject.errorCode);
    //     };

    //     client.onMessageArrived = (message) => {
    //         setMessage(message.payloadString);
    //     };

    //     client.connect({ onSuccess: () => client.subscribe('your-topic') });

    //     return () => {
    //         client.disconnect();
    //     };
    // }, []);

    return (
        <div>
            <p>Received message: {message}</p>
        </div>
    );
}