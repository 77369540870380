import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import _ from 'lodash';
const localizer = momentLocalizer(moment);
const initialcars = [
    {
        "car_type": "BUS-EV", "car_id": "EV-001", "licensePlate": "33-0001",
        start: new Date(2024, 5, 10),
        end: new Date(2024, 5, 12),
    },
    {
        "car_type": "BUS-EV", "car_id": "EV-002", "licensePlate": "33-0002",
        start: new Date(2024, 5, 11),
        end: new Date(2024, 5, 12),
    },
    {
        "car_type": "BUS-EV", "car_id": "EV-003", "licensePlate": "33-0003",
        start: new Date(2024, 5, 15),
        end: new Date(2024, 5, 15),
    },
    {
        "car_type": "BUS-44", "car_id": "B44-001", "licensePlate": "32-0001",
        start: new Date(2024, 5, 16),
        end: new Date(2024, 5, 18),
    },
    {
        "car_type": "BUS-44", "car_id": "B44-002", "licensePlate": "32-0002",
        start: new Date(2024, 5, 20),
        end: new Date(2024, 5, 21),
    },
    {
        "car_type": "BUS-44", "car_id": "B44-003", "licensePlate": "32-0003",
        start: new Date(2024, 5, 20),
        end: new Date(2024, 5, 25),
    },
    {
        "car_type": "BUS-44", "car_id": "B44-004", "licensePlate": "32-0004",
        start: new Date(2024, 5, 11),
        end: new Date(2024, 5, 11),
    },
]
const MyCalendar = ({ onClick, car_type }) => {
    const events = initialcars;
    const handleEventClick = (event) => {
        onClick({
            "car_type": event.car_type, "car_id": event.car_id, "licensePlate": event.licensePlate
        })
        console.log('Event clicked:', event);
        // Handle event click here, e.g., open a modal, navigate to a detail page, etc.
    };
    return (
        <div>
            <Calendar
                titleAccessor={"licensePlate"}
                localizer={localizer}
                events={_.filter(events, (item) => {
                    return item.car_type === car_type;
                })}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                onSelectEvent={handleEventClick}
            />
        </div>
    );
};

export default MyCalendar;