const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });
    return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
    children,
    RenderError,
    scopes = [],
    permissions = []
}) {
    const permissionGranted = hasPermission({ permissions, scopes });

    if (!permissionGranted) return RenderError ? <RenderError /> : <></>

    return <>{children}</>;
}