import React, { useEffect, useState } from 'react'
import { Api } from '../components/api'
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';

export default function RouterHasPermission({
    RenderHasPermission,
    RenderError,
    scope,
    // permission: fetchdata,
    permission,
    loading
}) {
    // const [fetchdata, setfetchdata] = useState(permission)
    // useEffect(() => {
    //     setfetchdata(permission)
    // }, [permission])

    function useHasPermission({ scopes }) {
        var hasPermission = false
        if (!permission) {
            return false
        }
        const list = [...JSON.parse(permission.value)]
        if (scopes) {
            scopes.forEach((scope) => {
                const check = list.find(f => f.id === scope)
                // const check = list.find(f => f.id === scope.split('.')[1])
                if (check?.value) {
                    hasPermission = true
                }
            });
            return hasPermission
        }
    }

    const hasPermission = useHasPermission({ scopes: scope });
    if (loading) {
        return (
            // <></>
            <Backdrop
                sx={{ color: "whitesmoke", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    } else {
        if (!scope) return (
            <RenderHasPermission permissions={permission} />
        );
        // if (!hasPermission && !loading) return RenderError ? <RenderError /> : <></>
        if (!hasPermission) return RenderError ? <RenderError /> : <></>

        if (hasPermission) return <RenderHasPermission permissions={permission} />
    }
    return <></>
}