import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    "&.Mui-selected": {
      backgroundColor: '#00AB55',
    }
  },
}));

export default function TransferList({ handleClose, open, stations, listStation, onSubmit }) {
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([
    ...stations
  ]);
  const [right, setRight] = React.useState([...listStation]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };


  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleOnSubmit = () => {
    onSubmit(right)
    handleClose()
    setChecked([])
  }
  const handleOnClose = () => {
    handleClose()
    setChecked([])
  }
  const classes = useStyles()
  const customList = (title, items) => (
    <Card>
      <List
        sx={{
          width: 220,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={value.name}
              role="listitem"
              button
              onClick={handleToggle(value)}
              selected={checked.indexOf(value) !== -1}
              classes={{ root: classes.listItemRoot }}
              style={{
                padding: 10
              }}
            >
              <ListItemText id={labelId} primary={`${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>เลือกสถานี</DialogTitle>
      <Grid container spacing={2} justifyContent="center" alignItems="center" padding={1}>
        <Grid item>{customList('Choices', left)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList('Chosen', right)}</Grid>
      </Grid>
      <Box padding={2}>
        <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
          <Grid item xs={6} justifyContent="center">
            <Button
              fullWidth
              onClick={handleOnClose}
              size="medium"
              variant="outlined"
              color="info"
            // fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} justifyContent="center">
            <Button
              fullWidth
              onClick={handleOnSubmit}
              size="medium"
              variant="contained"
              color="info"
            // fullWidth
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
