import React, { useState } from 'react'
import PlaylistCustom from './PlaylistCustom'
import PlaylistSearch from './PlaylistSearch'
import PlaylistSave from './PlaylistSave'

export default function PlaylistDash({ c_coname, openCustom, handleDialogCustom, handleSubscribe, userId, onGetGroup, handleSubscribeCustom }) {


    return (
        <>
            <PlaylistCustom c_coname={c_coname} openCustom={openCustom} handleSubscribe={handleSubscribe} handleDialogCustom={handleDialogCustom} userId={userId} onGetGroup={onGetGroup} handleSubscribeCustom={handleSubscribeCustom} />
            {/* <PlaylistSearch openSearch={openSearch} handleSearch={handleSearch} userId={userId} /> */}
        </>
    )
}
