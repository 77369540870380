import { styled, alpha } from '@mui/material/styles';
import React, { useState } from 'react'
import { Menu, MenuItem, List, ListItem, ListItemButton, ListItemIcon, ListItemText, IconButton, Divider, Box, Typography, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useEffect } from 'react';
import { Api } from '../../components/api';
import EditIcon from '@mui/icons-material/EditOutlined';


export default function MenuGroup({ anchorEl, handleClickCloseMenu, group, handleClickMenuGroup, groupSelect, onClickAll, handleSnackWithAction, handleDialog }) {
    const openMenu = Boolean(anchorEl);


    useEffect(() => {
        setSearch('')
    }, [openMenu])



    const [search, setSearch] = useState('')

    const handleChange = ({ target: { value } }) => {
        setSearch(value)
    }

    const onClick = ({ subs, groupId, cname }) => {
        handleClickMenuGroup({ subs: subs, groupId: { group_id: groupId, cname: cname } })

    }

    return (
        <StyledMenu
            id="group-menu"
            MenuListProps={{
                'aria-labelledby': 'group-button'
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClickCloseMenu}
        >
            <nav aria-label="main">

                <List
                    sx={{
                        width: 370,
                        textOverflow: "ellipsis"
                    }}
                >

                    <MenuItem onKeyDown={(e) => e.stopPropagation()}
                        sx={{
                            backgroundColor: "white !important",
                            '&:active': {
                                backgroundColor: 'white !important',
                            },
                            '&:hover': {
                                backgroundColor: 'white',
                            },
                        }}
                    >
                        <TextField
                            fullWidth
                            size="small"
                            label={"search"}
                            value={search}
                            onChange={handleChange}
                            autoFocus={openMenu}
                            autoComplete='off'
                        />
                    </MenuItem>
                    <ListItem
                        disablePadding
                    // key={i}
                    >
                        <ListItemButton
                            onClick={onClickAll}
                        >
                            <ListItemText primary={`All`} />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                    {
                        group.filter((value) => value.cname !== "" && (value.group_name.toUpperCase().includes(search.toUpperCase()) || value.group_id.toUpperCase().includes(search.toUpperCase()))).map((value, i) => {

                            return (
                                <ListItem
                                    disablePadding
                                    key={i}
                                >
                                    <ListItemButton
                                        onClick={() => {
                                            onClick({ subs: value.device, groupId: value.group_id, cname: value.cname })
                                        }}
                                    >
                                        <ListItemIcon>
                                            {
                                                groupSelect?.group_id === value.group_id && groupSelect?.cname === value.cname && (
                                                    <CheckIcon sx={{ fontSize: "14px" }} />
                                                )
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={`(${value.group_id}) ${value.group_name}`} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                    <Divider />
                    {/* <Box sx={{
                        paddingLeft: "20px"
                    }}>
                        <Typography color="gray">custom</Typography>
                    </Box> */}
                    {
                        group.filter((value) => value.cname === "" && (value.group_name.toUpperCase().includes(search.toUpperCase()) || value.group_id.toUpperCase().includes(search.toUpperCase()))).map((value, i) => {

                            return (
                                <ListItem
                                    disablePadding
                                    key={i}
                                    secondaryAction={
                                        <>
                                            <IconButton edge="end" aria-label="Edit"
                                                onClick={() => handleDialog(value)}
                                                color="success"
                                                size="small"
                                            >
                                                <EditIcon fontSize='16px' />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete"
                                                disabled={groupSelect?.group_id === value.group_id && groupSelect?.cname === value.cname}
                                                onClick={() => {
                                                    handleSnackWithAction(value)
                                                }}
                                                color="error"
                                                size="small"
                                            >
                                                <DeleteForeverIcon fontSize='16px' />
                                            </IconButton>
                                        </>
                                    }
                                >
                                    <ListItemButton onClick={() => {
                                        onClick({ subs: value.device, groupId: value.group_id, cname: value.cname })
                                    }}>
                                        <ListItemIcon>
                                            {
                                                groupSelect?.group_id === value.group_id && groupSelect?.cname === value.cname && (
                                                    <CheckIcon sx={{ fontSize: "14px" }} />
                                                )
                                            }
                                        </ListItemIcon>
                                        <ListItemText primary={`(${value.group_id}) ${value.group_name}`} />
                                    </ListItemButton>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </nav>
        </StyledMenu>
    )
}



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        // zIndex: theme.zindex.drawer - 1,
        borderRadius: 6,
        minWidth: 370,
        maxHeight: "calc(100vh - 100px)",
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));