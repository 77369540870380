import { atom } from "recoil";

export const groupSelectStorage = atom({
    key: 'groupSelectStorage',
    default: null
});

export const subscribeStorage = atom({
    key: 'subscribeStorage',
    default: []
});

export const selectDeviceStorage = atom({
    key: 'selectDeviceStorage',
    default: ''
});

export const mapStorage = atom({
    key: 'mapStorage',
    default: null
});


export const groupsStorage = atom({
    key: 'groupsStorage',
    default: []
});