import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import { Api } from '../../../components/api'
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { MainScopes, SCOPES_CRUD, SCOPES_DASHBOARD, SCOPES_POI, poi_permission } from '../../permission/SCOPESPAGES';
import PermissionsGate from '../../permission/PermissionsGate';

export default function UpdateContype({ data, onGet, account }) {
    const { cocode, c_coname, cogcode } = account;

    const [open, setOpen] = useState(false)
    const [contype, setContype] = useState(data)
    useEffect(() => {
        setContype(data)
    }, [data])

    const onHandleOpen = () => {
        setOpen(true)
    }
    const onHandleClose = () => {
        setOpen(false)
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setContype(e => {
            return {
                ...e,
                [name]: value
            }
        })
    }

    const onSubmit = () => {
        const data = JSON.stringify({
            // ...values,
            ...contype
        });
        Api.put(`/v0/if/Contypes`, data, { headers: { CompanyGroup: cogcode } })
            .then((res) => {
                onHandleClose()
                handleClickVariant("บันทึกสำเร็จ", "success")
                onGet()
                console.log('res', res)
            })
            .catch((err) => {
                handleClickVariant("บันทึกไม่สำเร็จ", "error")
                onHandleClose()
                console.log('err', err)
            })
    }

    return (
        <div>
            <IconButton onClick={onHandleOpen} size="small" ><EditNoteIcon fontSize="small" /></IconButton>
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth={true}
                onClose={onHandleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>
                    <Stack spacing={1} direction="row">
                        <Typography variant="h6">สร้างประเภท contype</Typography>
                        <PermissionForm isContype={contype} account={account} />
                    </Stack>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onHandleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box sx={{ padding: "10px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <TextField size="small" disabled label="contype" fullWidth name="contype" value={contype.contype} onChange={handleOnChange} />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField size="small" label="contypename" fullWidth name="contypename" value={contype.contypename} onChange={handleOnChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} marginBottom="10px" justifyContent="center">
                                    <Button size="small" variant="contained" onClick={onSubmit}>บันทึก</Button>
                                    <Button size="small" variant="contained" onClick={onHandleClose}>ยกเลิก</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}



const PermissionForm = ({ isContype, account }) => {
    const { contype } = isContype
    const { cocode, c_coname } = account;

    const [openUserId, setOpenUserId] = useState(false)
    const [permission, setPermission] = useState({})



    const handleOpenUserId = () => {

        Api.get(`/Tracking/Permission?cname=SIT&userid=contype${contype}&permission=contype`)
            .then((res) => {
                const { value } = res.data
                setPermission(JSON.parse(value))
                setOpenUserId(true)
            })
            .catch((err) => {
                console.log('err', err)
                setOpenUserId(true)
            })

    }
    const handleCloseUserId = () => {
        setOpenUserId(false)
        setPermission({})
    }
    const handleSaveUserId = () => {
        const data = JSON.stringify({
            "cname": c_coname,
            "userid": `contype${contype}`,
            "permission": "contype",
            "name": "contype",
            "value": JSON.stringify(permission)
        });
        Api.post(`/Tracking/Permission`, data)
            .then((res) => {
                handleCloseUserId()
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    const resetPasswordUserID = () => {

    }


    const onChange = (name, value) => {

        if (permission[name]) {
            setPermission((self) => {
                return { ...self, [name]: permission[name].concat(value) }
            })
        } else {
            setPermission((self) => {
                return { ...self, [name]: [].concat(value) }
            })
        }
    }
    const onChangeUnCheck = (name, value) => {
        const unCheck = permission[name].filter(v => v !== value) || []
        if (unCheck.length > 0) {
            setPermission((self) => {
                return { ...self, [name]: unCheck }
            })
        } else {
            let delPer = permission
            delete delPer[name]
            // console.log('delPer', delPer)
            setPermission((self) => {
                return { ...delPer }
            })
        }
    }




    return (
        <>
            <Button size="small" onClick={handleOpenUserId}>
                กำหนดสิทธิ์
            </Button>
            <Dialog
                open={openUserId}
                maxWidth="sm"
                fullWidth={true}
                onClose={handleCloseUserId}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>กำหนดสิทธิ์</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseUserId}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Box>
                        {
                            MainScopes.map((main, index) => {
                                return (
                                    <PermissionsGate
                                        permissions={[""]}
                                        scopes={[""]}
                                        key={"main" + index}
                                    >
                                        <Box sx={{ width: '100%', display: 'flex', flexDirection: "column", padding: "5px" }}
                                        >
                                            <Box sx={{ marginBottom: "15px", fontSize: "16px" }}>
                                                {main.name}
                                            </Box>
                                            <Box>
                                                {/* <Stack spacing={2} direction="row"> */}
                                                {
                                                    main.pages.map((page, pIndex) => {
                                                        return (
                                                            <>
                                                                <PermissionsGate
                                                                    RenderError={() => { return (<Button variant="outlined" size="small" sx={{ margin: "3px" }} onClick={() => { onChange(main.id, page.value) }}>{`${page.name}`}</Button>) }}
                                                                    permissions={permission[main.id]}
                                                                    scopes={[page.value]}
                                                                    key={"page" + pIndex}
                                                                >
                                                                    <Button variant="contained" size="small" sx={{ margin: "3px" }} onClick={() => { onChangeUnCheck(main.id, page.value) }}>{`${page.name}`}</Button>
                                                                </PermissionsGate>
                                                            </>

                                                        )
                                                    })
                                                }
                                                {/* </Stack> */}
                                            </Box>
                                            {/* <Divider /> */}
                                        </Box>
                                    </PermissionsGate>
                                )
                            })
                        }
                    </Box>
                </DialogContent >
                <DialogActions>
                    <Button onClick={handleSaveUserId}>บันทึก</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}
