// import "./styles.css";
import LinearProgress, {
    linearProgressClasses
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import BoltIcon from "@mui/icons-material/Bolt";

// export default function VerticalProgress() {
//     const progress = 90;
//     return (
//         <Box
//             sx={{
//                 flexGrow: 1,
//                 height: 200,
//                 gap: 1,
//                 display: "flex",
//                 p: 2
//             }}
//         >
//             <VerticalBar value={10} />
//             <VerticalBar value={progress} charge={true} />
//         </Box>
//     );
// }

// const VerticalBar = ({ value, charge }) => {
export default function VerticalProgress({ value, charge, name }) {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center"
            }}
        >
            <Box>
                {
                    `${charge === 2 ? "full" : value + "%"}`
                }
            </Box>
            <Box
                style={{
                    position: "relative",
                    zIndex: 2,
                    height: "120px"
                }}
            >
                <Box
                    style={{
                        position: "absolute",
                        zIndex: 2, 
                        paddingTop: "5px",
                        left: "-2px"
                    }}
                >
                    {
                        charge !== 0 && (
                            <BoltIcon />
                        )
                    }
                </Box>
                <VerticalLinearProgress
                    variant="determinate"
                    color="secondary"
                    value={value}
                    sx={{
                        [`& .${linearProgressClasses.bar}`]: {
                            transform: `translateY(${-value}%)!important`
                        }
                    }}
                />
            </Box>

            <Box
                sx={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "120px",
                    textAlign: "center"
                }}
            >
                {name}
            </Box>
        </Box>
    );
};

const VerticalLinearProgress = styled(LinearProgress)(() => ({
    width: "20px",
    height: "100%",
    borderRadius: "5px",
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: "#F5F6FA !important"
        // borderBottom: "2px solid blue"
    },
    [`&.${linearProgressClasses.colorSecondary}`]: {
        backgroundColor: "#1a90ff"
    }
}));
