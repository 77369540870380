import React from 'react'
import { Box, Stack, IconButton, Divider, Paper, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { TableVirtuoso } from 'react-virtuoso';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import HelpdeskHistory from './HelpdeskHistory';
import HelpdeskRepair from './HelpdeskRepair';
import HelpdeskGetRepair from './HelpdeskGetRepair';
import HelpdeskUpdate from './HelpdeskUpdate';

export default function HelpdeskMain() {
    return (
        <Box>
            <Box
                sx={(theme) => ({
                    padding: "10px"
                })}
            >
                <Stack direction="row" spacing={1}>
                    <HelpdeskRepair />
                    <HelpdeskHistory />
                </Stack>
            </Box>
            <Divider />
            <Box
                sx={(theme) => ({
                    height: `calc(100vh - 60px)`,
                    padding: "10px"
                })}
            >
                <TableVirtuoso
                    data={[...Array(5)]}
                    components={VTableComponents}
                    fixedHeaderContent={() => (
                        <TableRow sx={{ backgroundColor: "white" }}>
                            <TableCell>หัวข้อ</TableCell>
                            <TableCell>เรื่อง</TableCell>
                            <TableCell>หมายเหตุ</TableCell>
                            <TableCell>ผู้แจ้ง</TableCell>
                            <TableCell>ผู้รับงาน</TableCell>
                            <TableCell>สถานะ</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    )}
                    itemContent={(index, value) => (
                        <React.Fragment key={`replay-${index}`} >
                            <TableCell size="small">GPS {index + 1}</TableCell>
                            <TableCell size="small">ไม่มีสัญญาณ</TableCell>
                            <TableCell size="small">เสาหัก</TableCell>
                            <TableCell size="small">ปรียาวดี</TableCell>
                            <TableCell size="small">ปวัตร</TableCell>
                            <TableCell size="small">{index === 1 ? "อยู่ระหว่างดำเนินการ" : "เปิดงาน"}</TableCell>
                            <TableCell size="small">
                                <Stack direction="row" spacing={1} justifyContent="end">
                                    {
                                        index === 1 ? (
                                            <HelpdeskUpdate />
                                        )
                                            :
                                            (
                                                <HelpdeskGetRepair />
                                            )
                                    }
                                    <IconButton size="small"><WorkOutlineRoundedIcon fontSize="small" /></IconButton>
                                </Stack>
                            </TableCell>
                        </React.Fragment>
                    )}
                />
            </Box>
        </Box>
    )
}

const VTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props} ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};