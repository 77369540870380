// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import { SnackbarProvider } from 'notistack';
// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig> 
      <SnackbarProvider maxSnack={3}>
        <Router />
      </SnackbarProvider>
    </ThemeConfig>
  );
}

