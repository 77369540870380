import { styled } from '@mui/styles';
import { divIcon } from 'leaflet';
import React, { useEffect, useState } from 'react'
import { CircleMarker, MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';

const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
    width: '100%',
    // height: 150,
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    overflow: 'hidden',
    zIndex: 1,
}));

export default function MapRoute({ stations = [] }) {
    const center = [13.839660198254604, 100.63469639884744];
    const zoom = 5;
    const [map, setmap] = useState(null);
    useEffect(() => {
        if (map !== null && stations.length > 0) {
            map.fitBounds(stations.map(value => [value.latitude, value.longitude]), { duration: 1 })
        }
    }, [map])

    return (
        <MapContainerStyle
            whenCreated={setmap}
            center={center}
            zoom={zoom}
            minZoom={3}
            zoomControl={false}
            // scrollWheelZoom={true}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            attributionControl={false}
        // dragging={false}
        // bounds={bot}
        >
            <TileLayer
                url={'http://mts0.google.com/vt/lyrs=m@289000001&hl=th&src=app&x={x}&y={y}&z={z}&s=Gal&&apistyle=s.t%3A2%7Cs.e%3Ag%7Cp.c%3A%23ffD7DBDD%2Cs.t%3A6%7Cs.e%3Ag.f%7Cp.c%3A%23ffb6cfdb%2Cs.t%3A3%7Cs.e%3Al.t.s%7Cp.v%3Aoff%2Cs.t%3A51%7Cs.e%3Ag%7Cp.c%3A%23ffffffff%2Cs.t%3A50%7Cs.e%3Ag%7Cp.c%3A%23ffffffff%2Cs.t%3A49%7Cs.e%3Ag%7Cp.c%3A%23ffffffff%2Cs.t%3A51%7Cs.e%3Al%7Cp.v%3Aon%2Cs.t%3A2%7Cs.e%3Al%7Cp.v%3Aoff%2Cs.t%3A4%7Cp.v%3Aoff%2Cs.t%3A5%7Cp.c%3A%23ffebebeb%2Cs.t%3A1%7Cp.c%3A%23ffc4c4c4%7Cp.v%3Aoff%2Cs.t%3A33%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A34%7Cp.il%3Atrue%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A35%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A39%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A1%7Cp.c%3A%23ffc4c4c4%2Cs.t%3A49%7Cp.c%3A%23fff0e3bb'}
                maxNativeZoom={19}
                maxZoom={22}

            />
            {
                stations.length > 0 && (
                    stations.map(element => (
                        // <Marker icon={IconMarker()} key={element.nameBusStop} />
                        // <Marker icon={IconMarker({ className: 'icon-marker-focus' })} position={[element.latitude, element.longitude]} radius={5} >
                        //     <Popup>
                        //         {
                        //             element.nameBusStop
                        //         }
                        //     </Popup>
                        // </Marker>
                        <CircleMarker center={[element.latitude, element.longitude]} radius={5} >
                            <Tooltip
                                // permanent
                                interactive={true}
                                direction="top" 
                                // offset={[0, -25]}
                            >
                                {
                                    element.nameBusStop
                                }
                            </Tooltip>
                            {/* <Popup>
                                {
                                    element.nameBusStop
                                }
                            </Popup> */}
                        </CircleMarker>
                    ))
                )
            }
        </MapContainerStyle >
    )
}
