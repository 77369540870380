export const SCOPES_CRUD = {
    create: "C",
    read: "R",
    update: "U",
    delete: "D",
};
export const SCOPES_POI = {
    createCircle: "c-circle",
    updateCircle: "u-circle",
    readCircle: "r-circle",
    deleteCircle: "d-circle",

    createRectangle: "c-rectangle",
    updateRectangle: "u-rectangle",
    readRectangle: "r-rectangle",
    deleteRectangle: "d-rectangle",

    createPolyline: "c-polyline",
    updatePolyline: "u-polyline",
    readPolyline: "r-polyline",
    deletePolyline: "d-polyline",

    createPolygon: "c-polygon",
    updatePolygon: "u-polygon",
    readPolygon: "r-polygon",
    deletePolygon: "d-polygon"
};

export const SCOPES_DASHBOARD = {
    repair: "repair"
};

export const PageScopes = {
    dashboard: [
        {
            value: "repair",
            name: "แจ้งซ่อม",
            type: "string"
        }
    ],
    poiCircle: [
        {
            value: SCOPES_POI.createCircle,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_POI.updateCircle,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_POI.readCircle,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_POI.deleteCircle,
            name: "ลบ",
            type: "string"
        },
    ],
    poiPolygon: [
        {
            value: SCOPES_POI.createPolygon,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_POI.updatePolygon,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_POI.readPolygon,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_POI.deletePolygon,
            name: "ลบ",
            type: "string"
        },
    ],
    poiPolyline: [
        {
            value: SCOPES_POI.createPolyline,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_POI.updatePolyline,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_POI.readPolyline,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_POI.deletePolyline,
            name: "ลบ",
            type: "string"
        },
    ],
    poiRectangle: [

        {
            value: SCOPES_POI.createRectangle,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_POI.updateRectangle,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_POI.readRectangle,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_POI.deleteRectangle,
            name: "ลบ",
            type: "string"
        },
    ],
    admin: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    company: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    contypes: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    concodes: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    perfixs: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    sexs: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    accounts: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    customers: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    drivers: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ],
    reportEvents: [
        {
            value: "OBD",
            name: "Overspeed by device",
            type: "string"
        },
        {
            value: "OBG",
            name: "Overspeed by groups",
            type: "string"
        },
        {
            value: "OBDATE",
            name: "Overspeed by date",
            type: "string"
        },
        {
            value: "O2BD",
            name: "Overspeed/2 by date",
            type: "string"
        },
        {
            value: "CSBD",
            name: "Card Swipe by device",
            type: "string"
        },
        {
            value: "CSBG",
            name: "Card swipe by groups",
            type: "string"
        },
        {
            value: "CSTBD",
            name: "Card swipe total by date",
            type: "string"
        },
        {
            value: "ODOBD",
            name: "Odometer by date",
            type: "string"
        },
        {
            value: "LE",
            name: "log events",
            type: "string"
        },
        {
            value: "LBG",
            name: "Log by group",
            type: "string"
        },
        {
            value: "POILBD",
            name: "POI log by device",
            type: "string"
        },
        {
            value: "POILBG",
            name: "POI log by group",
            type: "string"
        },
        {
            value: "OD",
            name: "Offline device",
            type: "string"
        }

    ],
    dltcer: [
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        }
    ],
    log: [
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: "DOWN",
            name: "ดาวน์โหลดข้อมูล",
            type: "string"
        }
    ],
    speed: [
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: "P",
            name: "พิมพ์",
            type: "string"
        }
    ],
    booking: [
        {
            value: SCOPES_CRUD.create,
            name: "สร้าง",
            type: "string"
        },
        {
            value: SCOPES_CRUD.update,
            name: "แก้ไข",
            type: "string"
        },
        {
            value: SCOPES_CRUD.read,
            name: "ดูข้อมูล",
            type: "string"
        },
        {
            value: SCOPES_CRUD.delete,
            name: "ลบ",
            type: "string"
        }
    ]
}


export const MainScopes = [
    {
        id: "dashboard",
        name: "หน้าหลัก",
        pages: PageScopes.dashboard,
    },
    {
        id: "poi",
        name: "POI Circle",
        pages: PageScopes.poiCircle,
    },
    {
        id: "poi",
        name: "POI Polygon",
        pages: PageScopes.poiPolygon,
    },
    {
        id: "poi",
        name: "POI Polyline",
        pages: PageScopes.poiPolyline,
    },
    {
        id: "poi",
        name: "POI Rectangle",
        pages: PageScopes.poiRectangle,
    },
    {
        id: "company",
        name: "บริษัท",
        pages: PageScopes.company,
    },
    {
        id: "contypes",
        name: "ประเภทผู้ใช้งาน",
        pages: PageScopes.contypes,
    },
    {
        id: "concodes",
        name: "ประเภทสภาพบุคคล",
        pages: PageScopes.concodes,
    },
    {
        id: "perfixs",
        name: "คำนำหน้า",
        pages: PageScopes.perfixs,
    },
    {
        id: "sexs",
        name: "เพศ",
        pages: PageScopes.sexs,
    },
    {
        id: "admin",
        name: "ผู้ดูแลระบบ",
        pages: PageScopes.admin,
    },
    {
        id: "accounts",
        name: "บัญชีผู้ใช้งาน",
        pages: PageScopes.accounts,
    },
    {
        id: "customers",
        name: "บัญชีลูกค้า",
        pages: PageScopes.customers,
    },
    {
        id: "drivers",
        name: "บัญชีพนักงานขับรถ",
        pages: PageScopes.drivers,
    },
    {
        id: "reportEvents",
        name: "รายงาน อีเว้นท์",
        pages: PageScopes.reportEvents,
    },
    {
        id: "DLTCER",
        name: "ใบรับรอง DLT",
        pages: PageScopes.dltcer,
    },
    {
        id: "LOG",
        name: "Log",
        pages: PageScopes.log,
    },
    {
        id: "SPEED",
        name: "รายงานความเร็ว",
        pages: PageScopes.speed
    },
    {
        id: "BOOKING",
        name: "booking",
        pages: PageScopes.booking
    }
]
