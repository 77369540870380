import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// material
import {
  Button,
  Divider,
  TextField,
  Container,
  Avatar,
  Collapse,
  ListItemButton,
  List,
  ListSubheader,
  ListItemText,
  Grid
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { Box } from '@mui/system';

// components
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useUserActions } from '../../components/_actions/userActions';
import { Api } from '../../components/api';
// ----------------------------------------------------------------------



// ----------------------------------------------------------------------



export default function SettingsProfile({ account }) {

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };


  const { enqueueSnackbar } = useSnackbar();
  const userActions = useUserActions();

  const navigate = useNavigate();
  const phoneRegExp = /((\+66|0)(\d{1,2}-?\d{3}-?\d{3,4}))/;
  const handleSnackbar = ({ variant, message }) => {
    enqueueSnackbar(message, { variant, autoHideDuration: 1500, anchorOrigin: { horizontal: 'right', vertical: 'bottom' } });
  };
  const LoginSchema = Yup.object().shape({
    firstname: Yup.string().required('First Name is required'),
    lastname: Yup.string().required('First Name is required'),
    email: Yup.string().email().notRequired(),
    phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').notRequired(),
  });

  const formik = useFormik({
    initialValues: {
      firstname: account.firstname || '',
      lastname: account.lastname || '',
      email: account.email || '',
      phone: account.phone || '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const data = JSON.stringify({
        ...account,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone,
      });
      await Api.put('v0/if/Contact', data)
        .then(res => {
          handleOnClose()
          handleSnackbar({ variant: 'success', message: 'success!' })
          userActions.setAuthInfo({ dataAuth: res.data })
        }
        ).catch(error => {
          const { message } = error
          handleSnackbar({ variant: 'error', message: message });
          // console.log('JSON.stringify(error)', error.message)
        }
        )
    }
  });
  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setErrors, resetForm } = formik;
  const handleOnClose = () => {
    navigate('/settings');
    // setErrors({});
    // resetForm();
  }
  return (
    <Page>
      <Container>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}  >
            <Box width={400} margin="auto" padding={2} >
              <List
                sx={{ width: '100%', maxWidth: 320, bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              // subheader={
              //   <ListSubheader component="div" id="nested-list-subheader">
              //     Nested List Items
              //   </ListSubheader>
              // }
              >
                <ListItemButton onClick={handleClick}>
                  <ListItemText primary="Profile" />
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Box padding={2} >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="First name"
                          {...getFieldProps('firstname')}
                          error={Boolean(touched.firstname && errors.firstname)}
                          helperText={touched.firstname && errors.firstname}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Last name"
                          {...getFieldProps('lastname')}
                          error={Boolean(touched.lastname && errors.lastname)}
                          helperText={touched.lastname && errors.lastname}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          disabled
                          fullWidth
                          size="small"
                          label="Email"
                          {...getFieldProps('email')}
                          error={Boolean(touched.email && errors.email)}
                          helperText={touched.email && errors.email}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          size="small"
                          label="Phone"
                          {...getFieldProps('phone')}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Collapse>
                <Divider style={{ marginTop: 10 }} />

                <Grid container spacing={1} justifyContent="center" paddingX={2} marginTop={2}>
                  <Grid item xs={6} justifyContent="center">
                    <Button
                      fullWidth
                      onClick={handleOnClose}
                      size="medium"
                      variant="outlined"
                      color="info"
                    // fullWidth
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6} justifyContent="center">
                    <LoadingButton
                      fullWidth
                      size="medium"
                      type="submit"
                      variant="contained"
                      color="info"
                      loading={isSubmitting}
                    >
                      Save
                    </LoadingButton>
                  </Grid>
                </Grid>
              </List>
            </Box>
          </Form>
        </FormikProvider>
      </Container>
    </Page >
  );
}
