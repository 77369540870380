import { LoadingButton, LocalizationProvider, MobileDateTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Box, Card, CardHeader, Container, Backdrop, ListItemText, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { fDateTo } from '../../utils/formatTime';
import * as Yup from 'yup';
import { Navigate, useLocation } from 'react-router-dom';
import { Api } from '../../components/api';
import { useSnackbar } from 'notistack';

export default function SpeedChart({ userId, account }) {
    const [devices, setDevices] = useState([])
    const [loading, setLoading] = useState(true)
    const [defaultDevice, setDefaultDevice] = useState(null)
    const location = useLocation()

    useEffect(() => {
        Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
            var devices_ = res.data.sort((a, b) => a.vehicle_id.localeCompare(b.vehicle_id));
            setDevices(devices_);
            if (location.state?.defaultValue !== undefined) {
                setDefaultDevice(devices_.find(d => d.device === location.state.defaultValue))
            }
            setLoading(loading => !loading)
        }
        ).catch(e => {
            console.log('error', e)
            setLoading(loading => !loading)
        })

        // return () => {
        //     second
        // }
    }, [userId])


    return (
        <>
            {!loading && (
                <Chart account={account} defaultDevice={defaultDevice} devices={devices} />
            )}
        </>
    )
}

function Chart({ account, defaultDevice, devices }) {
    const [speed, setspeed] = useState([])
    const [loading, setLoading] = useState(true)
    const { enqueueSnackbar } = useSnackbar();
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }

    const option = {
        chart: {
            height: 350,
            // stacked: true,
            // type: 'area',
            toolbar: {
                // show: false,
                tools: {
                    download: false,
                    reset: true,
                    pan: true,
                    // zoomin: false,
                    // zoomout: false,
                    zoom: {
                        enabled: true,
                        show: false
                    }
                }
            },
            export: {
                csv: {
                    dateFormatter(timestamp) {
                        return new Date(timestamp).toDateString()
                    }
                }
            }
            // sparkline: {
            //     enabled: true
            // }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: speed.map(values => values.time),
            labels: {
                // format: 'dd/MM/yyyy HH:mm',
                datetimeUTC: false,
            }
        },
        tooltip: {
            x: {
                format: 'dd/MM/yyyy HH:mm:ss'
            },
        },
        legend: {
            show: false,
            position: "top",
            horizontalAlign: "left"
            // top
            // right
            // bottom
            // left
        },
        // grid: {
        //     show: false
        // },
        // tooltip: {
        //     enabled: false,
        //     theme: 'dark',
        //     fixed: {
        //         enabled: false
        //     },
        //     x: {
        //         show: false
        //     },
        //     y: {
        //         show: false
        //     },
        //     marker: {
        //         show: false
        //     }
        // }
        // colors: ['#546E7A', '#E91E63']
    }
    const series = [{ name: "speed", data: speed.map(values => values.speed) }]

    const selectedTime = new Date();
    const schema = Yup.object().shape({
        deviceid: Yup.object().shape({
            device: Yup.string().required('Device is required'),
        }).nullable().required('Device is required'),
    });
    const formik = useFormik({
        initialValues: {
            deviceid: defaultDevice,
            date_s: selectedTime.setHours(0, 0, 0),
            date_e: selectedTime.setHours(23, 59, 59),
        },
        validationSchema: schema,
        onSubmit: (values, { setSubmitting }) => {
            const { deviceid, date_s, date_e } = values
            const { cocode } = account;
            Api.get('/v0/gps/gps_log', {
                params: {
                    cocode: cocode,
                    device: deviceid.device,
                    StartTime: fDateTo(date_s, 'yyyy-MM-dd HH:mm'),
                    EndTime: fDateTo(date_e, 'yyyy-MM-dd HH:mm')
                }
            })
                .then(res => {
                    var list = res.data;
                    const speeds = list.map(values => {
                        return { speed: values.speed, time: values.datetime_gps }
                    })
                    setspeed(speeds)
                    setSubmitting()
                }
                ).catch(e => {
                    setSubmitting()
                    setspeed([])
                    handleSnackbar({ message: "Not Found Data", variant: "error" })
                });
        }
    });
    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

    return (
        <Box sx={{ overflow: 'hidden' }}>
            <Box padding={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <Autocomplete
                                        id="deviceid"
                                        name="deviceid"
                                        options={
                                            devices
                                        }
                                        defaultValue={values.deviceid}
                                        size="small"
                                        getOptionLabel={(option) => option.vehicle_id}
                                        isOptionEqualToValue={(option, value) => option.device === value.device}
                                        fullWidth
                                        renderOption={(props, item) => (
                                            <li {...props} key={item.key}>
                                                <ListItemText>{item.vehicle_id}</ListItemText>
                                            </li>
                                        )}
                                        filterOptions={(options, state) => {
                                            return options.filter(opt => opt.vehicle_id.toUpperCase().includes(state.inputValue.toUpperCase()))
                                        }}
                                        onChange={(e, value) => {
                                            setFieldValue("deviceid", value);
                                            setspeed([])
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                label="เลือกอุปกรณ์ GPS"
                                                fullWidth
                                                autoComplete="off"
                                                name="deviceid"
                                                {...params}
                                                {...getFieldProps('deviceid')}
                                                error={Boolean(touched.deviceid && errors.deviceid)}
                                            />
                                        )}
                                    />
                                    <MobileDateTimePicker
                                        ampm={false}
                                        disableFuture={true}
                                        label="วันเวลาเริ่ม"
                                        allowKeyboardControl={false}
                                        value={values.date_s}
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        format="dd/MM/yyyy HH:mm"
                                        onChange={(val) => {
                                            setFieldValue('date_s', val);
                                            // if (values.date_s > values.date_e) {
                                            //   setFieldValue('date_e', fDateTo(val, 'yyyy-MM-dd HH:mm'));
                                            // }
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            {...params}
                                            {...getFieldProps('date_r')}
                                            fullWidth
                                            placeholder="DD/MM/YYYY"
                                            autoComplete="off"
                                            error={Boolean(touched.date_s && errors.date_s)}
                                            helperText={touched.date_s && errors.date_s} />}
                                    />
                                    <MobileDateTimePicker
                                        minDate={new Date(values.date_s)}
                                        ampm={false}
                                        disableFuture={true}
                                        label="ถึงวันเวลา"
                                        allowKeyboardControl={false}
                                        value={values.date_e}
                                        inputFormat="dd/MM/yyyy HH:mm"
                                        format="dd/MM/yyyy HH:mm"
                                        onChange={(val) => {
                                            setFieldValue('date_e', val);
                                            // setFieldValue('date_e', fDateTo(val, 'yyyy-MM-dd HH:mm'));
                                            // if (values.date_e < values.date_s) {
                                            //   setFieldValue('date_s', fDateTo(fDateTo(val, 'yyyy-MM-dd') + ' 00:00', 'yyyy-MM-dd HH:mm'));
                                            // }
                                        }}
                                        renderInput={(params) => <TextField
                                            size="small"
                                            {...params}
                                            {...getFieldProps('date_e')}
                                            fullWidth
                                            placeholder="DD/MM/YYYY"
                                            autoComplete="off"
                                            error={Boolean(touched.date_e && errors.date_e)}
                                            helperText={touched.date_e && errors.date_e} />}
                                    />
                                    <LoadingButton
                                        style={{ height: 40 }}
                                        fullWidth={false}
                                        size="small"
                                        type="submit"
                                        variant="contained"
                                        loading={isSubmitting}
                                    >
                                        ค้นหา
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Form>
                    </FormikProvider>
                </LocalizationProvider>
            </Box>
            {
                speed.length > 0 && (
                    <Box elevation={0} >
                        <CardHeader sx={{
                            padding: 0,
                            paddingLeft: "25px"
                        }} title="SPEED" subheader={`${values.deviceid?.name} Start : ${fDateTo(values.date_s, 'dd-MM-yyyy HH:mm')}  End : ${fDateTo(values.date_e, 'dd-MM-yyyy HH:mm')}`} />
                        <Box padding={"20px"} minHeight="400px"
                            className="apexcharts-custom"
                        >
                            <ReactApexChart
                                type="area"
                                options={option}
                                series={series}
                                height="100%"
                            />
                        </Box>
                    </Box>
                )
            }
        </Box>
    )
}
