import React from 'react';
import {
    authAtom,
    // userAtom
} from "./_state/";
import {
    Navigate,
    // useLocation,
    // useNavigate,
} from "react-router-dom";
import {
    useRecoilState,
    // useRecoilValue
} from 'recoil';
function PrivateRoute({ children }) {
    const [auth, setAuth] = useRecoilState(authAtom);
    React.useEffect(() => {
        const interval = setInterval(() => {
            // console.log(`isNotAuth`, isNotAuth);
            // const isNotAuth = localStorage.getItem('user') == null || localStorage.getItem('userId') == null
            const isNotAuth = localStorage.getItem('user') === null
            if (isNotAuth) {
                localStorage.clear();
                setAuth(false);
            };
        }, 1000);
        return () => {
            clearInterval(interval);
        }
    }, [setAuth]);

    return auth ? children : <Navigate to="/login" />;
}

export default PrivateRoute;