import { useSetRecoilState } from 'recoil';

// import { authAtom, usersAtom, userAtom } from '../_state';
import { authAtom, userAtom } from '../_state';

import { useNavigate } from "react-router-dom";
// import { UseBase64 } from '../_crypto/Base64';
import { UseAesCbc } from '../_crypto/AesCbc';


export { useUserActions };


function useUserActions() {
    // const base64 = UseBase64();
    const aesCbc = UseAesCbc();

    const setAuth = useSetRecoilState(authAtom);
    const setUser = useSetRecoilState(userAtom);
    const navigate = useNavigate();
    // const setUsers = useSetRecoilState(usersAtom);
    // const setUser = useSetRecoilState(userAtom);
    // const location = useLocation();
    // const from = location.state?.from?.pathname || "/";
    return {
        login,
        logout,
        setAuthInfo,
    }

    function login({ dataAuth }) {
        const jsonStr = JSON.stringify(dataAuth);
        const encode = aesCbc.encrypt(jsonStr);
        localStorage.setItem('user', encode);
        // localStorage.setItem('userId', userId);
        setAuth(true);
        setUser(dataAuth);
        navigate('/');
        // navigate('from', { replace: true });
    }

    function logout() {
        // localStorage.removeItem('user');
        localStorage.clear();
        setAuth(null);
        setUser(null);
        setUser(null);
        navigate('/login');
    }

    function setAuthInfo({ dataAuth }) {
        localStorage.removeItem('user');
        const jsonStr = JSON.stringify(dataAuth);
        const encode = aesCbc.encrypt(jsonStr);
        localStorage.setItem('user', encode);
        setUser(dataAuth);
    }

}
