import React, { useEffect, useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogDriver from './DialogDriver';
import DialogBushostess from './DialogBushostess';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useSnackbar } from 'notistack';



import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Api } from '../../components/api';
import _ from 'lodash';
import Calendar from './Calendar';
import Calendarbydate from './Calendarbydate';

const initialForm = {}
const initial = []
const initialcars = [
    { "car_type": "BUS-EV", "car_id": "EV-001", "licensePlate": "33-0001" },
    { "car_type": "BUS-EV", "car_id": "EV-002", "licensePlate": "33-0002" },
    { "car_type": "BUS-EV", "car_id": "EV-003", "licensePlate": "33-0003" },
    { "car_type": "BUS-44", "car_id": "B44-001", "licensePlate": "32-0001" },
    { "car_type": "BUS-44", "car_id": "B44-002", "licensePlate": "32-0002" },
    { "car_type": "BUS-44", "car_id": "B44-003", "licensePlate": "32-0003" },
    { "car_type": "BUS-44", "car_id": "B44-004", "licensePlate": "32-0004" },
]
export default function CarsDialog({ disabled, onHandle, car_type, sDate, eDate }) {
    const [formState, setFormState] = useState(initialForm)

    const [cars, setCars] = useState(initialcars)
    // useEffect(() => {
    //     Api.get("/v0/if/ContactByContype?contype=23")
    //         .then(res => {
    //             setCars(res.data)
    //         })
    // }, [])
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
        setFormState(initial)
    }
    const checkundefined = (data) => {
        if (data === undefined) {
            return ""
        }
        return data
    }
    const handleSave = (car) => {
        onHandle({
            ...car
        })
        setOpen(false)
        // Api.get(`/v0/if/Address?id=${customer.id}&addcode=0`)
        //     .then(res => {
        //         const address = res.data[0]
        //         console.log('address', address)
        //         setOpen(false)
        //         onHandle({
        //             ...car
        //         })
        //         setFormState(initial)
        //     })
    }
    return (
        <Box>
            <IconButton disabled={disabled} onClick={handleOpen} size="small"><SearchIcon fontSize="inherit" /></IconButton>
            <Dialog
                open={open}
                maxWidth="md"
                fullWidth={true}
                onClose={handleClose}
                slotProps={{
                    backdrop: {
                        style: {
                            // background: "#F8F8FF", opacity: 0.3
                            background: "#a7d8de", opacity: 0.2
                            // background: "#000", opacity: 0.1
                        }
                    }
                }}
            >
                <DialogTitle>เลือกรถ</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => { setOpen(false) }}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {/* <Calendar onClick={(car) => handleSave(car)}
                        car_type={car_type}
                    /> */}
                    <Calendarbydate onClick={(car) => handleSave(car)} car_type={car_type} sDate={sDate} eDate={eDate} />
                </DialogContent >
                <DialogActions>
                    {/* <Button onClick={handleSave}>บันทึก</Button> */}
                </DialogActions>
            </Dialog >
        </Box >
    )
}