import React, { useEffect, useRef, useState } from 'react'
import { Api, Apimongodb } from '../../components/api'
import { styled, alpha } from '@mui/material/styles';
import { Box, Stack, Paper, TextField, InputAdornment, AppBar, Toolbar, IconButton, Typography, Drawer, useMediaQuery, Backdrop, Snackbar, Button } from '@mui/material';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import { MHidden } from '../../components/@material-extend';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ControlSide from './ControlSide';
import ContainerMap from './ContainerMap';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CircularProgress from '@mui/material/CircularProgress';
import CreatePOI from './CreatePOI';
import EditPOI from './EditPOI';
import SearchIcon from "@mui/icons-material/Search";


export default function MainPoi({ account, permissions }) {
    const { c_coname } = account;

    const [open, setOpen] = useState(false);
    const [poigeolist, setpoigeolist] = useState([]);
    const [poigeolistshow, setpoigeolistshow] = useState([]);


    const [map, setMap] = useState(null);
    const [search, setSearch] = useState("");
    const refMapStyle = useRef(null);
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap");


    const [poigeolistSelect, setpoigeolistSelect] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = ({ message, variant }) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });
    };
    const handleSnackWithAction = ({ message, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };
    const [listGroupPoi, setListGroupPoi] = useState([])


    useEffect(() => {
        Apimongodb.get(`/Tracking/poigeocodeall?cname=${c_coname}`)
            .then(res => {
                setpoigeolist(res.data.result)
                // setpoigeolistSelect(select)
                onGetPoigeocodelist(res.data.result, true)
                // handleCloseBackdrop()
            })
            .catch(err => {
                console.log('err', err)
            })

        Api.get(`/Tracking/poigeocodegroups?cname=${c_coname}`)
            .then(res => {
                const data = res.data
                // setListGroupPoi(data)
                setListGroupPoi(data.concat(
                    {
                        "group_id": "",
                        "name": "ไม่มีกลุ่ม"
                    }
                ))
            })
            .catch(err => {
                setListGroupPoi([].concat(
                    {
                        "group_id": "",
                        "name": ""
                    }
                ))
            })
    }, [])


    const onGetPoigeoByCname = () => {
        handleOpenBackdrop()
        Apimongodb.get(`/Tracking/poigeocodeall?cname=${c_coname}`)
            .then(res => {
                setpoigeolist(res.data.result)
                onGetPoigeocodelist(res.data.result)
            })
            .catch(err => {
                console.log('err', err)
            })
    }

    const setPoigeoByCname = (data) => {
        setpoigeolist(data)
        onGetPoigeocodelist(data)
    }

    const groupPoiShow = (mode, data) => {
        if (mode === "select") {
            const ndata = poigeolistSelect.concat(data)
            setpoigeolistSelect(ndata)
        }
        if (mode === "remove") {
            const ndata = poigeolistSelect.filter((item) => {
                return data.indexOf(item) === -1
            })
            setpoigeolistSelect(ndata)

        }
    }

    const onGetPoigeocodelist = (result, firsttime) => {
        const select = result.map(v => v.poi_id)
        if (firsttime) {
            // setpoigeolistSelect([])
            // setpoigeolistSelect(select)
        }
        var data = JSON.stringify({
            "cname": c_coname,
            "poi_id": select
        });
        Apimongodb.post(`/Tracking/poigeocodelist`, data)
            .then(res => {
                setpoigeolistshow(res.data.result)
                handleCloseBackdrop()
            })
            .catch(err => {
                console.log('err', err)
                handleCloseBackdrop()
            })
    }

    const onChangeSelect = ({ mode, select }) => {
        if (mode === "select") {
            const ndata = poigeolistSelect.concat(select)
            setpoigeolistSelect(ndata)
        } else {
            const ndata = poigeolistSelect.filter(v => v !== select)
            setpoigeolistSelect(ndata)
        }
    }

    const onDeletePoi = (poi_id) => {
        handleSnackWithAction({
            message: "ต้องการที่จะลบ?", onHandle: () => {
                const req = poigeolistshow.find(v => v.poi_id === poi_id)
                Apimongodb.delete(`/Tracking/poigeocode`, { data: req })
                    .then((res) => {
                        onGetPoigeoByCname()
                    })
                    .catch((error) => {
                        handleClickVariant({ message: "ลบข้อมูลไม่สำเร็จ", variant: "error" })
                        console.log(error);
                    });
            }
        })
    }




    const poigeolistfilter = poigeolist.filter(v => v.poi_name.toLowerCase().includes(search.toLowerCase()))
    const poigeolistshowfilter = poigeolistshow.filter((item) => {
        return poigeolistSelect.indexOf(item.poi_id) >= 0
    }).filter(v => v.poi_name.toLowerCase().includes(search.toLowerCase()))




    const onClickMove = (poi_id) => {
        const data = poigeolistshow.find(v => v.poi_id === poi_id)
        if (data) {
            if (data.poi_data?.geometry.type === "Circle") {
                map.setView([data.poi_data.geometry.coordinates[0][1], data.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                    "animate": true,
                    "duration": 1,
                })
            }
            if (data.poi_data?.geometry.type === "Rectangle") {
                map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                    return [rectangleGeo[1], rectangleGeo[0]];
                }), {
                    "animate": true,
                    "duration": 1,
                })
            }
            if (data.poi_data?.geometry.type === "Polyline") {
                map.fitBounds(data.poi_data.geometry.coordinates.map((rectangleGeo) => {
                    return [rectangleGeo[1], rectangleGeo[0]];
                }), {
                    "animate": true,
                    "duration": 1,
                })
            }
            if (data.poi_data?.geometry.type === "Polygon") {
                map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                    return [rectangleGeo[1], rectangleGeo[0]];
                }), {
                    "animate": true,
                    "duration": 1,
                })
            }
        }
    }




    const [poiSelect, setPoiSelect] = useState(null);
    const [handleEditData, setHandleEditData] = useState(null);
    const [modeFun, setModeFun] = useState('');

    const onEdited = (select) => {
        setPoiSelect(select)
        setHandleEditData(select)
        setModeFun('edit')
    }


    const onClickMoveEdited = (poi_id) => {
        const data = poigeolistshow.find(v => v.poi_id === poi_id)
        if (data) {
            if (data.poi_data?.geometry.type === "Circle") {
                map.setView([data.poi_data.geometry.coordinates[0][1], data.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                    "animate": true,
                    "duration": 1,
                })
                const select = {
                    "cname": data.cname,
                    "poi_id": data.poi_id,
                    "poi_name": data.poi_name,
                    "group_id": data.group_id,
                    "poi_data": data.poi_data,
                    "type": "Feature",
                    "properties": {
                        "radius": data.poi_data.properties.radius,
                        "shape": "circle"
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": data.poi_data.geometry.coordinates[0]
                    }
                }
                onEdited(select)
            }
            if (data.poi_data?.geometry.type === "Rectangle") {
                map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                    return [rectangleGeo[1], rectangleGeo[0]];
                }), {
                    "animate": true,
                    "duration": 1,
                })
                const select = {
                    "cname": data.cname,
                    "poi_id": data.poi_id,
                    "poi_name": data.poi_name,
                    "group_id": data.group_id,
                    "poi_data": data.poi_data,
                    "type": "Feature",
                    "properties": {
                        ...data.poi_data.properties,
                        "shape": "rectangle"
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": data.poi_data.geometry.coordinates
                    }
                }
                onEdited(select)
            }
            if (data.poi_data?.geometry.type === "Polyline") {
                map.fitBounds(data.poi_data.geometry.coordinates.map((rectangleGeo) => {
                    return [rectangleGeo[1], rectangleGeo[0]];
                }), {
                    "animate": true,
                    "duration": 1,
                })
                const select = {
                    "cname": data.cname,
                    "poi_id": data.poi_id,
                    "poi_name": data.poi_name,
                    "group_id": data.group_id,
                    "poi_data": data.poi_data,
                    "type": "Feature",
                    "properties": {
                        ...data.poi_data.properties,
                        "shape": "polyline"
                    },
                    "geometry": {
                        "type": "LineString",
                        "coordinates": data.poi_data.geometry.coordinates
                    }
                }
                onEdited(select)
            }
            if (data.poi_data?.geometry.type === "Polygon") {
                map.fitBounds(data.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                    return [rectangleGeo[1], rectangleGeo[0]];
                }), {
                    "animate": true,
                    "duration": 1,
                })
                const select = {
                    "cname": data.cname,
                    "poi_id": data.poi_id,
                    "poi_name": data.poi_name,
                    "group_id": data.group_id,
                    "poi_data": data.poi_data,
                    "type": "Feature",
                    "properties": {
                        ...data.poi_data.properties,
                        "shape": "polygon"
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": data.poi_data.geometry.coordinates
                    }
                }
                onEdited(select)

            }
        }
    }

    const onGetGroups = () => {
        Api.get(`/Tracking/poigeocodegroups?cname=${c_coname}`)
            .then(res => {
                const data = res.data
                setListGroupPoi(data.concat(
                    {
                        "group_id": "",
                        "name": "ไม่มีกลุ่ม"
                    }
                ))
            })
            .catch(err => {
                setListGroupPoi([].concat(
                    {
                        "group_id": "",
                        "name": ""
                    }
                ))
            })
    }


    const [openBackdrop, setOpenBackdrop] = useState(true);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleOpenBackdrop = () => {
        setOpenBackdrop(true);
    };


    const [latlng, setLatlng] = useState()

    const onSubmitSearch = (e) => {
        e.preventDefault()
        if (latlng.includes(",")) {
            const lat = latlng?.split(",")[0]
            const lng = latlng?.split(",")[1].replace(' ', '')
            const islatlng = [parseFloat(lat), parseFloat(lng)]
            map.flyTo(islatlng, 17, {
                "animate": true,
                "duration": 1,
            })
        }
    }

    return (
        <RootStyle>
            <AppBarStyle elevation={3} >
                <ToolbarStyle>
                    <IconButton onClick={() => setOpen(!open)} sx={{ mr: 1, color: 'text.primary' }}>
                        <MenuRoundedIcon />
                    </IconButton>
                </ToolbarStyle>
            </AppBarStyle>
            <DrawerStyle >
                <MHidden width="mdUp">
                    <Drawer
                        open={open}
                        onClose={() => setOpen(!open)}
                        // variant="permanent"
                        PaperProps={{
                            sx: { width: 320 }
                        }}
                    >
                        <Box sx={{ px: 1, py: 1 }}>
                            <Toolbar style={{ minHeight: '64px', paddingLeft: 35, paddingRight: 35 }}>
                                <Typography variant='h4' >POI</Typography>
                                <Box sx={{ flexGrow: 1 }} />
                            </Toolbar>
                        </Box>
                        <Box sx={{ paddingX: "5px" }}>
                            <ControlSide onGetGroups={onGetGroups} account={account} groupPoiShow={groupPoiShow} listGroupPoi={listGroupPoi} onClickMoveEdited={onClickMoveEdited} permissions={permissions} poigeolist={poigeolistfilter} map={map} onChangeSelect={onChangeSelect} onClickMove={onClickMove} poigeolistSelect={poigeolistSelect} onDeletePoi={onDeletePoi} />
                        </Box>
                    </Drawer>
                </MHidden>
                <MHidden width="mdDown">
                    <Drawer
                        open={true}
                        variant="permanent"
                        PaperProps={{
                            sx: { width: 320, height: 'calc(100% - 56px)', bottom: 56, padding: '10px' }
                        }}
                    >
                        <Box>
                            <Toolbar style={{ minHeight: '30px', paddingLeft: "0px", paddingRight: "0px" }}>
                                {/* <Typography variant='h4'>POI</Typography>
                                <Box sx={{ flexGrow: 1 }} /> */}
                                <TextField size="small" placeholder='POI' fullWidth value={search} onChange={(e) => { setSearch(e.target.value) }} />
                            </Toolbar>
                        </Box>
                        <Box sx={{ paddingX: "5px" }}>
                            <ControlSide onGetGroups={onGetGroups} account={account} groupPoiShow={groupPoiShow} listGroupPoi={listGroupPoi} onClickMoveEdited={onClickMoveEdited} permissions={permissions} poigeolist={poigeolistfilter} map={map} onChangeSelect={onChangeSelect} onClickMove={onClickMove} poigeolistSelect={poigeolistSelect} onDeletePoi={onDeletePoi} />
                        </Box>
                        <Paper sx={{ position: 'fixed', width: 320, bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
                            <BottomNavigationBar ative="settings" />
                        </Paper>
                    </Drawer>
                </MHidden>
            </DrawerStyle>
            <MainStyle>
                <Box>
                    <Box sx={{ position: "absolute", backgroundColor: "white", borderRadius: "10px", width: "200px", right: "50px", top: "10px", zIndex: 2 }}>
                        <form onSubmit={onSubmitSearch}>
                            <TextField size="small" fullWidth label="location lat,lng" value={latlng} onChange={(e) => { setLatlng(e.target.value) }}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton type="submit" edge="end">
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </form>
                    </Box>
                    <ContainerMap
                        permissions={permissions}
                        map={map}
                        setMap={setMap}
                        refMapStyle={refMapStyle}
                        selectedMapStyle={selectedMapStyle}
                        setSelectedMapStyle={setSelectedMapStyle}
                        poigeolistshow={poigeolistshowfilter}
                        onGetPoigeoByCname={onGetPoigeoByCname}
                        setpoigeolistshow={setpoigeolistshow}
                        onChangeSelect={onChangeSelect}
                        account={account}
                        poiSelect={poiSelect}
                        setPoiSelect={setPoiSelect}
                        handleEditData={handleEditData}
                        setHandleEditData={setHandleEditData}
                        modeFun={modeFun}
                        setModeFun={setModeFun}
                        onEdited={onEdited}
                    />
                </Box>
                {
                    modeFun === "create" && handleEditData !== null && (
                        <CreatePOI
                            handleEditData={handleEditData}
                            setHandleEditData={setHandleEditData}
                            setPoiSelect={setPoiSelect}
                            onGetPoigeoByCname={onGetPoigeoByCname}
                            onChangeSelect={onChangeSelect}
                            listGroupPoi={listGroupPoi}
                            setPoigeoByCname={setPoigeoByCname}
                            c_coname={c_coname}
                        />
                    )
                }
                {
                    modeFun === "edit" && handleEditData !== null && (
                        <EditPOI
                            handleEditData={handleEditData}
                            setHandleEditData={setHandleEditData}
                            setPoiSelect={setPoiSelect}
                            onGetPoigeoByCname={onGetPoigeoByCname}
                            // handleCloseBackdrop={handleCloseBackdrop}
                            // onGetPoiGroup={onGetPoiGroup}
                            // listPoiShow={listPoiShow}
                            listGroupPoi={listGroupPoi}
                        />
                    )
                }
                <MHidden width="mdUp">
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigationBar ative="settings" />
                    </Paper>
                </MHidden>
            </MainStyle>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </RootStyle>
    )
}


const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
        paddingTop: 66,
        paddingBottom: 56,
    }
}));


const AppBarStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    width: 0,
    maxHeight: 64,
    [theme.breakpoints.down('md')]: {
        // width: `calc(100% - ${350 + 1}px)`
        width: "100%"
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    minHeight: '64px',
}));
const DrawerStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        width: 320
    },
    width: 0,
}));

