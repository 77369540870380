import React from 'react'
import L from 'leaflet';

import markerIcon from '../../img/station_marker.svg'
import { Marker, Popup, Tooltip } from 'react-leaflet';

const markersIcon = new L.Icon({
    iconUrl: markerIcon,
    iconRetinaUrl: markerIcon,
    popupAnchor: [19.5, -20],
    iconSize: [40, 40],
    iconAnchor: [19.5, 40],
});

export default function MarkerLabel({ position, label }) {
    return (
        <Marker icon={markersIcon} position={position} >
            {/* {label && (
                <Tooltip direction="top" offset={[0, -40]} opacity={1}>
                    {label}
                </Tooltip>
            )} */}

            <Popup offset={[-20, -10]}>
                {label}
            </Popup>
        </Marker>
    )
}
