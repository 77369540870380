import React, { useState } from 'react'
import { Box, Collapse, TextField, Stack, Grid, Checkbox, Typography, Table, TableBody, TableHead, TableRow, TableCell, Button, IconButton, InputAdornment, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Autocomplete } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export default function SubWorkType() {

    const { state } = useLocation()

    const [openNew, setOpenNew] = useState(false)
    const [update, setUpdate] = useState({})

    const onHandleOpenNew = () => {
        setOpenNew(true)
    }
    const onHandleCloseNew = () => {
        setOpenNew(false)
    }

    const [openUpdate, setOpenUpdate] = useState(false)

    const onHandleOpenUpdate = (data) => {
        setOpenUpdate(true)
        setUpdate(data)
    }
    const onHandleCloseUpdate = () => {
        setOpenUpdate(false)
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleClickVariant = (message, variant) => {
        enqueueSnackbar(message, { variant: variant, anchorOrigin: { vertical: 'bottom', horizontal: 'right' }, autoHideDuration: 1000 });

    };
    const handleSnackWithAction = ({ message, data, onHandle }) => {
        closeSnackbar()
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            // style: { bgcolor: "whitesmoke !important" },
            action: (key) => {
                return (
                    <React.Fragment>
                        <Stack direction="row" spacing={1}>
                            <Button
                                size='small'
                                color="error"
                                variant="contained"
                                onClick={() => {
                                    console.log('data', data)
                                    closeSnackbar(key)
                                    onHandle()
                                }}
                            >
                                <CheckRoundedIcon fontSize="small" />
                            </Button>
                            <Button size='small' variant="contained" color="success" onClick={() => closeSnackbar(key)}>
                                <CloseRoundedIcon fontSize="small" />
                            </Button>
                        </Stack>
                    </React.Fragment>
                )
            }
        });
    };



    const onHandleDel = ({ }) => {
        handleClickVariant("ลบข้อมูลเสร็จสิ้น !", "success")
    }

    return (
        <div>

            <Box sx={{ justifyContent: "center", padding: "10px" }}>
                <Typography align="center" variant="h6" >ประเภทงานย่อย : {state?.name}</Typography>
            </Box>
            {/* <Box sx={{ padding: "10px" }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TextField size="small" disabled label="รหัสประเภทงาน" value={state.id} fullWidth />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField size="small" disabled label="ชื่อประเภทงาน" value={state.name} fullWidth />
                    </Grid>
                </Grid>
            </Box> */}
            <Box sx={{ justifyContent: "center", padding: "10px" }}>
                <TextField size="small" label='ค้นหา'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" >
                                <IconButton size="small"  ><SearchIcon fontSize="inherit" /></IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            <Table dense>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>ชื่อ</TableCell>
                        <TableCell align="right">
                            <Button size="small" onClick={onHandleOpenNew} variant="outlined" >
                                <AddOutlinedIcon />
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        state?.subtypes.map((sub, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell>{sub.id}</TableCell>
                                    <TableCell>{sub.name}</TableCell>
                                    <TableCell align="right">
                                        <Stack direction="row" justifyContent="flex-end" spacing={1}>
                                            <IconButton size="small" onClick={() => { onHandleOpenUpdate(sub) }} >
                                                <EditNoteIcon />
                                            </IconButton>
                                            <IconButton size="small"
                                                onClick={() => {
                                                    handleSnackWithAction({ message: "ต้องการลบข้อมูลประเภท?", data: sub, onHandle: onHandleDel })
                                                }}
                                            >
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }

                </TableBody>
            </Table>

            <Dialog
                open={openNew}
                maxWidth="md"
                fullWidth={true}
                onClose={onHandleCloseNew}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>ประเภทงาน</DialogTitle>
                <DialogContent>
                    <Box sx={{ padding: "10px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" label="รหัสประเภทงาน" value="" fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" label="ชื่อประเภทงาน" value="" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} marginBottom="10px" justifyContent="center">
                                    <Button variant="contained">บันทึก</Button>
                                    <Button variant="contained" onClick={onHandleCloseNew}>ยกเลิก</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openUpdate}
                maxWidth="md"
                fullWidth={true}
                onClose={onHandleCloseUpdate}
                slotProps={{
                    backdrop: {
                        style: {
                            background: "#a7d8de", opacity: 0.2
                        }
                    }
                }}
            >
                <DialogTitle>ประเภทงาน</DialogTitle>
                <DialogContent>
                    <Box sx={{ padding: "10px" }}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" label="รหัสประเภทงาน" value={update.id} fullWidth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField size="small" label="ชื่อประเภทงาน" value={update.name} fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" spacing={1} marginBottom="10px" justifyContent="center">
                                    <Button variant="contained">บันทึก</Button>
                                    <Button variant="contained" onClick={onHandleCloseUpdate}>ยกเลิก</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}
