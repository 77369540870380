import React, { useState } from 'react'
import { Box, Table, TableBody, TableHead, TableCell, TableRow, TableFooter, Stack, TextField, IconButton, Divider } from '@mui/material'
import Page from '../../components/Page'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import MoreVert from '@mui/icons-material/MoreVert';

const MainStyle = styled(Box)(({ theme }) => ({
    margin: 'auto',
    paddingBottom: 60,
    overflow: 'auto'
}));

export default function MainDrivers() {
    const [rows, setRows] = useState([
        { name: 'name1', lastName: "lname1" },
        { name: 'name2', lastName: "lname2" },
        { name: 'name3', lastName: "lname3" }
    ])
    const cloums = [{ dataKey: "name", title: "ชื่อ" }, { dataKey: "lastName", title: "นามสกุล" }, { dataKey: "", title: "" }]
    const navigate = useNavigate()
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [openDropDown, setOpenDropDown] = useState(false);


    const handleChange = ({ target: { value, name } }) => {
        setSearch(value)
    }


    const handleChangeRows = () => {
        setRows([])
    }


    const handleClickNew = () => {
        setOpen(!open)
    }

    const handleClickDel = () => {
        console.log('handleClickDel')
    }

    const handleClickMore = () => {
        // setOpenDropDown(!openDropDown)
        console.log('handleClickMore')
    }

    const handleClickEdit = () => {
        console.log('handleClickEdit')
    }

    return (
        <Page>
            <Box flexGrow={1} padding={1}>
                <Stack spacing={1} direction="row">
                    <TextField sx={{ backgroundColor: "white" }} placeholder='Search' size="small" onChange={handleChange} value={search} />
                    <IconButton
                        onClick={() => { navigate("new") }}
                    >
                        <PersonAddIcon />
                    </IconButton>
                </Stack>
            </Box>
            <Divider />
            <MainStyle>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                cloums.map((cloum, index) => {
                                    return (
                                        <TableCell key={`tableHeadCell-${index}`}>{cloum.title}</TableCell>
                                    )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, index) => {
                                return (
                                    <TableRow key={`tableRow-${index}`}>
                                        {
                                            cloums.map((cloum, index) => {
                                                return (
                                                    <TableCell key={`tableRowCell-${index}`}>
                                                        {
                                                            cloum.dataKey !== `` ?
                                                                row[cloum.dataKey] :
                                                                <Stack spacing={1} direction="row" alignItems="end" display="flex" justifyItems="end" justifyContent="end" >
                                                                    <IconButton size="small"
                                                                        onClick={handleClickEdit}
                                                                    >
                                                                        <Edit fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton size="small"
                                                                        onClick={handleClickMore}
                                                                    >
                                                                        <Delete fontSize="small" />
                                                                    </IconButton>
                                                                    <IconButton size="small"
                                                                        onClick={handleClickDel}
                                                                    >
                                                                        <MoreVert fontSize="small" />
                                                                    </IconButton>
                                                                </Stack>
                                                        }
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </MainStyle>
        </Page >
    )
}
