import { Circle, MapContainer, Polygon, Polyline, Rectangle, TileLayer } from "react-leaflet";
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import ListTileLayer from "../../layouts/ListTileLayer";
import EditControlPOI from "./EditControlPOI";
import DrawControl from "./DrawControl";
import CreatePOI from "./CreatePOI";
import EditPOI from "./EditPOI";
import { ControlPoiLayerMap } from "./ButtonLayerControl";
import HasPermission from "../../haspermission/HasPermission";

const MapContainerStyle = styled(MapContainer)(({ theme }) => ({
    overflow: 'hidden',
    height: '100vh',
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
    userSelect: "none",
    zIndex: 1,
    [theme.breakpoints.down('md')]: {
        height: "calc(100vh - 124px)",
        // height: '50vh'
    },
}));
const center = [13.839660198254604, 100.63469639884744];
const zoom = 13;


export default function ContainerMap({
    map,
    setMap,
    refMapStyle,
    selectedMapStyle,
    setSelectedMapStyle,
    poiSelect,
    setPoiSelect,
    editRef,
    setEditRef,
    handleCreated,
    editMode,
    setEditMode,
    refControlPOI,
    listPoiShow,
    listGroupPoi,
    editResize,
    editMove,
    handleEditData,
    setHandleEditData,
    setModeFun,
    modeFun,
    setListPoiShow,
    handleOpenBackdrop,
    handleCloseBackdrop,
    onGetPoiGroup,
    permissions
}) {


    const CreateCircle = (circle) => {
        return <Circle center={circle.poi_data.geometry.coordinates} radius={circle.poi_data.properties.radius} />
    }


    useEffect(() => {
        if (poiSelect !== null && editRef !== null) {
            editRef._toolbars.edit._modes.edit.handler.enable()

        }
    }, [poiSelect])


    const onEdited = (select) => {
        setPoiSelect(select)
        setHandleEditData(select)
        setModeFun('edit')
    }

    const onClickLayer = (value) => {
        if (refMapStyle.current) {
            refMapStyle.current.setUrl(value.url);
            setSelectedMapStyle(value.id)
            localStorage.setItem('selectedMapStyle', value.id)
        }
    }


    return (
        <Box
            sx={{
                position: "relative"
            }}
        >
            <MapContainerStyle
                preferCanvas={true}
                whenCreated={(ref) => {
                    setMap(ref)
                }}
                center={center}
                zoom={zoom}
                minZoom={3}
                zoomControl={false}
                scrollWheelZoom={true}
                doubleClickZoom={false}
                attributionControl={false}
            >
                <TileLayer
                    ref={refMapStyle}
                    url={ListTileLayer.find(value => value.id === selectedMapStyle).url}
                    maxNativeZoom={19}
                    maxZoom={22}
                />
                <ControlPoiLayerMap onClick={onClickLayer} selected={selectedMapStyle} />

                <EditControlPOI
                    poiSelect={poiSelect}
                    setEditRef={setEditRef}
                    handleCreated={handleCreated}
                    refControlPOI={refControlPOI}
                    editResize={editResize}
                    editMove={editMove}
                />
                {
                    listPoiShow.filter(c => c.poi_id !== poiSelect?.poi_id).map((geopoi, id_geopoi) => (
                        <React.Fragment key={"geopoiId" + id_geopoi}>
                            {geopoi.poi_data?.geometry.type === "Circle" && (
                                <HasPermission
                                    RenderError={() =>
                                        <Circle
                                            center={[geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]]}
                                            radius={geopoi.poi_data.properties.radius}
                                            eventHandlers={{
                                                click: () => {
                                                    map.setView([geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                                                        "animate": true,
                                                        "duration": 1,
                                                    })
                                                }
                                            }}
                                        />
                                    }
                                    permissions={permissions}
                                    scope={["update"]}
                                >
                                    <Circle
                                        center={[geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]]}
                                        radius={geopoi.poi_data.properties.radius}
                                        eventHandlers={{
                                            click: () => {
                                                const select = {
                                                    "cname": geopoi.cname,
                                                    "poi_id": geopoi.poi_id,
                                                    "poi_name": geopoi.poi_name,
                                                    "group_id": geopoi.group_id,
                                                    "poi_data": geopoi.poi_data,
                                                    "type": "Feature",
                                                    "properties": {
                                                        "radius": geopoi.poi_data.properties.radius,
                                                        "shape": "circle"
                                                    },
                                                    "geometry": {
                                                        "type": "Point",
                                                        "coordinates": geopoi.poi_data.geometry.coordinates[0]
                                                    }
                                                }
                                                // editRef._toolbars.edit._modes.edit.handler.enable()
                                                onEdited(select)
                                                map.setView([geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                                                    "animate": true,
                                                    "duration": 1,
                                                })
                                            }
                                        }}
                                    />
                                </HasPermission>
                            )}
                            {geopoi.poi_data?.geometry.type === "Polyline" && (
                                <HasPermission
                                    RenderError={() =>
                                        <Polyline
                                            eventHandlers={{
                                                click: () => {
                                                    map.fitBounds(geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                                        return [polylineGeo[1], polylineGeo[0]];
                                                    }), {
                                                        "animate": true,
                                                        "duration": 1,
                                                    })
                                                }
                                            }}
                                            positions={geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                                return [polylineGeo[1], polylineGeo[0]];
                                            })}
                                        />
                                    }
                                    permissions={permissions}
                                    scope={["update"]}
                                >
                                    <Polyline
                                        eventHandlers={{
                                            click: () => {
                                                // onEdited(circle)
                                                const select = {
                                                    "cname": geopoi.cname,
                                                    "poi_id": geopoi.poi_id,
                                                    "poi_name": geopoi.poi_name,
                                                    "group_id": geopoi.group_id,
                                                    "poi_data": geopoi.poi_data,
                                                    "type": "Feature",
                                                    "properties": {
                                                        ...geopoi.poi_data.properties,
                                                        "shape": "polyline"
                                                    },
                                                    "geometry": {
                                                        "type": "LineString",
                                                        "coordinates": geopoi.poi_data.geometry.coordinates
                                                    }
                                                }
                                                onEdited(select)
                                                map.fitBounds(geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                                    return [polylineGeo[1], polylineGeo[0]];
                                                }), {
                                                    "animate": true,
                                                    "duration": 1,
                                                })
                                            }
                                        }}
                                        positions={geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                            return [polylineGeo[1], polylineGeo[0]];
                                        })}
                                    />
                                </HasPermission>
                            )}
                            {geopoi.poi_data?.geometry.type === "Rectangle" && (
                                <HasPermission
                                    RenderError={() =>
                                        <Rectangle
                                            eventHandlers={{
                                                click: () => {
                                                    // onEdited(circle)
                                                    const select = {
                                                        "cname": geopoi.cname,
                                                        "poi_id": geopoi.poi_id,
                                                        "poi_name": geopoi.poi_name,
                                                        "group_id": geopoi.group_id,
                                                        "poi_data": geopoi.poi_data,
                                                        "type": "Feature",
                                                        "properties": {
                                                            ...geopoi.poi_data.properties,
                                                            "shape": "rectangle"
                                                        },
                                                        "geometry": {
                                                            "type": "Polygon",
                                                            "coordinates": geopoi.poi_data.geometry.coordinates
                                                        }
                                                    }
                                                    map.fitBounds(geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                        return [rectangleGeo[1], rectangleGeo[0]];
                                                    }), {
                                                        "animate": true,
                                                        "duration": 1,
                                                    })
                                                }
                                            }}
                                            bounds={
                                                geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                    return [rectangleGeo[1], rectangleGeo[0]];
                                                })
                                            }
                                        />
                                    }
                                    permissions={permissions}
                                    scope={["update"]}
                                >
                                    <Rectangle
                                        eventHandlers={{
                                            click: () => {
                                                // onEdited(circle)
                                                const select = {
                                                    "cname": geopoi.cname,
                                                    "poi_id": geopoi.poi_id,
                                                    "poi_name": geopoi.poi_name,
                                                    "group_id": geopoi.group_id,
                                                    "poi_data": geopoi.poi_data,
                                                    "type": "Feature",
                                                    "properties": {
                                                        ...geopoi.poi_data.properties,
                                                        "shape": "rectangle"
                                                    },
                                                    "geometry": {
                                                        "type": "Polygon",
                                                        "coordinates": geopoi.poi_data.geometry.coordinates
                                                    }
                                                }
                                                onEdited(select)
                                                map.fitBounds(geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                    return [rectangleGeo[1], rectangleGeo[0]];
                                                }), {
                                                    "animate": true,
                                                    "duration": 1,
                                                })
                                            }
                                        }}
                                        bounds={
                                            geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                return [rectangleGeo[1], rectangleGeo[0]];
                                            })
                                        }
                                    />
                                </HasPermission>
                            )}
                            {geopoi.poi_data?.geometry.type === "Polygon" && (
                                <HasPermission
                                    RenderError={() =>
                                        <Polygon
                                            eventHandlers={{
                                                click: () => {
                                                    map.fitBounds(geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                        return [rectangleGeo[1], rectangleGeo[0]];
                                                    }), {
                                                        "animate": true,
                                                        "duration": 1,
                                                    })
                                                }
                                            }}
                                            positions={
                                                geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                    return [rectangleGeo[1], rectangleGeo[0]];
                                                })
                                            }
                                        />
                                    }
                                    permissions={permissions}
                                    scope={["update"]}
                                >
                                    <Polygon
                                        eventHandlers={{
                                            click: () => {
                                                // onEdited(circle)
                                                const select = {
                                                    "cname": geopoi.cname,
                                                    "poi_id": geopoi.poi_id,
                                                    "poi_name": geopoi.poi_name,
                                                    "group_id": geopoi.group_id,
                                                    "poi_data": geopoi.poi_data,
                                                    "type": "Feature",
                                                    "properties": {
                                                        ...geopoi.poi_data.properties,
                                                        "shape": "polygon"
                                                    },
                                                    "geometry": {
                                                        "type": "Polygon",
                                                        "coordinates": geopoi.poi_data.geometry.coordinates
                                                    }
                                                }
                                                onEdited(select)
                                                map.fitBounds(geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                    return [rectangleGeo[1], rectangleGeo[0]];
                                                }), {
                                                    "animate": true,
                                                    "duration": 1,
                                                })
                                            }
                                        }}
                                        positions={
                                            geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                                return [rectangleGeo[1], rectangleGeo[0]];
                                            })
                                        }
                                    />
                                </HasPermission>
                            )}

                        </React.Fragment>
                    ))
                }


            </MapContainerStyle>

            {/* <HasPermission
                permissions={permissions}
                scopes={[SCOPES_CRUD.canCreate]}
            > */}
            <DrawControl
                editMode={editMode}
                setEditMode={setEditMode}
                editRef={editRef}
                drawCircle={true}
                setPoiSelect={setPoiSelect}
                setHandleEditData={setHandleEditData}
                poiSelect={poiSelect}
                permissions={permissions}
            />
            {/* </HasPermission> */}
            {
                // (modeFun === "create" && handleEditData !== null) && (
                modeFun === "create" && handleEditData !== null && (
                    <CreatePOI
                        listGroupPoi={listGroupPoi}
                        handleEditData={handleEditData}
                        setHandleEditData={setHandleEditData}
                        setPoiSelect={setPoiSelect}
                        setModeFun={setModeFun}
                        setListPoiShow={setListPoiShow}

                        handleOpenBackdrop={handleOpenBackdrop}
                        handleCloseBackdrop={handleCloseBackdrop}
                        onGetPoiGroup={onGetPoiGroup}
                        listPoiShow={listPoiShow}
                    />
                )
            }
            {
                modeFun === "edit" && handleEditData !== null && (
                    <EditPOI
                        listGroupPoi={listGroupPoi}
                        handleEditData={handleEditData}
                        setHandleEditData={setHandleEditData}
                        setPoiSelect={setPoiSelect}
                        setModeFun={setModeFun}
                        setListPoiShow={setListPoiShow}

                        handleOpenBackdrop={handleOpenBackdrop}
                        handleCloseBackdrop={handleCloseBackdrop}
                    />
                )
            }
        </Box >
    )
}



const ItemPOI = React.memo(({ listPoiShow, map, onEdited }) => {
    console.log('render item : ');

    return (
        <>
            {
                listPoiShow.map((geopoi, id_geopoi) => (
                    <React.Fragment key={"geopoiId" + id_geopoi}>
                        {geopoi.poi_data?.geometry.type === "Circle" && (
                            <Circle
                                center={[geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]]}
                                radius={geopoi.poi_data.properties.radius}
                                eventHandlers={{
                                    click: () => {
                                        const select = {
                                            "cname": geopoi.cname,
                                            "poi_id": geopoi.poi_id,
                                            "poi_name": geopoi.poi_name,
                                            "group_id": geopoi.group_id,
                                            "poi_data": geopoi.poi_data,
                                            "type": "Feature",
                                            "properties": {
                                                "radius": geopoi.poi_data.properties.radius,
                                                "shape": "circle"
                                            },
                                            "geometry": {
                                                "type": "Point",
                                                "coordinates": geopoi.poi_data.geometry.coordinates[0]
                                            }
                                        }
                                        // editRef._toolbars.edit._modes.edit.handler.enable()
                                        onEdited(select)
                                        map.setView([geopoi.poi_data.geometry.coordinates[0][1], geopoi.poi_data.geometry.coordinates[0][0]], map.getZoom(), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                }}
                            />
                        )}
                        {geopoi.poi_data?.geometry.type === "Polyline" && (
                            <Polyline
                                eventHandlers={{
                                    click: () => {
                                        // onEdited(circle)
                                        const select = {
                                            "cname": geopoi.cname,
                                            "poi_id": geopoi.poi_id,
                                            "poi_name": geopoi.poi_name,
                                            "group_id": geopoi.group_id,
                                            "poi_data": geopoi.poi_data,
                                            "type": "Feature",
                                            "properties": {
                                                ...geopoi.poi_data.properties,
                                                "shape": "polyline"
                                            },
                                            "geometry": {
                                                "type": "LineString",
                                                "coordinates": geopoi.poi_data.geometry.coordinates
                                            }
                                        }
                                        onEdited(select)
                                        map.fitBounds(geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                            return [polylineGeo[1], polylineGeo[0]];
                                        }), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                }}
                                positions={geopoi.poi_data.geometry.coordinates.map((polylineGeo) => {
                                    return [polylineGeo[1], polylineGeo[0]];
                                })}
                            />
                        )}
                        {geopoi.poi_data?.geometry.type === "Rectangle" && (
                            <Rectangle
                                eventHandlers={{
                                    click: () => {
                                        // onEdited(circle)
                                        const select = {
                                            "cname": geopoi.cname,
                                            "poi_id": geopoi.poi_id,
                                            "poi_name": geopoi.poi_name,
                                            "group_id": geopoi.group_id,
                                            "poi_data": geopoi.poi_data,
                                            "type": "Feature",
                                            "properties": {
                                                ...geopoi.poi_data.properties,
                                                "shape": "rectangle"
                                            },
                                            "geometry": {
                                                "type": "Polygon",
                                                "coordinates": geopoi.poi_data.geometry.coordinates
                                            }
                                        }
                                        onEdited(select)
                                        map.fitBounds(geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                            return [rectangleGeo[1], rectangleGeo[0]];
                                        }), {
                                            "animate": true,
                                            "duration": 1,
                                        })
                                    }
                                }}
                                bounds={
                                    geopoi.poi_data.geometry.coordinates[0].map((rectangleGeo) => {
                                        return [rectangleGeo[1], rectangleGeo[0]];
                                    })
                                }
                            />
                        )}

                    </React.Fragment>
                ))
            }
        </>
    )
});