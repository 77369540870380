import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box'
import { Grid, TextField, Stack } from '@mui/material'

import { TableVirtuoso } from 'react-virtuoso';
import { useEffect } from 'react';
import { useRef } from 'react';
import { Typography } from '@mui/material';
import { fDateTo } from '../../utils/formatTime';

import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ImageNotSupportedRoundedIcon from '@mui/icons-material/ImageNotSupportedRounded';
import HideImageIcon from '@mui/icons-material/HideImage';

import LoGoBLUETECH from './LOGO-BLUETECH.jpg'


import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import _ from 'lodash';
import { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import axios from 'axios';
import { Api } from '../../components/api';

const columns = [
  {
    width: 50,
    label: 'No.',
    dataKey: 'id',
    id: true,
    filter: false
  },
  {
    width: 190,
    label: 'วันที่',
    dataKey: 'triptime_start',
    datetime: true,
    filter: false
  },
  {
    width: 110,
    label: 'เลขที่ใบงาน',
    dataKey: 'tripid',
    filter: true
  },
  {
    width: 110,
    label: 'เลขอ้างอิง',
    dataKey: 'refcode',
    filter: true
  },
  {
    width: 110,
    label: 'ทะเบียนรถ',
    dataKey: 'vehicle_id',
    align: "right",
    filter: true
  },
  {
    width: 60,
    label: 'ประเภท',
    dataKey: 'type',
    align: "center",
    filter: false
  },

  {
    width: 160,
    label: 'Odometer (Km)',
    dataKey: 'odometer_start',
    align: "center",
    filter: false,
    odometer: (v) => { return v / 1000 }
  },
  {
    width: 60,
    label: 'SOC',
    dataKey: 'soc_start',
    filter: false,
    align: "center"
  },
  {
    width: 120,
    label: 'น้ำหนักรวม',
    dataKey: 'weight_total',
    filter: false,
    align: "right",
    unit: "(kg)"
  },
  {
    width: 120,
    label: 'น้ำหนักรถ',
    dataKey: 'weight_car',
    filter: false,
    align: "right",
    unit: "(kg)"
  },
  {
    width: 120,
    label: 'น้ำหนักสุทธิ',
    dataKey: 'weight_net',
    filter: false,
    align: "right",
    sum: true,
    unit: "(kg)"
  },
  // {
  //   width: 80,
  //   label: '',
  //   dataKey: 'menu',
  //   filter: false,
  //   align: "center",
  //   menu: true
  // }
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};


export default function OrdersTable({ search = '', rows = [], filterType = [] }) {


  useEffect(() => {
    ScrollToTop()
  }, [search])




  const ScrollToTop = () => {
    ref.current.scrollToIndex({
      index: 0,
      align: "start",
      behavior: "auto"
    });
  }

  const [dataKey, setDatakey] = React.useState("vehicle_id")
  const ref = useRef(null)


  // const handleClickMore = (event) => {
  //     setAnchorElMore(event.currentTarget);
  // };
  // const handleCloseMore = () => {
  //     setAnchorElMore(null);
  // };
  const MenuMoreTable = ({ row }) => {
    const [anchorEls, setAnchorEls] = React.useState(null);
    const handleActionClick = (event) => {
      setAnchorEls(event.currentTarget);
    }
    const handleActionClose = () => {
      setAnchorEls(null);
    }

    return (
      <>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={e => handleActionClick(e)}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={row.id}
          anchorEl={anchorEls}
          keepMounted
          open={Boolean(anchorEls)}
          onClose={e => handleActionClose()}
        >
          <MenuItem onClick={e => handleActionClose()}> ปิดงาน </MenuItem>
          <MenuItem onClick={e => handleActionClose()}> ยกเลิก </MenuItem>
        </Menu>
      </>
    )



  }
  const handelChangeKey = (eKey) => {

    ScrollToTop()
    setDatakey(eKey)
  }

  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const handleClickOpen = (tripid) => {
    Api.get(`/TMS/Trip?tripid=${tripid}`)
      .then(res => {
        setData(res.data)
      }
      )
    setOpen(true);
    // setData(data)
  };

  const handleClose = () => {
    setOpen(false);
    setData(null)
  };


  function OrderTableHead() {
    return (
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            // align={column.numeric || false ? 'right' : 'left'}
            align={column.align || "left"}
            style={{
              width: column.width,
              cursor: column.filter ? "pointer" : "unset",
              textDecoration: column.dataKey === dataKey ? "underline" : "",
              whiteSpace: "nowrap"

            }}
            onClick={() => {
              if (column.filter) {
                handelChangeKey(column.dataKey)
              }
            }}
            sx={{
              backgroundColor: 'background.paper',
            }}
          >
            {column.label} {column.unit}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  function RowContent(_index, row) {
    return (
      <React.Fragment>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            align={column.align || "left"}
            onClick={() => handleClickOpen(row.tripid)}
            sx={{ cursor: "pointer" }}
          >
            {
              column.menu ?
                <>
                  <MenuMoreTable row={row} />
                  {/* <Button size="small" variant="contained" >ปิด</Button> */}
                </>
                :
                column.id ?
                  _index + 1
                  :
                  column.datetime ? fDateTo(row[column.dataKey], 'dd/MM/yyyy HH:mm:ss')
                    :
                    column.odometer ? column.odometer(row[column.dataKey]).toLocaleString("th-TH", { minimumFractionDigits: 0, timeZone: 'UTC' })
                      :
                      row[column.dataKey].toLocaleString("th-TH", { minimumFractionDigits: 0, timeZone: 'UTC' })
            }
          </TableCell>
        ))}

      </React.Fragment>
    );
  }
  function NoContent(_index, row) {
    return (
      <React.Fragment>
        <TableCell
          align="center"
          colSpan={8}
        >
          กรุณาค้นหาข้อมูล
        </TableCell>

      </React.Fragment>
    );
  }

  const filterValue = (arr = [], filter = []) => {

    return arr.map(_arr => {
      if (filter.find(filter => filter.type === _arr.type)) {
        return _arr
      }
      return
    }).filter(f => f !== undefined)
  }



  const filterRows = _.orderBy(filterValue(rows.filter(val => val[dataKey].toString().toUpperCase().includes(search.toUpperCase())), filterType), ["triptime_start"], ["asc"])

  function OrderTableFooter() {
    return (
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.dataKey}
            variant="head"
            align={column.align || "left"}
            style={{ width: column.width }}
            sx={{
              backgroundColor: 'background.paper',
              whiteSpace: "nowrap"
            }}
          >
            {column.sum ? (filterRows.map(bill => bill[column.dataKey]).reduce((acc, amount) => acc + amount, 0)).toLocaleString("th-TH", { minimumFractionDigits: 0 }) : ''} {column.sum && column.unit}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function printDiv() {
    handlePrint()
  }

  const getTimeDiff = (sDate, eDate) => {
    if (sDate === null) {
      return ``
    }
    if (eDate === null) {
      return ``
    }
    var diff = new Date(eDate).getTime() - new Date(sDate).getTime();
    var msec = diff;
    var hh = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    let seconds = Math.floor(msec / 1000);
    let sec = seconds % 60;

    return `${hh !== 0 ? hh + ' ชั่วโมง' : ''} ${mm} นาที ${sec !== 0 ? sec + ' วินาที' : ''}`
  }


  const getImg = (event_id) => {
    if (data.trip_Tran[0].work[0].tms_event.filter(v => v.event_id === event_id).length > 0) {
      return data.trip_Tran[0].work[0].tms_event.filter(v => v.event_id === event_id)
    } else {
      return ["", ""]
    }
  }

  return (
    <Paper style={{ flex: '1 1 auto', height: "calc(100vh - 110px)" }}>
      <TableVirtuoso
        data={filterRows}
        components={VirtuosoTableComponents}
        fixedHeaderContent={OrderTableHead}
        itemContent={filterRows.length === 0 ? NoContent : RowContent}
        fixedFooterContent={OrderTableFooter}
        ref={ref}
      />
      {
        data !== null && (
          <Dialog
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>
              <Stack spacing={1} direction="row">
                <Typography>ข้อมูลงาน</Typography>
                {/* <Button size="small">แก้ไข</Button>
            <Button size="small">ยกเลิก</Button> */}
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Box
                // noValidate
                // component="form"
                ref={componentRef}
                sx={{
                  background: "white",
                  paddingX: "0.5cm",
                  paddingTop: "1cm"
                }}
                className="section-to-print"
              // elevation={0} minHeight="280mm" overflow="hidden"
              >
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <Box component="img" src={LoGoBLUETECH} sx={{ width: "80px" }} />
                </Box>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <Typography variant="caption" >โครงการนิคมอุตสาหกรรม ฉะเชิงเทราบลูเทคซิตี้</Typography>
                </Box>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <Typography variant="h5" >ใบงาน</Typography>
                </Box>
                <Box>
                  <Stack spacing={1} direction="row" sx={{ justifyContent: "space-between", display: "flex" }}>
                    <Typography fontSize="16px" >{`เลขที่ใบงาน ${data.tripid}`}</Typography>
                    <Typography fontSize="16px" >{`เลขอ้างอิง ${data.refcode}`}</Typography>
                  </Stack>
                </Box>
                <Box>
                  <Stack spacing={1} direction="row" sx={{ justifyContent: "space-between", display: "flex" }}>
                    <Typography fontSize="16px" >{`ทะเบียนรถ ${data.trip_Tran[0].work[0].vehicle_id}`}</Typography>
                    <Typography fontSize="16px" >{`ชื่อพนักงานขับ ${data.trip_Tran[0].work[0].driver_name}`}</Typography>
                  </Stack>
                </Box>
                <Box>
                  <Box sx={{ justifyContent: "center", display: "flex", marginY: "10px" }}>
                    <Typography variant="h6" >เริ่มงาน</Typography>
                  </Box>
                  <Box>
                    <TableContainer >
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ border: 1, borderRight: 0, textAlign: "center" }} width={200}>วันที่เริ่ม</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 0, textAlign: "center" }} width={200}>ค่าพลังงาน (%)</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 1, textAlign: "center" }} width={200}>มาตรวัดระยะทาง(km)</TableCell>
                          <TableCell sx={{ border: 1, borderLeft: 0, textAlign: "center" }} width={200}>น้ำหนักรถ(Kg)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ border: 1, borderRight: 0, borderTop: 0, textAlign: "center" }}>{`${data.trip_Tran[0].work[0].worktime_start !== null ? fDateTo(data.trip_Tran[0].work[0].worktime_start, "dd/MM/yyyy HH:mm:ss") : ""}`}</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 0, borderTop: 0, textAlign: "center" }}>{`${data.trip_Tran[0].work[0].soc_start}`}</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 1, borderTop: 0, textAlign: "center" }}>{`${(data.trip_Tran[0].work[0].odometer_start / 1000).toLocaleString()}`}</TableCell>
                          <TableCell sx={{ border: 1, borderLeft: 0, borderTop: 0, textAlign: "center" }}>{`${(data.trip_Tran[0].weight_net).toLocaleString()}`} </TableCell>
                        </TableRow>
                      </TableBody>
                    </TableContainer>
                  </Box>
                  <Box
                    sx={{ marginTop: "10px" }}
                  >
                    <Stack spacing={1} direction="row" sx={{ justifyContent: "space-around", display: "flex" }}>
                      {
                        getImg("IMG0").map((url, index) => {
                          return (
                            url !== "" ? (
                              <Box component="img" key={"S" + index} src={url.event_value} sx={{ borderWidth: "1px", borderColor: "#F1F5F9", borderStyle: "solid", borderRadius: "10px", aspectRatio: 16 / 9, width: "30%" }} />
                            )
                              :
                              <Box key={"S" + index} sx={{ borderWidth: "1px", borderColor: "#F1F5F9", borderStyle: "solid", borderRadius: "10px", aspectRatio: 16 / 9, width: "30%", height: "138px" }} >
                                <Box sx={{ margin: "auto", justifyContent: "center", display: "flex" }}>
                                  <Stack sx={{ marginTop: "20px" }}>
                                    <HideImageIcon sx={{ fontSize: "60px", margin: "auto" }} />
                                    <Typography variant="h5" >NO IMAGE AVAILABLE</Typography>
                                  </Stack>
                                </Box>
                              </Box>
                          )
                        }
                        )
                      }
                      {/* <Box component="img" src={data.imgUrl} sx={{ borderWidth: "1px", borderColor: "#F1F5F9", borderStyle: "solid", borderRadius: "10px", aspectRatio: 16 / 9, width: "30%" }} /> */}
                    </Stack>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                  <Box sx={{ justifyContent: "center", display: "flex", marginY: "10px" }}>
                    <Typography variant="h6" >จบงาน</Typography>
                  </Box>
                  <Box>
                    <TableContainer>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ border: 1, borderRight: 0, textAlign: "center" }} width={200}>วันที่จบ</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 0, textAlign: "center" }} width={200}>ค่าพลังงาน (%)</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 1, textAlign: "center" }} width={200}>มาตรวัดระยะทาง(km)</TableCell>
                          <TableCell sx={{ border: 1, borderLeft: 0, textAlign: "center" }} width={200}>น้ำหนักรถ(Kg)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ border: 1, borderRight: 0, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? data.trip_Tran[0].work[0].worktime_end !== null ? fDateTo(data.trip_Tran[0].work[0].worktime_end, "dd/MM/yyyy HH:mm:ss") : "" : ""}`}</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 0, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? data.trip_Tran[0].work[0].soc_end : ""}`}</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 1, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? (data.trip_Tran[0].work[0].odometer_end / 1000) > 0 ? (data.trip_Tran[0].work[0].odometer_end / 1000).toLocaleString() : "" : ""}`}</TableCell>
                          <TableCell sx={{ border: 1, borderLeft: 0, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? (data.trip_Tran[0].weight_net).toLocaleString() : ""}`} </TableCell>
                        </TableRow>
                      </TableBody>
                    </TableContainer>
                  </Box>
                  <Box
                    sx={{ marginTop: "10px" }}
                  >
                    <Stack spacing={1} direction="row" sx={{ justifyContent: "space-around", display: "flex" }}>
                      {
                        getImg("IMG1").map((url, index) => {
                          return (
                            url !== "" ? (
                              <Box component="img" key={"E" + index} src={url.event_value} sx={{ borderWidth: "1px", borderColor: "#F1F5F9", borderStyle: "solid", borderRadius: "10px", aspectRatio: 16 / 9, width: "30%" }} />
                            )
                              :
                              <Box key={"E" + index} sx={{ borderWidth: "1px", borderColor: "#F1F5F9", borderStyle: "solid", borderRadius: "10px", aspectRatio: 16 / 9, width: "30%", height: "138px" }} >
                                <Box sx={{ margin: "auto", justifyContent: "center", display: "flex" }}>
                                  <Stack sx={{ marginTop: "20px" }}>
                                    <HideImageIcon sx={{ fontSize: "60px", margin: "auto" }} />
                                    <Typography variant="h5" >NO IMAGE AVAILABLE</Typography>
                                  </Stack>
                                </Box>
                              </Box>
                          )
                        }
                        )
                      }
                      {/* <Box component="img" src={data.imgUrl} sx={{ borderWidth: "1px", borderColor: "#F1F5F9", borderStyle: "solid", borderRadius: "10px", aspectRatio: 16 / 9, width: "30%" }} />
                  <Box component="img" src={data.imgUrl} sx={{ borderWidth: "1px", borderColor: "#F1F5F9", borderStyle: "solid", borderRadius: "10px", aspectRatio: 16 / 9, width: "30%" }} /> */}
                    </Stack>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "5px" }}>
                  <Box sx={{ justifyContent: "center", display: "flex", marginY: "10px" }}>
                    <Typography variant="h6" >สรุปงาน</Typography>
                  </Box>
                  <Box>
                    <TableContainer>
                      <TableHead>
                        <TableRow >
                          <TableCell sx={{ border: 1, borderRight: 0, textAlign: "center" }} width={200}>ใช้เวลา</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 0, textAlign: "center" }} width={200}>ใช้พลังงาน (%)</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 1, textAlign: "center" }} width={200}>ระยะทาง(km)</TableCell>
                          <TableCell sx={{ border: 1, borderLeft: 0, textAlign: "center" }} width={200}>น้ำหนักรถ(Kg)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell sx={{ border: 1, borderRight: 0, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? getTimeDiff(data.trip_Tran[0].work[0].worktime_start, data.trip_Tran[0].work[0].worktime_end) : ""}`}</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 0, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? (data.trip_Tran[0].work[0].soc_start - data.trip_Tran[0].work[0].soc_end) > 0 ? (data.trip_Tran[0].work[0].soc_start - data.trip_Tran[0].work[0].soc_end).toLocaleString() : "" : ""}`}</TableCell>
                          <TableCell sx={{ border: 1, borderRight: 1, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? data.trip_Tran[0].work[0].odometer_end > data.trip_Tran[0].work[0].odometer_start ? ((data.trip_Tran[0].work[0].odometer_end - data.trip_Tran[0].work[0].odometer_start) / 1000).toLocaleString() : "" : ""}`}</TableCell>
                          <TableCell sx={{ border: 1, borderLeft: 0, borderTop: 0, textAlign: "center" }}>{`${data.type === "F" ? (data.trip_Tran[0].weight_net).toLocaleString() : ""}`}</TableCell>
                        </TableRow>
                      </TableBody>
                    </TableContainer>
                  </Box>
                </Box>
              </Box>
            </DialogContent>
            <DialogActions>

              <Button onClick={() => { printDiv("print") }}>พิมพ์</Button>
              {/* <Button >ลบ</Button> */}

            </DialogActions>
          </Dialog>
        )
      }
    </Paper >
  );
}
