import { Box, Container, Divider, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React, { useEffect, useState } from 'react'
import { Api } from '../components/api';
import Page from '../components/Page';
import { fDateTimeThai } from '../utils/formatTime';
const TABLE_HEAD = [
  { id: 'unit_id', label: 'UNIT ID' },
  { id: 'vehicle_id', label: 'ป้ายทะเบียน' },
  { id: 'vehicle_type', label: 'ยี่ห้อรถ' },
  { id: 'vehicle_chassis_no', label: 'เลขคัสซี' },
  { id: 'vehicle_register_type', label: 'ประเภทรถ' },
  { id: 'card_reader', label: 'เครื่องอ่านฯ' },
  { id: 'province_code', label: 'จังหวัด' },
  { id: 'data_status', label: 'สถานะ' },
  { id: 'log_time ', label: 'Time Log' }
];
const MainStyle = styled(Box)(({ theme }) => ({
  margin: 'auto',
  paddingBottom: 60,
  overflow: 'auto'
}));
export default function Dlt({ account }) {
  const [dltDevices, setDltDevices] = useState([])
  const getDeviceList = () => {
    // setLoading(true);
    Api.get(`v0/gps/masterfile_dlts?cocode=${account.cocode}&skip=0&take=999999`)
      .then(res => {
        setDltDevices(res.data.results);
      }
      )
    // setLoading(false);
  }

  useEffect(() => {
    getDeviceList();
  }, [])
  return (
    <Page>
      <Container maxWidth={false} style={{ padding: 0 }}>
        <Box flexGrow={1} padding={1}>
          <Typography variant="h4">DLT</Typography>
        </Box>
        <Divider />
        <MainStyle>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {
                    TABLE_HEAD.map((headCell, index) => {
                      return (
                        <TableCell id={headCell.id} align="left" key={index} >
                          <Typography variant="subtitle1" noWrap>
                            {headCell.label}
                          </Typography>
                        </TableCell>
                      )
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {dltDevices
                  .map((row) => {
                    const {
                      unit_id, vehicle_id, vehicle_chassis_no, vehicle_type, vehicle_register_type,
                      card_reader, province_code, data_status, log_time
                    } = row;
                    return (
                      <TableRow
                        hover
                        key={unit_id}
                        tabIndex={-1}
                        role="checkbox"
                      >
                        <TableCell component="th" scope="row" padding="normal">
                          <Typography variant="subtitle2" noWrap>{unit_id}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>{vehicle_id}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap> {vehicle_type}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>{vehicle_chassis_no}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>{vehicle_register_type}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>{card_reader}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>{province_code}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography noWrap>{data_status}</Typography>
                        </TableCell>
                        <TableCell align="center"
                        ><Typography noWrap>{fDateTimeThai(log_time)}</Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </MainStyle>
      </Container>
    </Page>
  )
}
