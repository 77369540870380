import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { Typography } from '@mui/material';
import './style.css'
const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#784af4',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    // ...(ownerState.active && {
    //     color: '#784af4',
    // }),
    color: '#784af4',
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 15,
        height: 15,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {/* {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )} */}
            <div className="QontoStepIcon-circle" />
        </QontoStepIconRoot>
    );
}

// const steps = [
//     { name: '1' },
//     { name: '2' },
//     { name: '3' },
//     { name: '4' },
//     { name: '5' },
//     { name: '6' },
//     { name: '7' },
//     { name: '8' },
//     { name: '9' },
//     { name: '10' },
//     { name: '11' },
//     { name: '12' },
//     { name: '13' },
//     { name: '14' },
//     { name: '15' },
//     { name: '16' },
//     { name: '17' },
//     { name: '18' },
//     { name: '19' },
// ];

export default function StepperRoute({ stations = [] }) {
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper alternativeLabel activeStep={stations.length - 1} connector={<QontoConnector />}>
                {stations.map((label, index) => (
                    <Step key={index}>
                        <StepLabel style={{ overflow: "hidden", textOverflow: "ellipsis", width: '13rem' }} StepIconComponent={QontoStepIcon}><Typography noWrap>{label.name}</Typography></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}
