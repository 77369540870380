import { atom } from 'recoil';
// import { UseBase64 as _base64 } from '../_component/_crypto/_base64';


// const base64 = _base64();
const authAtom = atom({
    key: 'auth',
    default: localStorage.getItem('user') !== null
    // default: localStorage.getItem('user') == null ? false : JSON.parse(base64.decode(localStorage.getItem('user'))) != null
});

export { authAtom };