import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
// material
import { styled, alpha } from '@mui/material/styles';
import { Box, Stack, TextField, InputAdornment, Slide, Dialog, Paper, AppBar, Toolbar, IconButton, Typography, Drawer, useMediaQuery, Backdrop, Snackbar, Button } from '@mui/material';
import ContainerMapDash from './ContainerMapDash';
import ControlSideDash from './ControlSideDash';
import { MHidden } from '../../components/@material-extend';
import BottomNavigationBar from '../../layouts/BottomNavigationBar';
import { Api } from '../../components/api';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';


import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { restConfig, useDevicesSub, useStationDashboard } from './RefreshInterval';
import MenuGroup from './MenuGroup';
import CardDetail from './CardDetail';
import ContainerDash from './ContainerDash';

import MapRoundedIcon from '@mui/icons-material/MapRounded';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { groupSelectStorage, groupsStorage, mapStorage, selectDeviceStorage, subscribeStorage } from './DashStorage';
import PlaylistEditCustom from './PlaylistDash/PlaylistEditCustom';



export default function MainDashboard({ account, userId, permissions }) {
    const { c_coname, cocode } = account;



    const [group, setGroup] = useRecoilState(groupsStorage);


    const [open, setOpen] = useState(false);
    const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    // const [map, setMap] = useRecoilState(mapStorage); 
    const [map, setMap] = useState(null);
    const refMapStyle = useRef(null);
    const [selectedMapStyle, setSelectedMapStyle] = useState(localStorage.getItem("selectedMapStyle") || "LongdoMap");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openBackdropLoad, setOpenBackdropLoad] = useState(false);
    const [fristLoad, setFristLoad] = useState(true);
    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleCloseBackdropLoad = () => {
        setOpenBackdropLoad(false);
    };
    const [search, setSearch] = useState('');
    const [groupSelect, setGroupSelect] = useRecoilState(groupSelectStorage);
    // const [groupSelect, setGroupSelect] = useState(null);

    const handleChange = (event) => {
        const { value } = event.target
        setSelectDevice('')
        setSearch(value)
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClickOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
        // setSearchGroup("")
    };
    const handleClickCloseMenu = () => {
        setAnchorEl(null);
        // setSearchGroup("")
    };
    const handleClickMenuGroup = ({ subs, groupId }) => {
        // console.log('handleSubscribe', { subs, groupId })
        mutate([])
        isCancel()
        // setSubscribe([])
        setTimeout(() => {
            setAnchorEl(null);
            setSubscribe(subs)
            setGroupSelect(groupId)
            getGroupRoute(groupId)
            if (subs.length !== 0) {
                setOpenBackdrop(true)
            } else {
                setOpenBackdrop(false)
            }
        }, 50)
    };

    const handleSubscribe = ({ subs, groupId }) => {
        setSubscribe(subs)
        setGroupSelect(groupId)
        setGroupRoute([])
        getGroupRoute(groupId)
        isCancel()
        if (subs.length !== 0) {
            setOpenBackdrop(true)
        }
    };
    const handleSubscribeCustom = ({ subs, groupId }) => {
        setSubscribe(subs)
        setGroupSelect(groupId)
        setGroupRoute([])
        isCancel()
        if (subs.length !== 0) {
            setOpenBackdrop(true)
        }
    };

    const [groupRoute, setGroupRoute] = useState([])
    const getGroupRoute = (_group) => {
        if (_group?.group_id) {
            Api.get(`/Tracking/GroupRoute?group_id=${_group?.group_id}`).then((res) => {
                var data = res.data.routes;
                setGroupRoute(data);
                // setLoading(false)
            }
            ).catch(e => {
                console.log('error', e)
            })
        }
    }


    const [mapView, setMapView] = useState(
        localStorage.getItem("MapView") === "false" ? false : true
    )

    const handleClickDashboard = (event) => {
        const up = !mapView
        setMapView(up)
        localStorage.setItem("MapView", String(up))
        // if (!up) {
        //     setOpenBackdrop(true)
        // }
        onClickCloseSideDash()
        // setOpenBackdrop(true)
    };

    const [routeMapShow, setRouteMapShow] = useState(localStorage.getItem("routeMapShow") === "false" ? false : true)
    const [busStopMapShow, setBusStopMapShow] = useState(localStorage.getItem("busStopMapShow") === "false" ? false : true)
    const [stationsMapShow, setStationsMapShow] = useState(localStorage.getItem("stationsMapShow") === "false" ? false : true)


    const [subscribe, setSubscribe] = useRecoilState(subscribeStorage)
    // const [subscribe, setSubscribe] = useState([])
    const [int, setInt] = useState(5000)
    const [selectDevice, setSelectDevice] = useRecoilState(selectDeviceStorage)
    const [isClickZoom, setIsClickZoom] = useState(false)

    // const [selectDevice, setSelectDevice] = useState('')
    const onClickSelectDivce = ({ device, latitude, longitude, isZoom }) => {
        setIsClickZoom(isZoom)
        setSelectDevice('')
        setTimeout(() => {
            setSelectDevice(device)
            if (!mapView) {
                setMapView(!mapView)
                localStorage.setItem("MapView", String(!mapView))
            }
        }, 20);

    }
    const onClickCloseSideDash = () => {
        setSelectDevice('')
    }

    const { devicesSub, isLoading, isError, isCancel, selectSub, mutate } = useDevicesSub({
        urls: subscribe.length > 0 ? `/Tracking/DevicesByList` : ``,
        // urls: subscribe.length > 0 && mapView ? `/Tracking/DevicesByList` : ``,
        args: subscribe,
        userId: userId,
        setBackdrop: setOpenBackdrop,
        setInt: setInt,
        options: { refreshInterval: int, revalidateOnFocus: true },
        select: selectDevice
    })
    // 
    const filter = devicesSub.filter((val) => val.name.toLocaleUpperCase().includes(search.toLocaleUpperCase()))





    const onClickAll = () => {

        // setSubscribe([])
        setGroupRoute([])
        setTimeout(() => {
            Api.get(`/Tracking/DevicesSearch?userid=${userId}&search=*`).then((res) => {
                const list = res.data;
                const _listDevice = list.map(v => v.device)
                handleClickMenuGroup({ subs: _listDevice, groupId: { group_id: '', cname: '' } })
            }).catch(e => {
                console.log('error', e)
            })

        }, 50);


    }



    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const handleSnackWithAction = ({ message, handle }) => {
        enqueueSnackbar(message, {
            anchorOrigin: {
                horizontal: "center",
                vertical: 'bottom'
            },
            variant: 'default',
            action: (key) => (
                <>
                    <Button
                        size='small'
                        color="error"
                        onClick={() => {
                            closeSnackbar(key)
                            handle()
                        }}
                    >
                        Yes
                    </Button>
                    <Button size='small' color="success" onClick={() => closeSnackbar(key)}>
                        Dismiss
                    </Button>
                </>
            )
        });
    };
    const handleSnackbar = ({ message, variant }) => {
        enqueueSnackbar(message,
            {
                autoHideDuration: 1000,
                anchorOrigin: {
                    horizontal: "right",
                    vertical: 'bottom'
                },
                variant: variant
            });
    }



    const handleDelCustomGroup = (group_id) => {
        Api.delete(`/Tracking/GroupCustom?userid=${userId}&group_id=${group_id}`)
            .then(res => {
                handleSnackbar({ variant: 'success', message: 'success!' })
                Api.get(`Tracking/GroupByUser?userid=${userId}`)
                    .then(res => {
                        var list = res.data;
                        setGroup(list)
                    }
                    ).catch(e => {
                        console.log('error', e)
                    })
            }
            ).catch(error => {
                handleSnackbar({ variant: 'error', message: error.response.data.Message });
            }
            )
    };



    // 
    const { subStations } = useStationDashboard({
        urls: groupSelect?.cname && groupSelect?.group_id && stationsMapShow ? `/Tracking/StationDashboard?cname=${groupSelect?.cname}&group_id=${groupSelect?.group_id}` : ``,
        // setBackdrop: setOpenBackdrop,
        options: { refreshInterval: int, revalidateOnFocus: true },
        setInt: setInt,
    })



    const [openCustom, setOpenCustom] = useState(false)
    const [editGroupList, setEditGroupList] = useState([])
    const [editGroup, setEditGroup] = useState(null)

    const handleEditGroup = (isGroup) => {
        const { device } = isGroup
        setEditGroup(isGroup)
        handleClickCloseMenu()
        setOpenBackdropLoad(true)
        // Api.post(url, req, { cancelToken: ourRequest.token })
        const req = { userId: userId, list: device }
        Api.post(`/Tracking/DevicesByList`, req).then((res) => {
            const _list = res.data;
            const list = _list.map(v => {
                return { device: v.device, name: v.name }
            })
            setOpenCustom(!openCustom)
            setEditGroupList(list)
            setOpenBackdropLoad(false)
        }).catch(e => {
            console.log('error', e)
        })
    }


    const onEditSubmit = (sub) => {

        const { group_id, group_name, cname } = editGroup
        const data = JSON.stringify({
            userid: userId,
            group_id: group_id,
            group_name: group_name,
            devices: sub
        });
        setOpenBackdropLoad(true)
        Api.delete(`/Tracking/GroupCustom?userid=${userId}&group_id=${group_id}`)
            .then(res => {
                Api.post('/Tracking/GroupCustom', data)
                    .then(res => {
                        handleSubscribeCustom({ subs: sub, groupId: { group_id: group_id, cname: cname } })
                        setEditGroup(null)
                        setOpenBackdropLoad(false)
                        // handleClickMenuGroup({ subs: sub, groupId: { group_id: group_id, cname: cname } })
                        GetGroup()
                        setOpenCustom(!openCustom)
                        handleSnackbar({ variant: 'success', message: 'success!' })

                    })
                    .catch(e => {
                        console.log('error', e)
                        // handleSnackbar({ variant: 'error', message: error.response.data.Message });
                    })
            })
            .catch(error => {
                handleSnackbar({ variant: 'error', message: error.response.data.Message });
                setOpenBackdropLoad(false)
            })


    }


    const GetGroup = () => {
        Api.get(`Tracking/GroupByUser?userid=${userId}`)
            .then(res => {
                var list = res.data;
                setGroup(list)
            })
            .catch(e => {
                console.log('error', e)
            })
    }



    useEffect(() => {
        // console.log('subscribe', subscribe)
        if (subscribe.length === 0 && fristLoad) {
            setOpenBackdrop(true)
            Api.get(`Tracking/GroupByUser?userid=${userId}`)
                .then(res => {
                    var list = res.data;
                    setGroup(list)
                    if (list.length > 0) {

                        const { device, group_id, cname } = list[0]
                        const _isGroup = { group_id: group_id, cname: cname }
                        setSubscribe(device || [])
                        setGroupSelect(_isGroup)
                        getGroupRoute(_isGroup)
                    } else {
                        setOpenBackdrop(false)
                    }
                })
                .catch((error) => {
                    console.log('error', error)
                    setOpenBackdrop(false)
                })
            setOpenBackdrop(false)
            setFristLoad(false)
        }
    }, [])

    const sideDashSize = localStorage.getItem("sideDashSize") ? JSON.parse(localStorage.getItem("sideDashSize")) : { x: 400, y: 0 }

    const [size, setSize] = useState(sideDashSize);
    // const [size, setSize] = useState({ x: 400, y: 0 });

    const handler = (mouseDownEvent) => {
        const startSize = size;
        const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };
        function onMouseMove(mouseMoveEvent) {
            setSize(currentSize => ({
                x: startSize.x - startPosition.x + mouseMoveEvent.pageX,
                y: startSize.y - startPosition.y + mouseMoveEvent.pageY
            }));
            localStorage.setItem("sideDashSize", JSON.stringify({
                x: startSize.x - startPosition.x + mouseMoveEvent.pageX,
                y: startSize.y - startPosition.y + mouseMoveEvent.pageY
            }))
        }

        function onMouseUp() {
            document.body.removeEventListener("mousemove", onMouseMove);
        }

        document.body.addEventListener("mousemove", onMouseMove);
        document.body.addEventListener("mouseup", onMouseUp, { once: true });

    };


    const sideDashMode = localStorage.getItem("sideDashMode") ? localStorage.getItem("sideDashMode") : "widget"
    const [sideDash, setSideDash] = useState(sideDashMode)

    return (
        <RootStyle>
            <AppBarStyle elevation={3}>
                <ToolbarStyle>
                    <IconButton onClick={() => setOpen(!open)} sx={{ mr: 1, color: 'text.primary' }}>
                        <MenuRoundedIcon />
                    </IconButton>
                </ToolbarStyle>
            </AppBarStyle>
            <DrawerStyle width={size.x} >
                <MHidden width="mdUp">
                    <Drawer
                        open={open}
                        onClose={() => setOpen(!open)}
                        // variant="permanent"
                        PaperProps={{
                            sx: { width: 400, padding: "10px" }
                        }}
                    >
                        <ControlSideDash
                            mapView={mapView}
                            handleClickDashboard={handleClickDashboard}
                            handleChange={handleChange}
                            handleClickOpenMenu={handleClickOpenMenu}
                            search={search}
                            devicesSub={devicesSub}
                            filter={filter}
                            subscribe={subscribe}
                            group={group}
                            groupSelect={groupSelect}
                            onClickSelectDivce={onClickSelectDivce}
                            userId={userId}
                            handleSubscribe={handleSubscribe}
                            account={account}
                            onGetGroup={GetGroup}

                            handleSubscribeCustom={handleSubscribeCustom}

                            setSideDash={setSideDash}
                            sideDash={sideDash}
                        />
                    </Drawer>
                </MHidden>
                <MHidden width="mdDown">
                    <Drawer
                        open={true}
                        variant="permanent"
                        PaperProps={{
                            sx: { width: size.x, height: 'calc(100% - 56px)', bottom: 56, padding: '10px' }
                        }}
                    >
                        <ControlSideDash
                            mapView={mapView}
                            handleClickDashboard={handleClickDashboard}
                            handleChange={handleChange}
                            handleClickOpenMenu={handleClickOpenMenu}
                            search={search}
                            devicesSub={devicesSub}
                            filter={filter}
                            subscribe={subscribe}
                            group={group}
                            groupSelect={groupSelect}
                            onClickSelectDivce={onClickSelectDivce}
                            userId={userId}
                            handleSubscribe={handleSubscribe}
                            account={account}
                            onGetGroup={GetGroup}

                            handleSubscribeCustom={handleSubscribeCustom}

                            setSideDash={setSideDash}
                            sideDash={sideDash}
                        />

                        <Paper sx={{ position: 'fixed', width: size.x, bottom: 0, left: 0, right: 0, zIndex: 2 }} elevation={3}>
                            <BottomNavigationBar ative="map" />
                        </Paper>
                    </Drawer>
                </MHidden>
            </DrawerStyle>
            <div id="container" style={{ width: 4, backgroundColor: "whitesmoke", cursor: "col-resize" }} onMouseDown={handler} />
            <MainStyle>
                <ContainerMapDash
                    map={map}
                    setMap={setMap}
                    refMapStyle={refMapStyle}
                    selectedMapStyle={selectedMapStyle}
                    setSelectedMapStyle={setSelectedMapStyle}
                    routeMapShow={routeMapShow}
                    setRouteMapShow={setRouteMapShow}
                    busStopMapShow={busStopMapShow}
                    setBusStopMapShow={setBusStopMapShow}
                    stationsMapShow={stationsMapShow}
                    setStationsMapShow={setStationsMapShow}
                    devicesSub={devicesSub}
                    filter={mapView ? filter : []}
                    selectSub={selectSub}
                    onClickCloseSideDash={onClickCloseSideDash}
                    userId={userId}
                    onClickSelectDivce={onClickSelectDivce}

                    // display={mapView}
                    groupRoute={groupRoute}
                    subStations={subStations}

                    isClickZoom={isClickZoom}
                    setIsClickZoom={setIsClickZoom}
                    cConame={c_coname}
                    cocode={cocode}
                    menuWidth={size.x}
                    permissions={permissions}
                />
                <MHidden width="mdUp">
                    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                        <BottomNavigationBar ative="map" />
                    </Paper>
                </MHidden>
            </MainStyle>

            <MenuGroup
                anchorEl={anchorEl}
                handleClickCloseMenu={handleClickCloseMenu}
                group={group}
                handleClickMenuGroup={handleClickMenuGroup}
                groupSelect={groupSelect}
                onClickAll={onClickAll}
                handleSnackWithAction={(value) =>
                    handleSnackWithAction({
                        message: `delete ${value.group_name}?`, handle: () => {
                            handleDelCustomGroup(value.group_id)
                        }
                    })
                }
                // setSubscribe={setSubscribe}
                // setOpenBackdrop={setOpenBackdrop}
                handleDialog={handleEditGroup}
            />

            <PlaylistEditCustom
                c_coname={c_coname}
                handleSubscribe={handleSubscribe}
                openCustom={openCustom}
                handleDialogCustom={
                    () => {
                        setOpenCustom(!openCustom)
                    }
                }
                userId={userId}
                editGroupList={editGroupList}
                onEditSubmit={onEditSubmit}

            />


            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
                onClick={handleCloseBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdropLoad}
                onClick={handleCloseBackdropLoad}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog
                fullScreen
                // open={openDialog}
                open={!mapView}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                disableEnforceFocus
            >
                <RootStyle>
                    <AppBarStyle elevation={3} >
                        <ToolbarStyle>
                            <IconButton onClick={() => setOpen(!open)} sx={{ mr: 1, color: 'text.primary' }}>
                                <MenuRoundedIcon />
                            </IconButton>
                        </ToolbarStyle>
                    </AppBarStyle>
                    <DrawerStyle width={size.x} >

                    </DrawerStyle>
                    <div id="container" style={{ width: 4, backgroundColor: "whitesmoke", cursor: "col-resize" }} onMouseDown={handler}>
                    </div>
                    <MainStyle>
                        <ContainerDash
                            userId={userId}
                            setOpenBackdrop={setOpenBackdrop}
                            handleSubscribe={handleSubscribe}
                            handleClickDashboard={handleClickDashboard}
                        />
                        <MHidden width="mdUp">
                            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                                <BottomNavigationBar ative="map" />
                            </Paper>
                        </MHidden>
                    </MainStyle>
                </RootStyle>
            </Dialog>
        </RootStyle >
    )
}



























const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
        paddingTop: 66,
    }
}));


const AppBarStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    width: 0,
    maxHeight: 64,
    [theme.breakpoints.down('md')]: {
        // width: `calc(100% - ${350 + 1}px)`
        width: "100%"
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    WebkitBoxAlign: 'center',
    alignItems: 'center',
    paddingLeft: '16px',
    paddingRight: '16px',
    minHeight: '64px',
}));
const DrawerStyle = styled('div')(({ theme, width }) => ({
    [theme.breakpoints.up('md')]: {
        flexShrink: 0,
        width: width
    },
    width: 0,
    userSelect: "none"
}));

