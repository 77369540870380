import React, { useRef } from 'react'
import { Marker, Pane, Popup, Tooltip } from 'react-leaflet'
import RotatedMarker from './RotatedMarker'
import { divIcon } from 'leaflet'
const IconMarker = ({ className, icon, bearing }) => {
    return divIcon({
        className: className,
        html: `
  <img src="${icon}" width="20" class="icon-car-center" style="
  transform: rotate(${(360 - bearing)}deg);
  " />
      `,
        iconSize: [40, 40],
        iconAnchor: [19.5, 20],
        popupAnchor: [19.5, -20]
    })
}

const LabelMaker = ({ className, name }) => {

    return divIcon({
        className: className,
        html: `
        <div style="background-color: white;text-align: center;border-radius: 10px;border: 2px solid whitesmoke;height: 35px;font-weight: bold;padding-top:8px;white-space: nowrap;padding-left:8px;padding-right:8px;">${name}</div>
      `,
        iconSize: 'auto',
        // iconSize: [145, 40],
        iconAnchor: [((name.length * 9) / 2), 55],
        // popupAnchor: [70, -40]
    })
}


export default function CarMarker({ data, onClick, select }) {

    const ref = useRef(null)

    return (
        <>
            <RotatedMarker
                interactive
                icon={
                    select ?
                        IconMarker({ className: "icon-car-focus", icon: data?.icon, bearing: data?.bearing })
                        : new Date(data?.datetime) < new Date().setMinutes(new Date().getMinutes() - 5) ?
                            IconMarker({ className: 'icon-car-offline', icon: data?.icon, bearing: data?.bearing }) :
                            IconMarker({ className: 'icon-car', icon: data?.icon, bearing: data?.bearing })
                }
                position={[data?.latitude, data?.longitude]}
                rotationAngle={data?.bearing}
                rotationOrigin="center"
                eventHandlers={{
                    click: onClick,
                    
                }
                }
                ref={ref}
            >
                {/* <CarTooltip name={data.name} /> */}
            </RotatedMarker>
            <Marker
                eventHandlers={{
                    click: onClick
                }}
                icon={LabelMaker({ className: select ? 'label-maker-focus' : 'label-maker', name: data.name })}
                position={[data.latitude, data.longitude]}
            />
        </>
    )
}


const CarTooltip = ({ name }) => {

    return (
        <Tooltip permanent direction="top" offset={[0, -15]} >{name}</Tooltip>
    )
}
